import * as CryptoJS from 'crypto-js';
import dayjs from 'dayjs/esm/index.js';
import id from 'dayjs/locale/id';
import customParseFormat from 'dayjs/plugin/customParseFormat.js';
import localizedFormat from 'dayjs/plugin/localizedFormat.js';
import { toast } from 'react-toastify';
import * as utc from 'dayjs/plugin/utc';
const { VITE_ACCESS_TOKEN_KEY } = import.meta.env;

export const SetDisplayName = (value, length = 0) => {
  if (length < 1) {
    return value;
  }

  if (length > 0) {
    let newName = '';
    const splitVal = value.split(' ');
    for (let index = 0; index <= length; index++) {
      newName += !splitVal[index] ? '' : ` ${splitVal[index]}`;
    }
    return newName;
  }
};

export const UpperCaseName = (value) => {
  if (!value) {
    return value;
  }

  value = value.toLowerCase().replace(/\b[a-z]/g, function (letter) {
    return letter.toUpperCase();
  });

  return value;
};

/* alert toastify */
export const notify = (type, timeOut, message, position = false) => {
  let alertPosition = '';
  switch (position) {
    case 'top_right':
      alertPosition = toast.POSITION.TOP_RIGHT;
      break;
    case 'top_left':
      alertPosition = toast.POSITION.TOP_LEFT;
      break;
    case 'bottom_left':
      alertPosition = toast.POSITION.BOTTOM_LEFT;
      break;
    case 'bottom_right':
      alertPosition = toast.POSITION.BOTTOM_RIGHT;
      break;
    case 'bottom_center':
      alertPosition = toast.POSITION.BOTTOM_CENTER;
      break;
    default:
      alertPosition = toast.POSITION.TOP_CENTER;
      break;
  }

  if (type === 'loading') {
    return toast.loading(Boolean(message) ? message : 'default text', {
      position: alertPosition,
      close: Boolean(timeOut) ? timeOut : false,
      theme: 'colored',
      pauseOnFocusLoss: false,
    });
  }

  if (type === 'error') {
    return toast.error(Boolean(message) ? message : 'default text', {
      position: alertPosition,
      close: Boolean(timeOut) ? timeOut : false,
      theme: 'colored',
      pauseOnFocusLoss: false,
    });
  }

  if (type === 'warn') {
    return toast.warn(Boolean(message) ? message : 'default text', {
      position: alertPosition,
      close: Boolean(timeOut) ? timeOut : false,
      theme: 'colored',
      pauseOnFocusLoss: false,
    });
  }

  if (type === 'success') {
    return toast.success(Boolean(message) ? message : 'default text', {
      position: alertPosition,
      close: Boolean(timeOut) ? timeOut : false,
      theme: 'colored',
      pauseOnFocusLoss: false,
    });
  }

  if (type === 'info') {
    return toast.info(Boolean(message) ? message : 'default text', {
      position: alertPosition,
      close: Boolean(timeOut) ? timeOut : false,
      theme: 'colored',
      pauseOnFocusLoss: false,
    });
  }
};

/* compare two array */
export const getDifferenceData = (array1, array2) => {
  return array1.filter(
    (object1) => !array2.some((object2) => object1.id === object2.id)
  );
};

export const parseToStar = (value, lengthAllow = 3) => {
  if (!value) {
    return value;
  }

  const splitString = value.split('');
  let star = '';
  for (let index = 0; index <= splitString.length; index++) {
    if (star.length >= lengthAllow) {
      star += '*';
    } else {
      star += splitString[index];
    }
  }
  return star;
};

/* helper date*/
export const reformatDate = (value, format = 'YYYY-MM-DD') => {
  if (!value) {
    return value;
  }

  dayjs.extend(customParseFormat);
  dayjs.extend(localizedFormat);
  return dayjs(value).locale(id).format(format);
};

export const parseDate = (value) => {
  if (!value) {
    return value;
  }

  const now = dayjs();
  const year = now.diff(value, 'years');
  const month = now.diff(value, 'month');
  const day = now.diff(value, 'day');

  if (year > 0) {
    return `${year} Tahun`;
  }

  if (month > 0) {
    return `${month} Bulan`;
  }

  if (day > 0) {
    return `${day} Hari`;
  }
};

export const dateToObj = (value) => {
  if (!value) {
    return value;
  }

  const parseDate = dayjs(value);

  return {
    year: parseDate.get('year'),
    month: parseDate.get('month'),
    day: parseDate.get('day'),
    date: parseDate.get('date'),
    hour: parseDate.get('hour'),
    minute: parseDate.get('minute'),
    second: parseDate.get('second'),
    millisecond: parseDate.get('millisecond'),
  };
};

export const dateNowToObj = () => {
  const parseDate = dayjs();

  return {
    year: parseDate.get('year'),
    month: parseDate.get('month'),
    day: parseDate.get('day'),
    date: parseDate.get('date'),
    hour: parseDate.get('hour'),
    minute: parseDate.get('minute'),
    second: parseDate.get('second'),
    millisecond: parseDate.get('millisecond'),
  };
};

export const diffTwoDate = (start, end, wantResultTo = 'day') => {
  if (wantResultTo === 'day') {
    return dayjs(start).diff(end, 'day');
  }
  if (wantResultTo === 'week') {
    return dayjs(start).diff(end, 'week');
  }
  if (wantResultTo === 'quarter') {
    return dayjs(start).diff(end, 'quarter');
  }
  if (wantResultTo === 'month') {
    return dayjs(start).diff(end, 'month');
  }
  if (wantResultTo === 'year') {
    return dayjs(start).diff(end, 'year');
  }
  if (wantResultTo === 'hour') {
    return dayjs(start).diff(end, 'hour');
  }
  if (wantResultTo === 'minute') {
    return dayjs(start).diff(end, 'minute');
  }
  if (wantResultTo === 'second') {
    return dayjs(start).diff(end, 'second');
  }
};

export const getDateNow = () => {
  dayjs.extend(utc);
  const date = dayjs().local().format('YYYY-MM-DD HH:mm:ss');
  return date;
};

export const getDatePlusInputHours = (
  paramDate,
  hours = 1,
  format = 'YYYY-MM-DD HH:mm:ss'
) => {
  if (paramDate) {
    return dayjs(paramDate).add(hours, 'hour').format(format);
  } else {
    return dayjs().add(hours, 'hour').format(format);
  }
};

export const getDatePlusInputMinutes = (
  paramDate,
  minutes = 60,
  format = 'YYYY-MM-DD HH:mm:ss'
) => {
  if (paramDate) {
    return dayjs(paramDate).add(minutes, 'minute').format(format);
  } else {
    return dayjs().add(minutes, 'minute').format(format);
  }
};

export const getDatePlusInputSecond = (
  paramDate,
  second = 10,
  format = 'YYYY-MM-DD HH:mm:ss'
) => {
  if (paramDate) {
    return dayjs(paramDate).add(second, 'second').format(format);
  } else {
    return dayjs().add(second, 'second').format(format);
  }
};

export const setTime = (value, number = 0, type = 'second') => {
  // type = second |millisecond |minute|hour
  // number second |minute = 0 - 59
  // number millisecond = 0 - 999
  // number hour = 0 - 23

  let result = '';
  switch (type) {
    case 'millisecond':
      result = dayjs(value).millisecond(number);
      break;
    case 'second':
      result = dayjs(value).second(number);
      break;
    case 'minute':
      result = dayjs(value).minute(number);
      break;
    default:
      result = dayjs(value).hour(number);
      break;
  }
  return result;
};

export const parseDateToString = (value) => {
  const date = dayjs(value).toISOString();
  return date;
};

export const parseDateToUnixTimeStampMS = (value) => {
  const date = dayjs(value).valueOf();
  return date;
};

/* check keys on objects*/
export const checkLengthKeys = (data = {}) => {
  const parseToArr = Object.keys(data);
  if (parseToArr.length > 0) {
    return true;
  } else {
    return false;
  }
};

/* reformat to currency */
export const reformatToIDRCurrency = (value) => {
  if (!value) {
    return value;
  }

  return Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR',
    minimumFractionDigits: 0,
  }).format(value);
};

export const randomString = (length, type = 'default') => {
  let result = '';
  let characters = '';

  if (type === 'number') {
    characters = '0123456789';
  } else if (type === 'alphabet') {
    characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  } else if (type === 'random') {
    characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789#@*!&_';
  } else {
    characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  }

  const charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

/* convert data type*/

export const convertDataToNumber = (value) => {
  if (typeof value === 'string') {
    return Number(value);
  } else if (typeof value === 'number') {
    return value;
  }
};

export const convertDataToString = (value) => {
  if (typeof value === 'string') {
    return value;
  } else if (typeof value === 'number') {
    return value.toString();
  }
};

export const convertDataTypeToFloat = (value) => {
  if (typeof value === 'string') {
    return parseFloat(value);
  } else if (typeof value === 'number') {
    return value;
  }
};

/* encrypter & decrypter */
export const encryptData = (data) => {
  return CryptoJS.DES.encrypt(data, VITE_ACCESS_TOKEN_KEY, {
    mode: CryptoJS.mode.CTR,
    padding: CryptoJS.pad.Iso97971,
  }).toString();
};

export const decyrptData = (data) => {
  return CryptoJS.DES.decrypt(data, VITE_ACCESS_TOKEN_KEY, {
    mode: CryptoJS.mode.CTR,
    padding: CryptoJS.pad.Iso97971,
  }).toString(CryptoJS.enc.Utf8);
};

export const getExtensionAllowedFile = (conditon) => {
  const videoExtension = [
    'video/mpeg',
    'video/mp4',
    'video/ogg',
    'video/webm',
    'video/x-msvideo',
    'video/webm',
  ];

  const imageExtension = [
    'image/jpeg',
    'image/png',
    'image/svg+xml',
    'image/tiff',
    'image/webp',
  ];

  const wordExtension = [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ];

  const pdfExtension = ['application/pdf'];

  const pptExtension = [
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  ];

  const excelExtension = [
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ];

  if (conditon === 'image') {
    return imageExtension;
  }

  if (conditon === 'video') {
    return videoExtension;
  }

  if (conditon === 'word') {
    return wordExtension;
  }

  if (conditon === 'excel') {
    return excelExtension;
  }

  if (conditon === 'pdf') {
    return pdfExtension;
  }

  if (conditon === 'ppt') {
    return pptExtension;
  }

  // word, ppt, image, video, pdf
  if (conditon === 'word-ppt-img-vid-pdf') {
    const extensionAll = wordExtension.concat(
      pptExtension,
      imageExtension,
      videoExtension,
      pdfExtension
    );
    return extensionAll;
  }
};

export const calculateDiscountedPrice = (
  originalPrice,
  discountPercentage = 10
) => {
  // Hitung harga setelah diskon
  const discountedPrice = (originalPrice * discountPercentage) / 100;
  return discountedPrice;
};

export const getLocalTimeFromUTC = (value) => {
  dayjs.extend(utc);
  return dayjs.utc(value).local().format('YYYY-MM-DD HH:mm:ss');
};
