import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FaRegEnvelope } from "react-icons/fa6";
import { HiOutlineEye, HiOutlineEyeOff, HiOutlineLockClosed } from "react-icons/hi";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Card, CardBody, Col, Form, Input, InputGroup, InputGroupText, Label, Row } from "reactstrap";
import * as yup from "yup";
import { setRefreshToken, setSessions, setToken } from "../../app/authSlice";
import HelmetTitle from "../../components/layout/HelmetTitle";
import { usePostLoginMutation } from "./api/AuthAPI";
import AlertForm from "./components/AlertForm";
import ContentLeftForm from "./components/ContentLeftForm";
import FooterForm from "./components/FooterForm";
import ForgotPasswordLink from "./components/ForgotPasswordLink";
import HeaderForm from "./components/HeaderForm";

const Login = ({ type }) => {
    const [isShowPassword, setIsShowPassword] = useState(false);
    const [isStatusAlert, setIsStatusAlert] = useState("");
    const [messageAlert, setMessageAlert] = useState("");
    const [postLogin, { isLoading }] = usePostLoginMutation()

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const schema = yup.object({
        email: yup
            .string()
            .email("Email harus berformat email!")
            .required("Email wajib di isi!"),
        password: yup.string().required("Kata sandi wajib di isi!"),
    });

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            email: "",
            password: "",
        },
    });

    const handleOnSubmit = async (param) => {
        try {
            const result = await postLogin(param).unwrap()
            if (result.data.role_name.toLowerCase() !== type) {
                setIsStatusAlert('error')
                setMessageAlert('User tidak ditemukan')
                return false
            } else {
                localStorage.setItem('refreshToken', result.refreshToken)
                localStorage.setItem('token', result.token)
                localStorage.setItem('data', JSON.stringify(result.data))
                dispatch(setToken(result.token))
                dispatch(setRefreshToken(result.refreshToken))
                dispatch(setSessions(result.data))

                setIsStatusAlert('success')
                setMessageAlert('Berhasil masuk, tunggu sebentar sedang diarahkan...')

                navigate(
                    type === 'lawyer' ? '/dashboard-lawyer' : '/dashboard-client'
                )
            }
        } catch (error) {
            setIsStatusAlert('error')
            setMessageAlert(error.data.message)
        }
    };

    return (
        <>
            <HelmetTitle
                title={`Login | ${type == 'client' ? 'Klien' : 'Lawyer'}`}
                meta="login, cerai, cerai-online, cerai_online, perceraian"
            />
            <Row className="justify-content-center align-self-center mt-8 mt-lg-12 px-2 px-lg-15 min-vh-100">
                <Col className="d-none d-lg-inline-block text-center" md={6} lg={4} xl={6}>
                    <ContentLeftForm
                        description={`Masuk Sebagai ${type === 'lawyer' ? 'Pengacara' : 'Klien'}`}
                    />
                </Col>
                <Col sm={12} md={8} lg={8} xl={6}>
                    <Card className="overflow-hidden shadow-none border-0 rounded-3 align-self-center bg-transparent px-lg-6">
                        <HeaderForm
                            title={`Masuk Sebagai ${type === 'lawyer' ? 'Pengacara' : 'Klien'}`}
                        />
                        <CardBody className="py-lg-5">
                            <div className="p-0 p-lg-2">
                                <Form
                                    onSubmit={handleSubmit(handleOnSubmit)}
                                    className="form-horizontal"
                                >
                                    {
                                        isStatusAlert
                                        &&
                                        <AlertForm
                                            status={isStatusAlert}
                                            description={messageAlert}
                                        />
                                    }
                                    <div className="mb-2">
                                        <Label for="email" className="fs-lg-5">
                                            Email
                                        </Label>
                                        <InputGroup>
                                            <InputGroupText className={`bg-transparent border-end-0 text-start justify-content-end rounded-3 rounded-end-0 ${errors.email && 'border-orange'}`}>
                                                <FaRegEnvelope size={20} />
                                            </InputGroupText>
                                            <Controller
                                                name="email"
                                                control={control}
                                                render={({ field: { onChange, onBlur, value } }) => {
                                                    return (
                                                        <>
                                                            <Input
                                                                onChange={onChange}
                                                                onBlur={onBlur}
                                                                value={value}
                                                                bsSize="lg"
                                                                className={`d-none d-md-block border-start-0 rounded-3 rounded-start-0 text-start ${errors.email && 'border-orange'}`}
                                                                type="email"
                                                                placeholder="Masukan email anda"
                                                            />
                                                            <Input
                                                                onChange={onChange}
                                                                onBlur={onBlur}
                                                                value={value}
                                                                bsSize="sm"
                                                                className="d-block d-md-none border-start-0 rounded-3 rounded-start-0 text-start"
                                                                type="email"
                                                                placeholder="Masukan email anda"
                                                            />
                                                        </>
                                                    )
                                                }}
                                            />
                                        </InputGroup>

                                    </div>
                                    <div className="mb-3">
                                        <span className="mt-2 text-orange text-start">
                                            {errors.email?.message}
                                        </span>
                                    </div>

                                    <div className="mb-2">
                                        <Label for="password" className="fs-lg-5">
                                            Kata Sandi
                                        </Label>
                                        <InputGroup>
                                            <InputGroupText className={`bg-transparent border-end-0 text-start justify-content-end rounded-3 rounded-end-0 ${errors.password && 'border-orange'}`}>
                                                <HiOutlineLockClosed size={20} />
                                            </InputGroupText>
                                            <Controller
                                                name="password"
                                                control={control}
                                                render={({ field: { onChange, onBlur, value } }) => {
                                                    return (
                                                        <>
                                                            <Input
                                                                onChange={onChange}
                                                                onBlur={onBlur}
                                                                value={value}
                                                                bsSize="lg"
                                                                className={`d-none d-md-block border-start-0 border-end-0 rounded-3 rounded-start-0 rounded-end-0 text-start ${errors.password && 'border-orange'}`}
                                                                type={isShowPassword ? 'text' : 'password'}
                                                                placeholder="Masukan kata sandi anda"
                                                            />
                                                            <Input
                                                                onChange={onChange}
                                                                onBlur={onBlur}
                                                                value={value}
                                                                bsSize="sm"
                                                                className={`d-block d-md-none border-start-0 border-end-0 rounded-3 rounded-start-0 rounded-end-0 text-start ${errors.password && 'border-orange'}`}
                                                                type={isShowPassword ? 'text' : 'password'}
                                                                placeholder="Masukan kata sandi anda"
                                                            />
                                                        </>
                                                    )
                                                }}
                                            />

                                            <InputGroupText className={`bg-transparent border-start-0 text-start justify-content-end rounded-3 rounded-start-0 ${errors.password && 'border-orange'}`} style={{ 'cursor': 'pointer' }} onClick={
                                                () => {
                                                    setIsShowPassword(!isShowPassword)
                                                }}>
                                                {isShowPassword ?
                                                    <HiOutlineEyeOff size={20} />
                                                    :
                                                    <HiOutlineEye size={20} />
                                                }
                                            </InputGroupText>
                                        </InputGroup>
                                    </div>
                                    <div className="mb-3">
                                        <span className="mt-2 text-orange text-start">
                                            {errors.password?.message}
                                        </span>
                                    </div>

                                    <div className="d-flex justify-content-end mb-3">
                                        <ForgotPasswordLink type={type} />
                                    </div>
                                    <div className="mb-4">
                                        <Button type="submit"
                                            block
                                            color="danger"
                                            className="rounded-4"
                                            disabled={isLoading}
                                        >
                                            {isLoading ? 'Memproses...' : 'Masuk'}
                                        </Button>
                                    </div>
                                    <FooterForm type={type} from="login" />
                                </Form>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
}

export default Login;
