import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

const FooterNonAuth = () => {
  return (
    <footer className="bg-white border-top border-primary border-1 py-3">
      <Container>
        <Row className="align-items-center justify-content-center text-center">
          <Col
            md={12}
            className="mb-1 align-items-center justify-content-center text-center"
          >
            <img
              src="image-121@2x.png"
              className="avatar-title avatar-sm mx-auto d-block bg-transparent"
            />
          </Col>
          <Col md={12} className="mb-1">
            Copyright © {new Date().getFullYear()} ceraionline.com
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default FooterNonAuth;
