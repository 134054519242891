import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import PropsTypes from "prop-types";

/**
 *
 * @param {{modalSize: "sm"|"md"|'lg'|'xl'}} props
 * @returns
 */

function ModalMenu(props) {
  const toggle = () => props.setModal(!props.modal);
  return (
    <Modal
      className="custom-backdrop"
      isOpen={props.modal}
      toggle={toggle}
      centered
      backdrop={false}
      size={props.modalSize ?? "xl"}
    >
      {/* <ModalHeader toggle={toggle}>
      </ModalHeader> */}
      <span className="text-center bg-red pt-4 pb-3 font-size-18 fw-bold">
        {props.title}
      </span>
      <ModalBody>
        <div
          className={`mb-4 ${props.isDescriptionCenter ? "text-center" : ""}`}
        >
          {props.description}
        </div>
        {props.children}
      </ModalBody>
      {!props.hideModalBottom ? (
        <ModalFooter className="d-flex justify-content-center w-full">
          <Button color="primary" onClick={toggle} className="py-3 px-5">
            Kembali
          </Button>
        </ModalFooter>
      ) : null}
    </Modal>
  );
}

ModalMenu.propTypes = {
  modal: PropsTypes.bool,
  setModal: PropsTypes.func,
  title: PropsTypes.string,
  description: PropsTypes.string,
  children: PropsTypes.element,
  hideModalBottom: PropsTypes.bool,
  isDescriptionCenter: PropsTypes.bool,
  modalSize: PropsTypes.string,
};

export default ModalMenu;
