import { useEffect, useRef, useState } from 'react';
import Countdown, { zeroPad } from 'react-countdown';
import { IoIosArrowBack } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import {
  BreadcrumbItem,
  Button,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { orderSetupSelector } from '../../app/orderSlice';
import {
  diffTwoDate,
  getDateNow,
  getDatePlusInputSecond,
  getLocalTimeFromUTC,
  parseDateToUnixTimeStampMS,
} from '../../helpers/GlobalHelper';
import { authSetupSelector } from '../../app/authSlice';
import ModalShowRate from '../../features/case/components/ModalShowRate';
import ModalFormRate from '../../features/case/components/ModalFormRate';
import { CONSULTATION_EXPIRATION } from '../../constants/app.constants';
import { showConfirmationModal } from '../../app/globalSlice';
import {
  useDeleteEndConsultationSessionMutation,
  useGetDetailOrderQuery,
} from '../../features/case/api/CaseAPI';
import { useLocation } from 'react-router-dom';

const CaseLayoutConsultation = ({
  breadcrumbTitle,
  isChat,
  children,
  handlerBackTo,
}) => {
  const dispatch = useDispatch();

  const [dateConsultation, setDateConsultation] = useState('');
  const [isCountDownRunning, setIsCountDownRunning] = useState(false);
  const [isCountingDone, setIsCountingDone] = useState(false);
  const [isModalShowRating, setIsModalShowRating] = useState(false);
  const [isModalShowFormRating, setIsModalShowFormRating] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const countRef = useRef('');
  const interval = useRef();

  const location = useLocation();
  const { caseId } = location.state;
  const { refetch: refetchDetailOrder } = useGetDetailOrderQuery(
    {
      case_id: caseId,
    },
    {
      refetchOnMountOrArgChange: false,
      refetchOnReconnect: true,
    }
  );

  const [deleteEndConsultationSession] =
    useDeleteEndConsultationSessionMutation();

  const { detail_order_service, consultation_sessions } =
    useSelector(orderSetupSelector);

  const { sessions } = useSelector(authSetupSelector);

  useEffect(() => {
    let getSessionConsultation = consultation_sessions.filter(
      (val) => val.is_active && !val.is_skip
    );
    if (getSessionConsultation.length > 0) {
      getSessionConsultation = getSessionConsultation[0];
      const dateConsultation = getLocalTimeFromUTC(
        getSessionConsultation.start_time
      );

      const idInterval = setInterval(() => {
        const getPlus1FromDateConsultation = getDatePlusInputSecond(
          dateConsultation,
          3601
        );
        // const getPlus1FromDateConsultation = getDatePlusInputSecond('2023-10-18 20:04:00', 3610)
        setDateConsultation(getPlus1FromDateConsultation);
        const compareDateConsulWithNow = diffTwoDate(
          getDateNow(),
          dateConsultation,
          'second'
        );

        if (
          compareDateConsulWithNow >= 0 &&
          compareDateConsulWithNow <= CONSULTATION_EXPIRATION
        ) {
          setIsCountDownRunning(true);
          setIsCountingDone(false);
        } else if (compareDateConsulWithNow >= CONSULTATION_EXPIRATION) {
          setIsCountDownRunning(false);
          setIsCountingDone(true);
        } else {
          setIsCountingDone(false);
          setIsCountDownRunning(false);
        }
      }, 3000);

      interval.current = idInterval;
      return () => {
        clearInterval(interval.current);
      };
    } else {
      setIsCountingDone(false);
      setIsCountDownRunning(false);
    }
  }, [consultation_sessions]);

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      return <span className="text-primary">Konsultasi Telah Berakhir</span>;
    } else {
      return (
        <>
          <span>Sisa Waktu Konsultasi:</span>{' '}
          <span className="text-primary">
            {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
          </span>
        </>
      );
    }
  };

  const ContentCountDown = ({
    data,
    dateConsultationSession,
    isCountDownRunning,
    isCountingDone,
  }) => {
    if (data.category_order_service_name === 'new_consultation') {
      return (
        <span className="text-primary">Konsultasi Baru dan Belum Di Bayar</span>
      );
    }

    if (
      ['rejected_from_lawyer', 'reject_consultation'].includes(
        data.category_order_service_name
      )
    ) {
      return (
        <span className="text-primary">Sesi Konsultasi Telah Ditolak</span>
      );
    }

    if (
      ['canceled_by_system', 'canceled_by_client'].includes(
        data.category_order_service_name
      )
    ) {
      return (
        <span className="text-primary">Sesi Konsultasi Telah Dibatalkan</span>
      );
    }

    if (data.category_order_service_name === 'waiting_payout') {
      return (
        <span className="text-primary">
          Menunggu Pelunasan Untuk Sesi Konsultasi
        </span>
      );
    }

    if (data.category_order_service_name === 'end_case') {
      return <span className="text-primary">Konsultasi Telah Berakhir</span>;
    }

    if (data.category_order_service_name === 'reschedule_consultation') {
      return <span className="text-primary">Pengajuan Jadwal Lain</span>;
    }

    if (data.category_order_service_name === 'progress') {
      if (isCountDownRunning) {
        return (
          <Countdown
            autoStart={true}
            ref={countRef}
            intervalDelay={3}
            date={parseDateToUnixTimeStampMS(dateConsultationSession)}
            renderer={renderer}
            onStop={true}
          />
        );
      }

      return (
        <span className="text-primary">Menunggu Sesi Konsultasi Dimulai</span>
      );
    }
  };

  const ContentRate = ({ sessions, detail_order_service }) => {
    if (detail_order_service.category_order_service_name !== 'end_case') {
      return '';
    }

    if (sessions?.role_name?.toLowerCase() === 'client') {
      return (
        <Button
          onClick={() => setIsModalShowFormRating(!isModalShowFormRating)}
          color="danger"
          size="sm"
        >
          Berikan Rating
        </Button>
      );
    }

    if (sessions?.role_name?.toLowerCase() === 'lawyer') {
      return (
        <Button
          onClick={() => setIsModalShowRating(!isModalShowRating)}
          color="danger"
          size="sm"
        >
          Lihat Rating
        </Button>
      );
    }
  };

  const handlerBackToList = () => {
    // clearInterval(interval.current);
    // navigate(backTo)
    handlerBackTo(interval.current);
  };

  const toggleDropdownOpen = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const finishConsultationSession = () => {
    dispatch(
      showConfirmationModal({
        description: 'Apa anda yakin ingin menyelesaikan sesi konsultasi ini?',
        action: async () => {
          const activeConsultationSessions = consultation_sessions.filter(
            (val) => val.is_active && !val.is_skip
          );
          if (activeConsultationSessions.length < 1) {
            return;
          }
          const result = await deleteEndConsultationSession({
            id: activeConsultationSessions[0].id,
          });
          console.log('debug finish consultation', result);
          await refetchDetailOrder();
        },
      })
    );
  };

  return (
    <>
      <div className="page-content w-100 timeline overflow-auto pb-md-3 h-100 row gx-0 gx-sm-3">
        <Col lg={12} xl={12} className="mb-4">
          <div>
            <div
              className={`d-flex align-items-center gap-1 px-3 py-1 ${
                isChat ? 'bg-white border border-2' : ''
              } `}
            >
              <Button
                onClick={handlerBackToList}
                color="transparent"
                className="me-0 px-0"
              >
                <IoIosArrowBack size={20} color="#495057" />
              </Button>
              <div className="flex-grow-1">
                <ol className="breadcrumb m-0">
                  <BreadcrumbItem active>
                    <div className="text-dark font-size-14">
                      {breadcrumbTitle}
                    </div>
                  </BreadcrumbItem>
                </ol>
              </div>
              <div>
                <ContentCountDown
                  data={detail_order_service}
                  dateConsultationSession={dateConsultation}
                  isCountDownRunning={isCountDownRunning}
                  isCountingDone={isCountingDone}
                />
              </div>
              {isCountDownRunning && (
                <Dropdown isOpen={isDropdownOpen} toggle={toggleDropdownOpen}>
                  <DropdownToggle tag="span">
                    <i className="fa fa-fw fa-ellipsis-v" />
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={finishConsultationSession}>
                      Selesaikan sesi konsultasi
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              )}

              <div className="ms-1">
                <ContentRate
                  detail_order_service={detail_order_service}
                  sessions={sessions}
                />
              </div>
            </div>
            {children}
          </div>
        </Col>
      </div>
      <ModalShowRate
        isOpen={isModalShowRating}
        toggle={() => setIsModalShowRating(!isModalShowRating)}
      />
      <ModalFormRate
        isOpen={isModalShowFormRating}
        toggle={() => setIsModalShowFormRating(!isModalShowFormRating)}
      />
    </>
  );
};

export default CaseLayoutConsultation;
