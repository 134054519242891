import { Card, CardBody, CardText, Col } from "reactstrap";

const NotValidTokenVerifyRegister = () => {
    return (
        <Col xs={12} className="mt-0 justify-content-center text-center">
            <img
                className="img-content rounded"
                alt="empty"
                src="empty.svg"
            />
            <Card className="bg-transparent shadow-none mt-0">
                <CardBody className="bg-transparent">
                    <CardText className="text-secondary fw-normal">
                        Kode verifikasi tidak ditemukan!
                    </CardText>
                </CardBody>
            </Card>
        </Col>
    );
}

export default NotValidTokenVerifyRegister;
