import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import { authSetupSelector } from "../../app/authSlice";
import { orderSetupSelector, setActiveProgressTimeline, setDetailOrderService, setIsRefetchingDetailProgressTimeline, setIsRefetchingListProgressTimeline, setRoomId } from "../../app/orderSlice";
import CaseLayoutDivorce from "../../components/layout/CaseLayoutDivorce";
import HelmetTitle from "../../components/layout/HelmetTitle";
import { checkLengthKeys } from "../../helpers/GlobalHelper";
import { useGetDetailProgressNoCacheMutation } from "./api/CaseAPI";
import FormDetailProgressTimeline from "./components/FormDetailProgressTimeline";
import FormProgressTimeline from "./components/FormProgressTimeline";
import FormConfirmationEndCase from "./components/FormConfirmationEndCase";
import FormEndCase from "./components/FormEndCase";

const DetailProgressTimelineOrder = ({ type }) => {
    const location = useLocation();
    const {
        idProgressTimeline,
        data_room_id,
        data_detail_order_service,
    } = location.state;

    const [conditionFormDetail, setConditionFormDetail] = useState('');
    const [dataFormDetail, setDataFormDetail] = useState('');
    const [isFetchDetailProgress, setIsFetchDetailProgress] = useState(false);

    const { sessions, token } = useSelector(authSetupSelector)
    const {
        detail_order_service,
        room_id,
        is_progress_timeline_active,
        is_refetching_detail_progress_timeline,
    } = useSelector(orderSetupSelector)

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [
        getDetailProgressNoCache,
        {
            isLoading: isLoadingGetDetailProgressTimelineNoCache,
            isError: isErrorGetDetailProgressTimelineNoCache,
        }
    ] = useGetDetailProgressNoCacheMutation();
    // const {
    //     data: dataDetailProgressTimeline,
    //     isLoading: isLoadingDetailProgressTimeline,
    //     isError: isErrorDetailProgressTimeline,
    //     refetch: refetchDetailProgressTimeline
    // } = useGetDetailProgressTimelineQuery(
    //     {
    //         id: is_progress_timeline_active,
    //     },
    //     {
    //         refetchOnMountOrArgChange: false,
    //         refetchOnReconnect: true
    //     });

    const handlerRefetchDetailProgressTimeline = async () => {
        // return await refetchDetailProgressTimeline()
        if (is_progress_timeline_active) {
            try {
                const getData = await getDetailProgressNoCache({ id: is_progress_timeline_active }).unwrap()
                const { data } = getData;
                const { role_name } = sessions;
                setDataFormDetail(data)

                if (role_name.toLowerCase() === 'lawyer') {
                    if (
                        [
                            "upload_documents", "power_of_attorney", "lawsuit_list"
                        ].includes(data.code_category_order_timeline)
                    ) {
                        setConditionFormDetail("form_request_file")
                    } else if (data.code_category_order_timeline === 'end_divorce') {
                        setConditionFormDetail("form_end_case")
                    } else {
                        setConditionFormDetail("form_create_information")
                    }
                }

                if (role_name.toLowerCase() === 'client') {
                    if (
                        [
                            "upload_documents", "power_of_attorney", "lawsuit_list"
                        ].includes(data.code_category_order_timeline)
                    ) {
                        setConditionFormDetail("form_upload_file_from_client")
                    } else if (data.code_category_order_timeline === 'end_divorce') {
                        setConditionFormDetail("from_confirm_end_case")
                    } else {
                        setConditionFormDetail("form_information")
                    }
                }

                setIsFetchDetailProgress(false);
            } catch (error) {
                console.warn("error:", error)
                setIsFetchDetailProgress(false);
            }

        } else {
            setIsFetchDetailProgress(false);
        }
    }

    const handlerBackToChat = () => {
        navigate(`/case-${type}/detail-case-${type}`, { state: { caseId: detail_order_service.case_id } });
    }

    const Content = ({ isLoading, isError, isCondition, data }) => {
        if (isLoading) {
            return <span className="fst-italic">Sedang memuat...</span>
        } else if (isError) {
            return <span className="text-primary">Terjadi kesalahan</span>
        } else if (['form_request_file', 'form_create_information'].includes(isCondition)) {
            return <FormProgressTimeline
                isCondition={isCondition}
                dataDetailProgress={data}
            />
        } else if (isCondition === 'form_end_case') {
            return <FormEndCase
                dataDetailProgress={data}
            />
        } else if (isCondition === 'from_confirm_end_case') {
            return <FormConfirmationEndCase
                dataDetailProgress={data}
            />
        } else {
            return <FormDetailProgressTimeline
                isCondition={isCondition}
                dataDetailProgress={data}
            />
        }
    }

    // useEffect(() => {
    //     if (isLoadingGetDetailProgressTimelineNoCache) {
    //         setConditionFormDetail('')
    //         setDataFormDetail('')
    //     }
    // }, [isLoadingGetDetailProgressTimelineNoCache]);

    useEffect(() => {
        if (isErrorGetDetailProgressTimelineNoCache) {
            setConditionFormDetail('')
            setDataFormDetail('')
        }
    }, [isErrorGetDetailProgressTimelineNoCache]);

    useEffect(() => {
        if (token) {
            setIsFetchDetailProgress(true)
        }
    }, [token]);

    useEffect(() => {
        if (!checkLengthKeys(detail_order_service)) {
            dispatch(
                setDetailOrderService(data_detail_order_service)
            )
        }
    }, [detail_order_service]);

    useEffect(() => {
        if (!room_id) {
            dispatch(
                setRoomId(data_room_id)
            )
        }
    }, [room_id]);

    useEffect(() => {
        if (idProgressTimeline) {
            dispatch(setActiveProgressTimeline(idProgressTimeline))
            setIsFetchDetailProgress(true);
        }
    }, [idProgressTimeline]);

    useEffect(() => {
        if (is_refetching_detail_progress_timeline) {
            setIsFetchDetailProgress(true);
            dispatch(setIsRefetchingDetailProgressTimeline(false))
        }
    }, [is_refetching_detail_progress_timeline]);

    useEffect(() => {
        if (isFetchDetailProgress) {
            handlerRefetchDetailProgressTimeline()
            dispatch(setIsRefetchingListProgressTimeline(true))
        }
    }, [isFetchDetailProgress]);

    // useEffect(() => {
    //     if (checkLengthKeys(dataDetailProgressTimeline)) {
    //         const { data } = dataDetailProgressTimeline;
    //         const { role_name } = sessions;
    //         setDataFormDetail(data)

    //         if (role_name.toLowerCase() === 'lawyer') {
    //             if (
    //                 [
    //                     "upload_documents", "end_divorce", "power_of_attorney", "lawsuit_list"
    //                 ].includes(data.code_category_order_timeline)
    //             ) {
    //                 setConditionFormDetail("form_request_file")
    //             } else {
    //                 setConditionFormDetail("form_create_information")
    //             }
    //         }

    //         if (role_name.toLowerCase() === 'client') {
    //             if (
    //                 [
    //                     "upload_documents", "end_divorce", "power_of_attorney", "lawsuit_list"
    //                 ].includes(data.code_category_order_timeline)
    //             ) {
    //                 setConditionFormDetail("form_upload_file_from_client")
    //             } else {
    //                 setConditionFormDetail("form_information")
    //             }
    //         }
    //     }
    // }, [dataDetailProgressTimeline]);

    return (
        <>
            <HelmetTitle
                title={`Detail Progres Timeline | Detail Kasus | ${type}`}
                meta="detailkasus"
            />
            <CaseLayoutDivorce
                breadcrumbTitle='Kembali Ke Halaman Chat'
                isChat={false}
                handlerBackTo={handlerBackToChat}
            >
                <Card className="py-1 mt-3 border border-2 rounded-3 mx-3">
                    <CardBody>
                        <Content
                            isLoading={isLoadingGetDetailProgressTimelineNoCache}
                            isError={isErrorGetDetailProgressTimelineNoCache}
                            isCondition={conditionFormDetail}
                            data={dataFormDetail}
                        />
                    </CardBody>
                </Card>
            </CaseLayoutDivorce>
        </>
    );
}

export default DetailProgressTimelineOrder;
