import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const { VITE_BASE_URL } = import.meta.env;

const getToken = (state) => state.authSetup.token;

export const ratingApi = createApi({
    reducerPath: "ratingApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${VITE_BASE_URL}/rating`,
        prepareHeaders: (headers, { getState }) => {
            const token = getToken(getState());
            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getRating: builder.mutation({
            query: ({ case_id }) => ({
                url: `/get-by-case-id?case_id=${case_id}`,
                method: 'GET',
            }),
            invalidatesTags: ["get-rating"],
        }),
        putRating: builder.mutation({
            query: (body) => ({
                method: 'PUT',
                body
            }),
            invalidatesTags: ["update-rating"],
        }),
    }),
});

export const {
    usePutRatingMutation,
    useGetRatingMutation,
} = ratingApi