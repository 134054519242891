import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, FormGroup, Label, Modal, ModalBody } from "reactstrap";
import { orderSetupSelector, setIsRefetchingDetailProgressTimeline, setIsRefetchingListProgressTimeline } from "../../../app/orderSlice";
import { notify } from "../../../helpers/GlobalHelper";
import { usePostConfirmationEndDivorceMutation } from "../api/CaseAPI";

const ModalRejectEndCaseDivorce = ({ isOpen, toggle }) => {
    const [valueReason, setValueReason] = useState('');
    const dispatch = useDispatch()

    const [
        postConfirmationEndDivorce,
        {
            isLoading: isLoadingPostConfirmationEndDivorce,
            isError: isErrorPostConfirmationEndDivorce,
            isSuccess: isSuccessPostConfirmationEndDivorce,
            error: errorPostConfirmationEndDivorce,
        }
    ] = usePostConfirmationEndDivorceMutation();

    const {
        is_progress_timeline_active,
    } = useSelector(orderSetupSelector)

    const handlerCloseForm = () => {
        toggle()
    }

    const handlerOnSubmit = async () => {
        if (valueReason.length < 10) {
            return notify('warn', 30000, 'Alasan wajib diisi, dan minimal panjang 10 karakter');
        }

        const param = {
            id_order_timeline: is_progress_timeline_active,
            status_confirmation: false,
            reason_confirmation: valueReason
        }

        await postConfirmationEndDivorce(param).unwrap();
    }

    useEffect(() => {
        if (isErrorPostConfirmationEndDivorce) {
            notify('error', 30000, errorPostConfirmationEndDivorce.data.message);
        }
    }, [isErrorPostConfirmationEndDivorce]);

    useEffect(() => {
        if (isSuccessPostConfirmationEndDivorce) {
            toggle()
            dispatch(setIsRefetchingDetailProgressTimeline(true))
            notify('success', 30000, 'Berhasil menolak!');
        }
    }, [isSuccessPostConfirmationEndDivorce]);

    return (
        <Modal
            className="border-0 custom-backdrop"
            isOpen={isOpen}
            toggle={handlerCloseForm}
            centered
            keyboard={false}
            backdrop={false}
            size='md'
        >
            <span className="text-center pt-4 pb-0 font-size-18 fw-bold">
                Peringatan!
            </span>
            <ModalBody>
                <div
                    className='mb-0 text-center'
                >
                    Apakah anda yakin ingin menolak proses penyelesaian kasus?
                </div>
                <FormGroup className="w-100 mt-3">
                    <Label for="forReason">
                        <span className="font-size-14 fw-bold">
                            Alasan
                        </span>
                    </Label>
                    <textarea
                        placeholder="Masukan alasan mengapa anda menolak"
                        onChange={(e) => setValueReason(e.target.value)}
                        className="form-control description_input bg-white py-2"
                        value={valueReason}
                    ></textarea>
                </FormGroup>
                <div className="mt-3 d-flex justify-content-between gap-3">
                    <Button
                        onClick={handlerCloseForm}
                        color="secondary"
                        outline
                        block
                        disabled={isLoadingPostConfirmationEndDivorce}
                    >
                        Batalkan
                    </Button>
                    <Button
                        onClick={handlerOnSubmit}
                        color="primary"
                        outline
                        block
                        disabled={isLoadingPostConfirmationEndDivorce}
                    >
                        {isLoadingPostConfirmationEndDivorce ? 'Memproses...' : 'Tolak'}
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    );
}

export default ModalRejectEndCaseDivorce;
