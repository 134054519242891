import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Row, Col, BreadcrumbItem } from "reactstrap";

const Breadcrumb = (props) => {
  const ContentRightBreadCrumb = ({ hideRightBreadcrumb, title, breadcrumbItem, titleWithLink, to }) => {
    if (!hideRightBreadcrumb) {
      if (!titleWithLink) {
        return <div className="page-title-right">
          <ol className="breadcrumb m-0">
            {title && (
              <BreadcrumbItem>
                <Link to="#">{title}</Link>
              </BreadcrumbItem>
            )}
            {breadcrumbItem && (
              <BreadcrumbItem active>
                <Link to="#">{breadcrumbItem}</Link>
              </BreadcrumbItem>
            )}
          </ol>
        </div>
      } else {
        return <div className="page-title-right">
          <ol className="breadcrumb m-0">
            <BreadcrumbItem active>
              <Link to={to.pathName} state={to.state} className="text-primary">
                {titleWithLink}
              </Link>
            </BreadcrumbItem>
          </ol>
        </div>
      }
    }
  }
  return (
    <Row>
      <Col xs="12">
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          <h4 className="mb-0 font-size-18">{props.breadcrumbItem}</h4>
          <ContentRightBreadCrumb {...props} />
        </div>
      </Col>
    </Row>
  );
};

Breadcrumb.propTypes = {
  rightBreadCrumWithUrl: PropTypes.bool,
  titleWithLink: PropTypes.string,
  to: PropTypes.object,
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string,
  hideRightBreadcrumb: PropTypes.bool,
};

export default Breadcrumb;
