import { useDispatch, useSelector } from "react-redux";
import { usePostRefreshTokenMutation } from "../../auth/api/AuthAPI";
import { orderSetupSelector, setChooseConsultationSession, setModalCase } from "../../../app/orderSlice";
import { authSetupSelector, setRefreshToken, setSessions, setToken } from "../../../app/authSlice";
import { Button, Modal, ModalBody } from "reactstrap";
import { usePostApproveOrderConsultationMutation } from "../api/CaseAPI";
import { useEffect } from "react";
import { notify, reformatDate } from "../../../helpers/GlobalHelper";

const ModalInfoApproveConsultation = () => {
    const { refresh_token } = useSelector(authSetupSelector)
    const {
        is_modal_approve_consultation,
        choose_consultation_session,
        detail_order_service
    } = useSelector(orderSetupSelector)

    const dispatch = useDispatch();
    const [postRefreshToken] = usePostRefreshTokenMutation();

    const [postApproveOrderConsultation,
        {
            isLoading: loadingApproveOrder,
            isSuccess: successApproveOrder,
            isError: isErrorApproveOrder,
            error: errorApproveOrder
        }
    ] = usePostApproveOrderConsultationMutation();


    const handlerRefreshSession = async () => {
        const resultGenerateNewToken = await postRefreshToken({ refresh_token }).unwrap()
        const dataSessions = resultGenerateNewToken.data;

        localStorage.setItem('data', JSON.stringify(dataSessions))
        dispatch(setSessions(dataSessions))

        localStorage.setItem('refreshToken', resultGenerateNewToken.refreshToken)
        localStorage.setItem('token', resultGenerateNewToken.token)

        dispatch(setToken(resultGenerateNewToken.token))
        dispatch(setRefreshToken(resultGenerateNewToken.refreshToken))
        return true
    }

    const handlerApproveConsultation = async () => {
        try {
            await handlerRefreshSession()
            const param = {
                case_id: detail_order_service.case_id,
                id_consultation_session: choose_consultation_session.id_consultation_session
            };
            await postApproveOrderConsultation(param).unwrap();
        } catch (error) {
            console.warn("error approve order consultation:", error.data.message)
            notify('error', 30000, `error message: ${error.data.message}`);
        }
    }

    useEffect(() => {
        if (successApproveOrder) {
            notify('success', 30000, 'Berhasil menerima orderan konsultasi');
            dispatch(setChooseConsultationSession({}))
            dispatch(setModalCase({ modalName: 'approve_consultation', isActive: !is_modal_approve_consultation }))
        }
    }, [successApproveOrder]);

    useEffect(() => {
        if (isErrorApproveOrder) {
            notify('error', 30000, errorApproveOrder.data.message);
        }
    }, [isErrorApproveOrder, errorApproveOrder]);

    return (
        <Modal
            isOpen={is_modal_approve_consultation}
            toggle={() => dispatch(setModalCase({ modalName: 'approve_consultation', isActive: !is_modal_approve_consultation }))}
            centered
            keyboard={false}
            backdrop={false}
            className="border-0 custom-backdrop"
            size='md'
        >
            <ModalBody>
                <div className="text-center fw-bold mb-2">
                    Terima
                </div>
                <div className="border-bottom mb-3"></div>
                <div className="text-center mb-2">
                    Apakah anda yakin ingin menerima sesi konsultasi pada
                    {' '}
                    <b>
                        {reformatDate(`${choose_consultation_session.date} ${choose_consultation_session.time}`, 'LLL')}
                    </b>?
                </div>
                <div className='d-flex justify-content-center gap-2'>
                    <Button
                        onClick={() => dispatch(setModalCase({ modalName: 'approve_consultation', isActive: !is_modal_approve_consultation }))}
                        outline
                        type="button"
                        color="secondary"
                        className="rounded-3"
                        size="sm"
                        block
                        disabled={loadingApproveOrder}
                    >
                        Kembali
                    </Button>
                    <Button
                        onClick={handlerApproveConsultation}
                        outline
                        type="button"
                        color="danger"
                        className="rounded-3"
                        size="sm"
                        block
                        disabled={loadingApproveOrder}
                    >
                        {loadingApproveOrder ? 'Memproses...' : 'Ya'}
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    );
}

export default ModalInfoApproveConsultation;
