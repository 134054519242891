import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SelectAsync from "react-select/async";
import { authSetupSelector, setRefreshToken, setToken } from '../../app/authSlice';
import { usePostRefreshTokenMutation } from '../../features/auth/api/AuthAPI';
import { useLazyGetListBankQuery } from '../../features/payments/api/paymentAPI';

const SelectorListBanks = ({ onChange, value = '' }) => {
    const [newValue, setNewValue] = useState('');
    const { refresh_token } = useSelector(authSetupSelector)

    const [postRefreshToken] = usePostRefreshTokenMutation()

    const dispatch = useDispatch()

    const [getListBank] = useLazyGetListBankQuery()

    const handlerRefreshSession = async () => {
        const resultGenerateNewToken = await postRefreshToken({ refresh_token }).unwrap()

        localStorage.setItem('refreshToken', resultGenerateNewToken.refreshToken)
        localStorage.setItem('token', resultGenerateNewToken.token)

        dispatch(setToken(resultGenerateNewToken.token))
        dispatch(setRefreshToken(resultGenerateNewToken.refreshToken))

        return true;
    }

    const handlerGetListBanks = async (value) => {
        await handlerRefreshSession();
        const getData = await getListBank({ limit: 1000, page: 1, gateway_from: 'OY', type: 'bank', keyword: value }).unwrap();

        let data = [];

        if (getData.data) {
            data = getData.data.map((val) => {
                return { value: val.id, label: val.bank_name }
            })

            return data
        } else {
            return data
        }
    }

    // useEffect(() => {
    //     handlerGetListBanks('')
    // }, []);

    useEffect(() => {
        if (value) {
            new Promise(async () => {
                const getListBank = await handlerGetListBanks('');
                const result = getListBank.filter((val) => {
                    return val.value == value
                })

                if (result) {
                    setNewValue(result[0])
                }
            })
        }
    }, [value]);

    return (
        <SelectAsync
            onChange={onChange}
            value={newValue}
            theme={(theme) => ({
                ...theme,
                borderRadius: "0.25rem",
                colors: {
                    ...theme.colors,
                    primary25: '#FFEDED',
                    primary: '#E91E23',
                },
            })}
            loadOptions={handlerGetListBanks}
            defaultOptions
        />
    );
}

export default SelectorListBanks;
