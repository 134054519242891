import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const { VITE_BASE_URL } = import.meta.env;

const getToken = (state) => state.authSetup.token;

export const taxServiceApi = createApi({
    reducerPath: "taxServiceApi",
    tagTypes: ["taxServiceApi"],
    baseQuery: fetchBaseQuery({
        baseUrl: `${VITE_BASE_URL}/tax-services/`,
        prepareHeaders: (headers, { getState }) => {
            const token = getToken(getState());
            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getListTaxByCategoryService: builder.query({
            query: ({ id_category_service }) => ({
                url: `by-category-service?id_category_service=${id_category_service}`,
            }),
            invalidatesTags: ["by-category-service"],
        }),
    })
})

export const {
    useGetListTaxByCategoryServiceQuery,
} = taxServiceApi;

