import { Button, Col, Modal, ModalBody, Row } from "reactstrap";

const ModalFailedBookingDivorce = ({ isOpen, toggle }) => {
    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            centered
            keyboard={false}
            backdrop={false}
            className="border-0 custom-backdrop"
            size='lg'
        >
            <ModalBody>
                <div className="text-center fw-bold mb-2">
                    Booking Gagal
                </div>
                <div className="border-bottom mb-3"></div>
                <Row>
                    <Col xs={12}>
                        <p className="text-primary">
                            Mohon maaf booking pengacara gagal kemungkinan dari sistem, silahkan coba kembali atau pilih pengacara lain. Terimakasih
                        </p>
                        <div className="px-lg-15">
                            <Button
                                onClick={() => toggle(isOpen)}
                                block color="secondary"
                            >
                                Pilih Pengacara Lain
                            </Button>
                        </div>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    );
}

export default ModalFailedBookingDivorce;
