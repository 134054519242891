import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Input, Label, Modal, ModalBody } from "reactstrap";
import { orderSetupSelector, setActiveProgressTimeline, setConditionShowingLeftCard, setIsRefetchingListProgressTimeline } from "../../app/orderSlice";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { useEffect } from "react";
import { usePostRefreshTokenMutation } from "../../features/auth/api/AuthAPI";
import { usePostCreateProgressOrderTimelineMutation } from "../../features/case/api/CaseAPI";
import { notify } from "../../helpers/GlobalHelper";
import { authSetupSelector, setRefreshToken, setToken } from "../../app/authSlice";

const ModalValidateCreateProgressTimeline = ({ isOpen, toggle }) => {
    const [postRefreshToken] = usePostRefreshTokenMutation()
    const [
        postCreateProgressOrderTimeline,
        {
            isSuccess: isSuccessCreateProgressTimeline,
            isLoading: isLoadingCreateProgressTimeline,
            isError: isErrorCreateProgressTimeline,
        }
    ] = usePostCreateProgressOrderTimelineMutation();

    const dispatch = useDispatch()

    const { refresh_token } = useSelector(authSetupSelector)
    const {
        detail_order_service,
        choosed_category_progress,
    } = useSelector(orderSetupSelector)

    const schema = yup.object({
        custom_title: yup.string().required("Nama Progress Wajib Diisi!"),
        description: yup.string().optional(),
    });

    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            custom_title: "",
            description: "",
        },
    });

    const handlerRefreshSession = async () => {
        const resultGenerateNewToken = await postRefreshToken({ refresh_token }).unwrap()

        localStorage.setItem('refreshToken', resultGenerateNewToken.refreshToken)
        localStorage.setItem('token', resultGenerateNewToken.token)

        dispatch(setToken(resultGenerateNewToken.token))
        dispatch(setRefreshToken(resultGenerateNewToken.refreshToken))
        return true;
    }

    const handlerOnSubmit = async (param) => {
        await handlerRefreshSession();

        const status_confirmation = choosed_category_progress.code !== 'end_divorce'

        param = {
            ...param,
            id_order_service: detail_order_service.id,
            id_category_order_timeline: choosed_category_progress.id,
            status_confirmation,
            reason_confirmation: '',
        }

        const processCreate = await postCreateProgressOrderTimeline(param).unwrap();
        const { status, data } = processCreate;
        if (status === 'success') {
            // dispatch(setActiveProgressTimeline(data.id))
            setValue('custom_title', '')
            setValue('description', '')

            toggle('created', data.id)
            notify('success', '30000', 'Berhasil membuat progress timeline baru!')
        }
    }

    useEffect(() => {
        setValue('custom_title', choosed_category_progress.name)
    }, [choosed_category_progress]);

    // useEffect(() => {
    //     if (isSuccessCreateProgressTimeline) {
    //         setValue('custom_title', '')
    //         setValue('description', '')
    //         dispatch(setIsRefetchingListProgressTimeline(true))

    //         toggle('created')
    //         notify('success', '30000', 'Berhasil membuat progress timeline baru!')
    //     }
    // }, [isSuccessCreateProgressTimeline]);

    useEffect(() => {
        if (isErrorCreateProgressTimeline) {
            notify('error', '30000', 'Terjadi kesalahan, mohon coba kembali atau hubungi admin cerai online!')
        }
    }, [isErrorCreateProgressTimeline]);

    return (
        <Modal
            className="custom-backdrop"
            isOpen={isOpen}
            toggle={() => toggle('', '')}
            keyboard={false}
            centered
            backdrop={false}
            size='md'
        >
            <span className="text-center pt-4 pb-0 font-size-18 fw-bold">
                Peringatan!
            </span>
            <ModalBody>
                <div className="border-top mb-3"></div>
                <Form onSubmit={handleSubmit(handlerOnSubmit)}>
                    <div
                        className='mb-2'
                    >
                        Apakah anda yakin ingin membuat progres baru dengan pilihan category:
                    </div>
                    {
                        choosed_category_progress.code === 'end_divorce'
                        &&
                        <div className="mb-2 text-primary">
                            Jika anda mengklik tombol lanjut, maka anda harus menunggu konfirmasi dari klien kurang dari 7 hari!
                        </div>
                    }
                    <span className="text-primary font-size-12 fst-italic mb-2">
                        Anda dapat merubah nama progress dibawah ini, sesuai kondisi di lapangan!
                    </span>
                    <Controller
                        name='custom_title'
                        control={control}
                        render={({ field: { onChange, onBlur, value } }) => {
                            return <>
                                <Input
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    value={value}
                                    type="text"
                                    className="form-control mt-2"
                                    placeholder="Ubah nama progres timeline"
                                />
                            </>
                        }}
                    />
                    <div className="text-orange font-size-12 mt-2">
                        {errors.custom_title?.message}
                    </div>

                    <Label className="mt-3">
                        Deskripsi
                    </Label>
                    <Controller
                        name='description'
                        control={control}
                        render={({ field: { onChange, onBlur, value } }) => {
                            return <>
                                <Input
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    value={value}
                                    type="textarea"
                                    className="form-control"
                                    placeholder="masukan deskripsi disini"
                                />
                            </>
                        }}
                    />
                    <div className="text-orange font-size-12 mt-2">
                        {errors.description?.message}
                    </div>

                    <div className="d-flex mt-3">
                        <Button
                            onClick={() => toggle('', '')}
                            block
                            color="secondary"
                            className="me-1"
                            disabled={isLoadingCreateProgressTimeline}
                        >
                            Batalkan
                        </Button>
                        <Button
                            type="submit"
                            block
                            color="danger"
                            className="ms-1"
                            disabled={isLoadingCreateProgressTimeline}
                        >
                            {
                                isLoadingCreateProgressTimeline
                                    ?
                                    'Memperoses...'
                                    :
                                    'Lanjutkan'
                            }
                        </Button>
                    </div>
                </Form>
            </ModalBody>
        </Modal>
    );
}

export default ModalValidateCreateProgressTimeline;
