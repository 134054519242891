import { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { RiGraduationCapFill, RiMapPin2Fill } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import {
  CardBody,
  CardText,
  CardTitle,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap';
import { orderSetupSelector } from '../../../app/orderSlice';
import avatar from '../../../assets/images/users/user.png';
import {
  UpperCaseName,
  notify,
  reformatToIDRCurrency,
} from '../../../helpers/GlobalHelper';
import { getHours, setHours, setMinutes } from 'date-fns';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import {
  authSetupSelector,
  setRefreshToken,
  setToken,
} from '../../../app/authSlice';
import { usePostRefreshTokenMutation } from '../../auth/api/AuthAPI';
import {
  useLazyGetOrderQuery,
  usePostOrderConsultationMutation,
} from '../../case/api/CaseAPI';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '../../../components/global/LoadingButton';
import { CategoryOrderService } from '../../../constants/order.constants';
import { ORDER_PAYMENT_CHECK_POLLING_INTERVAL_MS } from '../../../constants/app.constants';

const ModalFormConsultation = ({ isOpen, toggle }) => {
  const [userAvatar, setUserAvatar] = useState(avatar);

  const [isWaitingForPayment, setIsWaitingForPayment] = useState(false);

  const [userFullName, setUserFullName] = useState('-');
  const [userEducationName, setUserEducationName] = useState('-');
  const [userLocation, setUserLocation] = useState('-');
  const [chooseService, setChooseService] = useState({ price: 0 });
  const [priceConsultation, setPriceConsultation] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const {
    choose_lawyer,
    detail_service_consultation,
    tax_service_consultation,
  } = useSelector(orderSetupSelector);

  const { refresh_token, sessions } = useSelector(authSetupSelector);
  const [postRefreshToken] = usePostRefreshTokenMutation();
  const [
    postOrderConsultation,
    {
      isLoading: isLoadingOrderConsultation,
      isError: isErrorOrderConsultation,
    },
  ] = usePostOrderConsultationMutation();
  const [getOrder] = useLazyGetOrderQuery();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handlerRefreshSession = async () => {
    const resultGenerateNewToken = await postRefreshToken({
      refresh_token,
    }).unwrap();

    localStorage.setItem('refreshToken', resultGenerateNewToken.refreshToken);
    localStorage.setItem('token', resultGenerateNewToken.token);

    dispatch(setToken(resultGenerateNewToken.token));
    dispatch(setRefreshToken(resultGenerateNewToken.refreshToken));
    return true;
  };

  const handlerOrderConsultation = async () => {
    if (detail_service_consultation.price < 1) {
      return notify(
        'warn',
        30000,
        'Layanan gratis atau harga Rp.0 belum disupport oleh cerai online!'
      );
    }

    const param = {
      tax_service: tax_service_consultation,
      id_service_rate: detail_service_consultation.id,
    };
    await handlerRefreshSession();
    const result = await postOrderConsultation(param).unwrap();
    const { data: { orderId, paymentLink } = {} } = result;

    setIsWaitingForPayment(true);

    if (!paymentLink) {
      return notify(
        'warn',
        30000,
        'Terjadi kesalahan ketika mencoba generate payment link'
      );
    }

    window.open(paymentLink, '_blank');

    // disable the "bayar" button
    // change the screen to loading page waiting for user payment
    // start polling to get the order status
    const orderStatusPolling = setInterval(async () => {
      const result = await getOrder({
        id: orderId,
      });
      const {
        data: { data: { id_category_order_service, case_id } = {} } = {},
      } = result;

      if (id_category_order_service == CategoryOrderService.progress) {
        clearInterval(orderStatusPolling);
        const roleName = sessions.role_name.toLowerCase();
        navigate(`/case-${roleName}/detail-case-${roleName}`, {
          state: { caseId: case_id },
        });
      }
    }, ORDER_PAYMENT_CHECK_POLLING_INTERVAL_MS);
  };

  useEffect(() => {
    setUserAvatar(!choose_lawyer.photo ? avatar : choose_lawyer.photo);
    setUserFullName(!choose_lawyer.full_name ? '-' : choose_lawyer.full_name);
    setUserEducationName(
      !choose_lawyer?.education?.institution_name
        ? '-'
        : choose_lawyer.education.institution_name
    );
    setUserLocation(
      `${choose_lawyer.address_domicile} ${choose_lawyer.province_domicile?.name}`
    );
    const getService = choose_lawyer?.service_rates?.filter(
      (val) => val.category_service_name === 'Konsultasi online (chat) / jam'
    );
    setChooseService(
      !getService
        ? {
            price: 0,
          }
        : getService[0]
    );
  }, [choose_lawyer]);

  useEffect(() => {
    setPriceConsultation(chooseService.price);
    setTotalPrice(chooseService.price + tax_service_consultation);
  }, [chooseService]);

  useEffect(() => {
    console.warn('isErrorOrderConsultation:', isErrorOrderConsultation);
  }, [isErrorOrderConsultation]);

  useEffect(() => {
    if (!isOpen) {
      setUserFullName('-');
      setUserEducationName('-');
      setUserLocation('-');
      setChooseService({ price: 0 });
      setPriceConsultation(0);
      setTotalPrice(0);
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      centered
      keyboard={false}
      backdrop={false}
      className="border-0 custom-backdrop"
      size="lg"
      // fullscreen
    >
      <ModalHeader toggle={toggle}>Ringkasan Pembayaran</ModalHeader>
      <ModalBody>
        <Row>
          <Col xs={12} className="justify-content-sm-center text-center">
            <img
              className="img-thumbnail my-3 my-lg-0 avatar avatar-lg"
              src={userAvatar}
              data-holder-rendered="true"
            />
          </Col>
          <Col xs={12} className="mt-2">
            <CardBody>
              <CardTitle className="text-center mt-1">
                {UpperCaseName(userFullName)}
              </CardTitle>
              <Row className="mt-5">
                <Col xs={12} lg={6}>
                  <CardText className="my-2">
                    <span className="me-2 me-lg-3">
                      <RiGraduationCapFill size={18} />
                    </span>
                    <span>{UpperCaseName(userEducationName)}</span>
                  </CardText>
                </Col>
                <Col xs={12} lg={6}>
                  <CardText className="my-2">
                    <span className="me-2 me-lg-3">
                      <RiMapPin2Fill size={18} />
                    </span>
                    <span>
                      {!userLocation ? '-' : UpperCaseName(userLocation)}
                    </span>
                  </CardText>
                </Col>
              </Row>
            </CardBody>
          </Col>
          {/* <Col xs={12} className='mt-4coma5'>
                        <CardTitle>
                            Pilih Sesi Konsultasi
                        </CardTitle>
                    </Col> */}
          {/* <Col xs={12} className='mt-3'>
                        <ReactDatePicker
                            locale="id"
                            selected={selectedTimeConsultation}
                            onChange={handlerChooseDate}
                            showTimeSelect
                            timeFormat="p"
                            timeIntervals={15}
                            dateFormat="yyyy-MM-dd HH:mm"
                            timeCaption="WIB"
                            minTime={minChooseConsultationTime}
                            maxTime={maxChooseConsultationTime}
                            minDate={minChooseConsultationDate}
                            maxDate={maxChooseConsultationDate}
                            className="form-control cursor-pointer"
                            placeholderText="Pilih waktu konsultasi anda"
                        />
                    </Col> */}
          <Col xs={12} className="mt-5 mb-2">
            <div className="d-flex justify-content-between">
              <div>Biaya Sesi 1 Jam</div>
              <div className="fw-bold">
                {reformatToIDRCurrency(priceConsultation)}
              </div>
            </div>
          </Col>
          <Col xs={12} className="mt-3 mb-2">
            <div className="d-flex justify-content-between">
              <div>Biaya Layanan</div>
              <div className="fw-bold">
                {reformatToIDRCurrency(tax_service_consultation)}
              </div>
            </div>
          </Col>
          <Col xs={12} className="mt-3 mb-2">
            <div className="d-flex justify-content-between">
              <div className="fw-bold fs-5">Total Pembayaran</div>
              <div className="fw-bold fs-5">
                {reformatToIDRCurrency(totalPrice)}
              </div>
            </div>
          </Col>
          <Col xs={12} className="mt-3 mb-2">
            <BsFillInfoCircleFill className="me-3 text-primary" size={20} />
            <span className="fw-bold fst-italic text-primary">
              Setelah ini anda akan diarahkan ke halaman untuk menyelesaikan
              pembayaran anda
            </span>
          </Col>
          {/* <Col xs={12} className='text-center my-3'>
                        <Button
                            onClick={handlerOrderConsultation}
                            color="primary"
                            className='btn-primary'
                            block
                            disabled={isLoadingOrderConsultation}
                        >
                            Kirim Pengajuan
                        </Button>
                    </Col> */}

          <Col xs={12} className="text-center my-3">
            <LoadingButton
              onClick={handlerOrderConsultation}
              color="primary"
              className="btn-primary"
              block
              disabled={isLoadingOrderConsultation || isWaitingForPayment}
              isLoading={isLoadingOrderConsultation || isWaitingForPayment}
            >
              Bayar
            </LoadingButton>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default ModalFormConsultation;
