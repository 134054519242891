import React from 'react';
import LinkifyReact from 'linkify-react';
import LinkifyHtml from 'linkify-html';

function LinkifyComponent(props) {
    const { content } = props;
    const options = {
        target: '_blank',
        rel: 'nofollow noopener noreferrer',
        nl2br: true,
    };

    return (
        <LinkifyReact
            tagName="p"
            options={options}
            className="mb-0 my-0"
        >
            {content}
        </LinkifyReact>
    );
}

export default LinkifyComponent;