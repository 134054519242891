import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  reqFileData: [],
};

const caseSlice = createSlice({
  name: "caseSetup",
  initialState,
  reducers: {
    addNewFileData: (state, action) => {
      //for check if data has been added in the state
      const checkCurrentData = state.reqFileData?.filter(
        (val) =>
          val.docName?.toLowerCase() === action.payload?.docName?.toLowerCase()
      );
      if (!checkCurrentData.length) {
        state.reqFileData.push(action.payload);
      }
    },
    removeFileData: (state, action) => {
      const removeData = state.reqFileData?.filter(
        (val) =>
          val.docName?.toLowerCase() !== action.payload?.docName?.toLowerCase()
      );
      state.reqFileData = removeData;
    },
  },
});

export const { addNewFileData, removeFileData } = caseSlice.actions;

export default caseSlice.reducer;
