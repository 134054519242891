import React, { useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { useSelector } from "react-redux";
import { BreadcrumbItem, Button, Col } from "reactstrap";
import { authSetupSelector } from "../../app/authSlice";
import ModalFormRate from "../../features/case/components/ModalFormRate";
import ModalShowRate from "../../features/case/components/ModalShowRate";
import TimelineSidebar from "./TimelineSidebar";
import { orderSetupSelector } from "../../app/orderSlice";

const CaseLayoutDivorce = ({
  breadcrumbTitle,
  isChat,
  children,
  handlerBackTo,
}) => {
  const [isModalShowRating, setIsModalShowRating] = useState(false);
  const [isModalShowFormRating, setIsModalShowFormRating] = useState(false);

  const {
    sessions
  } = useSelector(authSetupSelector)

  const {
    detail_order_service,
  } = useSelector(orderSetupSelector)

  const ContentRate = ({ sessions, detail_order_service }) => {
    if (detail_order_service.category_order_service_name !== "end_case") {
      return ''
    }

    if (sessions?.role_name?.toLowerCase() === 'client') {
      return <Button
        onClick={
          () => setIsModalShowFormRating(!isModalShowFormRating)
        }
        color="danger"
        size="sm"
      >
        Berikan Rating
      </Button>
    }

    if (sessions?.role_name?.toLowerCase() === 'lawyer') {
      return <Button
        onClick={() => setIsModalShowRating(!isModalShowRating)}
        color="danger"
        size="sm"
      >
        Lihat Rating
      </Button>
    }
  }

  const handlerBackToList = () => {
    handlerBackTo()
  }

  return (
    <>
      <div className="page-content w-100 timeline overflow-auto pb-md-3 h-100 row gx-0 gx-sm-3">
        <Col lg={12} xl={9} className="mb-4">
          <div>
            <div
              className={`d-flex align-items-center gap-1 px-3 py-1 ${isChat ? "bg-white border border-2" : ""
                } `}
            >
              <Button
                onClick={handlerBackToList}
                color="transparent"
                className="me-0 px-0"
              >
                <IoIosArrowBack size={20} color="#495057" />
              </Button>
              <div className="page-title-right flex-grow-1">
                <ol className="breadcrumb m-0">
                  <BreadcrumbItem active>
                    <div className="text-dark font-size-14">
                      {breadcrumbTitle}
                    </div>
                  </BreadcrumbItem>
                </ol>
              </div>
              <div className="ms-1">
                <ContentRate
                  detail_order_service={detail_order_service}
                  sessions={sessions}
                />
              </div>
            </div>
            {children}
          </div>
        </Col>
        <Col className="my-4 my-md-0 mb-1 content-list-progress">
          <TimelineSidebar />
        </Col>
      </div>
      <ModalShowRate
        isOpen={isModalShowRating}
        toggle={
          () => setIsModalShowRating(!isModalShowRating)
        }
      />
      <ModalFormRate
        isOpen={isModalShowFormRating}
        toggle={
          () => setIsModalShowFormRating(!isModalShowFormRating)
        }
      />
    </>
  );
};

export default CaseLayoutDivorce;
