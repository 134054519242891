import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { checkLengthKeys, getExtensionAllowedFile, notify, reformatDate } from "../../../helpers/GlobalHelper";
import { usePostRefreshTokenMutation } from "../../auth/api/AuthAPI";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateStatusRefetchSession } from "../../../app/profileSlice";
import { Button, Col, Form, Input, Label, Row } from "reactstrap";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { authSetupSelector, setRefreshToken, setSessions, setToken } from "../../../app/authSlice";
import { usePostUploadAnotherFileMutation } from "../../file/api/FileAPI";
import { usePostActivationAccountLawyerMutation, usePutUpdateDataEducationMutation } from "../../user/api/UsersAPI";
import { Link } from "react-router-dom";
import SelectQualification from "../../../components/form/SelectQualification";

const FormEducationLawyer = ({ onChangeTab }) => {
    const [previewFileCertificate, setPreviewFileCertificate] = useState(null);
    const [isUpdateEducation, setIsUpdateEducation] = useState(false);
    const [defaultValueQualification, setDefaultValueQualification] = useState('');

    const [postRefreshToken, { isLoading: isLoadingRefetchSession }] = usePostRefreshTokenMutation()
    const [
        postUploadAnotherFile,
        {
            isLoading: isLoadingUploadFile,
            isError: isErrorUploadFile,
        }
    ] = usePostUploadAnotherFileMutation()
    const [
        putUpdateDataEducation,
        {
            isLoading: isLoadingUpdateDataEducation,
            isSuccess: isSuccessUpdateDataEducation,
            isError: isErrorUpdateDataEducation,
            error: errorUpdateDataEducation,
        }
    ] = usePutUpdateDataEducationMutation()
    const [
        postActivationAccountLawyer,
        {
            isLoading: isLoadingActivationAccountLawyer,
            isSuccess: isSuccessActivationAccountLawyer
        }
    ] = usePostActivationAccountLawyerMutation()

    const dispatch = useDispatch()
    const { sessions, refresh_token } = useSelector(authSetupSelector)

    const schema = yup.object({
        institution_name: yup.string().required("Nama institusi wajib di isi!"),
        join_date: yup.string().required("Tanggal masuk wajib di isi!"),
        end_date: yup.string().required("Tanggal lulus wajib di isi!"),
        qualification: yup.string().required("Kualifikasi wajib di isi!"),
        major: yup.string().required("Jurusan wajib di isi!"),
        score: yup.number().max(4.00, 'Maksimal skor atau nilai adalah 4.00').required("Skor/Nilai wajib di isi!"),
        file_certificate: yup.string().required("File Ijazah dan Tanda Anggota wajib di upload!"),
    })

    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue,
        clearErrors,
        setError
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            institution_name: "",
            join_date: "",
            end_date: "",
            qualification: "",
            major: "",
            score: 0.1,
            file_certificate: "",
            pkpa_number_certificate: "",
            upa_number_certificate: ""
        },
    });

    const handlerRefreshSession = async (isUpdateProfile = false) => {

        const resultGenerateNewToken = await postRefreshToken({ refresh_token }).unwrap()
        const dataSessions = resultGenerateNewToken.data;
        if (isUpdateProfile) {
            localStorage.setItem('data', JSON.stringify(dataSessions))
            dispatch(setSessions(dataSessions))
            return true;

        }

        localStorage.setItem('refreshToken', resultGenerateNewToken.refreshToken)
        localStorage.setItem('token', resultGenerateNewToken.token)

        dispatch(setToken(resultGenerateNewToken.token))
        dispatch(setRefreshToken(resultGenerateNewToken.refreshToken))
        return true;

    }

    const handleChangeCertificate = async (event) => {
        const file = event.target.files[0];

        if (!file) {
            setValue('file_certificate', "")
            setError("file_certificate", { type: 'required', message: 'File Ijazah dan Tanda Anggota wajib di isi!' })
            return false
        }

        if (file.size > 5242880) {
            setValue('file_certificate', "")
            setError("file_certificate", { type: 'required', message: 'File Ijazah dan Tanda Anggota wajib di isi!' })
            return notify('error', 5000, 'File Ijazah dan Tanda Anggota maksimal 5mb!');
        }

        const getExtensionFileAllowed = getExtensionAllowedFile('pdf')

        if (!getExtensionFileAllowed.includes(file.type)) {
            setValue('file_certificate', "")
            setError("file_certificate", { type: 'required', message: 'File Ijazah dan Tanda Anggota wajib di isi!' })
            return notify('error', 5000, 'File Ijazah dan Tanda Anggota hanya mendukung: pdf');
        }

        await handlerRefreshSession(false)
        const resultUploadFile = await postUploadAnotherFile({ file }).unwrap()
        setValue('file_certificate', resultUploadFile.data.file)
        clearErrors("file_certificate")
    }

    const handleOnSubmit = async (param) => {
        await handlerRefreshSession(false)
        // param.join_date = dayjs(param.join_date).format('YYYY-MM-DD')
        // param.end_date = dayjs(param.end_date).format('YYYY-MM-DD')
        param.join_date = reformatDate(param.join_date)
        param.end_date = reformatDate(param.end_date)
        await putUpdateDataEducation(param).unwrap()
    }

    const ContentButtonActivation = (params) => {
        const {
            isLoadingUploadFile,
            isLoadingUpdateDataEducation,
            handlerActivationAccount,
            statusVerifyJob
        } = params

        if ([3, 4].includes(statusVerifyJob)) {
            return (
                <>
                    <Button
                        type='button'
                        className="d-none d-lg-block ms-lg-2 mt-3"
                        color="info"
                        disabled={isLoadingUploadFile || isLoadingUpdateDataEducation ? true : false}
                        onClick={handlerActivationAccount}
                    >
                        Aktivasi Sebagai Lawyer
                    </Button>
                    <Button
                        type='button'
                        block
                        className="d-block d-lg-none ms-lg-2 mt-3"
                        color="info"
                        disabled={isLoadingUploadFile || isLoadingUpdateDataEducation ? true : false}
                        onClick={handlerActivationAccount}
                    >
                        Aktivasi Sebagai Lawyer
                    </Button>
                </>
            )
        }
    }

    const handlerActivationAccount = async () => {
        await handlerRefreshSession(false)
        await postActivationAccountLawyer().unwrap()
    }

    useEffect(() => {
        if (isLoadingRefetchSession) {
            dispatch(updateStatusRefetchSession(true))
        } else {
            setTimeout(() => {
                dispatch(updateStatusRefetchSession(false))
            }, 3000);
        }

    }, [isLoadingRefetchSession]);

    useEffect(() => {
        const checkingObj = checkLengthKeys(sessions)

        if (checkingObj && !isUpdateEducation) {
            let joinDate = sessions.education.join_date
            let endDate = sessions.education.end_date

            if (joinDate) {
                joinDate = new Date(sessions.education.join_date);
                joinDate.toString()
            }

            if (endDate) {
                endDate = new Date(sessions.education.end_date);
                endDate.toString()
            }

            const valueQualification = !sessions.education.qualification ? '-' : sessions.education.qualification

            setDefaultValueQualification(valueQualification)
            setPreviewFileCertificate(sessions.education.file_certificate)

            setValue('institution_name', sessions.education.institution_name)
            setValue('qualification', valueQualification)
            setValue('join_date', joinDate)
            setValue('end_date', endDate)
            setValue('major', sessions.education.major)
            setValue('score', sessions.education.score)
            setValue('file_certificate', sessions.education.file_certificate)
            setValue('pkpa_number_certificate', sessions.education.pkpa_number_certificate)
            setValue('upa_number_certificate', sessions.education.upa_number_certificate)
        }
    }, [sessions, isUpdateEducation]);

    useEffect(() => {
        if (isSuccessUpdateDataEducation) {
            notify('success', 30000, 'Berhasil merubah data edukasi dan sertifikat');
            setIsUpdateEducation(false)
            handlerRefreshSession(true)
            onChangeTab(4)
        }
    }, [isSuccessUpdateDataEducation]);

    useEffect(() => {
        if (isSuccessActivationAccountLawyer) {
            notify('success', 30000, 'Berhasil mengirimkan email aktivasi!');
            setIsUpdateEducation(false)
            handlerRefreshSession(true)
        }
    }, [isSuccessActivationAccountLawyer]);

    useEffect(() => {
        if (isErrorUpdateDataEducation) {
            notify('error', 30000, errorUpdateDataEducation.data.message);
        }
    }, [isErrorUpdateDataEducation]);

    return (
        <Form onSubmit={handleSubmit(handleOnSubmit)}>
            <Row>
                <Col xs={12}>
                    <Label className="fw-bold fs-6">
                        Pendidikan Terakhir / Sedang dijalani
                        <span className="text-orange">
                            *
                        </span>
                    </Label>
                </Col>
                <Col xs={12}>
                    <div className="mb-3">
                        <Controller
                            name='institution_name'
                            control={control}
                            render={({ field: { onChange, onBlur, value } }) => {
                                return <>
                                    <Input
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        value={value}
                                        type="text"
                                        className="form-control"
                                        placeholder="Nama Institusi"
                                    />
                                </>
                            }}
                        />
                    </div>
                    <div className="mb-3">
                        <span className="mt-2 text-orange text-start">
                            {errors.institution_name?.message}
                        </span>
                    </div>
                </Col>
                <Col xs={12} lg={6}>
                    <div className="mb-3">
                        <Controller
                            name='join_date'
                            control={control}
                            render={({ field: { onChange, onBlur, value } }) => {
                                return <>
                                    <DatePicker
                                        dateFormat="yyyy-MM-dd"
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        selected={value}
                                        placeholderText="Pilih tanggal masuk"
                                        className="form-control cursor-pointer"
                                        showMonthDropdown
                                        showYearDropdown
                                    />
                                </>
                            }}
                        />
                    </div>
                    <div className="mb-3">
                        <span className="mt-2 text-orange text-start">
                            {errors.join_date?.message}
                        </span>
                    </div>
                </Col>
                <Col xs={12} lg={6}>
                    <div className="mb-3">
                        <Controller
                            name='end_date'
                            control={control}
                            render={({ field: { onChange, onBlur, value } }) => {
                                return <>
                                    <DatePicker
                                        dateFormat="yyyy-MM-dd"
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        selected={value}
                                        placeholderText="Pilih tanggal keluar"
                                        className="form-control cursor-pointer"
                                        showMonthDropdown
                                        showYearDropdown
                                        maxDate={new Date()}
                                    />
                                </>
                            }}
                        />
                    </div>
                    <div className="mb-3">
                        <span className="mt-2 text-orange text-start">
                            {errors.end_date?.message}
                        </span>
                    </div>
                </Col>
                <Col xs={12}>
                    <div className="mb-3">
                        <Controller
                            name='qualification'
                            control={control}
                            render={({ field: { onChange, value } }) => {
                                return <>
                                    <SelectQualification
                                        onChange={(param) => onChange(param.value)}
                                        value={value}
                                    />
                                </>
                            }}
                        />
                    </div>
                    <div className="mb-3">
                        <span className="mt-2 text-orange text-start">
                            {errors.qualification?.message}
                        </span>
                    </div>
                </Col>
                <Col xs={12}>
                    <div className="mb-3">
                        <Controller
                            name='major'
                            control={control}
                            render={({ field: { onChange, onBlur, value } }) => {
                                return <>
                                    <Input
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        value={value}
                                        type="text"
                                        className="form-control"
                                        placeholder="Jurusan"
                                    />
                                </>
                            }}
                        />
                    </div>
                    <div className="mb-3">
                        <span className="mt-2 text-orange text-start">
                            {errors.major?.message}
                        </span>
                    </div>
                </Col>
                <Col xs={12} lg={12}>
                    <div className="mb-1">
                        <span className="mt-2 text-black-50 text-start">
                            Untuk IPK harap gunakan titik, contoh: 3.9
                        </span>
                    </div>
                    <div className="mb-3">
                        <Controller
                            name='score'
                            control={control}
                            render={({ field: { onChange, onBlur, value } }) => {
                                return <>
                                    <Input
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        value={value}
                                        type="number"
                                        step="0.01"
                                        min="0"
                                        max="4.00"
                                        className="form-control"
                                        placeholder="Skor / nilai (dalam skala 4.00)"
                                    />
                                </>
                            }}
                        />
                    </div>
                    <div className="mb-3">
                        <span className="mt-2 text-orange text-start">
                            {errors.score?.message}
                        </span>
                    </div>
                </Col>
                <Col xs={12}>
                    <Label className="fw-bold fs-6">
                        Dokumen Ijazah & Tanda Anggota
                        <span className="text-orange">
                            *
                        </span>
                    </Label>
                </Col>
                <Col xs={12} lg={12}>
                    <div className="mb-1">
                        <span className="mt-2 text-black-50 text-start">
                            Silakan upload dalam 1 file PDF (max. 5 mb) berisi: Ijazah, tanda anggota IKADIN, AAI, IPHI, HAPI, SPI, AKHI, HKHPM, APSI
                        </span>
                    </div>
                    <div className="mb-3">
                        <input
                            onChange={handleChangeCertificate}
                            type="file"
                            accept=".pdf,"
                            className="form-control"
                            placeholder="Upload"
                        />
                        <Controller
                            name='file_certificate'
                            control={control}
                            render={({ field: { value } }) => {
                                return <>
                                    <input
                                        value={value}
                                        type="text"
                                        hidden
                                    />
                                </>
                            }}
                        />
                    </div>
                    {
                        previewFileCertificate && <Link to={previewFileCertificate} target="_blank"
                        >
                            Lihat file
                        </Link>
                    }

                    <div className="mb-3">
                        <span className="mt-2 text-orange text-start">
                            {errors.file_certificate?.message}
                        </span>
                    </div>
                </Col>
                <Col xs={12}>
                    <Label className="fw-bold fs-6">
                        Sertifikat
                    </Label>
                </Col>
                <Col xs={12} lg={6}>
                    <div className="mb-3">
                        <Controller
                            name='pkpa_number_certificate'
                            control={control}
                            render={({ field: { onChange, onBlur, value } }) => {
                                return <>
                                    <Input
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        value={value}
                                        type="text"
                                        className="form-control"
                                        placeholder="Nomor sertifikat PKPA"
                                    />
                                </>
                            }}
                        />
                    </div>
                    <div className="mb-3">
                        <span className="mt-2 text-orange text-start">
                            {errors.pkpa_number_certificate?.message}
                        </span>
                    </div>
                </Col>
                <Col xs={12} lg={6}>
                    <div className="mb-3">
                        <Controller
                            name='upa_number_certificate'
                            control={control}
                            render={({ field: { onChange, onBlur, value } }) => {
                                return <>
                                    <Input
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        value={value}
                                        type="text"
                                        className="form-control"
                                        placeholder="Nomor sertifikat UPA"
                                    />
                                </>
                            }}
                        />
                    </div>
                    <div className="mb-3">
                        <span className="mt-2 text-orange text-start">
                            {errors.upa_number_certificate?.message}
                        </span>
                    </div>
                </Col>
                <Col xs={12}>
                    <div className="d-lg-flex justify-content-lg-end">
                        <Button
                            type='button'
                            className="d-none d-lg-block ms-lg-2 mt-3"
                            color="secondary"
                            disabled={isLoadingUploadFile || isLoadingUpdateDataEducation ? true : false}
                            onClick={() => onChangeTab(2)}
                        >
                            Kembali
                        </Button>
                        <Button
                            type='button'
                            block
                            className="d-block d-lg-none ms-lg-2 mt-3"
                            color="secondary"
                            disabled={isLoadingUploadFile || isLoadingUpdateDataEducation ? true : false}
                            onClick={() => onChangeTab(2)}
                        >
                            Kembali
                        </Button>
                        <ContentButtonActivation
                            handlerActivationAccount={handlerActivationAccount}
                            isLoadingUploadFile={isLoadingUploadFile}
                            isLoadingUpdateDataEducation={isLoadingUpdateDataEducation}
                            statusVerifyJob={!sessions.status_verify_job ? 0 : sessions.status_verify_job}
                        />
                        <Button
                            type='submit'
                            className="d-none d-lg-block ms-lg-2 mt-3"
                            color="primary"
                            disabled={isLoadingUploadFile || isLoadingUpdateDataEducation ? true : false}
                        >
                            Selanjutnya
                        </Button>
                        <Button
                            type='submit'
                            block
                            className="d-block d-lg-none ms-lg-2 mt-3"
                            color="primary"
                            disabled={isLoadingUploadFile || isLoadingUpdateDataEducation ? true : false}
                        >
                            Selanjutnya
                        </Button>
                    </div>
                </Col>
            </Row>
        </Form>
    );
}

export default FormEducationLawyer;
