import { useEffect, useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { BreadcrumbItem, Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap";
import { authSetupSelector } from "../../app/authSlice";
import HelmetTitle from "../../components/layout/HelmetTitle";
import TableComp from "../../components/table/TableComp";
import { historyHeaderTableTransactionMenu } from "../../docs/HeaderTable";
import { UpperCaseName, reformatDate, reformatToIDRCurrency } from "../../helpers/GlobalHelper";
import { useGetDetailHistoryTransactionsQuery } from "../payments/api/paymentAPI";
import ModalValidateWithdrawConsultation from "./components/ModalValidateWithdrawConsultation";
import { paymentSetupSelector, setDataDetailOrder, setDataHistoryTransaction, setRefetchingDetailHistoryTransaction } from "../../app/paymentSlice";
import ModalValidateWithdrawDP from "./components/ModalValidateWithdrawDP";
import ModalValidateWithdrawRemaining from "./components/ModalValidateWithdrawRemaining";

const DetailHistoryTransaction = ({ type }) => {
    const [isModalValidateWithdrawConsultation, setIsModalValidateWithdrawConsultation] = useState(false);
    const [isModalShowValidateDP, setIsModalShowValidateDP] = useState(false);
    const [isModalShowValidateWdRemainingMoney, setIsModalShowValidateWdRemainingMoney] = useState(false);

    const location = useLocation();
    const { caseId } = location.state;
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const {
        data: dataDetailHistoryTransaction,
        isLoading: isLoadingDetailHistoryTransaction,
        isError: isErrorDetailHistoryTransaction,
        refetch: refetchDetailHistoryTransaction,
    } = useGetDetailHistoryTransactionsQuery(
        {
            caseId
        },
        {
            refetchOnMountOrArgChange: false,
            refetchOnReconnect: true
        });

    const { sessions, token } = useSelector(authSetupSelector)
    const { isRefetchingDetailHistoryTransaction } = useSelector(paymentSetupSelector)

    const handlerBackToList = () => {
        dispatch(setRefetchingDetailHistoryTransaction(false))
        navigate(`/history-transaction-${type}`);
    }

    const handlerClickAction = ({ type, url }) => {
        if (type === 'payout_from_client') {
            window.open(url, '_blank', 'noopener,noreferrer');
        }

        if (type === 'disbursment_non_termin') {

        }
    }

    const handlerWithdrawFromConsltationCase = (historyTransaction, order) => {
        dispatch(setDataHistoryTransaction(historyTransaction))
        dispatch(setDataDetailOrder(order))

        setIsModalValidateWithdrawConsultation(true);
    }

    const handlerWithdrawTermin = (historyTransaction, order, type) => {
        dispatch(setDataHistoryTransaction(historyTransaction))
        dispatch(setDataDetailOrder(order))
        if (type === 'dp') {
            setIsModalShowValidateDP(true)
        }

        if (type === 'wd_remaining_money') {
            setIsModalShowValidateWdRemainingMoney(true)
        }
    }

    const Content = ({ isLoading, isError, data }) => {
        if (isLoading) {
            return <span>Sedang memuat...</span>
        } else {
            if (isError) {
                return <span className="text-primary">Terjadi kesalahan</span>
            } else {
                return <ContentDetailTransaction param={data} />
            }
        }
    }

    const ContentDetailTransaction = ({ param }) => {
        const { client, lawyer, order, historyTransaction, listHistoryTransaction } = param.data;

        let dataTable = []
        if (listHistoryTransaction.length > 0) {
            dataTable = listHistoryTransaction.map((value) => {
                let payout_name = 'DP';
                let action;
                let percent_amount = '-';
                let amount = '-';
                let tax_service = '-';

                if (value.transaction_type === 'payout_from_client') {
                    payout_name = 'Pembayaran Dari Klien';
                    amount = reformatToIDRCurrency(value.total_payout - value.tax_nominal)
                    tax_service = reformatToIDRCurrency(value.tax_nominal)
                    action = <Button
                        onClick={
                            () => handlerClickAction({ type: 'payout_from_client', url: value.snap_url, })
                        }
                        color="primary"
                        size="sm"
                        outline
                        className="rounded-4"
                    >
                        Lihat Bukti Pembayaran
                    </Button>
                }

                if (value.transaction_type === 'disbursment_non_termin') {
                    payout_name = 'Penarikan Seluruh Saldo Dari Kasus Ini';
                    action = ''
                    percent_amount = '100%';
                    tax_service = reformatToIDRCurrency(value.tax_nominal)
                    amount = reformatToIDRCurrency(value.withdrawal_nominal)
                }

                if (value.transaction_type === 'disbursment_termin') {

                    payout_name = value.name_order;
                    tax_service = reformatToIDRCurrency(value.tax_nominal)
                    amount = reformatToIDRCurrency(value.withdrawal_nominal)
                    percent_amount = `${value.total_percent}%`
                    action = ''
                }

                let status = '-';

                switch (value.name_category_detail_transaction) {
                    case 'successfuly_payout':
                        status = 'Telah Dibayarkan'
                        break;
                    case 'waiting_payout':
                        status = 'Menunggu Pembayaran'
                        break;
                    case 'request_to_client':
                        status = 'Mengajukan Ke Klien'
                        break;
                    case 'successful_withdrawal':
                        status = 'Penarikan Berhasil'
                        break;
                    case 'approved_from_client':
                        status = 'Diterima Oleh Klien'
                        break;
                    case 'approved_by_system':
                        status = 'Diterima Oleh Sistem'
                        break;
                    case 'rejected_from_client':
                        status = 'Ditolak Oleh Klien'
                        break;
                    case 'canceled_by_system':
                        status = 'Dibatalkan Oleh Sistem'
                        break;
                    case 'not_yet_paid':
                        status = 'Belum Di Bayarkan'
                        break;
                    case 'failed_payout':
                        status = 'Pembayaran Gagal'
                        break;
                    case 'expired':
                        status = 'Sesi Pembayaran Telah Kadaluarsa'
                        break;
                    case 'closed':
                        status = 'Telah berakhir'
                        break;
                    case 'failed_disbursment':
                        status = 'Penarikan Gagal'
                        break;
                    case 'pending_from_service_provider':
                        status = 'Menunggu Respon Payment Gateway'
                        break;
                    default:
                        '-'
                        break;
                }

                return {
                    payout_name,
                    amount,
                    tax_service,
                    percent_amount,
                    date_request: `${reformatDate(value.created_at, 'lll')} WIB`,
                    status,
                    action
                }
            })
        }

        return <Row>
            <Col xs={12}>
                <Label className="fw-bold font-size-14 mb-0">
                    Data Diri Klien
                </Label>
            </Col>
            <Col xs={12} className="mt-1">
                <Row className="mt-2 font-size-14">
                    <Col xs={12} lg={3}>
                        Nama Lengkap
                        {' '}
                        <span className="d-inline-block d-lg-none px-0 mx-0">:</span>
                    </Col>
                    <Col lg={1} className="d-none d-lg-block width-content">
                        :
                    </Col>
                    <Col xs={12} lg={8} className="mt-1-sm">
                        {UpperCaseName(client.full_name)}
                    </Col>
                </Row>
                <Row className="mt-2 font-size-14">
                    <Col xs={12} lg={3}>
                        Status Sebagai
                        {' '}
                        <span className="d-inline-block d-lg-none px-0 mx-0">:</span>
                    </Col>
                    <Col lg={1} className="d-none d-lg-block width-content">
                        :
                    </Col>
                    <Col xs={12} lg={8} className="mt-1-sm">
                        Pemohon
                    </Col>
                </Row>
                <Row className="mt-2 font-size-14">
                    <Col xs={12} lg={3}>
                        No Telepon
                        {' '}
                        <span className="d-inline-block d-lg-none px-0 mx-0">:</span>
                    </Col>
                    <Col lg={1} className="d-none d-lg-block width-content">
                        :
                    </Col>
                    <Col xs={12} lg={8} className="mt-1-sm">
                        {client.phone_number}
                    </Col>
                </Row>
                <Row className="mt-2 font-size-14">
                    <Col xs={12} lg={3}>
                        Email
                        {' '}
                        <span className="d-inline-block d-lg-none px-0 mx-0">:</span>
                    </Col>
                    <Col lg={1} className="d-none d-lg-block width-content">
                        :
                    </Col>
                    <Col xs={12} lg={8} className="mt-1-sm">
                        {client.email}
                    </Col>
                </Row>
            </Col>
            <Col xs={12} className="mt-3">
                <Label className="fw-bold font-size-14 mb-0">
                    Data Diri Pengacara
                </Label>
            </Col>
            <Col xs={12} className="mt-1">
                <Row className="mt-2 font-size-14">
                    <Col xs={12} lg={3}>
                        Nama Lengkap
                        {' '}
                        <span className="d-inline-block d-lg-none px-0 mx-0">:</span>
                    </Col>
                    <Col lg={1} className="d-none d-lg-block width-content">
                        :
                    </Col>
                    <Col xs={12} lg={8} className="mt-1-sm">
                        {UpperCaseName(lawyer.full_name)}
                    </Col>
                </Row>
                <Row className="mt-2 font-size-14">
                    <Col xs={12} lg={3}>
                        Status Sebagai
                        {' '}
                        <span className="d-inline-block d-lg-none px-0 mx-0">:</span>
                    </Col>
                    <Col lg={1} className="d-none d-lg-block width-content">
                        :
                    </Col>
                    <Col xs={12} lg={8} className="mt-1-sm">
                        Pengacara
                    </Col>
                </Row>
                <Row className="mt-2 font-size-14">
                    <Col xs={12} lg={3}>
                        No Telepon
                        {' '}
                        <span className="d-inline-block d-lg-none px-0 mx-0">:</span>
                    </Col>
                    <Col lg={1} className="d-none d-lg-block width-content">
                        :
                    </Col>
                    <Col xs={12} lg={8} className="mt-1-sm">
                        {lawyer.phone_number}
                    </Col>
                </Row>
                <Row className="mt-2 font-size-14">
                    <Col xs={12} lg={3}>
                        Email
                        {' '}
                        <span className="d-inline-block d-lg-none px-0 mx-0">:</span>
                    </Col>
                    <Col lg={1} className="d-none d-lg-block width-content">
                        :
                    </Col>
                    <Col xs={12} lg={8} className="mt-1-sm">
                        {lawyer.email}
                    </Col>
                </Row>
            </Col>
            <Col xs={12} className="mt-3 mb-0">
                <Label className="fw-bold font-size-14 mb-0">
                    Jenis Transaksi
                </Label>
            </Col>
            <Col xs={12} className="mt-1">
                <span>{order.name_service_rate}</span>
            </Col>
            <Col xs={12} className="mt-3 mb-0">
                <Label className="fw-bold font-size-14 mb-0">
                    Kasus ID
                </Label>
            </Col>
            <Col xs={12} className="mt-1">
                <span>{order.case_id}</span>
            </Col>
            <Col xs={12} className="mt-3 mb-0">
                <Label className="fw-bold font-size-14 mb-0">
                    Uang Tersedia
                </Label>
            </Col>
            <Col xs={12} className="mt-1">
                <span>{reformatToIDRCurrency(historyTransaction.available_amount)}</span>
            </Col>
            <Col xs={12} className="mt-4 mb-3">
                <div className="d-flex justify-content-between">
                    <Label className="fw-bold font-size-14">
                        Riwayat Transaksi
                    </Label>
                    <ContentButtonHeaderTransactionList {...param} />
                </div>
            </Col>
            <Col xs={12} className="mt-1">
                <TableComp
                    headerTable={historyHeaderTableTransactionMenu}
                    data={dataTable}
                    currentPage={1}
                    totalPage={1}
                    totalData={dataTable.length}
                    onClickPage={() => console.info('not found next page')}
                    headerClassTable="bg-danger opacity-50"
                    messageEmptyData="Tidak Ada Riwayat Transaksi"
                />
            </Col>
        </Row>
    }

    const ContentButtonHeaderTransactionList = (param) => {
        const { order, historyTransaction } = param.data;

        if (sessions.role_name.toLowerCase() !== 'client') {
            if (order.name_service_rate === 'Konsultasi online (chat) / jam' && order.name_category_order_service === 'end_case') {
                if (historyTransaction.total_percent_available_amount === 100) {
                    return <Button
                        onClick={() => handlerWithdrawFromConsltationCase(historyTransaction, order)}
                        color="primary"
                        size="sm">
                        Tarik Semua Uang Dari Kasus Ini
                    </Button>
                }
            }

            if (order.name_service_rate !== 'Konsultasi online (chat) / jam') {
                if (order.name_category_order_service === 'progress') {
                    if (historyTransaction.total_percent_available_amount > 50) {
                        return <Button
                            onClick={() => handlerWithdrawTermin(historyTransaction, order, 'dp')}
                            color="primary"
                            size="sm">
                            Tarik DP
                        </Button>
                    }
                } else {
                    if ([
                        'paid_by_the_client',
                        'success_disbursment_dp',
                        'success_disbursment_withdrawal_of_remaining_balance'
                    ].includes(historyTransaction.status)) {
                        let label = 'Tarik DP'
                        let typeButton = 'dp'
                        if (historyTransaction.total_percent_available_amount > 0 && historyTransaction.total_percent_available_amount < 51) {
                            label = 'Tarik Sisa Uang'
                            typeButton = 'wd_remaining_money'
                        }

                        return <Button
                            onClick={() => handlerWithdrawTermin(historyTransaction, order, typeButton)}
                            color="primary"
                            size="sm">
                            {label}
                        </Button>
                    }
                }

            }
        }
    }

    useEffect(() => {
        if (token) {
            refetchDetailHistoryTransaction()
        }
    }, [token]);

    useEffect(() => {
        if (type) {
            refetchDetailHistoryTransaction()
        }
    }, [type]);

    useEffect(() => {
        if (isRefetchingDetailHistoryTransaction) {
            dispatch(setRefetchingDetailHistoryTransaction(false))
            refetchDetailHistoryTransaction()
        }
    }, [isRefetchingDetailHistoryTransaction]);

    return <>
        <HelmetTitle
            title='Detail Riwayat Transaksi'
            meta="detail-history-transaksi"
        />
        <div className="page-content">
            <Container fluid>
                <Row>
                    <Col xs={12}>
                        <div className="d-flex">
                            <Button
                                onClick={handlerBackToList}
                                color="transparent"
                                className="me-0 px-0"
                            >
                                <IoIosArrowBack size={20} color="#495057" />
                            </Button>
                            <div className="page-title-right flex-grow-1">
                                <ol className="breadcrumb m-0">
                                    <BreadcrumbItem active>
                                        <div className="text-dark font-size-16">
                                            Kembali Ke List Riwayat Transaksi
                                        </div>
                                    </BreadcrumbItem>
                                </ol>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12}>
                        <Card className="mt-3">
                            <CardBody className="mx-3">
                                <Content
                                    isLoading={isLoadingDetailHistoryTransaction}
                                    isError={isErrorDetailHistoryTransaction}
                                    data={dataDetailHistoryTransaction}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
        <ModalValidateWithdrawConsultation
            isOpen={isModalValidateWithdrawConsultation}
            toggle={(val) => setIsModalValidateWithdrawConsultation(val)}
        />
        <ModalValidateWithdrawDP
            isOpen={isModalShowValidateDP}
            toggle={(val) => setIsModalShowValidateDP(val)}
        />
        <ModalValidateWithdrawRemaining
            isOpen={isModalShowValidateWdRemainingMoney}
            toggle={(val) => setIsModalShowValidateWdRemainingMoney(val)}
        />
    </>;
}

export default DetailHistoryTransaction;
