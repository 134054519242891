import { useDispatch, useSelector } from "react-redux";
import { Button, Form, FormGroup, Input, Label, Modal, ModalBody } from "reactstrap";
import { orderSetupSelector, setIsRefetchingDetailProgressTimeline, setIsRefetchingListFileProgressTimeline } from "../../../app/orderSlice";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { usePostCreateConditionForUploadFileMutation } from "../api/CaseAPI";
import { useEffect } from "react";
import { notify } from "../../../helpers/GlobalHelper";

const ModalFormRequestFile = ({ isOpen, toggle }) => {
    const dispatch = useDispatch()
    const {
        is_progress_timeline_active
    } = useSelector(orderSetupSelector)

    const [
        postCreateConditionForUploadFile,
        {
            isLoading: isLoadingCreateConditionUploadFile,
            isError: isErrorCreateConditionUploadFile,
            isSuccess: isSuccessCreateConditionUploadFile,
        }
    ] = usePostCreateConditionForUploadFileMutation();

    const schema = yup.object({
        setup_file_name: yup.string().required("Nama Dokumen wajib di isi!"),
    })

    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            setup_file_name: "",
        },
    });

    const handlerOnSubmit = async (param) => {
        param.id_order_timeline = is_progress_timeline_active
        await postCreateConditionForUploadFile(param).unwrap()
    }

    const handlerCloseModal = () => {
        setValue('setup_file_name', '')
        toggle()
    }

    useEffect(() => {
        if (isErrorCreateConditionUploadFile) {
            notify('error', 30000, 'Terjadi kesalahan saat memperoses');
        }
    }, [isErrorCreateConditionUploadFile]);


    useEffect(() => {
        if (isSuccessCreateConditionUploadFile) {
            dispatch(setIsRefetchingDetailProgressTimeline(true))
            // dispatch(setIsRefetchingListFileProgressTimeline(true))
            handlerCloseModal()
            notify('success', 30000, 'Berhasil menambahkan');
        }
    }, [isSuccessCreateConditionUploadFile]);

    return (
        <Modal
            className="border-0 custom-backdrop"
            isOpen={isOpen}
            toggle={handlerCloseModal}
            centered
            keyboard={false}
            backdrop={false}
            size='md'
        >
            <span className="text-center pt-4 pb-0 font-size-18 fw-bold">
                Tambah Jenis Dokumen
            </span>
            <ModalBody>
                <div
                    className='mb-3 text-center'
                >
                    Dokumen dapat dikirimkan dalam bentuk Foto / Video / Dokumen
                    Masukkan satu nama dokumen yang Anda minta
                </div>
                <Form onSubmit={handleSubmit(handlerOnSubmit)}>
                    <FormGroup>
                        <Label for="name_document">
                            Nama Dokumen
                            {' '}
                            <span className="text-orange">*</span>
                        </Label>
                        <Controller
                            name='setup_file_name'
                            control={control}
                            render={({ field: { onChange, onBlur, value } }) => {
                                return <>
                                    <Input
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        value={value}
                                        type="text"
                                        className="form-control"
                                        placeholder="Contoh: Kartu Keluarga"

                                    />
                                </>
                            }}
                        />
                        <div className="mt-1 mb-3">
                            <span className="mt-1 text-orange text-start">
                                {errors.setup_file_name?.message}
                            </span>
                        </div>
                    </FormGroup>


                    <div className="d-flex gap-2 mt-3 justify-content-center">
                        <Button
                            onClick={handlerCloseModal}
                            color="secondary"
                            outline
                            block
                            disabled={isLoadingCreateConditionUploadFile}
                        >
                            Kembali
                        </Button>
                        <Button
                            type="submit"
                            color="danger"
                            outline
                            block
                            disabled={isLoadingCreateConditionUploadFile}
                        >
                            {isLoadingCreateConditionUploadFile ? 'Memperoses...' : 'Simpan'}
                        </Button>
                    </div>
                </Form>
            </ModalBody>
        </Modal>
    );
}

export default ModalFormRequestFile;
