import React, { useEffect, useRef, useState } from 'react';
import { BsFolder } from 'react-icons/bs';
import { FaFile, FaFileDownload, FaFileUpload } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Label, Row, Table } from 'reactstrap';
import { orderSetupSelector, setIsRefetchingDetailProgressTimeline } from '../../../app/orderSlice';
import EmptyFile from "../../../assets/images/empty-file.png";
import { UpperCaseName, getExtensionAllowedFile, notify } from '../../../helpers/GlobalHelper';
import { usePostUploadFileByIdProgressTimelineMutation } from '../api/CaseAPI';

const FormDetailProgressTimeline = ({ isCondition, dataDetailProgress }) => {
    const [dataFile, setDataFile] = useState([]);
    const [choosedFileList, setChoosedFileList] = useState('');
    const [isEnd, setIsEnd] = useState(false);

    const inputRef = useRef(null);
    const dispatch = useDispatch()

    const {
        detail_order_service,
    } = useSelector(orderSetupSelector)

    const [
        postUploadFileByIdProgressTimeline,
        {
            isError: isErrorUploadFileByIdProgressTimeline,
            isSuccess: isSuccessUploadFileByIdProgressTimeline,
            error: errorUploadFileByIdProgressTimeline,
        }
    ] = usePostUploadFileByIdProgressTimelineMutation();

    const ContentListFile = ({ isCondition, dataFile, isEnd }) => {
        if (dataFile.length > 0) {
            return <div className="table-responsive">
                <Table className="table mt-4">
                    <thead className="table-primary">
                        <tr>
                            <th className="fw-bold ps-5 text-dark opacity-75">
                                Dokumen
                            </th>
                            <th className="fw-bold ps-5 text-center text-dark opacity-75">
                                Tipe File
                            </th>
                            <th className="fw-bold text-center text-dark opacity-75"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {dataFile?.map((val) => {
                            const fileName = isCondition === 'form_information' ? val.file_name : val.setup_file_name
                            const extensionAllowed = isCondition === 'form_information' ? val.extension_file_uploaded : 'Video/Foto/Dokumen'
                            return <tr key={val.id}>
                                <td className="ps-5 py-3 fw-semibold">
                                    {fileName}
                                </td>
                                <td className="ps-5 text-center py-3 fw-semibold">
                                    {!extensionAllowed ? '-' : extensionAllowed}
                                </td>
                                <td className="py-3">
                                    <div className="d-flex gap-3 justify-content-center">
                                        {
                                            val.url
                                            &&
                                            <FaFileDownload
                                                onClick={() => {
                                                    const redirectUrl = window.open();
                                                    redirectUrl.location.href = val.url;
                                                }}
                                                size={24}
                                                className="cursor-pointer text-primary"
                                                title="download file ini"
                                            />
                                        }

                                        {
                                            !val.url
                                            &&
                                            <FaFile
                                                size={24}
                                                className="text-secondary"
                                                title="belum ada file"
                                            />
                                        }

                                        {
                                            isCondition === 'form_upload_file_from_client' && !isEnd
                                                ?
                                                <FaFileUpload
                                                    // onClick={() => {
                                                    //     inputRef.current?.click()
                                                    //     setChoosedFileList(val)
                                                    // }}
                                                    onClick={() => handlerTriggerInputFile(val)}
                                                    size={24}
                                                    className="text-success cursor-pointer"
                                                    title="upload file"
                                                />
                                                :
                                                <></>
                                        }
                                    </div>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </Table>
            </div>
        } else {
            return <div className="text-center d-flex justify-content-center flex-column align-items-center">
                <img src={EmptyFile} className="empty-file" />
                <span>Tidak ada dokumen</span>
            </div>
        }
    }

    const handlerTriggerInputFile = (param) => {
        setChoosedFileList(param)
        inputRef.current?.click()
    }

    const handlerUploadFile = async (event) => {
        if (event.target.files.length > 0) {
            const getExtensionFileAllowed = getExtensionAllowedFile('word-ppt-img-vid-pdf');

            if (!getExtensionFileAllowed.includes(event.target.files[0].type)) {
                setChoosedFileList('')
                return notify('warn', 30000, 'Ektensi file yang anda masukan tidak diperbolehkan!');
            } else {
                const param = {
                    id_file: choosedFileList.id,
                    file: event.target.files[0],
                }
                await postUploadFileByIdProgressTimeline(param).unwrap();
            }
        } else {
            setChoosedFileList('')
        }
    }

    useEffect(() => {
        if (detail_order_service.category_order_service_name === 'end_case') {
            setIsEnd(true);
        } else {
            setIsEnd(false);
        }
    }, [detail_order_service]);

    useEffect(() => {
        setDataFile(dataDetailProgress.file)
    }, [dataDetailProgress]);


    useEffect(() => {
        if (isSuccessUploadFileByIdProgressTimeline) {
            dispatch(setIsRefetchingDetailProgressTimeline(true))
            setChoosedFileList('')
            notify('success', 30000, 'Berhasil mengupload file');
        }
    }, [isSuccessUploadFileByIdProgressTimeline]);

    useEffect(() => {
        if (isErrorUploadFileByIdProgressTimeline) {
            setChoosedFileList('')
            notify('error', 30000, errorUploadFileByIdProgressTimeline.data.message);
        }
    }, [isErrorUploadFileByIdProgressTimeline]);

    return (
        <>
            <Row>
                <div className="pb-1 fw-bold mt-2 font-size-14">
                    {UpperCaseName(dataDetailProgress?.custom_title)}
                </div>
            </Row>
            <Row className="mt-4">
                <div className="d-flex align-items-center gap-3">
                    <BsFolder size={20} color="#F54748" />
                    <span className="font-size-14 fw-bold">Dokumen Terlampir</span>
                </div>
                <ContentListFile isCondition={isCondition} dataFile={dataFile} isEnd={isEnd} />
            </Row>
            <Row className='mt-4'>
                <Col xs={12}>
                    <Label for="description">
                        <span className="font-size-14 fw-bold">
                            Deskripsi
                        </span>
                    </Label>
                </Col>
                <Col xs={12}>
                    <p className='fst-light'>
                        {dataDetailProgress?.description}
                    </p>
                </Col>
                <input
                    ref={inputRef}
                    type="file"
                    hidden
                    onChange={handlerUploadFile}
                    accept="image/*,.pdf,video/*,.ppt,.pptx,.doc,.docx"
                />
            </Row>
        </>
    );
}

export default FormDetailProgressTimeline;
