import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

const FooterAuth = () => {
  return (
    <footer className="footer">
      <Container fluid={true}>
        <Row>
          <Col md={12}>{new Date().getFullYear()} © ceraionline.com</Col>
        </Row>
      </Container>
    </footer>
  );
};

export default FooterAuth;
