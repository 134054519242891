import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const FooterForm = ({ type, from }) => {
    const [url, setUrl] = useState('/');
    const [title, setTitle] = useState('lorem');
    const [description, setDescription] = useState('lorem');

    const [urlChangeRole, setUrlChangeRole] = useState('/');
    const [titleChangeRole, setTitleChangeRole] = useState('lorem');

    useEffect(() => {
        if (from === 'login') {
            setTitle('Daftar')
            setDescription('Belum memiliki akun?')

            if (type === 'lawyer') {
                setUrl('/register-lawyer')
            } else {
                setUrl('/register')
            }
        } else {
            setTitle('Masuk')
            setDescription('Sudah memiliki akun?')

            if (type === 'lawyer') {
                setUrl('/login-lawyer')
            } else {
                setUrl('/login')
            }
        }

        if (type === 'lawyer') {
            setUrlChangeRole('/register')
            setTitleChangeRole('Klien')
        } else {
            setUrlChangeRole('/register-lawyer')
            setTitleChangeRole('Pengacara')
        }
    }, [type, from]);
    return (
        <>
            <div className="d-none d-lg-block mb-3 text-center fs-5">
                {description} {' '} <Link to={url}>
                    {title}
                </Link>
            </div>
            <div className="d-block d-lg-none mb-3 text-center">
                {description} {' '} <Link to={url}>
                    {title}
                </Link>
            </div>

            <p className="d-none d-lg-block fs-5 text-center">
                atau
            </p>

            <p className="d-block d-lg-none text-center">
                atau
            </p>

            <div className="d-none d-lg-block mb-3 text-center fs-5">
                Daftar atau masuk sebagai <Link to={urlChangeRole}>
                    {titleChangeRole}
                </Link>
            </div>
            <div className="d-block d-lg-none mb-3 text-center">
                Daftar atau masuk sebagai <Link to={urlChangeRole}>
                    {titleChangeRole}
                </Link>
            </div>
        </>
    );
}

export default FooterForm;
