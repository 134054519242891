import { createSlice } from "@reduxjs/toolkit";
import { checkLengthKeys } from "../helpers/GlobalHelper";

const initialState = {
    listBanks: [],
    listAccountBanks: [],
    accountBankMain: {
        account_bank_id: null,
        account_bank_id_user: null,
        account_bank_name: null,
        account_bank_number: null,
        account_bank_file_kyc_verify: null,
        account_bank_file_recognition: null,
        is_main: null,
        bank_code: null,
        bank_name: null,
        gateway_from: null,
        account_bank_type: null,
        created_at: null,
        updated_at: null,
    },
    isRefetchingDetailHistoryTransaction: false,
    dataHistoryTransaction: {},
    dataDetailOrder: {},
};

const paymentSlice = createSlice({
    name: 'paymentSetup',
    initialState,
    reducers: {
        setDataListBank: (state, action) => {
            const { email } = action.payload
            state.email = email
        },
        setDataListAccountBank: (state, action) => {
            state.listAccountBanks = action.payload
        },
        setMainAccountBank: (state, action) => {
            if (checkLengthKeys(action.payload)) {
                state.accountBankMain = action.payload
            } else {
                state.accountBankMain = {
                    account_bank_id: null,
                    account_bank_id_user: null,
                    account_bank_name: null,
                    account_bank_number: null,
                    account_bank_file_kyc_verify: null,
                    account_bank_file_recognition: null,
                    is_main: null,
                    bank_code: null,
                    bank_name: null,
                    gateway_from: null,
                    account_bank_type: null,
                    created_at: null,
                    updated_at: null,
                }
            }
        },
        setEmptyListAccountBank: (state) => {
            state.listAccountBanks = [];
            state.accountBankMain = {
                account_bank_id: null,
                account_bank_id_user: null,
                account_bank_name: null,
                account_bank_number: null,
                account_bank_file_kyc_verify: null,
                account_bank_file_recognition: null,
                is_main: null,
                bank_code: null,
                bank_name: null,
                gateway_from: null,
                account_bank_type: null,
                created_at: null,
                updated_at: null,
            }
        },
        setRefetchingDetailHistoryTransaction: (state, action) => {
            state.isRefetchingDetailHistoryTransaction = action.payload
        },
        setDataHistoryTransaction: (state, action) => {
            state.dataHistoryTransaction = action.payload
        },
        setDataDetailOrder: (state, action) => {
            state.dataDetailOrder = action.payload
        },
    }
})

export const paymentSetupSelector = (state) => state.paymentSetup
export const {
    setDataListBank,
    setDataListAccountBank,
    setMainAccountBank,
    setEmptyListAccountBank,
    setRefetchingDetailHistoryTransaction,
    setDataHistoryTransaction,
    setDataDetailOrder,
} = paymentSlice.actions
export default paymentSlice.reducer;