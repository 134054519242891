import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    email: null,
    token: null,
    refresh_token: null,
    sessions: {}
};

const authSlice = createSlice({
    name: 'authSetup',
    initialState,
    reducers: {
        forgotPassword: (state, action) => {
            const { email } = action.payload
            state.email = email
        },
        resendVerifyEmailRegister: (state, action) => {
            const { email } = action.payload
            state.email = email
        },
        setToken: (state, action) => {
            state.token = action.payload
        },
        setRefreshToken: (state, action) => {
            state.refresh_token = action.payload
        },
        setSessions: (state, action) => {
            state.sessions = action.payload
        },
        setEmptySession: (state) => {
            state.email = null;
            state.token = null;
            state.refresh_token = null;
            state.sessions = {};
        }
    }
})

export const authSetupSelector = (state) => state.authSetup
export const {
    forgotPassword,
    resendVerifyEmailRegister,
    setToken,
    setRefreshToken,
    setSessions,
    setEmptySession
} = authSlice.actions
export default authSlice.reducer;