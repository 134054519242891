import React, { useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { editProgress, setProgress } from "../../app/timeline";
import TimelineLayout from "./components/TimelineLayout";
import TimelineDetailsPreview from "./components/TimelineDetailsPreview";
import { addNewFileData } from "../../app/caseSlice";

function TimelineDetails({ }) {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [files, setFiles] = useState([]);
  const [desc, setDesc] = useState("");
  const timeline = useSelector((state) => state.progressSetup);

  const handleSaveTimeline = (val) => {
    //set progress timeline to sidebar
    if (state.isCreated) {
      dispatch(setProgress(val));
    } else if (state.isEdit) {
      dispatch(editProgress({ ...val, id: state?.item?.id }));
    }
    navigate("/chat-view");
  };

  const handleSaveReqDocFile = (data) => {
    dispatch(addNewFileData(data));
  };

  const handleSaveRequestFiles = (data) => {
  };

  useLayoutEffect(() => {
    //if data create from modal
    if (state?.title && state?.to) {
      setTitle(state.title);
    } else {
      // if data from side bar where store at state or db
      setTitle(state?.item?.progressName);
      setDesc(state?.item?.progressDescription);
    }

    if (state.isEdit || state.isPreview) {
      setFiles(state?.item?.files);
    } else if (state.isCreated) {
      setDesc("");
      setFiles([]);
    }
    if (
      !timeline?.progress?.length &&
      !state.isCreated &&
      !state?.title.includes("Request Berkas/File")
    ) {
      navigate("/chat-view");
    }
  }, [state, timeline]);

  return (
    <TimelineLayout
      timeline={timeline}
      title={title}
      setTitle={setTitle}
      onSaveEdit={handleSaveTimeline}
      files={files}
      desc={desc}
      setDesc={setDesc}
      mode={state?.isPreview ? "preview" : state.isEdit ? "edit" : "created"}
      item={state.item}
      onSaveReqDocFileModal={handleSaveReqDocFile}
      onSaveRequestFiles={handleSaveRequestFiles}
    />
  );
}

export default TimelineDetails;
