import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Row } from "reactstrap";
import { orderSetupSelector, setChooseConsultationSession, setModalCase } from "../../../app/orderSlice";
import LinkifyComponent from "../../../components/linkify/linkifyComponent";
import { reformatDate } from "../../../helpers/GlobalHelper";

function CardChat({ children,
  dataBubble,
  sessions,
  clientName,
  lawyerName
}) {
  const [titleBubble, setTitleBubble] = useState('');
  const [isSender, setIsSender] = useState(false);
  const [date, setDate] = useState('');
  const [dataButton, setDataButton] = useState([]);

  const dispatch = useDispatch()

  const {
    detail_order_service,
    consultation_sessions,
  } = useSelector(orderSetupSelector)

  const handlerClickButton = (param, consultation_session = null) => {
    if (param.action_url) {
      return window.open(param.action_url, '_blank', 'noopener,noreferrer')
    }

    switch (param.action_name) {
      case 'canceled_by_client':
        dispatch(setModalCase({ isActive: true }))
        break;
      case 'rejected_from_lawyer':
        dispatch(setModalCase({ modalName: 'reject', isActive: true }))
        break;
      case 'reschedule_consultation':
        dispatch(setModalCase({ modalName: 'reschdule_consultation', isActive: true }))
        break;
      case 'consultation_time':
        dispatch(setChooseConsultationSession(consultation_session))
        dispatch(setModalCase({ modalName: 'approve_consultation', isActive: true }))
        break;
      case 'preview_detail_case':
        const roleName = sessions.role_name.toLowerCase() === 'lawyer' ? 'lawyer' : 'client';
        window.open(`${window.location.origin}/case-${roleName}/detail-info-case-${roleName}/${detail_order_service.case_id}`, '_blank', 'noopener,noreferrer');

        break;
      default:
        if (detail_order_service.service_name === 'Konsultasi online (chat) / jam') {
          dispatch(setChooseConsultationSession(consultation_session))
          dispatch(setModalCase({ modalName: 'approve_consultation', isActive: true }))
        } else {
          dispatch(setModalCase({ modalName: 'approve_divorce', isActive: true }))
        }
        break;
    }
  }

  useEffect(() => {
    if (dataBubble.from === 1) {
      setTitleBubble('Sistem')
      setIsSender(false)
    } else if (dataBubble.from === sessions.id) {
      setTitleBubble('Kamu')
      setIsSender(true)
    } else {
      if (dataBubble.from === detail_order_service.id_lawyer) {
        setTitleBubble(detail_order_service.lawyer_name)
      } else {
        setTitleBubble(detail_order_service.client_name)
      }
      setIsSender(false)
    }

    if (!dataBubble.created_at) {
      setDate('')
    } else {
      setDate(reformatDate(dataBubble.created_at, 'LLL'))
    }

    if (!dataBubble.button) {
      setDataButton([]);
    } else {
      setDataButton(dataBubble.button);
    }
  }, [dataBubble, sessions, clientName, lawyerName, detail_order_service]);

  const ContentMessage = ({ message }) => {
    if (!message) {
      return <p className="mb-0"></p>
    } else {
      return <LinkifyComponent content={message} />
    }
  }

  const ContentButton = ({ data }) => {
    if (data) {
      return data.map((val) => {
        let chooseSessionConsultations = []
        if (consultation_sessions) {
          chooseSessionConsultations = consultation_sessions.filter((val) => val.id_chat_reply === dataBubble.id)

          chooseSessionConsultations = chooseSessionConsultations.length > 1 ? chooseSessionConsultations : chooseSessionConsultations[0]
        }

        if (!val.isHidden) {
          if (['3', 3].includes(val.show_to)) {
            return <Button
              onClick={() => handlerClickButton(val)}
              key={val.id}
              color="transparent"
              className={` my-1 rounded-4 ${isSender ? 'btn-primary' : 'btn-outline-primary'}`}
              size="sm"
              block
              disabled={!val.is_active ? true : false}
            >
              {val.name}
            </Button>
          } else if (isSender) {
            if (['2', 2].includes(val.show_to)) {
              if (val.action_name === 'consultation_time') {
                return consultation_sessions.map((valConsultation) => {
                  if (valConsultation.id_chat_reply === dataBubble.id) {
                    const mapConsultationTime = reformatDate(`${valConsultation.date} ${valConsultation.time}`, 'LLLL');

                    return <Button
                      onClick={() => handlerClickButton(val, valConsultation)}
                      key={val.id}
                      color="transparent"
                      className={` my-1 rounded-4 ${isSender ? 'btn-primary' : 'btn-outline-primary'}`}
                      size="sm"
                      block
                      disabled={!val.is_active ? true : false}
                    >
                      {mapConsultationTime}
                    </Button>

                  }
                })
              } else {
                return <Button
                  onClick={() => handlerClickButton(val, chooseSessionConsultations)}
                  key={val.id}
                  color="transparent"
                  className={` my-1 rounded-4 ${isSender ? 'btn-primary' : 'btn-outline-primary'}`}
                  size="sm"
                  block
                  disabled={!val.is_active ? true : false}
                >
                  {val.name}
                </Button>
              }
            }
          } else {
            if (['1', 1].includes(val.show_to)) {
              if (val.action_name === 'consultation_time') {
                return consultation_sessions.map((valConsultation) => {
                  if (valConsultation.id_chat_reply === dataBubble.id) {
                    const mapConsultationTime = reformatDate(`${valConsultation.date} ${valConsultation.time}`, 'LLLL');

                    return <Button
                      onClick={() => handlerClickButton(val, valConsultation)}
                      key={val.id}
                      color="transparent"
                      className={` my-1 rounded-4 ${isSender ? 'btn-primary' : 'btn-outline-primary'}`}
                      size="sm"
                      block
                      disabled={!val.is_active ? true : false}
                    >
                      {mapConsultationTime}
                    </Button>

                  }
                })
              } else {
                return <Button
                  onClick={() => handlerClickButton(val, chooseSessionConsultations)}
                  key={val.id}
                  color="transparent"
                  className={` my-1 rounded-4 ${isSender ? 'btn-primary' : 'btn-outline-primary'}`}
                  size="sm"
                  block
                  disabled={!val.is_active ? true : false}
                >
                  {val.name}
                </Button>
              }
            }
          }
        }
      })
    }
  }

  return (
    <Row
      className={`d-flex flex-column mb-5 ${isSender ? "align-items-end" : ""}`}
    >
      <div className="card-input-wrapper">
        {
          titleBubble
          &&
          <div
            className={`${isSender ? 'text-end' : 'text-start'}`}>
            {titleBubble}
          </div>
        }
        <Card
          className={`${isSender ? "chat-card-sender" : "chat-card-reciever bg-transparent"
            } d-flex flex-column gap-4 mb-2 p-3 font-size-12`}
        >
          <ContentMessage message={children} />
          {dataButton.length > 0 && <Row className="px-2">
            <ContentButton data={dataButton} />
          </Row>}
        </Card>
        <div
          className={`d-flex gap-2 font-size-18 ps-0 ${isSender ? "justify-content-end" : ""
            }`}
        >
          <div className="font-size-12 fw-light">{date}</div>
        </div>
      </div>
    </Row>
  );
}

export default CardChat;
