import { Col, Row } from "reactstrap";
import ContentLeftForm from "./components/ContentLeftForm";
import ForgotPasswordContent from "./components/ForgotPasswordContent";
import HelmetTitle from "../../components/layout/HelmetTitle";

const ForgotPassword = ({ type }) => {
    return (
        <>
            <HelmetTitle
                title={`Lupa Kata Sandi | ${type == 'client' ? 'Klien' : 'Lawyer'}`}
                meta="forgotpassword, forgot, password, ceraionline"
            />
            <Row className="justify-content-center align-self-center mt-8 mt-lg-12 px-2 px-lg-15 min-vh-100">
                <Col
                    className="d-none d-lg-inline-block text-center"
                    md={6}
                    lg={4}
                    xl={6}
                >
                    <ContentLeftForm
                        ImgContent="/image-full@1x.png"
                        description='Lupa Password'
                    />
                </Col>
                <Col sm={12} md={8} lg={8} xl={6}>
                    <ForgotPasswordContent type={type} />
                </Col>
            </Row>
        </>
    );
}

export default ForgotPassword;
