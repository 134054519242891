import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Badge, Button, Col, Container, Row } from "reactstrap";
import { authSetupSelector, setRefreshToken, setSessions, setToken } from "../../app/authSlice";
import EmptyTransaction from "../../assets/images/empty-transaction.png";
import Breadcrumb from "../../components/layout/Breadcrumb";
import HelmetTitle from "../../components/layout/HelmetTitle";
import NotifyDevelopmentProcessing from "../../components/modal/NotifyDevelopmentProcessing";
import TableComp from "../../components/table/TableComp";
import { caseHeaderTableTransactionsMenu } from "../../docs/HeaderTable";
import { UpperCaseName, reformatToIDRCurrency } from "../../helpers/GlobalHelper";
import { usePostRefreshTokenMutation } from "../auth/api/AuthAPI";
import { useGetListHistoryTransactionsQuery } from "../payments/api/paymentAPI";
import WidgetBankAccount from "./components/WidgetBankAccount";

const HistoryTransaction = ({ type }) => {
  const role_name = type === "client" ? "Klien" : "Lawyer";

  const [dataList, setDataList] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [isModalNotifyDevelopment, setIsModalNotifyDevelopment] = useState(false);

  const { token, refresh_token } = useSelector(authSetupSelector)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [postRefreshToken] = usePostRefreshTokenMutation();

  const {
    data: dataListHistoryTransactions,
    refetch: refetchListHistoryTransactions
  } = useGetListHistoryTransactionsQuery(
    {
      limit: 10,
      page,
      keyword
    },
    {
      refetchOnMountOrArgChange: false,
      refetchOnReconnect: true
    });

  const handlerRefetchListHistoryTransactions = async () => {
    refetchListHistoryTransactions()
  }

  const handlerRefreshSession = async () => {
    const resultGenerateNewToken = await postRefreshToken({ refresh_token }).unwrap()
    const dataSessions = resultGenerateNewToken.data;

    localStorage.setItem('data', JSON.stringify(dataSessions))
    dispatch(setSessions(dataSessions))

    localStorage.setItem('refreshToken', resultGenerateNewToken.refreshToken)
    localStorage.setItem('token', resultGenerateNewToken.token)

    dispatch(setToken(resultGenerateNewToken.token))
    dispatch(setRefreshToken(resultGenerateNewToken.refreshToken))
    return true
  }

  const handlerChangePage = (param) => {
    setPage(param)
  }

  const handleViewDetails = (caseId) => {
    navigate(`/history-transaction-${type}/detail`, { state: { caseId } });
  };

  const handlerMapData = async (param) => {
    if (!param) {
      setDataList([])
      setKeyword('')
      setPage(1)
      setTotalPage(1)
      setTotalData(0)
      return false
    }

    if (!param.data) {
      setDataList([])
      setKeyword('')
      setPage(1)
      setTotalPage(1)
      setTotalData(0)
      return false
    } else {
      setPage(param.paginate.currentPage)
      setTotalPage(param.paginate.totalPages)
      setTotalData(param.paginate.totalItems)
      const data = await Promise.all(
        param.data.map((val) => {
          let status = 'Tidak Ada Riwayat Transaksi';
          let badgeColor = 'warning';
          if (val.status_transaction) {
            switch (val.status_transaction) {
              case 'not_paid_by_the_client':
                status = 'Tidak Dibayar Oleh Klien'
                badgeColor = 'info'
                break;
              case 'paid_by_the_client':
                status = 'Telah Dibayar Oleh Klien'
                badgeColor = 'info'
                break;
              case 'waiting_payout_from_client':
                status = 'Menunggu Konfirmasi Pembayaran'
                badgeColor = 'info'
                break;
              case 'process_disbursment_from_lawyer':
                status = 'Proses Pencairan Dari Pengacara'
                badgeColor = 'info'
                break;
              case 'approve_disbursment_by_client':
                status = 'Pencairan Telah Dikonfirmasi Klien'
                badgeColor = 'info'
                break;
              case 'approve_disbursment_by_system':
                status = 'Pencairan Telah Dikonfirmasi Sistem'
                badgeColor = 'info'
                break;
              case 'reject_disbursment_by_client':
                status = 'Pencairan Telah Ditolak Klien'
                badgeColor = 'info'
                break;
              case 'request_disbursment_from_lawyer':
                status = 'Pengajuan Pencairan'
                badgeColor = 'info'
                break;
              case 'failed_disbursment':
                status = 'Pencairan Gagal'
                badgeColor = 'info'
                break;
              case 'success_disbursment_dp':
                status = 'Pencairan Dp Berhasil'
                badgeColor = 'info'
                break;
              case 'success_disbursment_all_amount':
                status = 'Pencairan Semua Uang Berhasil'
                badgeColor = 'info'
                break;
              case 'success_disbursment_termin':
                status = 'Pencairan Termin Berhasil'
                badgeColor = 'info'
                break;
              case 'failed_disbursment_dp':
                status = 'Pencairan Dp Berhasil'
                badgeColor = 'info'
                break;
              case 'failed_disbursment_all_amount':
                status = 'Pencairan Semua Uang Berhasil'
                badgeColor = 'info'
                break;
              case 'failed_disbursment_termin':
                status = 'Pencairan Termin Gagal'
                badgeColor = 'info'
                break;
              case 'failed_disbursment_withdrawal_of_remaining_balance':
                status = 'Pencairan Sisa Uang Gagal'
                badgeColor = 'info'
                break;
              case 'success_disbursment_withdrawal_of_remaining_balance':
                status = 'Pencairan Sisa Uang Berhasil'
                badgeColor = 'info'
                break;
              case 'done_transaction':
                status = 'Transaksi Selesai'
                badgeColor = 'success'
                break;
              default:
                status = 'Tidak Ada Riwayat Transaksi'
                badgeColor = 'warning'
                break;
            }
          }

          return {
            case_id: val.case_id,
            client_name: UpperCaseName(val.client_name),
            service_name: val.service_name,
            price_service: reformatToIDRCurrency(val.price_service),
            status_transaction: <Badge pill color={badgeColor} className={`p-2 ${badgeColor === 'warning' ? 'text-secondary' : ''} `}>
              <span className="font-size-10">
                {UpperCaseName(status)}
              </span>
            </Badge>,
            tools: status.toLowerCase() !== 'tidak ada riwayat transaksi' && <>
              <Button
                onClick={() => handleViewDetails(val.case_id)}
                color="primary"
                className="btn-primary btn-sm"
              >
                Lihat
              </Button>
            </>
          }
        })
      )
      setDataList(data)
    }
  }

  useEffect(() => {
    setDataList([])
    setTimeout(() => {
      setPage(1);
      setKeyword('');
      handlerRefetchListHistoryTransactions();
    }, 3000);
  }, [type]);

  useEffect(() => {
    handlerMapData(dataListHistoryTransactions)
  }, [dataListHistoryTransactions]);

  useEffect(() => {
    if (token) {
      handlerRefetchListHistoryTransactions();
    }
  }, [token]);

  return (
    <>
      <HelmetTitle
        title={`Riwayat Transaksi | ${role_name}`}
        meta="riwayattransaksik, klien, riwayat_transaksi, ceraionline"
      />
      <div className="page-content px-md-4coma5">
        <Container fluid>
          <Breadcrumb breadcrumbItem="Riwayat Transaksi" />
          <Row>
            <Col xs={12} md={6}>
              {type !== "client" && <WidgetBankAccount />}
            </Col>
            <Col xs={12} className="mt-3">
              {totalData > 0 ? (
                <TableComp
                  headerTable={caseHeaderTableTransactionsMenu}
                  data={dataList}
                  currentPage={page}
                  totalPage={totalPage}
                  totalData={totalData}
                  onClickPage={handlerChangePage}
                />
              ) : (
                <div className="empty-transaction">
                  <img src={EmptyTransaction} className="empty-transaction" />
                  <span className="text-secondary">
                    Belum ada histori transaksi
                  </span>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </div>
      <NotifyDevelopmentProcessing
        isOpen={isModalNotifyDevelopment}
        toggle={() => setIsModalNotifyDevelopment(!isModalNotifyDevelopment)}
      />
    </>
  );
};

export default HistoryTransaction;
