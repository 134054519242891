import React, { useRef } from "react";
import HelmetTitle from "../../components/layout/HelmetTitle";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { paymentInputInformationUserFiled as inputInfoUser } from "../../docs/text";
import { LuClipboardEdit } from "react-icons/lu";

function Payment({ type }) {
  const amountRef = useRef();
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const codeRef = useRef();
  const phoneRef = useRef();
  const emailRef = useRef();
  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = {
      amount: amountRef.current,
      first_name: firstNameRef.current,
      last_name: lastNameRef.current,
      code_country: codeRef.current,
      phone_number: phoneRef.current,
      email: emailRef.current,
    };
    // e.target.reset();
  };
  return (
    <>
      <HelmetTitle
        title={`Payment | ${type === "client" ? "Klien" : "Lawyer"}`}
        meta="chat, klien, chatklien, ceraionline"
      />
      <Row className="page-content w-100 h-75 bg-white gx-0">
        <Col md={8} className="pe-5">
          {/* <div className="">
            <img src="/logo@2x.png" alt="logo" height={"60"} />
          </div> */}
          <Form onSubmit={handleSubmit}>
            <div className="mt-5 mb-3 font-size-18 fw-bold">AMOUNT</div>
            <FormGroup className="mb-5">
              <Label for="amount">
                Jumlah yang harus dibayar{" "}
                <span className="text-primary">*</span>
              </Label>
              <Input
                id="amount"
                name="amount"
                placeholder="2.499.000"
                type="number"
                onChange={(e) => (amountRef.current = e.target.value)}
              />
            </FormGroup>
            <div className="mt-4 mb-3 font-size-18 fw-bold">
              INFORMASI PENGGUNA
            </div>
            {inputInfoUser.map((value, index) => {
              return (
                <FormGroup key={value.key + "_key_" + index}>
                  <Label for={value.key}>
                    {value.value} <span className="text-primary">*</span>
                  </Label>
                  {value.key.includes("code") ? (
                    <Input
                      id={value.key}
                      name={value.key}
                      type="select"
                      onChange={(e) => (codeRef.current = e.target.value)}
                    >
                      <option>Kode Negara</option>
                      <option value="ID">ID | +62</option>
                    </Input>
                  ) : (
                    <Input
                      id={value.key}
                      name={value.key}
                      placeholder={value.value}
                      type={value.type}
                      onChange={(e) =>
                      ((value.key.includes("first")
                        ? firstNameRef
                        : value.key.includes("last")
                          ? lastNameRef
                          : value.key.includes("phone")
                            ? phoneRef
                            : emailRef
                      ).current = e.target.value)
                      }
                    />
                  )}
                </FormGroup>
              );
            })}
            <Button className="float-end mt-3 mb-5 btn-card-red" type="submit">
              Melanjutkan
            </Button>
          </Form>
        </Col>
        <Col className="payment-section">
          <Container className="p-4 d-flex flex-column gap-3 payment-details">
            <h3>Detail Pembayaran</h3>
            <span>
              Link ID #: <span className="fw-light">HeheID-1234</span>
            </span>
            <div className="fw-bold mt-2">
              <div>
                <LuClipboardEdit size={24} />
                <span>Keterangan</span>
              </div>
              <div className="fw-normal py-3">
                Pembayaran ini untuk bla bla. Dengan pengacara terbaik dari kota
                ini.
              </div>
            </div>
            <div className="amount-details">
              <span>Total Pembayaran</span>
              <span>2.500.000</span>
            </div>
          </Container>
        </Col>
      </Row>
    </>
  );
}

export default Payment;
