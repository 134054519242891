import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { Col, Container, Row } from "reactstrap";
import { authSetupSelector, setRefreshToken, setSessions, setToken } from "../../app/authSlice";
import { orderSetupSelector, setConsultationSessions, setDetailOrderService, setHistoryChatAction, setMultipleBubbleChat, setRoomId } from "../../app/orderSlice";
import EmptyCaseImg from "../../assets/images/empty-case.png";
import HelmetTitle from "../../components/layout/HelmetTitle";
import { checkLengthKeys, notify } from "../../helpers/GlobalHelper";
import { usePostRefreshTokenMutation } from "../auth/api/AuthAPI";
import { useGetDetailOrderQuery } from "./api/CaseAPI";
import DetailCaseConsultation from "./components/DetailCaseConsultation";
import DetailCaseDivorce from "./components/DetailCaseDivorce";

const DetailOrder = ({ type }) => {
    const role_name = type === "client" ? "Klien" : "Lawyer";

    const location = useLocation();
    const { caseId } = location.state;

    const { token, refresh_token } = useSelector(authSetupSelector)
    const { detail_order_service } = useSelector(orderSetupSelector)

    const dispatch = useDispatch();

    const [postRefreshToken] = usePostRefreshTokenMutation();

    const {
        data: dataDetailOrder,
        isLoading: loadingDetailOrder,
        isError: errorDetailOrder,
        refetch: refetchDetailOrder
    } = useGetDetailOrderQuery(
        {
            case_id: caseId,
        },
        {
            refetchOnMountOrArgChange: false,
            refetchOnReconnect: true
        });

    const handlerRefreshSession = async () => {
        const resultGenerateNewToken = await postRefreshToken({ refresh_token }).unwrap()
        const dataSessions = resultGenerateNewToken.data;

        localStorage.setItem('data', JSON.stringify(dataSessions))
        dispatch(setSessions(dataSessions))

        localStorage.setItem('refreshToken', resultGenerateNewToken.refreshToken)
        localStorage.setItem('token', resultGenerateNewToken.token)

        dispatch(setToken(resultGenerateNewToken.token))
        dispatch(setRefreshToken(resultGenerateNewToken.refreshToken))
        return true
    }

    const handlerRefetchDetailOrder = () => {
        refetchDetailOrder()
    }

    useEffect(() => {
        handlerRefreshSession()
        setTimeout(() => {
            handlerRefetchDetailOrder();
        }, 3000);
    }, [type]);

    useEffect(() => {
        if (token) {
            handlerRefetchDetailOrder();
        }
    }, [token]);

    useEffect(() => {
        if (dataDetailOrder) {
            const { data } = dataDetailOrder

            dispatch(setRoomId(data.id))
            dispatch(
                setDetailOrderService(data.detail_order_service)
            )
            dispatch(
                setMultipleBubbleChat(data.bubble_chats)
            )
            dispatch(
                setConsultationSessions(data.consultation_sessions)
            )
            dispatch(setHistoryChatAction(data.history_chat_action))
        }
    }, [dataDetailOrder]);

    useEffect(() => {
        if (loadingDetailOrder) {
            notify('loading', false, 'Sedang memuat data');
        } else {
            toast.dismiss()
        }
    }, [loadingDetailOrder]);

    useEffect(() => {
        console.warn("errorDetailOrder:", errorDetailOrder)
    }, [errorDetailOrder]);

    if (checkLengthKeys(detail_order_service)) {
        if (detail_order_service.service_name === 'Konsultasi online (chat) / jam') {
            return <DetailCaseConsultation roleName={role_name} />
        } else {
            return <DetailCaseDivorce roleName={role_name} />
        }
    } else {
        return (
            <>
                <HelmetTitle
                    title={`Detail Kasus | ${role_name}`}
                    meta="detailkasus"
                />
                <Container fluid>
                    <Row className="page-content w-100 h-100 mt-5">
                        <Col xs={12} className="mt-3">
                            <div className="empty-transaction">
                                <img src={EmptyCaseImg} />
                                <span className="text-secondary">
                                    sedang memuat kasus...
                                </span>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

export default DetailOrder;
