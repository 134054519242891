import { Button, Col, Modal, ModalBody, Row } from "reactstrap";

const ModalSuccessBookingDivorce = ({ isOpen, toggle }) => {
    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            centered
            keyboard={false}
            backdrop={false}
            className="border-0 custom-backdrop"
            size='lg'
        >
            <ModalBody>
                <div className="text-center fw-bold mb-2">
                    Pengajuan Berhasil
                </div>
                <div className="border-bottom mb-3"></div>
                <Row>
                    <Col xs={12}>
                        <p>
                            Selamat, berkas Anda sudah diajukan ke Lawyer bersangkutan. Konfirmasi akan dilakukan dalam 1 x 24 jam, detail bisa dicek melalui email dan dashboard, Anda dapa melakukan chat dengan lawyer bersangkutan
                        </p>
                        <div className="px-lg-15">
                            <Button
                                onClick={() => toggle(isOpen)}
                                block
                                color="primary"
                                className="btn-primary"
                            >
                                Chat dengan Lawyer
                            </Button>
                        </div>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    );
}

export default ModalSuccessBookingDivorce;
