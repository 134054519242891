import { store } from '../../app/store';
import Socket from '../Socket';

export const refreshData = () => {
  Socket().disconnect();
  Socket().connect();
};

export const sendMessage = (param) => {
  const state = store.getState();
  const { refresh_token } = state.authSetup;

  const { id, chat_id, message, is_file, file, from, created_at, updated_at } =
    param;
  const data = {
    id,
    chat_id,
    from,
    message,
    is_file,
    is_button: false,
    is_read: false,
    button: [],
    file,
    list_user_read: [],
    consultation_sessions: [],
    created_at,
    updated_at,
  };

  Socket().emit('send_message', data);
};
