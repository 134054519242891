import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    province_id: '31',
    page: 1,
    limit: 1,
    lawyerList: []
};

const landingPageSlice = createSlice({
    name: 'landingPageSetup',
    initialState,
    reducers: {
        changeValueProvince: (state, action) => {
            state.province_id = action.payload;
            state.page = 1;
            state.limit = 10;
            state.lawyerList = []
        },
        setValueDataListLawyer: (state, action) => {
            action.payload.map((val) => {
                state.lawyerList.push(val)
            })
        },
        setValuePageDataLawyer: (state, action) => {
            state.page = action.payload
        },
        resetDataListLawyer: (state) => {
            state.page = 1;
            state.limit = 10;
            state.lawyerList = []
        }
    }
})

export const landingPageSetupSelector = (state) => state.landingPageSetup
export const {
    changeValueProvince,
    setValueDataListLawyer,
    resetDataListLawyer,
    setValuePageDataLawyer,
} = landingPageSlice.actions

export default landingPageSlice.reducer
