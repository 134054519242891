import { Card, CardBody, CardText, Col } from "reactstrap";

const CheckingVerificationRegister = () => {
    return (
        <Col xs={12} className="mt-0 justify-content-center text-center">
            <img
                className="img-content rounded"
                alt="waiting"
                src="waiting.svg"
            />
            <Card className="bg-transparent shadow-none mt-0">
                <CardBody className="bg-transparent">
                    <CardText className="text-secondary fw-normal">
                        Silahkan menunggu, sedang memeriksa token anda...
                    </CardText>
                </CardBody>
            </Card>
        </Col>
    );
}

export default CheckingVerificationRegister;
