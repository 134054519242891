import classnames from "classnames";
import { Collapse } from "reactstrap";

const AccordionItem = ({
    headerName,
    isContent,
    isCollapsed,
    onClick
}) => {
    return (
        <>
            <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                    <button
                        className={classnames(
                            "accordion-button",
                            "accordion-button-white",
                            "fw-medium",
                            { collapsed: isCollapsed }
                        )}
                        type="button"
                        onClick={() => onClick()}
                        style={{ cursor: "pointer" }}
                    >
                        {headerName}
                    </button>
                </h2>

                <Collapse isOpen={isCollapsed} className="accordion-collapse">
                    <div className="accordion-body">
                        {isContent}
                    </div>
                </Collapse>
            </div>
        </>
    );
}

export default AccordionItem;
