import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setRefreshToken, setSessions, setToken } from "../../app/authSlice";
import { usePostRefreshTokenMutation } from "../../features/auth/api/AuthAPI";
import FooterAuth from "./FooterAuth";
import HeaderAuth from "./HeaderAuth";
import SidebarAuth from "./SidebarAuth";
import { updateStatusRefetchSession } from "../../app/profileSlice";
import { refreshData } from "../../socket/Clients/ClientAction";

const AuthLayout = (props) => {
    const { children, type } = props;
    const [postRefreshToken, { isLoading: loadingRefreshToken }] = usePostRefreshTokenMutation()
    useEffect(() => {
        let body = document.body;
        body.removeAttribute('data-layout')
        checkingSession();
    }, []);


    const navigate = useNavigate()
    const dispatch = useDispatch()

    const redirectToLogin = (type) => {
        const url = type === 'lawyer' ? '/login-lawyer' : '/login';
        localStorage.clear();
        navigate(url)
    }

    const checkingSession = async () => {
        const refresh_token = localStorage.getItem("refreshToken");
        if (!refresh_token) {
            redirectToLogin(type);
        }

        try {
            const resultGenerateNewToken = await postRefreshToken({ refresh_token }).unwrap()
            const dataSessions = resultGenerateNewToken.data;
            if (dataSessions.role_name.toLowerCase() !== type) {
                return navigate('/error-404')
            }

            localStorage.setItem('refreshToken', resultGenerateNewToken.refreshToken)
            localStorage.setItem('token', resultGenerateNewToken.token)
            localStorage.setItem('data', JSON.stringify(dataSessions))
            dispatch(setToken(resultGenerateNewToken.token))
            dispatch(setRefreshToken(resultGenerateNewToken.refreshToken))
            dispatch(setSessions(dataSessions))
            refreshData()
        } catch (error) {
            const errStatus = error.data;
            if (errStatus) {
                if (error.data.status !== 200) {
                    redirectToLogin(type)
                }
            }
        }
    }

    useEffect(() => {
        if (loadingRefreshToken) {
            dispatch(updateStatusRefetchSession(true))
        } else {
            setTimeout(() => {
                dispatch(updateStatusRefetchSession(false))
            }, 3000);
        }
    }, [loadingRefreshToken]);

    useEffect(() => {
        refreshData()
    }, [children]);

    return (
        <>
            <HeaderAuth />
            <SidebarAuth />
            <div className="main-content">
                {children}
            </div>
            <FooterAuth />
        </>
    );
}

export default AuthLayout;
