import { useState } from "react";
import FormSendEmailForgot from "./FormSendEmailForgot";
import ForgotPasswordVerificationCode from "./ForgotPasswordVerificationCode";
import ChangePasswordFromForgot from "./ChangePasswordFromForgot";
import ForgotChangePasswordSuccess from "./ForgotChangePasswordSuccess";

const ForgotPasswordContent = ({ type }) => {
    const [isStatusForm, setIsStatusForm] = useState('form-forgot');

    if (isStatusForm === 'form-forgot') {
        return <FormSendEmailForgot
            type={type}
            handlerStatusForm={(val) => setIsStatusForm(val)}
        />
    }

    if (isStatusForm === 'form-verification-code') {
        return <ForgotPasswordVerificationCode
            type={type}
            handlerStatusForm={(val) => setIsStatusForm(val)}
        />
    }

    if (isStatusForm === 'form-change-password') {
        return <ChangePasswordFromForgot
            type={type}
            handlerStatusForm={(val) => setIsStatusForm(val)}
        />
    }

    if (isStatusForm === 'change-password-success') {
        return <ForgotChangePasswordSuccess type={type} />
    }
}

export default ForgotPasswordContent;
