import { useNavigate } from "react-router-dom";
import { Button, Card, CardBody, CardTitle } from "reactstrap";

const ForgotChangePasswordSuccess = ({ type }) => {
    const navigate = useNavigate()
    const handlerRedirect = () => {
        navigate(type === 'lawyer' ? '/login-lawyer' : '/login')
    }
    return (
        <Card className="overflow-hidden shadow-none border-0 rounded-3 align-self-center bg-transparent px-lg-6 mt-10">
            <CardBody>
                <CardTitle className="mt-0 fs-3 fw-bold text-center">
                    Selamat Kata Sandi Baru Anda Telah Di Buat
                </CardTitle>
                <div>
                    <Button
                        type="button"
                        block
                        color="transparent"
                        className="text-primary fs-5"
                        onClick={() => handlerRedirect()}
                    >
                        Klik Disini Untuk Masuk
                    </Button>
                </div>
            </CardBody>
        </Card>
    );
}

export default ForgotChangePasswordSuccess;
