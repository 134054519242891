import Input from "react-phone-number-input/input";

const PhoneNumber = ({ value = '', onChange, className = "" }) => {
    return (
        <>
            <Input
                international
                country="ID"
                value={value}
                withCountryCallingCode
                placeholder="8392xxxx"
                onChange={onChange}
                className={`form-control rounded-3 ${className}`}
            />
        </>
    );
}

export default PhoneNumber;
