import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Badge, Button, Container, Input } from "reactstrap";
import { authSetupSelector, setRefreshToken, setSessions, setToken } from "../../app/authSlice";
import { clearRoomChat } from "../../app/orderSlice";
import EmptyCase from "../../assets/images/empty-case.png";
import Breadcrumb from "../../components/layout/Breadcrumb";
import HelmetTitle from "../../components/layout/HelmetTitle";
import TableComp from "../../components/table/TableComp";
import { caseHeaderTableMenus } from "../../docs/HeaderTable";
import { UpperCaseName, reformatDate } from "../../helpers/GlobalHelper";
import { usePostRefreshTokenMutation } from "../auth/api/AuthAPI";
import { useGetListOrdersQuery } from "./api/CaseAPI";

const CaseOrder = ({ type }) => {
  const [dataList, setDataList] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [totalData, setTotalData] = useState(0);

  const { token, refresh_token } = useSelector(authSetupSelector)
  const dispatch = useDispatch();

  const [postRefreshToken] = usePostRefreshTokenMutation();

  const {
    data: dataListOrder,
    refetch: refetchListOrder
  } = useGetListOrdersQuery(
    {
      limit: 10,
      page,
      keyword
    },
    {
      refetchOnMountOrArgChange: false,
      refetchOnReconnect: true
    });

  const navigate = useNavigate();

  const role_name = type === "client" ? "Klien" : "Lawyer";

  const handleViewDetails = (caseId) => {
    navigate(`/case-${type}/detail-case-${type}`, { state: { caseId } });
  };

  const handlerMoveToPickLawyer = () => {
    navigate('/lawyer-list')
  }

  const handlerRefetchListOrders = async () => {
    await handlerRefreshSession();
    refetchListOrder()
  }

  const handlerTypingKeyword = (param) => {
    const value = !param.target.value ? '' : param.target.value.replace('#', '')
    setKeyword(value)
  }

  const handlerRefreshSession = async () => {
    const resultGenerateNewToken = await postRefreshToken({ refresh_token }).unwrap()
    const dataSessions = resultGenerateNewToken.data;

    localStorage.setItem('data', JSON.stringify(dataSessions))
    dispatch(setSessions(dataSessions))

    localStorage.setItem('refreshToken', resultGenerateNewToken.refreshToken)
    localStorage.setItem('token', resultGenerateNewToken.token)

    dispatch(setToken(resultGenerateNewToken.token))
    dispatch(setRefreshToken(resultGenerateNewToken.refreshToken))
    return true
  }

  const handlerMapData = async (param) => {
    if (!param) {
      setDataList([])
      setKeyword('')
      setPage(1)
      setTotalPage(1)
      setTotalData(0)
      return false
    }

    if (!param.data) {
      setDataList([])
      setKeyword('')
      setPage(1)
      setTotalPage(1)
      setTotalData(0)
      return false
    } else {
      setPage(param.paginate.currentPage)
      setTotalPage(param.paginate.totalPages)
      setTotalData(param.paginate.totalItems)
      const data = await Promise.all(
        param.data.map((val) => {
          let status = '';
          if (val.category_order_service) {
            switch (val.category_order_service) {
              case 'canceled_by_system':
                status = 'dibatalkan oleh sistem'
                break;
              case 'new_case':
                status = 'Kasus Baru'
                break;
              case 'new_consultation':
                status = 'Konsultasi Baru'
                break;
              case 'canceled_by_client':
                status = 'Dibatalkan Oleh Klien'
                break;
              case 'reject_consultation':
                status = 'Konsultasi Dibatalkan'
                break;
              case 'rejected_from_lawyer':
                status = 'Konsultasi Dibatalkan'
                break;
              case 'reschedule_consultation':
                status = 'Pengajuan Ulang Konsultasi'
                break;
              case 'progress':
                status = 'Sedang Berjalan'
                break;
              case 'waiting_payout':
                status = 'Menunggu Pembayaran'
                break;
              case 'request_end_case_to_client':
                status = 'Pengajuan penyelesaian kasus'
                break;
              default:
                status = 'kasus telah selesai'
                break;
            }
          }
          return {
            case_id: `#${val.case_id}`,
            updated_at: !val.updated_at ? '-' : reformatDate(val.updated_at, 'LLL'),
            client_name: UpperCaseName(val.client_name),
            client_city_domicile: !val.client_city_domicile ? '-' : UpperCaseName(val.client_city_domicile.name),
            category_order_service: <Badge pill color="info" className="p-2">
              <span className="font-size-10">
                {UpperCaseName(status)}
              </span>
            </Badge>,
            tools: <>
              <Button
                onClick={() => handleViewDetails(val.case_id)}
                color="primary"
                className="btn-primary btn-sm"
              >
                Lihat
              </Button>
            </>
          }
        })
      );
      setDataList(data)
    }
  }

  const handlerChangePage = (param) => {
    setPage(param)
  }

  const handlerResetFilter = () => {
    setDataList([])
    setKeyword('')
    setPage(1)
    setTotalPage(1)
    setTotalData(0)
  }

  useEffect(() => {
    dispatch(clearRoomChat())
    setDataList([])
    setTimeout(() => {
      setPage(1);
      setKeyword('');
      handlerRefetchListOrders();
    }, 3000);
  }, [type]);

  useEffect(() => {
    handlerMapData(dataListOrder)
  }, [dataListOrder]);

  useEffect(() => {
    if (token) {
      handlerRefetchListOrders();
    }
  }, [token]);

  return (
    <>
      <HelmetTitle
        title={`Kasus | ${role_name}`}
        meta="kasuslawyer, kasusklien, kasus_klien, kasus_lawyer, ceraionline"
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumb breadcrumbItem="Kasus" hideRightBreadcrumb />
        </Container>
      </div>

      <div className="px-md-4 mb-10 mt-0">
        <div className="d-inline-flex mb-3">
          <Input
            type="text"
            placeholder="Cari Kasus ID"
            onChange={handlerTypingKeyword}
            value={!keyword ? '' : keyword}
          />
        </div>

        {totalData > 0 ? (
          <TableComp
            headerTable={caseHeaderTableMenus}
            data={dataList}
            currentPage={page}
            totalPage={totalPage}
            totalData={totalData}
            onClickPage={handlerChangePage}
          />
        ) : (
          <div className="empty-transaction px-3 text-center">
            <img src={EmptyCase} />
            {type !== "client" ? (
              <span className="text-secondary fw-bold">
                Tidak menemukan kasus{" "}
                <span
                  onClick={handlerResetFilter}
                  className="text-primary fw-bold cursor-pointer"
                >
                  Setel Ulang Kasus
                </span>
              </span>
            ) : (
              <span className="text-secondary">
                Anda belum mengajukan booking lawyer. Cari lawyer{" "}
                <span
                  onClick={handlerMoveToPickLawyer}
                  className="text-primary fw-bold cursor-pointer"
                >
                  di sini
                </span>
                {" "}
                atau
                {" "}
                <span
                  onClick={handlerResetFilter}
                  className="text-primary fw-bold cursor-pointer"
                >
                  Setel Ulang Kasus
                </span>
              </span>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default CaseOrder;
