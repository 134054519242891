import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  progress: [],
  currentProgress: {},
};

const progressSlice = createSlice({
  name: "progressSetup",
  initialState,
  reducers: {
    setProgress: (state, action) => {
      state.progress = [action.payload, ...state.progress];
      state.currentProgress = action.payload;
    },
    changeCurrentProgress: (state, action) => {
      state.currentProgress = action.payload;
    },
    editProgress: (state, action) => {
      const edit = state.progress.map((item) => {
        if (item?.id === action.payload?.id) {
          return { ...action.payload };
        } else {
          return { ...item };
        }
      });
      state.progress = edit;
    },
    resetProgress: () => {
      return initialState;
    },
  },
});

export const {
  setProgress,
  resetProgress,
  changeCurrentProgress,
  editProgress,
} = progressSlice.actions;

export default progressSlice.reducer;
