import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const { VITE_BASE_URL } = import.meta.env;


const getToken = (state) => state.authSetup.token;

export const paymentApi = createApi({
    reducerPath: "paymentApi",
    tagTypes: ["paymentApi"],
    baseQuery: fetchBaseQuery({
        baseUrl: `${VITE_BASE_URL}/payment/`,
        prepareHeaders: (headers, { getState }) => {
            const token = getToken(getState());
            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getListBank: builder.query({
            query: ({ limit, page, gateway_from, type, keyword }) => ({
                url: `list-bank?limit=${limit}&page=${page}&gateway_from=${gateway_from}&type=${type}&keyword=${keyword}`,
            }),
            invalidatesTags: ["get-list-banks"],
        }),
        getListAccountBank: builder.query({
            query: () => ({
                url: 'list-account-bank',
            }),
            invalidatesTags: ["get-list-banks-account"],
        }),
        postDataAccountBank: builder.mutation({
            query: (body) => ({
                url: `add-account-bank`,
                method: 'POST',
                body
            }),
            invalidatesTags: ["add-account-bank"],
        }),
        getListHistoryTransactions: builder.query({
            query: ({ limit, page, keyword }) => ({
                url: `list-history-transactions?limit=${limit}&page=${page}&keyword=${keyword}`,
            }),
            invalidatesTags: ["get-list-history-transactions"],
        }),
        getDetailHistoryTransactions: builder.query({
            query: ({ caseId }) => ({
                url: `get-detail-history-transaction?case_id=${caseId}`,
            }),
            invalidatesTags: ["get-detail-history-transaction"],
        }),
        postRequestDisbursementConsultationOrder: builder.mutation({
            query: (body) => ({
                url: `request-disbursment-consultation-order`,
                method: 'POST',
                body
            }),
            invalidatesTags: ["request-disbursment-consultation-order"],
        }),
        postRequestDisbursementTermin: builder.mutation({
            query: (body) => ({
                url: `request-disbursment-termin`,
                method: 'POST',
                body
            }),
            invalidatesTags: ["request-disbursment-termin"],
        }),
    })
})

export const {
    useLazyGetListBankQuery,
    useGetListAccountBankQuery,
    usePostDataAccountBankMutation,
    useGetListHistoryTransactionsQuery,
    useGetDetailHistoryTransactionsQuery,
    usePostRequestDisbursementConsultationOrderMutation,
    usePostRequestDisbursementTerminMutation,
} = paymentApi;

