import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./assets/scss/theme.scss";
import NonAuthLayout from "./components/layout/NonAuthLayout";
import NonAuthMiddleware from "./components/layout/NonAuthMiddleware";
import ForgotPassword from "./features/auth/ForgotPassword";
import Login from "./features/auth/Login";
import Register from "./features/auth/Register";
import VerificationRegister from "./features/auth/VerificationRegister";
import DashboardClient from "./features/dashboard/DashboardClient";
import DashboardLawyer from "./features/dashboard/DashboardLawyer";
import Error404 from "./features/errors/Error404";
import Home from "./features/landingPage/home";
import ProfileLawyer from "./features/profile/ProfileLawyer";
import ProfileClient from "./features/profile/ProfileClient";
import AuthLayout from "./components/layout/AuthLayout";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ActivationAccountLawyer from "./features/verify/ActivationAccountLawyer";
import ListLawyer from "./features/lawyerList/ListLawyer";
import HowAboutWork from "./features/etc/HowAboutWork";
import HistoryTransaction from "./features/historyTransaction/HistoryTransaction";
import CaseOrder from "./features/case/CaseOrder";
import ChatView from "./features/chat/ChatView";
import Payment from "./features/payments/Payment";
import TimelineDetails from "./features/timelineDetails/TimelineDetails";
import socketConnect from './socket/SocketConnect'
import { useEffect } from "react";
import DetailOrder from "./features/case/DetailOrder";
import DetailInfoOrder from "./features/case/DetailInfoOrder";
import DetailProgressTimelineOrder from "./features/case/DetailProgressTimelineOrder";
import DetailHistoryTransaction from "./features/historyTransaction/DetailHistoryTransaction";

const Routing = () => {
  useEffect(() => {
    socketConnect()
  }, []);

  const authProtectedRoutes = [
    {
      path: "/dashboard-lawyer",
      component: <DashboardLawyer type="lawyer" />,
      type: "lawyer",
    },
    {
      path: "/dashboard-client",
      component: <DashboardClient type="client" />,
      type: "client",
    },
    {
      path: "/profile-lawyer",
      component: <ProfileLawyer type="lawyer" />,
      type: "lawyer",
    },
    {
      path: "/profile-client",
      component: <ProfileClient type="client" />,
      type: "client",
    },
    {
      path: "/history-transaction-client",
      component: <HistoryTransaction type="client" />,
      type: "client",
    },
    {
      path: "/history-transaction-lawyer",
      component: <HistoryTransaction type="lawyer" />,
      type: "lawyer",
    },
    {
      path: "/history-transaction-client/detail",
      component: <DetailHistoryTransaction type="client" />,
      type: "client",
    },
    {
      path: "/history-transaction-lawyer/detail",
      component: <DetailHistoryTransaction type="lawyer" />,
      type: "lawyer",
    },
    {
      path: "/case-client",
      component: <CaseOrder type="client" />,
      type: "client",
    },
    {
      path: "/case-lawyer",
      component: <CaseOrder type="lawyer" />,
      type: "lawyer",
    },
    {
      path: "/case-lawyer/detail-case-lawyer",
      component: <DetailOrder type="lawyer" />,
      type: "lawyer",
    },
    {
      path: "/case-client/detail-case-client",
      component: <DetailOrder type="client" />,
      type: "client",
    },
    {
      path: "/case-client/detail-info-case-client/:caseId",
      component: <DetailInfoOrder type="client" />,
      type: "client",
    },
    {
      path: "/case-lawyer/detail-info-case-lawyer/:caseId",
      component: <DetailInfoOrder type="lawyer" />,
      type: "lawyer",
    },
    {
      path: "/case-client/detail-progress-timeline",
      component: <DetailProgressTimelineOrder type="client" />,
      type: "client",
    },
    {
      path: "/case-lawyer/detail-progress-timeline",
      component: <DetailProgressTimelineOrder type="lawyer" />,
      type: "lawyer",
    },
    {
      path: "/chat-view",
      component: <ChatView type="client" />,
      type: "client",
    },
    { path: "/payment-client", component: <Payment type="client" /> },
    {
      path: "/timeline-details-client",
      component: <TimelineDetails type={"client"} />,
      type: "client",
    },
  ];

  const nonAuthRoutes = [
    {
      path: "/",
      component: <Home type="client" />,
      type: "client",
      isPublic: true,
    },
    {
      path: "/login",
      component: <Login type="client" />,
      type: "client",
      isPublic: false,
    },
    {
      path: "/register",
      component: <Register type="client" />,
      type: "client",
      isPublic: false,
    },
    {
      path: "/forgot-password",
      component: <ForgotPassword type="client" />,
      type: "client",
      isPublic: false,
    },
    {
      path: "/login-lawyer",
      component: <Login type="lawyer" />,
      type: "lawyer",
      isPublic: false,
    },
    {
      path: "/register-lawyer",
      component: <Register type="lawyer" />,
      type: "lawyer",
      isPublic: false,
    },
    {
      path: "/forgot-password-lawyer",
      component: <ForgotPassword type="lawyer" />,
      type: "lawyer",
      isPublic: false,
    },
    {
      path: "/verification-register",
      component: <VerificationRegister />,
      type: "lawyer",
      isPublic: false,
    },
    {
      path: "/verification-job-user",
      component: <ActivationAccountLawyer />,
      type: "lawyer",
      isPublic: true,
    },
    {
      path: "/lawyer-list",
      component: <ListLawyer />,
      type: "lawyer",
      isPublic: true,
    },
    {
      path: "/how-to-work",
      component: <HowAboutWork />,
      type: "lawyer",
      isPublic: true,
    },
  ];

  return (
    <>
      <ToastContainer limit={10} />
      <BrowserRouter>
        <Routes>
          {nonAuthRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <NonAuthMiddleware isPublic={route.isPublic} type={route.type}>
                  <NonAuthLayout type={route.type}>
                    {route.component}
                  </NonAuthLayout>
                </NonAuthMiddleware>
              }
              key={idx}
              exact={true}
            />
          ))}
          {authProtectedRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <AuthLayout type={route.type}>{route.component}</AuthLayout>
              }
              key={idx}
              exact={true}
            />
          ))}
          <Route path="*" element={<Error404 />} exact={true} />
          <Route path="error-404" element={<Error404 />} exact={true} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default Routing;
