import { Card, CardBody, Col, Container, Form, Row } from "reactstrap";
import AuthLayout from "../../components/layout/AuthLayout";
import HelmetTitle from "../../components/layout/HelmetTitle";
import Breadcrumb from "../../components/layout/Breadcrumb";
import FormDataProfileClient from "./components/FormDataProfileClient";

const ProfileClient = ({ type }) => {
    return (
        <>
            <HelmetTitle
                title="Profile | Klien"
                meta="profile, klien, profileklien, ceraionline"
            />
            <div className="page-content">
                <Container fluid>
                    <Breadcrumb breadcrumbItem="Profile" />
                    <Row className="justify-content-center">
                        <Col xs={12}>
                            <Card>
                                <CardBody>
                                    <FormDataProfileClient />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default ProfileClient;
