import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const { VITE_BASE_URL } = import.meta.env;


const getToken = (state) => state.authSetup.token;

export const authApi = createApi({
    reducerPath: "authApi",
    tagTypes: ["Auth"],
    baseQuery: fetchBaseQuery({
        baseUrl: `${VITE_BASE_URL}/auth/`,
        prepareHeaders: (headers, { getState }) => {
            const token = getToken(getState());
            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        postLogin: builder.mutation({
            query: (body) => ({
                url: `login`,
                method: 'POST',
                body
            }),
            invalidatesTags: ["Auth"],
        }),
        postRegister: builder.mutation({
            query: (body) => ({
                url: `register`,
                method: 'POST',
                body
            }),
            invalidatesTags: ["Auth"],
        }),
        postResendVerificationRegister: builder.mutation({
            query: (body) => ({
                url: `resend-verification-register`,
                method: 'POST',
                body
            }),
            invalidatesTags: ["Auth"],
        }),
        postVerificationRegister: builder.mutation({
            query: (body) => ({
                url: `verification-register`,
                method: 'POST',
                body
            }),
            invalidatesTags: ["Auth"],
        }),
        getUsers: builder.query({
            query: () => "/users",
        }),
        postForgotPassword: builder.mutation({
            query: (body) => ({
                url: `forgot-password`,
                method: 'POST',
                body
            }),
            invalidatesTags: ["Auth"],
        }),
        postResendForgotPassword: builder.mutation({
            query: (body) => ({
                url: `resend-verification-forgot-password`,
                method: 'POST',
                body
            }),
            invalidatesTags: ["Auth"],
        }),
        postVerificationCodeForgotPassword: builder.mutation({
            query: (body) => ({
                url: `verification-forgot-password`,
                method: 'POST',
                body
            }),
            invalidatesTags: ["Auth"],
        }),
        postChangeForgotPassword: builder.mutation({
            query: (body) => ({
                url: `change-password`,
                method: 'POST',
                body
            }),
            invalidatesTags: ["Auth"],
        }),
        postRefreshToken: builder.mutation({
            query: (body) => ({
                url: `refresh-token`,
                method: 'POST',
                body
            }),
            invalidatesTags: ["Auth"],
        }),
        postLogout: builder.mutation({
            query: () => ({
                url: `logout`,
                method: 'POST',
            }),
            invalidatesTags: ["Auth"],
        }),
    })
})
// useLoginPostMutation
export const {
    useGetUsersQuery,
    usePostLoginMutation,
    usePostRegisterMutation,
    usePostVerificationRegisterMutation,
    usePostResendVerificationRegisterMutation,
    usePostForgotPasswordMutation,
    usePostResendForgotPasswordMutation,
    usePostVerificationCodeForgotPasswordMutation,
    usePostChangeForgotPasswordMutation,
    usePostRefreshTokenMutation,
    usePostLogoutMutation
} = authApi;

