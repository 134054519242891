import { useEffect, useRef, useState } from "react";
import Countdown, { zeroPad } from "react-countdown";
import { FaPaperPlane } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { Button, Card, CardBody, CardText, CardTitle, Col, Row } from "reactstrap";
import { authSetupSelector } from "../../../app/authSlice";
import { usePostResendVerificationRegisterMutation } from "../api/AuthAPI";

const { VITE_DELAY_TIME_RESEND } = import.meta.env;


const ContentSuccessRegister = () => {
    const defaultTime = parseInt(VITE_DELAY_TIME_RESEND);
    const countRef = useRef("");
    const { email } = useSelector(authSetupSelector)
    const [timeCountDown, setTimeCountDown] = useState(Date.now() + defaultTime);
    const [postResendVerificationRegister] = usePostResendVerificationRegisterMutation()

    useEffect(() => {
        countRef?.current?.start();
    }, [timeCountDown]);

    const handlerResendVerification = (event) => {
        event.preventDefault()
        setTimeCountDown(Date.now() + defaultTime)
        postResendVerificationRegister({ email })
    }

    const renderer = ({ minutes, seconds, completed }) => {
        if (completed) {
            return (
                <>
                    <Button
                        type="button"
                        color="primary"
                        onClick={handlerResendVerification}
                    >
                        Kirim Ulang <FaPaperPlane />
                    </Button>
                </>
            )
        } else {
            return (
                <>
                    <CardText className="text-secondary fw-normal">
                        <span className="mb-3 me-2">
                            Jika tidak menerima kode verifikasi, silahkan kirim ulang setelah:
                        </span>
                        <span className="text-primary">
                            {zeroPad(minutes)}:{zeroPad(seconds)}
                        </span>
                    </CardText>
                </>
            )
        }
    };

    return (
        <Row className="justify-content-center align-self-center mt-15 mt-lg-20 px-2 px-lg-15 min-vh-100 bg-transparent">
            <Col xs={12} className="mt-0 justify-content-center text-center">
                <img
                    className="img-content rounded"
                    alt="200x200"
                    width="20"
                    src="send-email.svg"
                />
                <Card className="bg-transparent shadow-none mt-0">
                    <CardBody className="bg-transparent">
                        <CardTitle className="mt-0 fs-5 fw-bold">
                            Selamat Anda sudah terdaftar!
                        </CardTitle>
                        <CardText className="text-secondary fw-normal">
                            Silakan lakukan verifikasi email
                            untuk menggunakan layanan Cerai Online
                        </CardText>
                        <Countdown
                            autoStart={false}
                            ref={countRef}
                            date={timeCountDown}
                            renderer={renderer}
                        />
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
}

export default ContentSuccessRegister;
