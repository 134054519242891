import { Button, Spinner } from 'reactstrap';

export const LoadingButton = ({ isLoading, children, ...restOfProps }) => {
  return (
    <Button {...restOfProps}>
      <span>{children} </span>{' '}
      {isLoading ? <Spinner size="sm">Loading...</Spinner> : null}
    </Button>
  );
};
