import { Link } from "react-router-dom";
import SidebarContentAuth from "./SidebarContentAuth";

const SidebarAuth = () => {
    return (
        <div className="vertical-menu">
            <div className="navbar-brand-box bg-white ">
                <Link to="/" className="logo logo-dark">
                    <span className="logo-sm">
                        <img src="/logo@2x.png" alt="logo" height="15" />
                    </span>
                    <span className="logo-lg">
                        <img src="/logo@2x.png" alt="logo" height="30" />
                    </span>
                </Link>
            </div>
            <SidebarContentAuth />
        </div>
    );
}

export default SidebarAuth;
