import { useEffect, useState } from 'react';
import { useGetProvincesQuery } from '../../features/zones/api/ZoneApi';
import Select from 'react-select';

const SelectorProvince = ({ onChange, value = '', placeholder = 'Cari provinsi', restrictionsByLabel = '' }) => {
    const [listProvince, setListProvince] = useState([]);
    const [defaultValue, setDefaultValue] = useState({ value: '', label: '' });
    const { data } = useGetProvincesQuery();

    const handlerSetListValue = (data, restrictionsByLabel) => {
        if (!restrictionsByLabel) {
            if (data) {
                const option = data.data.map(({ id: value, name: label }) => ({
                    value, label
                }))

                return setListProvince(option)
            } else {
                return setListProvince([])
            }
        } else {
            if (data) {
                const newOptions = []
                data.data.map(({ id: value, name: label }) => (
                    restrictionsByLabel.includes(label) && newOptions.push({ value, label })
                ))
                return setListProvince(newOptions)
            } else {
                return setListProvince([])
            }

        }
    }

    useEffect(() => {
        handlerSetListValue(data, restrictionsByLabel)
    }, [data, restrictionsByLabel]);

    useEffect(() => {
        if (value) {
            const resultFind = listProvince.find((param) => param.value === value)
            setDefaultValue(
                resultFind
            )
        } else {
            setDefaultValue({ value: '', label: '' })
        }
    }, [value, listProvince]);

    return (
        <>
            <Select
                onChange={onChange}
                value={defaultValue}
                isSearchable={true}
                options={listProvince}
                placeholder={placeholder}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: "0.25rem",
                    colors: {
                        ...theme.colors,
                        primary25: '#FFEDED',
                        primary: '#E91E23',
                    },
                })}
            />
        </>
    );
}

export default SelectorProvince;
