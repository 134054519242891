import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalBody } from 'reactstrap';
import { authSetupSelector, setRefreshToken, setSessions, setToken } from '../../../app/authSlice';
import { orderSetupSelector, setModalCase } from '../../../app/orderSlice';
import { notify } from '../../../helpers/GlobalHelper';
import { usePostRejectOrderMutation } from '../api/CaseAPI';
import { usePostRefreshTokenMutation } from '../../auth/api/AuthAPI';

const ModalRejectOrder = () => {
    const { refresh_token } = useSelector(authSetupSelector)
    const {
        is_modal_reject_order,
        detail_order_service
    } = useSelector(orderSetupSelector)

    const dispatch = useDispatch();
    const [postRefreshToken] = usePostRefreshTokenMutation();
    const [postRejectOrder,
        {
            isLoading: loadingRejectOrder,
            isSuccess: successRejectOrder,
            isError: isErrorRejectOrder,
            error: errorRejectOrder
        }
    ] = usePostRejectOrderMutation();

    const handlerRefreshSession = async () => {
        const resultGenerateNewToken = await postRefreshToken({ refresh_token }).unwrap()
        const dataSessions = resultGenerateNewToken.data;

        localStorage.setItem('data', JSON.stringify(dataSessions))
        dispatch(setSessions(dataSessions))

        localStorage.setItem('refreshToken', resultGenerateNewToken.refreshToken)
        localStorage.setItem('token', resultGenerateNewToken.token)

        dispatch(setToken(resultGenerateNewToken.token))
        dispatch(setRefreshToken(resultGenerateNewToken.refreshToken))
        return true
    }

    const handlerRejectOrder = async () => {
        try {
            await handlerRefreshSession()
            const param = {
                case_id: detail_order_service.case_id
            };
            await postRejectOrder(param).unwrap();
        } catch (error) {
            console.warn("error reject order:", error.data.message)
            notify('error', 30000, `error message: ${error.data.message}`);
        }
    }

    useEffect(() => {
        if (successRejectOrder) {
            notify('success', 30000, 'Berhasil menolak orderan');
            dispatch(setModalCase({ modalName: 'reject', isActive: !is_modal_reject_order }))
        }
    }, [successRejectOrder]);

    useEffect(() => {
        if (errorRejectOrder) {
            notify('error', 30000, errorRejectOrder.data.message);
        }
    }, [isErrorRejectOrder, errorRejectOrder]);

    return (
        <Modal
            isOpen={is_modal_reject_order}
            toggle={() => dispatch(setModalCase({ modalName: 'reject', isActive: !is_modal_reject_order }))}
            centered
            keyboard={false}
            backdrop={false}
            className="border-0 custom-backdrop"
            size='md'
        >
            <ModalBody>
                <div className="text-center fw-bold mb-2">
                    Tolak
                </div>
                <div className="border-bottom mb-3"></div>
                <div className="text-center mb-2">
                    Apakah anda yakin ingin menolak orderan ini?
                </div>
                <div className='d-flex justify-content-center gap-2'>
                    <Button
                        onClick={() => dispatch(setModalCase({ modalName: 'reject', isActive: !is_modal_reject_order }))}
                        outline
                        type="button"
                        color="secondary"
                        className="rounded-3"
                        size="sm"
                        block
                        disabled={loadingRejectOrder}
                    >
                        Kembali
                    </Button>
                    <Button
                        onClick={handlerRejectOrder}
                        outline
                        type="button"
                        color="danger"
                        className="rounded-3"
                        size="sm"
                        block
                        disabled={loadingRejectOrder}
                    >
                        {loadingRejectOrder ? 'Memproses...' : 'Ya'}
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    );
}

export default ModalRejectOrder;
