import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "reactstrap";

function ButtonCard({ isSender, buttonText, to }) {
  const navigate = useNavigate();
  return (
    <Button
      className={!isSender ? "btn-card-red btn-sm" : "btn-card-outline btn-sm"}
      onClick={() => navigate(to)}
    >
      <Link className={`${isSender ? "" : "text-white"} font-size-12`}>
        {buttonText}
      </Link>
    </Button>
  );
}

export default ButtonCard;
