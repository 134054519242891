import { useEffect, useState } from "react";
import { useGetRegenciesQuery } from "../../features/zones/api/ZoneApi";
import Select from 'react-select';

const SelectorRegencies = ({ provinceId = '', onChange, value = '', isHaveParent = false }) => {
    const [listRegencies, setListRegencies] = useState([]);
    const [defaultValue, setDefaultValue] = useState({ value: '', label: '' });

    const { data } = useGetRegenciesQuery(provinceId);

    useEffect(() => {
        if (isHaveParent) {
            if (data && provinceId) {
                const option = data.data.map(({ id: value, name: label }) => ({
                    value, label
                }))

                setListRegencies(option)
            } else {
                setListRegencies([])
            }
        } else {
            if (data) {
                const option = data.data.map(({ id: value, name: label }) => ({
                    value, label
                }))

                setListRegencies(option)
            } else {
                setListRegencies([])
            }
        }

    }, [data, isHaveParent, provinceId]);

    useEffect(() => {
        if (isHaveParent) {
            if (value && provinceId) {
                const resultFind = listRegencies.find((param) => param.value === value)
                setDefaultValue(resultFind)
            } else {
                setDefaultValue({ value: '', label: '' })
            }
        } else {
            if (value) {
                const resultFind = listRegencies.find((param) => param.value === value)
                setDefaultValue(resultFind)
            } else {
                setDefaultValue({ value: '', label: '' })
            }
        }
    }, [value, listRegencies, isHaveParent, provinceId]);

    return (
        <>
            <Select
                onChange={onChange}
                value={defaultValue}
                isSearchable={true}
                options={listRegencies}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: "0.25rem",
                    colors: {
                        ...theme.colors,
                        primary25: '#FFEDED',
                        primary: '#E91E23',
                    },
                })}
            />
        </>
    );
}

export default SelectorRegencies;
