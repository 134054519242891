import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { orderSetupSelector, setIsRefetchingDetailProgressTimeline, setIsRefetchingListProgressTimeline } from "../../../app/orderSlice";
import { Badge, Button, Col, FormGroup, Label, List, Row, Table } from "reactstrap";
import { FaFile, FaFileDownload } from "react-icons/fa";
import EmptyFile from "../../../assets/images/empty-file.png";
import { UpperCaseName, getExtensionAllowedFile, notify } from "../../../helpers/GlobalHelper";
import { BsFolder, BsImages } from "react-icons/bs";
import { useDeleteFileProgressTimelineMutation, usePostUploadFileInOrderTimelineMutation, usePutUpdateProgressOrderTimelineMutation } from "../api/CaseAPI";
import { MdDelete } from "react-icons/md";
import FileDesc from "../../timelineDetails/components/FileDesc";
import { listDescDetailTimeline } from "../../../docs/text";
import ListDescription from "../../timelineDetails/components/ListDescription";

const FormEndCase = ({ dataDetailProgress }) => {
    const [dataFile, setDataFile] = useState([]);
    const [isUpdating, setIsUpdating] = useState(false);
    const [valuDescriptionProgress, setValuDescriptionProgress] = useState('');
    const [colorCountLengthDescription, setColorCountLengthDescription] = useState('');
    const [maxLengthDescription, setMaxLengthDescription] = useState(1000);
    const [statusEndCase, setStatusEndCase] = useState('Belum Dikonfirmasi');
    const [reasonRejected, setReasonReject] = useState('');


    const inputRef = useRef(null);
    const dispatch = useDispatch();

    const [
        postUploadFileInOrderTimeline,
        {
            isError: isErrorUploadFileInOrderTimeline,
            isSuccess: isSuccessUploadFileInOrderTimeline,
            error: errorUploadFileInOrderTimeline,
        }
    ] = usePostUploadFileInOrderTimelineMutation();

    const [
        deleteFileProgressTimeline,
        {
            isLoading: isLoadingDeleteFileProgressTimeline,
            isError: isErrorDeleteFileProgressTimeline,
            error: errorDeleteFileProgressTimeline,
            isSuccess: isSuccessDeleteFileProgressTimeline,
        }
    ] = useDeleteFileProgressTimelineMutation();

    const [
        putUpdateProgressOrderTimeline,
        {
            isLoading: isLoadingUpdateProgressTimeline,
            isError: isErrorUpdateProgressTimeline,
            isSuccess: isSuccessUpdateProgressTimeline,
        }
    ] = usePutUpdateProgressOrderTimelineMutation();

    const {
        detail_order_service,
    } = useSelector(orderSetupSelector)

    const handlerUpdate = async () => {
        if (valuDescriptionProgress.length > maxLengthDescription) {
            return notify('warn', 30000, `Panjang deskripsi tidak boleh dari ${maxLengthDescription} karakter`);
        }

        const param = {
            id: dataDetailProgress.id,
            custom_title: dataDetailProgress.custom_title,
            description: valuDescriptionProgress
        }

        await putUpdateProgressOrderTimeline(param).unwrap()
    }

    const handlerDeleteFile = async (param) => {
        if (!isLoadingDeleteFileProgressTimeline) {
            const paramReq = {
                id_file: param.id
            }
            await deleteFileProgressTimeline(paramReq).unwrap();
        } else {
            return notify('error', 30000, 'Sedang memproses permintaan sebelumnya!');
        }
    }

    const handlerUploadFile = async (event) => {
        if (event.target.files.length > 0) {
            const getExtensionFileAllowed = getExtensionAllowedFile('word-ppt-img-vid-pdf');
            if (!getExtensionFileAllowed.includes(event.target.files[0].type)) {
                return notify('warn', 30000, 'Ektensi file yang anda masukan tidak diperbolehkan!');
            } else {
                const param = {
                    file: event.target.files[0],
                    id_order_timeline: dataDetailProgress.id,
                    file_name: ''
                }
                await postUploadFileInOrderTimeline(param).unwrap();
            }
        }
    }

    const ContentUploadFile = () => {
        return <Row className="mt-4">
            <Col sm={6} className="mb-5">
                <FileDesc title="Upload dokumentasi / berkas">
                    <Button
                        onClick={() => {
                            inputRef.current?.click();
                        }}
                        className="border-0 w-100 file_upload file_button p-3 d-flex flex-column align-items-center justify-content-center gap-3"
                    >
                        <BsImages size={42} color="#438FFE" />
                        <span className="text-black">
                            Browes image / Video / Document
                        </span>
                    </Button>
                    <input
                        ref={inputRef}
                        type="file"
                        hidden
                        onChange={handlerUploadFile}
                        accept="image/*,.pdf,video/*,.ppt,.pptx,.doc,.docx"
                    />
                </FileDesc>
            </Col>
            <Col>
                <FileDesc title="Tips & Info" type="desc">
                    <div className="border-0 w-100 file_upload file_button p-2">
                        <List className="mt-3">
                            {listDescDetailTimeline.map((item) => (
                                <ListDescription
                                    key={item.key}
                                    listTitle={item.title}
                                    listDesc={item.desc}
                                />
                            ))}
                        </List>
                    </div>
                </FileDesc>
            </Col>
        </Row>
    }

    const ContentListFile = ({ dataFile, isUpdating }) => {
        if (dataFile.length > 0) {
            return <div className="table-responsive">
                <Table className="table mt-4">
                    <thead className="table-primary">
                        <tr>
                            <th className="fw-bold ps-5 text-dark opacity-75">
                                Dokumen
                            </th>
                            <th className="fw-bold ps-5 text-center text-dark opacity-75">
                                Tipe File
                            </th>
                            <th className="fw-bold text-center text-dark opacity-75"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {dataFile?.map((val) => {
                            return <tr key={val.id}>
                                <td className="ps-5 py-3 fw-semibold">
                                    {!val.file_name ? '-' : val.file_name}
                                </td>
                                <td className="ps-5 text-center py-3 fw-semibold">
                                    {!val.extension_file_uploaded ? '-' : val.extension_file_uploaded}
                                </td>
                                <td className="py-3">
                                    <div className="d-flex gap-3 justify-content-center">
                                        {
                                            val.url
                                            &&
                                            <FaFileDownload
                                                onClick={() => {
                                                    const redirectUrl = window.open();
                                                    redirectUrl.location.href = val.url;
                                                }}
                                                size={24}
                                                className="cursor-pointer text-primary"
                                                title="download file ini"
                                            />
                                        }

                                        {
                                            !val.url
                                            &&
                                            <FaFile
                                                size={24}
                                                className="text-secondary"
                                                title="belum ada file"
                                            />
                                        }

                                        {
                                            isUpdating
                                            &&
                                            <MdDelete
                                                onClick={() => handlerDeleteFile(val)}
                                                size={24}
                                                className="cursor-pointer text-orange"
                                                title="hapus file ini"
                                            />
                                        }

                                    </div>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </Table>
            </div>
        } else {
            return <div className="text-center d-flex justify-content-center flex-column align-items-center">
                <img src={EmptyFile} className="empty-file" />
                <span>Tidak ada dokumen</span>
            </div>
        }
    }

    useEffect(() => {
        if (detail_order_service.category_order_service_name === 'request_end_case_to_client') {
            if (!dataDetailProgress.status_confirmation) {
                if (dataDetailProgress.reason_confirmation.length > 0) {
                    setIsUpdating(false)
                    setStatusEndCase('Telah ditolak')
                    setReasonReject(dataDetailProgress.reason_confirmation)
                } else {
                    setIsUpdating(true)
                    setStatusEndCase('Belum dikonfirmasi')
                    setReasonReject('')
                }
            } else {
                setIsUpdating(false)
                setStatusEndCase('Telah diterima')
                setReasonReject('')
            }
        } else {
            if (!dataDetailProgress.status_confirmation) {
                if (dataDetailProgress.reason_confirmation.length > 0) {
                    setIsUpdating(false)
                    setStatusEndCase('Telah ditolak')
                    setReasonReject(dataDetailProgress.reason_confirmation)
                } else {
                    setIsUpdating(false)
                    setStatusEndCase('Belum dikonfirmasi')
                    setReasonReject('')
                }
            } else {
                setIsUpdating(false)
                setStatusEndCase('Telah diterima')
                setReasonReject('')
            }
        }
        setDataFile(dataDetailProgress.file)
        setValuDescriptionProgress(dataDetailProgress.description)
    }, [dataDetailProgress, detail_order_service]);

    useEffect(() => {
        if (isErrorUpdateProgressTimeline) {
            notify('error', 30000, 'Terjadi kesalahan saat memproses');
        }
    }, [isErrorUpdateProgressTimeline]);

    useEffect(() => {
        if (isSuccessUpdateProgressTimeline) {
            dispatch(setIsRefetchingListProgressTimeline(true))
            dispatch(setIsRefetchingDetailProgressTimeline(true))
            notify('success', 30000, 'Berhasil mengupdate');
        }
    }, [isSuccessUpdateProgressTimeline]);

    useEffect(() => {
        if (valuDescriptionProgress.length <= maxLengthDescription / 2) {
            setColorCountLengthDescription('text-success')
        } else if (valuDescriptionProgress.length >= maxLengthDescription / 2 && valuDescriptionProgress.length < maxLengthDescription) {
            setColorCountLengthDescription('text-warning')
        } else {
            setColorCountLengthDescription('text-orange')
        }
    }, [valuDescriptionProgress]);

    useEffect(() => {
        if (isSuccessDeleteFileProgressTimeline) {
            dispatch(setIsRefetchingDetailProgressTimeline(true))
            notify('success', 30000, 'Berhasil menghapus file yang sudah di upload');
        }
    }, [isSuccessDeleteFileProgressTimeline]);

    useEffect(() => {
        if (isErrorDeleteFileProgressTimeline) {
            notify('error', 30000, errorDeleteFileProgressTimeline.data.message);
        }
    }, [isErrorDeleteFileProgressTimeline]);

    useEffect(() => {
        if (isSuccessUploadFileInOrderTimeline) {
            dispatch(setIsRefetchingDetailProgressTimeline(true))
            notify('success', 30000, 'Berhasil mengupload file');
        }
    }, [isSuccessUploadFileInOrderTimeline]);

    useEffect(() => {
        if (isErrorUploadFileInOrderTimeline) {
            notify('error', 30000, errorUploadFileInOrderTimeline.data.message);
        }
    }, [isErrorUploadFileInOrderTimeline]);

    return (
        <>
            <Row>
                <div className="pb-1 fw-bold mt-2 font-size-14">
                    {UpperCaseName(dataDetailProgress?.custom_title)}
                </div>
                <div className="font-size-12 mt-1 ms-0 text-primary">
                    *Anda masih dapat melakukan upload, hapus file dan juga update deskripsi progress sebelum di konfirmasi!
                </div>
                <div className="mt-3">
                    <span>
                        Status:{' '}
                        <Badge color="info" className="my-0 font-size-12 px-2">
                            {statusEndCase}
                        </Badge>
                    </span>
                </div>
                {
                    reasonRejected.length > 0
                    &&
                    <div className="mt-3">
                        <span>Alasan: {reasonRejected}</span>
                    </div>
                }
            </Row>

            {isUpdating && <ContentUploadFile />}

            <Row className="mt-4">
                <div className="d-flex align-items-center gap-3">
                    <BsFolder size={20} color="#F54748" />
                    <span className="font-size-14 fw-bold">Dokumen Terlampir</span>
                </div>
                <ContentListFile dataFile={dataFile} isUpdating={isUpdating} />
            </Row>

            <FormGroup className="w-100 h-25">
                <Label for="forDescription">
                    <span className="font-size-14 fw-bold">
                        Deskripsi
                    </span>
                </Label>
                <textarea
                    placeholder="Masukkan informasi terkait persidangan seperti lokasi, nama yang terlibat, hasil putusan, dan lainnya"
                    onChange={(e) => setValuDescriptionProgress(e.target.value)}
                    className="form-control description_input bg-white py-2"
                    value={valuDescriptionProgress}
                ></textarea>
                <p className="mt-2">
                    Maksimal Panjang Deskripsi: {' '}
                    <span className={`${colorCountLengthDescription} fw-bold`}>
                        {valuDescriptionProgress.length}
                    </span>
                    /
                    <span className="text-orange fw-bold">
                        {maxLengthDescription}
                    </span>
                </p>
            </FormGroup>
            <div className="d-flex justify-content-center gap-3 mt-3">
                {
                    isUpdating
                    &&
                    <Button
                        onClick={handlerUpdate}
                        className="rounded-3"
                        color="danger"
                        block
                        disabled={isLoadingUpdateProgressTimeline}
                    >
                        {isLoadingUpdateProgressTimeline ? 'Memproses...' : 'Simpan'}
                    </Button>
                }
            </div>
        </>
    );
}

export default FormEndCase;
