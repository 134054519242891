import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useRef, useState } from "react";
import Countdown, { zeroPad } from "react-countdown";
import { Controller, useForm } from "react-hook-form";
import { FaPaperPlane } from "react-icons/fa6";
import { HiCode, HiInformationCircle } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Card, CardText, CardTitle, Form, Input, InputGroup, InputGroupText } from "reactstrap";
import * as yup from "yup";
import { authSetupSelector, forgotPassword } from "../../../app/authSlice";
import { usePostResendForgotPasswordMutation, usePostVerificationCodeForgotPasswordMutation } from "../api/AuthAPI";
import AlertForm from "./AlertForm";
import HeaderForm from "./HeaderForm";
const { VITE_DELAY_TIME_RESEND } = import.meta.env;

const ForgotPasswordVerificationCode = ({ type, handlerStatusForm }) => {
    const defaultTime = parseInt(VITE_DELAY_TIME_RESEND);
    const [messageAlert, setMessageAlert] = useState("");
    const [isStatusAlert, setIsStatusAlert] = useState("");
    const [timeCountDown, setTimeCountDown] = useState(Date.now() + defaultTime);
    const countRef = useRef("");
    const navigate = useNavigate();
    const dispatch = useDispatch();


    const { email } = useSelector(authSetupSelector)
    const [postResendForgotPassword, { isLoading }] = usePostResendForgotPasswordMutation()
    const [postVerificationCodeForgotPassword] = usePostVerificationCodeForgotPasswordMutation()


    const schema = yup.object({
        code: yup.number().required('Kode verifikasi wajib di isi').positive('nilai harus berupa positif, tidak boleh minus').integer('nilai harus berupa angka')
    });

    const {
        control,
        handleSubmit: handleSubmitVerificationCode,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            code: ""
        },
    });

    const handleOnSubmit = async (param) => {
        try {
            param.email = email

            await postVerificationCodeForgotPassword(param).unwrap();
            handlerStatusForm('form-change-password')
        } catch (error) {
            setIsStatusAlert('error');
            setMessageAlert(error.data.message)
        }
    };

    const handlerCancel = () => {
        dispatch(forgotPassword({ email: '' }))
        navigate(type === 'lawyer' ? '/login-lawyer' : '/login')
    }

    const handlerResendCode = async (event) => {
        try {
            event.preventDefault()

            await postResendForgotPassword({ email }).unwrap();

            setIsStatusAlert('info');
            setMessageAlert(
                <span>
                    < HiInformationCircle /> Kode baru telah dikirimkan
                </span>
            )
            setTimeCountDown(Date.now() + defaultTime)

        } catch (error) {
            setIsStatusAlert('error');
            setMessageAlert(error.data.message)
        }
    }

    useEffect(() => {
        countRef?.current?.start();
    }, [timeCountDown]);

    const renderer = ({ minutes, seconds, completed }) => {
        if (completed) {
            return (
                <div className="d-flex justify-content-end">
                    <Button
                        type="button"
                        color="transparent"
                        onClick={handlerResendCode}
                        className="text-primary"
                    >
                        Kirim Ulang <FaPaperPlane />
                    </Button>
                </div>
            )
        } else {
            return (
                <>
                    <CardText className="text-secondary fw-normal">
                        <span className="mb-3 me-2">
                            Jika tidak menerima kode verifikasi, silahkan kirim setelah:
                        </span>
                        <span className="text-primary">
                            {zeroPad(minutes)}:{zeroPad(seconds)}
                        </span>
                    </CardText>
                </>
            )
        }
    };

    return (
        <Card className="overflow-hidden shadow-none border-0 rounded-3 align-self-center bg-transparent px-lg-6">
            <HeaderForm
                title="Lupa Kata Sandi"
            />
            <CardTitle className="d-none d-lg-block mt-0 fs-3 fw-bold text-center">
                Lupa Kata Sandi
            </CardTitle>
            <CardText className="text-secondary fs-5 fw-normal text-center mt-3">
                Silakan masukkan verifikasi kode yang dikirimkan di email
            </CardText>
            <div className="p-0 p-lg-2 mt-4">
                <Form
                    onSubmit={handleSubmitVerificationCode(handleOnSubmit)}
                    className="form-horizontal"
                >
                    {
                        isStatusAlert
                        &&
                        <AlertForm
                            status={isStatusAlert}
                            description={messageAlert}
                        />
                    }
                    <div className="mb-2">
                        <InputGroup>
                            <InputGroupText className={`bg-transparent border-end-0 text-start justify-content-end rounded-3 rounded-end-0 ${errors.code && 'border-orange'}`}>
                                <HiCode size={20} />
                            </InputGroupText>
                            <Controller
                                name="code"
                                control={control}
                                render={({ field: { onChange, onBlur, value } }) => {
                                    return (
                                        <>
                                            <Input
                                                bsSize="lg"
                                                className={`d-none d-md-block border-start-0 rounded-3 rounded-start-0 text-start ${errors.code && 'border-orange'}`}
                                                type="text"
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                value={value}
                                                placeholder="Masukan kode verifikasi anda"
                                            />
                                            <Input
                                                bsSize="sm"
                                                className={`d-block d-md-none border-start-0 rounded-3 rounded-start-0 text-start ${errors.code && 'border-orange'}`}
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                value={value}
                                                type="text"
                                                placeholder="Masukan kode verifikasi anda"
                                            />
                                        </>
                                    )
                                }}
                            />
                        </InputGroup>
                    </div>
                    <div className="mt-3">
                        <Countdown
                            autoStart={false}
                            ref={countRef}
                            date={timeCountDown}
                            renderer={renderer}
                        />
                    </div>
                    <div className="mt-4">
                        <Button
                            type="submit"
                            block
                            color="danger"
                            className="rounded-4"
                            disabled={isLoading}
                        >
                            {!isLoading ? 'Verifikasi' : 'Memproses...'}
                        </Button>
                    </div>
                    <div className="mt-2">
                        <Button
                            onClick={
                                () => handlerCancel()
                            }
                            type="button"
                            block
                            color="secondary"
                            className="rounded-4"
                        >
                            Kembali
                        </Button>
                    </div>
                </Form>
            </div>
        </Card>
    );
}

export default ForgotPasswordVerificationCode;
