import AuthLayout from "../../components/layout/AuthLayout";
import { Col, Container, Row } from "reactstrap";
import CardWelcome from "../../components/dashboard/CardWelcome";
import HelmetTitle from "../../components/layout/HelmetTitle";

const DashboardLawyer = ({ type }) => {
    return (
        <>
            <HelmetTitle
                title="Dashboard | Lawyer"
                meta="dashboard, lawyer, dashboardlawyer, ceraionline"
            />
            <div className="page-content">
                <Container fluid>
                    <Row className="justify-content-center">
                        <Col xs={10}>
                            <CardWelcome type='lawyer' />
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default DashboardLawyer;
