import { Fragment, useEffect, useState } from "react";
import CurrencyInput from "react-currency-input-field";
import { BsInfoCircleFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, CardBody, Col, Label, Row } from "reactstrap";
import { authSetupSelector, setRefreshToken, setSessions, setToken } from "../../../app/authSlice";
import { profileSetupSelector, updateServiceRateData } from "../../../app/profileSlice";
import { usePostRefreshTokenMutation } from "../../auth/api/AuthAPI";
import { usePostActivationAccountLawyerMutation, usePutUpdateDataServiceRatesMutation } from "../../user/api/UsersAPI";
import { checkLengthKeys, notify, reformatToIDRCurrency } from "../../../helpers/GlobalHelper";
import { listInfoPriceService } from "../../../docs/text";

const FormServiceRatesLawyer = ({ onChangeTab }) => {
    const [isUpdateServiceRate, setIsUpdateServiceRate] = useState(false);
    const [listServiceRates, setListServiceRates] = useState([]);
    const dispatch = useDispatch()
    const { sessions, refresh_token } = useSelector(authSetupSelector)
    const { serviceRates } = useSelector(profileSetupSelector)

    const [postRefreshToken, { isLoading: isLoadingRefetchSession }] = usePostRefreshTokenMutation()
    const [
        putUpdateDataServiceRates,
        {
            isLoading: isLoadingUpdateDataServiceRate,
            isSuccess: isSuccessDataServiceRate,
            isError: isErrorUpdateDataServiceRate,
            error: errorUpdateDataServiceRate,
        }
    ] = usePutUpdateDataServiceRatesMutation()

    const [
        postActivationAccountLawyer,
        {
            isLoading: isLoadingActivationAccountLawyer,
            isSuccess: isSuccessActivationAccountLawyer
        }
    ] = usePostActivationAccountLawyerMutation()

    const handlerRefreshSession = async (isUpdateProfile = false) => {
        const resultGenerateNewToken = await postRefreshToken({ refresh_token }).unwrap()
        const dataSessions = resultGenerateNewToken.data;
        if (isUpdateProfile) {
            localStorage.setItem('data', JSON.stringify(dataSessions))
            dispatch(setSessions(dataSessions))
            return true;
        }

        localStorage.setItem('refreshToken', resultGenerateNewToken.refreshToken)
        localStorage.setItem('token', resultGenerateNewToken.token)

        dispatch(setToken(resultGenerateNewToken.token))
        dispatch(setRefreshToken(resultGenerateNewToken.refreshToken))
        return true;
    }

    const handlerTypingPriceService = async (value, id) => {

        const existingIndex = listServiceRates.findIndex(
            (item) => item.id === id
        )

        if (existingIndex !== -1) {
            const listServiceRatesUpdated = [...listServiceRates]
            listServiceRatesUpdated[existingIndex] = {
                id: id,
                category_service_name: listServiceRates[existingIndex].category_service_name,
                price: !value ? 0 : Number(value),
            }
            setListServiceRates(listServiceRatesUpdated)
        } else {
            setListServiceRates([
                ...listServiceRates,
                {
                    id,
                    category_service_name: listServiceRates[existingIndex].category_service_name,
                    price: !value ? 0 : Number(value)
                },
            ])
        }
    }

    const handlerSaveServiceRate = async () => {
        const getListPriceCantMinimum = listServiceRates.filter((val) => val.price > 0 && val.price < 25000);
        if (getListPriceCantMinimum.length > 0) {
            getListPriceCantMinimum.map(
                (val) => notify('warn', 90000, `${val.category_service_name} tidak boleh kurang dari Rp. 25.000 atau dapat dikosongkan jika tidak ingin di aktifkan!`)
            )
            return false
        } else {
            await handlerRefreshSession(false)
            await putUpdateDataServiceRates({ service_rates: listServiceRates }).unwrap()
            return true
        }
    }

    const handlerActivationAccount = async () => {
        await handlerRefreshSession(false)
        await postActivationAccountLawyer().unwrap()

    }

    useEffect(() => {
        const checkingObj = checkLengthKeys(sessions)

        if (checkingObj && !isUpdateServiceRate) {
            dispatch(updateServiceRateData(sessions.service_rates))
            setListServiceRates(sessions.service_rates)
        }
    }, [sessions, isUpdateServiceRate]);

    useEffect(() => {
        if (isSuccessDataServiceRate) {
            notify('success', 30000, 'Berhasil merubah data harga layanan!');
            setIsUpdateServiceRate(false)
            handlerRefreshSession(true)
        }
    }, [isSuccessDataServiceRate]);

    useEffect(() => {
        if (isSuccessActivationAccountLawyer) {
            notify('success', 30000, 'Berhasil mengirimkan email aktivasi!');
            setIsUpdateServiceRate(false)
            handlerRefreshSession(true)
        }
    }, [isSuccessActivationAccountLawyer]);

    useEffect(() => {
        if (isErrorUpdateDataServiceRate) {
            notify('error', 30000, errorUpdateDataServiceRate.data.message);
        }
    }, [isErrorUpdateDataServiceRate]);

    const ContentButtonActivation = (params) => {
        const {
            handlerActivationAccount,
            statusVerifyJob
        } = params
        if ([3, 4].includes(statusVerifyJob)) {
            return (
                <>
                    <Button
                        type='button'
                        className="d-none d-lg-block ms-lg-2 mt-3"
                        color="info"
                        disabled={isLoadingActivationAccountLawyer || isLoadingUpdateDataServiceRate ? true : false}
                        onClick={handlerActivationAccount}
                    >
                        Aktivasi Sebagai Lawyer
                    </Button>
                    <Button
                        type='button'
                        block
                        className="d-block d-lg-none ms-lg-2 mt-3"
                        color="info"
                        disabled={isLoadingActivationAccountLawyer || isLoadingUpdateDataServiceRate ? true : false}
                        onClick={handlerActivationAccount}
                    >
                        Aktivasi Sebagai Lawyer
                    </Button>
                </>
            )
        }
    }

    const ContentSubList = ({ data }) => {
        return <ul style={{ listStyleType: 'disc' }} className="my-1">
            {data.map((val) => <li className="mb-1">
                <p className="fs-6 mb-0">
                    {val.info}
                </p>
            </li>)}
        </ul>
    }

    return (
        <Row>
            <Col xs={12}>
                <h6 className="my-0 text-dark">
                    <BsInfoCircleFill size={16} className="me-3" /> Cara mengatur Tarif Layanan dan Pehitungan Komisi Cerai Online
                </h6>
            </Col>
            <Col xs={12} className="mt-3">
                <Card outline color="secondary" className="border">
                    <CardBody className="px-0">
                        <ul style={{ listStyleType: 'disc' }}>
                            {listInfoPriceService.map((val) =>
                                <li className="mb-1">
                                    <p className="fs-6 mb-0">
                                        {val.info}
                                    </p>
                                    {val.subInfo.length > 0 && <ContentSubList data={val.subInfo} />}
                                </li>
                            )}
                        </ul>
                    </CardBody>
                </Card>
            </Col>
            <Col xs={12}>
                <Label className="fw-bold fs-6">
                    Tarif Layanan
                    <span className="text-orange">
                        *
                    </span>
                </Label>
            </Col>
            <Col xs={12} className="mt-3">
                <Row className="align-items-center">
                    {listServiceRates.map((val) => (
                        <Fragment key={val.id}>
                            <Col xs={12} lg={6} className="mb-2">
                                <Label className="fw-light fs-6 text-secondary">
                                    {val.category_service_name}
                                </Label>
                            </Col>
                            <Col xs={12} lg={6} className="mb-2">
                                <CurrencyInput
                                    name={`price${val.id}`}
                                    className="form-control"
                                    placeholder="Masukan biaya jasa anda"
                                    // defaultValue={val.price}
                                    value={val.price}
                                    maxLength={10}
                                    intlConfig={{ locale: 'id-ID', currency: 'IDR' }}
                                    onValueChange={
                                        (value) => handlerTypingPriceService(value, val.id)
                                    }
                                />
                            </Col>
                        </Fragment>
                    ))}
                </Row>
            </Col>
            <Col xs={12} >
                <div className="d-lg-flex justify-content-lg-end">
                    <Button
                        type='button'
                        className="d-none d-lg-block ms-lg-2 mt-3"
                        color="secondary"
                        onClick={() => onChangeTab(3)}
                        disabled={isLoadingActivationAccountLawyer || isLoadingUpdateDataServiceRate ? true : false}
                    >
                        Kembali
                    </Button>
                    <Button
                        type='button'
                        block
                        className="d-block d-lg-none ms-lg-2 mt-3"
                        color="secondary"
                        onClick={() => onChangeTab(3)}
                        disabled={isLoadingActivationAccountLawyer || isLoadingUpdateDataServiceRate ? true : false}
                    >
                        Kembali
                    </Button>
                    <ContentButtonActivation
                        handlerActivationAccount={handlerActivationAccount}
                        isLoadingUpdateData={isLoadingUpdateDataServiceRate}
                        statusVerifyJob={!sessions.status_verify_job ? 0 : sessions.status_verify_job}
                    />
                    <Button
                        type='button'
                        onClick={() => handlerSaveServiceRate()}
                        className="d-none d-lg-block ms-lg-2 mt-3"
                        color="primary"
                        disabled={isLoadingActivationAccountLawyer || isLoadingUpdateDataServiceRate ? true : false}
                    >
                        Simpan
                    </Button>
                    <Button
                        type='button'
                        onClick={() => handlerSaveServiceRate()}
                        block
                        className="d-block d-lg-none ms-lg-2 mt-3"
                        color="primary"
                        disabled={isLoadingActivationAccountLawyer || isLoadingUpdateDataServiceRate ? true : false}
                    >
                        Simpan
                    </Button>
                </div>
            </Col>
        </Row>
    );
}

export default FormServiceRatesLawyer;
