import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setRefreshToken, setSessions, setToken } from "../../app/authSlice";
import { usePostRefreshTokenMutation } from "../../features/auth/api/AuthAPI";
import FooterNonAuth from "./FooterNonAuth";
import HeaderNonAuth from "./HeaderNonAuth";
import { refreshData } from "../../socket/Clients/ClientAction";

const NonAuthLayout = ({ type, children }) => {
    const [isLoading, setIsLoading] = useState(true);

    const dispatch = useDispatch()

    const [postRefreshToken] = usePostRefreshTokenMutation()

    const checkingSession = async () => {
        const refresh_token = localStorage.getItem("refreshToken");
        if (!refresh_token) {
            return false
        }

        try {
            const resultGenerateNewToken = await postRefreshToken({ refresh_token }).unwrap()
            const dataSessions = resultGenerateNewToken.data;
            localStorage.setItem('refreshToken', resultGenerateNewToken.refreshToken)
            localStorage.setItem('token', resultGenerateNewToken.token)
            localStorage.setItem('data', JSON.stringify(dataSessions))
            dispatch(setToken(resultGenerateNewToken.token))
            dispatch(setRefreshToken(resultGenerateNewToken.refreshToken))
            dispatch(setSessions(dataSessions))
            refreshData()
            return true
        } catch (error) {
            console.warn("error:", error)
            return false
        }
    }

    useEffect(() => {
        let body = document.body;
        body.setAttribute('data-layout', 'horizontal')
    }, []);

    useEffect(() => {
        checkingSession()
        setInterval(() => {
            setIsLoading(false)
        }, 2500);
    }, []);

    useEffect(() => {
        refreshData()
    }, [children]);

    return (
        <>
            {isLoading &&
                <div id="preloader">
                    <div id="status">
                        <div className="spinner-chase">
                            <div className="chase-dot" />
                            <div className="chase-dot" />
                            <div className="chase-dot" />
                            <div className="chase-dot" />
                            <div className="chase-dot" />
                            <div className="chase-dot" />
                        </div>
                    </div>
                </div>
            }
            <div id="layout-wrapper">
                <HeaderNonAuth type={type} />
                <div className="main-content bg-white">
                    {children}
                </div>
                <FooterNonAuth />
            </div>
        </>
    );
}

export default NonAuthLayout;
