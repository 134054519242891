import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FaRegEnvelope } from "react-icons/fa6";
import { HiOutlineEye, HiOutlineEyeOff, HiOutlineLockClosed, HiOutlineUser } from "react-icons/hi";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useDispatch } from "react-redux";
import { Button, Card, CardBody, Col, Form, Input, InputGroup, InputGroupText, Label, Row } from "reactstrap";
import * as yup from "yup";
import YupPassword from "yup-password";
import { resendVerifyEmailRegister } from "../../../app/authSlice";
import { usePostRegisterMutation } from "../api/AuthAPI";
import AlertForm from "./AlertForm";
import ContentLeftForm from "./ContentLeftForm";
import FooterForm from "./FooterForm";
import ForgotPasswordLink from "./ForgotPasswordLink";
import HeaderForm from "./HeaderForm";
import PhoneNumber from "../../../components/form/PhoneNumber";
import RadioGender from "../../../components/form/RadioGender";
YupPassword(yup)

const RegisterContentForm = ({ type, onUpdatePage }) => {
    const [isShowPassword, setIsShowPassword] = useState(false);
    const [isStatusAlert, setIsStatusAlert] = useState("");
    const [messageAlert, setMessageAlert] = useState("");
    const [postRegister, { isLoading }] = usePostRegisterMutation()
    const dispatch = useDispatch()

    const schema = yup.object({
        full_name: yup.string().required("Nama lengkap wajib di isi!"),
        gender: yup.mixed().oneOf(['male', 'female'], "Jenis kelamin hanya tersedia Laki - laki dan Perempuan!").required("jenis kelamin wajib di isi!"),
        phone_number: yup
            .string()
            .required('No Telepon wajib di isi!')
            .matches(/^\+62\d{10,}$/, 'Format tidak valid & minimal 10 digit sesudah +62!'),
        email: yup
            .string()
            .email("Email harus berformat email!")
            .required("Email wajib di isi!"),
        password: yup.string().minUppercase(1, "Minimal 1 huruf kapital!").minLowercase(1, "Minimal 1 huruf kecil!").minNumbers(1, "Minimal 1 angka!").minSymbols(1, "Minimal 1 simbol: _-#1@&^$").min(8, "Minimal panjang 8 karakter!")
    });

    const {
        control,
        handleSubmit,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            full_name: "",
            gender: "male",
            phone_number: "",
            email: "",
            password: "",
        },
    });

    const handleOnSubmit = async (param) => {
        try {
            param.role = type === 'client' ? 'Client' : 'Lawyer'
            const { email } = param
            await postRegister(param).unwrap();
            setMessageAlert('Berhasil daftar')
            onUpdatePage(true)
            dispatch(resendVerifyEmailRegister({ email }))
        } catch (error) {
            const typeMessage = typeof error.data.message;
            setIsStatusAlert('error')

            if (typeMessage === 'string') {
                setMessageAlert(error.data.message)
            } else {
                let messageAll = '';
                error.data.message.forEach((val) => {
                    if (val.field) {
                        messageAll += `No telepon: format no telepon tidak valid!`
                    }
                });
                setMessageAlert(messageAll)
            }
        }
    };

    return (
        <Row className="justify-content-center align-self-center mt-8 mt-lg-12 px-2 px-lg-15 min-vh-100">
            <Col className="d-none d-lg-inline-block text-center" md={6} lg={4} xl={6}>
                <ContentLeftForm
                    description={`Daftar Sebagai ${type === 'lawyer' ? 'Pengacara' : 'Klien'}`}
                />
            </Col>
            <Col sm={12} md={8} lg={8} xl={6}>
                <Card className="overflow-hidden shadow-none border-0 rounded-3 align-self-center bg-transparent px-lg-6">
                    <HeaderForm
                        title={`Daftar Sebagai ${type === 'lawyer' ? 'Pengacara' : 'Klien'}`}
                    />
                    <CardBody className="py-lg-5">
                        <div className="p-0 p-lg-2">
                            <Form
                                onSubmit={handleSubmit(handleOnSubmit)}
                                className="form-horizontal"
                            >
                                {
                                    isStatusAlert
                                    &&
                                    <AlertForm description={messageAlert} />
                                }

                                <div className="mb-2">
                                    <Label for="email">
                                        Nama Lengkap
                                        {` `}
                                        <span className="text-orange">*</span>
                                    </Label>
                                    <InputGroup>
                                        <InputGroupText className={
                                            `bg-transparent border-end-0 text-start justify-content-end rounded-3 rounded-end-0 ${errors.full_name && 'border-orange'}`
                                        }>
                                            <HiOutlineUser size={20} />
                                        </InputGroupText>
                                        <Controller
                                            name="full_name"
                                            control={control}
                                            render={({
                                                field:
                                                { onChange, onBlur, value }
                                            }) => {
                                                return <>
                                                    <Input
                                                        bsSize="lg"
                                                        className={`d-none d-md-block border-start-0 rounded-3 rounded-start-0 text-start ${errors.full_name && 'border-orange'}`}
                                                        onBlur={onBlur}
                                                        onChange={onChange}
                                                        value={value}
                                                        type="text"
                                                        placeholder="Masukan nama anda"
                                                    />
                                                    <Input
                                                        bsSize="sm"
                                                        className={`d-block d-md-none border-start-0 rounded-3 rounded-start-0 text-start ${errors.full_name && 'border-orange'}`}
                                                        onBlur={onBlur}
                                                        onChange={onChange}
                                                        value={value}
                                                        type="text"
                                                        placeholder="Masukan nama anda"
                                                    />
                                                </>
                                            }}
                                        />
                                    </InputGroup>
                                </div>
                                <div className="mb-3">
                                    <span className="mt-2 text-orange text-start">
                                        {errors.full_name?.message}
                                    </span>
                                </div>

                                <div className="mb-2">
                                    <Label for="gender" className="mb-3">
                                        Jenis Kelamin
                                        {` `}
                                        <span className="text-orange">*</span>
                                    </Label>
                                    <div className="d-flex justify-content-md-between flex-wrap px-md-5">
                                        <Controller
                                            name="gender"
                                            control={control}
                                            render=
                                            {
                                                ({
                                                    field: { onChange, value }
                                                }) => {
                                                    return <RadioGender
                                                        onChange={onChange}
                                                        value={value}
                                                    />
                                                }
                                            }
                                        />
                                    </div>
                                </div>

                                <div className="mb-2">
                                    <Label for="phone_number">
                                        No. Telepon
                                        {` `}
                                        <span className="text-orange">*</span>
                                    </Label>
                                    <Controller
                                        name="phone_number"
                                        control={control}
                                        rules={{
                                            validate: (value) => isValidPhoneNumber(value)
                                        }}
                                        render={({ field: { onChange, value } }) => {
                                            return (
                                                <>
                                                    <div className="d-none d-md-block">

                                                        <PhoneNumber
                                                            value={value}
                                                            onChange={onChange}
                                                            className={`fs-5 ${errors.phone_number && 'border-orange'}`}
                                                        />
                                                    </div>
                                                    <div className="d-block d-md-none">
                                                        <PhoneNumber
                                                            value={value}
                                                            onChange={onChange}
                                                            className={errors.phone_number && 'border-orange'}
                                                        />
                                                    </div>
                                                </>
                                            )
                                        }}
                                    />
                                </div>
                                <div className="mb-3">
                                    <span className="mt-2 text-orange text-start">
                                        {errors.phone_number?.message}
                                    </span>
                                </div>

                                <div className="mb-2">
                                    <Label for="email">
                                        Email
                                        {` `}
                                        <span className="text-orange">*</span>
                                    </Label>
                                    <Controller
                                        name="email"
                                        control={control}
                                        render={(
                                            {
                                                field: { onChange, onBlur, value }
                                            }) => {
                                            return (
                                                <InputGroup>
                                                    <InputGroupText className={`bg-transparent border-end-0 text-start justify-content-end rounded-3 rounded-end-0 ${errors.email && 'border-orange'}`}>
                                                        <FaRegEnvelope size={20} />
                                                    </InputGroupText>
                                                    <Input
                                                        bsSize="lg"
                                                        className={`d-none d-md-block border-start-0 rounded-3 rounded-start-0 text-start ${errors.email && 'border-orange'}`}
                                                        type="email"
                                                        onChange={onChange}
                                                        onBlur={onBlur}
                                                        value={value}
                                                        placeholder="Masukan email anda"
                                                    />
                                                    <Input
                                                        bsSize="sm"
                                                        className={`d-block d-md-none border-start-0 rounded-3 rounded-start-0 text-start ${errors.email && 'border-orange'}`}
                                                        onChange={onChange}
                                                        onBlur={onBlur}
                                                        value={value}
                                                        type="email"
                                                        placeholder="Masukan email anda"
                                                    />
                                                </InputGroup>
                                            )
                                        }}
                                    />

                                </div>
                                <div className="mb-3">
                                    <span className="mt-2 text-orange text-start">
                                        {errors.email?.message}
                                    </span>
                                </div>

                                <div className="mb-2">
                                    <Label for="password">
                                        Kata Sandi
                                        {` `}
                                        <span className="text-orange">*</span>
                                    </Label>
                                    <Controller
                                        name="password"
                                        control={control}
                                        render={({ field: { onChange, onBlur, value } }) => {
                                            return (
                                                <InputGroup>
                                                    <InputGroupText
                                                        className={`bg-transparent border-end-0 text-start justify-content-end rounded-3 rounded-end-0 ${errors.password && 'border-orange'}`}
                                                    >
                                                        <HiOutlineLockClosed size={20} />
                                                    </InputGroupText>
                                                    <Input
                                                        name="password"
                                                        bsSize="lg"
                                                        className={`d-none d-md-block border-start-0 border-end-0 rounded-3 rounded-start-0 rounded-end-0 text-start ${errors.password && 'border-orange'}`}
                                                        type={isShowPassword ? 'text' : 'password'}
                                                        placeholder="Masukan kata sandi anda"
                                                        onChange={onChange}
                                                        onBlur={onBlur}
                                                        value={value}
                                                    />
                                                    <Input
                                                        name="password"
                                                        bsSize="sm"
                                                        className={`d-block d-md-none border-start-0 border-end-0 rounded-3 rounded-start-0 rounded-end-0 text-start ${errors.password && 'border-orange'}`}
                                                        type={isShowPassword ? 'text' : 'password'}
                                                        placeholder="Masukan kata sandi anda"
                                                        onChange={onChange}
                                                        onBlur={onBlur}
                                                        value={value}
                                                    />
                                                    <InputGroupText className={`bg-transparent border-start-0 text-start justify-content-end rounded-3 rounded-start-0 ${errors.password && 'border-orange'}`} style={{ 'cursor': 'pointer' }} onClick={
                                                        () => {
                                                            setIsShowPassword(!isShowPassword)
                                                        }}>
                                                        {isShowPassword ?
                                                            <HiOutlineEyeOff size={20} />
                                                            :
                                                            <HiOutlineEye size={20} />
                                                        }
                                                    </InputGroupText>
                                                </InputGroup>
                                            )
                                        }}
                                    />

                                </div>
                                <div className="mb-3">
                                    <span className="mt-2 text-orange text-start">
                                        {errors.password?.message}
                                    </span>
                                </div>

                                <div className="d-flex justify-content-end mb-3">
                                    <ForgotPasswordLink type={type} />
                                </div>

                                <div className="mb-4">
                                    <Button type="submit"
                                        block
                                        color="danger"
                                        className="rounded-4"
                                        disabled={isLoading}
                                    >
                                        {!isLoading ? 'Daftar' : 'Memproses...'}
                                    </Button>
                                </div>
                                <FooterForm type={type} from="register" />
                            </Form>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
}

export default RegisterContentForm;
