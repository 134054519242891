import { Link } from "react-router-dom";
import { Card, CardBody, CardText, CardTitle, Col } from "reactstrap";

const VerificationRegisterSuccess = ({ type = "client" }) => {
    return (
        <Col xs={12} className="mt-0 justify-content-center text-center">
            <Card className="bg-transparent shadow-none mt-0">
                <CardBody className="bg-transparent">
                    <CardTitle className="mt-0 fs-5 fw-bold">
                        Verifikasi Berhasil
                    </CardTitle>
                    <CardText className="text-secondary fw-normal">
                        Terimakasih telah mendaftarkan diri sebagai pengguna Cerai Online.
                    </CardText>
                    <CardText className="text-secondary fw-normal">
                        Silahkan melakukan login,{' '}
                        <Link
                            to={type === 'client' ? '/login' : '/login-lawyer'}
                            className="text-primary"
                        >
                            Di sini
                        </Link>
                    </CardText>
                </CardBody>
            </Card>
        </Col>
    );
}

export default VerificationRegisterSuccess;
