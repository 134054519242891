import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    id_rating: null,
    rate: 0,
    comment: null,
};

const ratingSlice = createSlice({
    name: 'ratingSetup',
    initialState,
    reducers: {
        setRate: (state, action) => {
            state.rate = action.payload;
        },
        setComment: (state, action) => {
            state.comment = action.payload;
        },
        setDataRating: (state, action) => {
            const { id, rate, comment } = action.payload
            state.id_rating = id;
            state.rate = rate;
            state.comment = comment;
        },
        resetDataRating: (state) => {
            state.id_rating = null;
            state.rate = 0;
            state.comment = null;
        }
    }
})

export const ratingSetupSelector = (state) => state.ratingSetup
export const {
    setDataRating,
    resetDataRating,
    setRate,
    setComment,
} = ratingSlice.actions
export default ratingSlice.reducer;