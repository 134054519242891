import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { authApi } from '../features/auth/api/AuthAPI';
import { usersApi } from '../features/user/api/UsersAPI';
import { fileApi } from '../features/file/api/FileAPI';
import { paymentApi } from '../features/payments/api/paymentAPI';
import { caseApi } from '../features/case/api/CaseAPI';
import { taxServiceApi } from '../features/taxServices/api/taxServiceAPI';
import authReducer from './authSlice';
import profileReducer from './profileSlice';
import landingPageReducer from './landingPageSlice';
import paymentReducer from './paymentSlice';
import orderReducer from './orderSlice';
import ratingReducer from './ratingSlice';
import progressReduces from './timeline';
import caseReducer from './caseSlice';
import globalReducer from './globalSlice';
import { zoneApi } from '../features/zones/api/ZoneApi';
import { ratingApi } from '../features/rating/api/RatingAPI';
const { VITE_APP_DEV } = import.meta.env;

export const store = configureStore({
  reducer: {
    authSetup: authReducer,
    profileSetup: profileReducer,
    landingPageSetup: landingPageReducer,
    paymentSetup: paymentReducer,
    orderSetup: orderReducer,
    progressSetup: progressReduces,
    caseSetup: caseReducer,
    ratingSetup: ratingReducer,
    globalSetup: globalReducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [fileApi.reducerPath]: fileApi.reducer,
    [zoneApi.reducerPath]: zoneApi.reducer,
    [paymentApi.reducerPath]: paymentApi.reducer,
    [caseApi.reducerPath]: caseApi.reducer,
    [taxServiceApi.reducerPath]: taxServiceApi.reducer,
    [ratingApi.reducerPath]: ratingApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      usersApi.middleware,
      authApi.middleware,
      fileApi.middleware,
      zoneApi.middleware,
      paymentApi.middleware,
      caseApi.middleware,
      taxServiceApi.middleware,
      ratingApi.middleware,
    ]),
  devTools: VITE_APP_DEV === 'false' || !VITE_APP_DEV ? false : true,
});
setupListeners(store.dispatch);
