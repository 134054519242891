import { useEffect, useState } from "react";
import { useGetDistrictsQuery } from "../../features/zones/api/ZoneApi";
import Select from 'react-select';

const SelectorDistricts = ({
    cityId = '',
    onChange,
    value = '',
    isHaveParent = false
}) => {
    const [listDistrict, setListDistrict] = useState([]);
    const [defaultValue, setDefaultValue] = useState({ value: '', label: '' });

    const { data } = useGetDistrictsQuery(cityId);

    useEffect(() => {
        if (isHaveParent) {
            if (data && cityId) {
                const option = data.data.map(({ id: value, name: label }) => ({
                    value, label
                }))

                setListDistrict(option)
            } else {
                setListDistrict([])
            }
        } else {
            if (data) {
                const option = data.data.map(({ id: value, name: label }) => ({
                    value, label
                }))

                setListDistrict(option)
            } else {
                setListDistrict([])
            }
        }
    }, [data, isHaveParent, cityId]);

    useEffect(() => {
        if (isHaveParent) {
            if (value && cityId) {
                const resultFind = listDistrict.find((param) => param.value === value)
                setDefaultValue(resultFind)
            } else {
                setDefaultValue({ value: '', label: '' })
            }
        } else {
            if (value) {
                const resultFind = listDistrict.find((param) => param.value === value)
                setDefaultValue(resultFind)
            } else {
                setDefaultValue({ value: '', label: '' })
            }
        }
    }, [value, listDistrict, isHaveParent, cityId]);

    return (
        <>
            <Select
                onChange={onChange}
                value={defaultValue}
                isSearchable={true}
                options={listDistrict}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: "0.25rem",
                    colors: {
                        ...theme.colors,
                        primary25: '#FFEDED',
                        primary: '#E91E23',
                    },
                })}
            />
        </>
    );
}

export default SelectorDistricts;
