import classnames from "classnames";
import { useState } from "react";
import { BsFillCheckCircleFill, BsFillInfoCircleFill, BsFillXCircleFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Card, CardBody, Col, Container, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import { authSetupSelector, setRefreshToken, setSessions, setToken } from "../../app/authSlice";
import Breadcrumb from "../../components/layout/Breadcrumb";
import HelmetTitle from "../../components/layout/HelmetTitle";
import FormDataProfileLawyer from "./components/FormDataProfileLawyer";
import FormEducationLawyer from "./components/FormEducationLawyer";
import FormPersonalDataLawyer from "./components/FormPersonalDataLawyer";
import FormServiceRatesLawyer from "./components/FormServiceRatesLawyer";
import { profileSetupSelector } from "../../app/profileSlice";
import { FaSpinner } from "react-icons/fa6";
import { usePostRefreshTokenMutation } from "../auth/api/AuthAPI";

const ProfileLawyer = () => {
    const [activeTab, setactiveTab] = useState(1)
    const { sessions, refresh_token } = useSelector(authSetupSelector)
    const { isLoadingRefetchSession } = useSelector(profileSetupSelector)
    const dispatch = useDispatch()

    const [postRefreshToken] = usePostRefreshTokenMutation()

    const handlerRefreshSession = async (isUpdateProfile = false) => {
        const resultGenerateNewToken = await postRefreshToken({ refresh_token }).unwrap()
        const dataSessions = resultGenerateNewToken.data;

        localStorage.setItem('data', JSON.stringify(dataSessions))
        dispatch(setSessions(dataSessions))

        localStorage.setItem('refreshToken', resultGenerateNewToken.refreshToken)
        localStorage.setItem('token', resultGenerateNewToken.token)

        dispatch(setToken(resultGenerateNewToken.token))
        dispatch(setRefreshToken(resultGenerateNewToken.refreshToken))
        return true
    }

    const handlerChangeTab = async (tabId) => {
        handlerRefreshSession()
        setactiveTab(tabId)
    }

    const ContentNavItems = ({ handlerChangeTab }) => {
        return (
            <>
                <NavItem
                    style={{ marginBottom: '0.5rem' }}
                    className={classnames({ current: activeTab === 1 })}
                >
                    <NavLink
                        className={classnames({ current: activeTab === 1 })}
                        onClick={
                            () => handlerChangeTab(1)
                        }
                        disabled={false}
                    >
                        <span className="number">
                            1.
                        </span>
                        Data Profile
                    </NavLink>
                </NavItem>
                <NavItem
                    style={{ marginBottom: '0.5rem' }}
                    className={classnames({ current: activeTab === 2 })}
                >
                    <NavLink
                        className={classnames({ current: activeTab === 2 })}
                        onClick={
                            () => handlerChangeTab(2)
                        }

                        disabled={false}
                    >
                        <span className="number">
                            2.
                        </span>
                        Data Diri
                    </NavLink>
                </NavItem>
                <NavItem
                    style={{ marginBottom: '0.5rem' }}
                    className={classnames({ current: activeTab === 3 })}
                >
                    <NavLink
                        className={classnames({ current: activeTab === 3 })}
                        onClick={
                            () => handlerChangeTab(3)
                        }

                        disabled={false}
                    >
                        <span className="number">
                            3.
                        </span>
                        Pendidikan dan sertifikasi
                    </NavLink>
                </NavItem>
                <NavItem
                    style={{ marginBottom: '0.5rem' }}
                    className={classnames({ current: activeTab === 4 })}
                >
                    <NavLink
                        className={classnames({ current: activeTab === 4 })}
                        onClick={
                            () => handlerChangeTab(4)
                        }
                        disabled={false}
                    >
                        <span className="number">
                            4.
                        </span>
                        Tarif Layanan
                    </NavLink>
                </NavItem>
            </>
        )
    }

    const ContentAlert = ({ sessions, loading }) => {
        if (loading) {
            return <Alert color="danger" className="my-3">
                <FaSpinner
                    className="me-3 text-primary fa-spin-pulse fa-spin fa-solid"
                    size={20}
                />
                <span className="text-primary">
                    Sedang memuat data profile
                </span>
            </Alert>
        } else {
            if ([0, 3, 4, 5].includes(sessions.status_verify_job)) {
                let message = ''

                if (sessions.status_verify_job === 0) {
                    message = 'Akun anda belum aktif sebagai lawyer, mohon lengkapi data dan jadilah lawyer bersama kami'
                }

                if (sessions.status_verify_job === 3) {
                    message = 'Akun anda sedang dalam tahap pelengkapan data, ayo segera selesaikan hingga tahap ke 4 lalu kirimkan email aktivasi untuk menjadi lawyer'
                }

                if (sessions.status_verify_job === 4) {
                    message = 'Email Aktivasi sudah dikirimkan ke admin, jika belum ada konfirmasi anda masih dapat mengirimkan email aktivasi 1x lagi'
                }

                if (sessions.status_verify_job === 5) {
                    message = 'Email Aktivasi sudah dikirimkan ke admin dan sudah batas maksimal pengiriman, mohon menunggu konfirmasi dari admin'
                }

                return <Alert color="warning" className="my-3">
                    <BsFillInfoCircleFill
                        className="me-3 text-warning"
                        size={20}
                    />
                    {' '}
                    {message}
                </Alert>
            }

            if (sessions.status_verify_job === 2) {
                return <Alert color="danger" className="my-3 bg-orange">
                    <BsFillXCircleFill
                        className="me-3 text-white"
                        size={20}
                    />
                    <span className="text-white">
                        Pengajuan anda ditolak, karena:{` `}{sessions.status_verify_job_reason}
                    </span>
                </Alert>
            }

            if (sessions.status_verify_job === 1) {
                return <Alert color="success" className="my-3">
                    <BsFillCheckCircleFill
                        className="me-3 text-success"
                        size={20}
                    />
                    Selamat akun Anda sudah terverifikasi, Anda sudah bisa mulai bekerjasama dengan klien
                </Alert>
            }
        }
    }

    return (
        <>
            <HelmetTitle
                title="Profile | Lawyer"
                meta="profile, lawyer, profilelawyer, ceraionline"
            />
            <div className="page-content">
                <Container fluid>
                    <Breadcrumb breadcrumbItem="Profile" />

                    <Row className="justify-content-center">
                        <Col xs={12}>
                            <div className="wizard clearfix">
                                <div className="steps clearfix">
                                    <ul>
                                        <ContentNavItems
                                            handlerChangeTab={(value) => handlerChangeTab(value)}
                                        />
                                    </ul>
                                </div>
                                <ContentAlert
                                    sessions={sessions}
                                    loading={isLoadingRefetchSession}
                                />
                                <Card className="mt-3">
                                    <CardBody>
                                        <div
                                            className="content clearfix"
                                            style={{ 'marginBottom': '0px', padding: '0px' }}
                                        >
                                            <TabContent
                                                activeTab={activeTab}
                                                className="body"
                                            >
                                                <TabPane className="mt-0 py-0" tabId={1}>
                                                    <FormDataProfileLawyer
                                                        onChangeTab={(value) => handlerChangeTab(value)}
                                                    />
                                                </TabPane>
                                                <TabPane className="mt-0 py-0" tabId={2}>
                                                    <FormPersonalDataLawyer
                                                        onChangeTab={(value) => handlerChangeTab(value)}
                                                    />
                                                </TabPane>
                                                <TabPane className="mt-0 py-0" tabId={3}>
                                                    <FormEducationLawyer
                                                        onChangeTab={(value) => handlerChangeTab(value)}
                                                    />
                                                </TabPane>
                                                <TabPane className="mt-0 py-0" tabId={4}>
                                                    <FormServiceRatesLawyer
                                                        onChangeTab={(value) => handlerChangeTab(value)}
                                                    />
                                                </TabPane>
                                            </TabContent>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default ProfileLawyer;
