import { Button, Modal, ModalBody } from 'reactstrap';
import { notify, reformatToIDRCurrency } from '../../../helpers/GlobalHelper';
import { useDispatch, useSelector } from 'react-redux';
import {
  paymentSetupSelector,
  setDataDetailOrder,
  setDataHistoryTransaction,
  setRefetchingDetailHistoryTransaction,
} from '../../../app/paymentSlice';
import { usePostRequestDisbursementConsultationOrderMutation } from '../../payments/api/paymentAPI';
import { useEffect } from 'react';

const ModalValidateWithdrawConsultation = ({ isOpen, toggle }) => {
  const dispatch = useDispatch();
  const [
    postRequestDisbursementConsultationOrder,
    {
      isLoading: isLoadingPostRequestDisbursementConsultationOrder,
      isError: isErrorPostRequestDisbursementConsultationOrder,
      isSuccess: isSuccessPostRequestDisbursementConsultationOrder,
      error: errorPostRequestDisbursementConsultationOrder,
    },
  ] = usePostRequestDisbursementConsultationOrderMutation();

  const { dataHistoryTransaction, dataDetailOrder, accountBankMain } =
    useSelector(paymentSetupSelector);

  const handlerCloseForm = () => {
    dispatch(setDataHistoryTransaction({}));
    dispatch(setDataDetailOrder({}));
    toggle();
  };

  const handlerOnSubmit = async () => {
    const param = {
      case_id: dataDetailOrder.case_id,
    };

    await postRequestDisbursementConsultationOrder(param).unwrap();
  };

  useEffect(() => {
    if (isErrorPostRequestDisbursementConsultationOrder) {
      notify(
        'error',
        30000,
        errorPostRequestDisbursementConsultationOrder.data.message
      );
    }
  }, [isErrorPostRequestDisbursementConsultationOrder]);

  useEffect(() => {
    if (isSuccessPostRequestDisbursementConsultationOrder) {
      dispatch(setRefetchingDetailHistoryTransaction(true));
      notify('success', 30000, 'Berhasil mengajukan pencairan');
      handlerCloseForm();
    }
  }, [isSuccessPostRequestDisbursementConsultationOrder]);

  return (
    <Modal
      className="border-0 custom-backdrop"
      isOpen={isOpen}
      toggle={handlerCloseForm}
      centered
      keyboard={false}
      backdrop={false}
      size="md"
    >
      <span className="text-center pt-4 pb-0 font-size-18 fw-bold">
        Peringatan!
      </span>
      <ModalBody>
        <div className="mb-0 text-center">
          Apakah anda yakin ingin menarik semua uang sebesar{' '}
          <span className="text-primary">
            {reformatToIDRCurrency(dataHistoryTransaction.available_amount)}
          </span>{' '}
          dari kasus{' '}
          <span className="text-primary">{dataDetailOrder.case_id}</span> ke
          rekening{' '}
          <span className="text-primary">
            {accountBankMain.bank_name} - {accountBankMain.account_bank_number}
          </span>{' '}
          atas nama{' '}
          <span className="text-primary">
            {accountBankMain.account_bank_name}
          </span>{' '}
          ?
        </div>
        <div className="mt-3 d-flex justify-content-between gap-3">
          <Button
            onClick={handlerCloseForm}
            color="secondary"
            outline
            block
            disabled={isLoadingPostRequestDisbursementConsultationOrder}
          >
            Batalkan
          </Button>
          <Button
            onClick={handlerOnSubmit}
            color="primary"
            outline
            block
            disabled={isLoadingPostRequestDisbursementConsultationOrder}
          >
            {isLoadingPostRequestDisbursementConsultationOrder
              ? 'Memproses...'
              : 'Ajukan'}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalValidateWithdrawConsultation;
