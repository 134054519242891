import React, { useEffect } from 'react';
import Rating from 'react-rating';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Form, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { authSetupSelector, setRefreshToken, setToken } from '../../../app/authSlice';
import { orderSetupSelector } from '../../../app/orderSlice';
import { ratingSetupSelector, setComment, setDataRating, setRate } from '../../../app/ratingSlice';
import { notify } from '../../../helpers/GlobalHelper';
import { usePostRefreshTokenMutation } from '../../auth/api/AuthAPI';
import { useGetRatingMutation, usePutRatingMutation } from '../../rating/api/RatingAPI';

const ModalFormRate = ({ isOpen, toggle }) => {
    const dispatch = useDispatch()

    const {
        refresh_token
    } = useSelector(authSetupSelector)

    const {
        id_rating, rate, comment
    } = useSelector(ratingSetupSelector)

    const {
        detail_order_service
    } = useSelector(orderSetupSelector)

    const [postRefreshToken] = usePostRefreshTokenMutation()
    const [
        putRating,
        {
            isLoading: loadingPutRating,
            isSuccess: isSuccessPutRating,
            isError: isErrorPutRating,
            error: errorRating
        }
    ] = usePutRatingMutation()

    const [
        getRating
    ] = useGetRatingMutation()

    const handlerRefreshSession = async () => {
        const resultGenerateNewToken = await postRefreshToken({ refresh_token }).unwrap()

        localStorage.setItem('refreshToken', resultGenerateNewToken.refreshToken)
        localStorage.setItem('token', resultGenerateNewToken.token)

        dispatch(setToken(resultGenerateNewToken.token))
        dispatch(setRefreshToken(resultGenerateNewToken.refreshToken))
        return true;
    }

    const handlerCloseForm = () => {
        if (!loadingPutRating) {
            toggle()
        }
    }

    const handlerRefreshDataRating = async () => {
        await handlerRefreshSession()
        const getDataRating = await getRating(
            {
                case_id: detail_order_service.case_id
            }
        ).unwrap()
        dispatch(setDataRating(getDataRating.data))
    }

    const handlerSetRate = (value) => {
        dispatch(setRate(value))
    }

    const handlerSetComment = (param) => {
        dispatch(setComment(param.target.value))
    }

    const handlerSubmitRating = async () => {
        if (rate < 1) {
            return notify('error', 5000, 'Rating minimal 1');
        }

        if (comment?.length > 250) {
            return notify('error', 5000, 'Komentar maksimal 250 karakter');
        }

        await handlerRefreshSession();
        const data = {
            id: id_rating,
            rate,
            comment
        }

        await putRating(data).unwrap();
    }

    useEffect(() => {
        if (isOpen) {
            handlerRefreshDataRating()
        }
    }, [isOpen]);

    useEffect(() => {
        if (isSuccessPutRating) {
            handlerRefreshDataRating()
            notify(
                'success', 30000, 'Berhasil menyimpan rating'
            )
        }
    }, [isSuccessPutRating]);

    useEffect(() => {
        if (isErrorPutRating) {
            handlerRefreshDataRating()
            notify(
                'error', 30000, errorRating.data.message
            )
        }
    }, [isErrorPutRating]);

    return (
        <Modal
            className="border-0 custom-backdrop"
            isOpen={isOpen}
            toggle={handlerCloseForm}
            centered
            keyboard={false}
            backdrop={false}
            size='md'
        >
            <ModalHeader toggle={handlerCloseForm}>
                Berikan Rating
            </ModalHeader>
            <ModalBody>
                <Form>
                    <Row>
                        <Col xs={12}>
                            <Label for="email" className='mb-0'>
                                Rating
                                {` `}
                                <span className="text-orange">*</span>
                            </Label>
                        </Col>
                        <Col xs={12}>
                            <Rating
                                max={5}
                                onChange={handlerSetRate}
                                emptySymbol="mdi mdi-star-outline text-secondary "
                                fullSymbol="mdi mdi-star text-warning"
                                className="rating-symbol-background-md"
                                initialRating={rate}
                            />
                        </Col>
                        <Col xs={12} className='mt-2'>
                            <Label for="comment" className='mb-0'>
                                Komentar
                            </Label>
                        </Col>
                        <Col xs={12} className='mt-2'>
                            <Input
                                onChange={handlerSetComment}
                                id="commnet"
                                name="comment"
                                type="textarea"
                                value={!comment ? '' : comment}
                            />
                        </Col>
                        <Col xs={12} className='mt-3'>
                            <Button
                                color='danger'
                                onClick={handlerSubmitRating}
                                block
                                disabled={loadingPutRating}
                            >
                                {loadingPutRating ? 'Memproses...' : 'Simpan'}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </ModalBody>
        </Modal>
    );
}

export default ModalFormRate;
