import Select from 'react-select';

const SelectQualification = ({ onChange, value = '' }) => {
    const qualificationEducation = [
        { value: '-', label: 'Tidak Bersekolah' },
        { value: 'S3', label: 'S3' },
        { value: 'S2', label: 'S2' },
        { value: 'S1', label: 'S1' },
        { value: 'SMA/Sederajat', label: 'SMA/Sederajat' },
        { value: 'SMP', label: 'SMP' },
        { value: 'SD', label: 'SD' },
    ];
    return (
        <Select
            onChange={onChange}
            defaultValue={{ value: '', label: '' }}
            value={{ value, label: value }}
            isSearchable={true}
            options={qualificationEducation}
            theme={(theme) => ({
                ...theme,
                borderRadius: "0.25rem",
                colors: {
                    ...theme.colors,
                    primary25: '#FFEDED',
                    primary: '#E91E23',
                },
            })}
        />
    );
}

export default SelectQualification;
