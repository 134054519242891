import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalBody } from 'reactstrap';
import { paymentSetupSelector, setDataDetailOrder, setDataHistoryTransaction, setRefetchingDetailHistoryTransaction } from '../../../app/paymentSlice';
import { calculateDiscountedPrice, checkLengthKeys, notify, reformatToIDRCurrency } from '../../../helpers/GlobalHelper';
import { usePostRequestDisbursementTerminMutation } from '../../payments/api/paymentAPI';

const ModalValidateWithdrawRemaining = ({ isOpen, toggle }) => {
    const [priceCalculate, setPriceCalculate] = useState(0);

    const dispatch = useDispatch();

    const [
        postRequestDisbursementTermin,
        {
            isLoading: isLoadingPostDisbursementTermin,
            isError: isErrorPostDisbursementTermin,
            isSuccess: isSuccessPostDisbursementTermin,
            error: errorPostDisbursementTermin,
        }
    ] = usePostRequestDisbursementTerminMutation();

    const { dataHistoryTransaction, dataDetailOrder } = useSelector(paymentSetupSelector)

    const handlerCloseForm = () => {
        if (isLoadingPostDisbursementTermin) {
            return false;
        } else {
            dispatch(setDataHistoryTransaction({}))
            dispatch(setDataDetailOrder({}))
            toggle()
        }
    }

    const handlerOnSubmit = async () => {
        const param = {
            case_id: dataDetailOrder.case_id,
            amount: priceCalculate
        }
        await postRequestDisbursementTermin(param).unwrap();
    }

    useEffect(() => {
        if (isErrorPostDisbursementTermin) {
            notify('error', 30000, errorPostDisbursementTermin.data.message);
        }
    }, [isErrorPostDisbursementTermin]);

    useEffect(() => {
        if (isSuccessPostDisbursementTermin) {
            dispatch(setRefetchingDetailHistoryTransaction(true))
            notify('success', 30000, 'Berhasil mengajukan pencairan');
            handlerCloseForm()
        }
    }, [isSuccessPostDisbursementTermin]);

    useEffect(() => {
        if (checkLengthKeys(dataHistoryTransaction)) {
            setPriceCalculate(dataHistoryTransaction.available_amount)
        }
    }, [dataHistoryTransaction]);

    return (
        <Modal
            className="border-0 custom-backdrop"
            isOpen={isOpen}
            toggle={handlerCloseForm}
            centered
            keyboard={false}
            backdrop={false}
            size='md'
        >
            <span className="text-center pt-4 pb-0 font-size-18 fw-bold">
                Peringatan!
            </span>
            <ModalBody>
                <div
                    className='mb-0 text-center'
                >
                    Apakah anda yakin ingin menarik sisa uang sebesar <span className='text-primary'>{reformatToIDRCurrency(priceCalculate)}</span> {' '} dari kasus <span className='text-primary'>{dataDetailOrder.case_id}</span>?
                </div>
                <div className="mt-3 d-flex justify-content-between gap-3">
                    <Button
                        onClick={handlerCloseForm}
                        color="secondary"
                        outline
                        block
                        disabled={isLoadingPostDisbursementTermin}
                    >
                        Batalkan
                    </Button>
                    <Button
                        onClick={handlerOnSubmit}
                        color="primary"
                        outline
                        block
                        disabled={isLoadingPostDisbursementTermin}
                    >
                        {isLoadingPostDisbursementTermin ? 'Memproses...' : 'Ajukan'}
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    );
}

export default ModalValidateWithdrawRemaining;
