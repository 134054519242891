import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, CardBody, Col, Container, Label, Row } from 'reactstrap';
import { authSetupSelector } from '../../app/authSlice';
import Breadcrumb from '../../components/layout/Breadcrumb';
import HelmetTitle from '../../components/layout/HelmetTitle';
import { UpperCaseName, reformatDate } from '../../helpers/GlobalHelper';
import { useGetDetailInfoOrderNoCacheMutation } from './api/CaseAPI';

const DetailInfoOrder = ({ type }) => {
    const [data, setData] = useState('');
    const [notFound, setNotFound] = useState(false);
    const [loading, setLoading] = useState(true);
    const role_name = type === "client" ? "Klien" : "Lawyer";

    const { caseId } = useParams();
    const navigate = useNavigate()

    const { token } = useSelector(authSetupSelector)
    const [
        getDetailInfoOrderNoCache,
        {
            isError: isErrorGetDetailInfoOrderNoCache,
            isLoading: isLoadingGetDetailInfoOrderNoCache,
            error: errorGetDetailInfoOrderNoCache,
        }
    ] = useGetDetailInfoOrderNoCacheMutation();

    const getDetailInfoOrder = async () => {
        try {
            const resultGetInfo = await getDetailInfoOrderNoCache({ case_id: caseId }).unwrap()
            if (resultGetInfo.status !== 'success') {
                throw new Error('error getting detail info')
            } else {
                setLoading(false)
                setNotFound(false)
                setData(resultGetInfo.data)
            }
        } catch (error) {
            console.error("error getting info:", error)
            setLoading(false)
            setNotFound(true)
            return true
        }
    }

    useEffect(() => {
        if (token) {
            getDetailInfoOrder();
        }
    }, [token]);

    useEffect(() => {
        if (isLoadingGetDetailInfoOrderNoCache) {
            setLoading(true);
        }
    }, [isLoadingGetDetailInfoOrderNoCache]);

    useEffect(() => {
        if (isErrorGetDetailInfoOrderNoCache) {
            console.error("error getting info:", errorGetDetailInfoOrderNoCache.data.message);
            setLoading(false);
            setNotFound(true);
        }
    }, [isErrorGetDetailInfoOrderNoCache]);


    if (loading) {
        return <>
            <HelmetTitle
                title='Sedang Memuat...'
                meta="detailinfokasus"
            />
            <div className="page-content">
                <div className="empty-transaction px-md-4 mb-10 mt-0 text-center">
                    sedang memuat...
                </div>
            </div>
        </>

    } else {
        if (notFound) {
            return <>
                <HelmetTitle
                    title={`Detail Info Kasus | ${role_name}`}
                    meta="detailinfokasus"
                />
                <div className="page-content mt-10">
                    <div className="px-md-4 mt-0 text-center">
                        <span>
                            Kasus tidak ditemukan,
                        </span>
                        {' '}
                        <span
                            onClick={() => navigate(`/case-${type}`)}
                            className="text-primary fw-bold cursor-pointer mt-0"
                        >
                            Klik untuk Kembali
                        </span>
                    </div>
                </div>
            </>
        } else {
            return <>
                <HelmetTitle
                    title={`Detail Info Kasus | ${role_name}`}
                    meta="detailinfokasus"
                />
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumb
                            breadcrumbItem="Info Kasus"
                            rightBreadCrumWithUrl={true}
                            to={{
                                pathName: `/case-${type}/detail-case-${type}`,
                                state: { caseId }
                            }}
                            titleWithLink={caseId}
                        />
                        <Row>
                            <Card className='bg-white border rounded'>
                                <CardBody>
                                    <Col xs={12} className="mt-4">
                                        <Label className="fw-bold font-size-14">
                                            Data Diri Pengaju Perceraian
                                        </Label>
                                    </Col>
                                    <Col xs={12}>
                                        <Row className="mt-3 font-size-14">
                                            <Col xs={12} lg={3}>
                                                Nama Lengkap
                                                {' '}
                                                <span className="d-inline-block d-lg-none px-0 mx-0">:</span>
                                            </Col>
                                            <Col lg={1} className="d-none d-lg-block width-content">
                                                :
                                            </Col>
                                            <Col xs={12} lg={8} className="mt-1-sm">
                                                {!data ? '' : UpperCaseName(data.client.full_name)}
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col xs={12} lg={3}>
                                                Jenis Kelamin
                                                {' '}
                                                <span className="d-inline-block d-lg-none px-0 mx-0">:</span>
                                            </Col>
                                            <Col lg={1} className="d-none d-lg-block width-content">
                                                :
                                            </Col>
                                            <Col xs={12} lg={8} className="mt-1-sm">
                                                {
                                                    data?.client?.gender?.toLowerCase() === 'male'
                                                        ?
                                                        'Laki - laki'
                                                        :
                                                        'Perempuan'
                                                }
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col xs={12} lg={3}>
                                                Status Sebagai
                                                {' '}
                                                <span className="d-inline-block d-lg-none px-0 mx-0">:</span>
                                            </Col>
                                            <Col lg={1} className="d-none d-lg-block width-content">
                                                :
                                            </Col>
                                            <Col xs={12} lg={8} className="mt-1-sm">
                                                Pemohon
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col xs={12} lg={3}>
                                                No Telepon
                                                {' '}
                                                <span className="d-inline-block d-lg-none px-0 mx-0">:</span>
                                            </Col>
                                            <Col lg={1} className="d-none d-lg-block width-content">
                                                :
                                            </Col>
                                            <Col xs={12} lg={8} className="mt-1-sm">
                                                {
                                                    !data ? '' : data.client.phone_number
                                                }
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col xs={12} lg={3}>
                                                Email
                                                {' '}
                                                <span className="d-inline-block d-lg-none px-0 mx-0">:</span>
                                            </Col>
                                            <Col lg={1} className="d-none d-lg-block width-content">
                                                :
                                            </Col>
                                            <Col xs={12} lg={8} className="mt-1-sm">
                                                {
                                                    !data ? '' : data.client.email
                                                }
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col xs={12} lg={3}>
                                                Tempat Tanggal Lahir
                                                {' '}
                                                <span className="d-inline-block d-lg-none px-0 mx-0">:</span>
                                            </Col>
                                            <Col lg={1} className="d-none d-lg-block width-content">
                                                :
                                            </Col>
                                            <Col xs={12} lg={8} className="mt-1-sm">
                                                {
                                                    !data ? '' : data.client.place_birth + ', ' + reformatDate(`${data.client.date_birth} 00:00`, 'LL')
                                                }
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col xs={12} lg={3}>
                                                Pendidikan Terakhir
                                                {' '}
                                                <span className="d-inline-block d-lg-none px-0 mx-0">:</span>
                                            </Col>
                                            <Col lg={1} className="d-none d-lg-block width-content">
                                                :
                                            </Col>
                                            <Col xs={12} lg={8} className="mt-1-sm">
                                                {
                                                    !data ? '' : data.client.education.qualification
                                                }
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col xs={12} lg={3}>
                                                Pekerjaan Saat Ini
                                                {' '}
                                                <span className="d-inline-block d-lg-none px-0 mx-0">:</span>
                                            </Col>
                                            <Col lg={1} className="d-none d-lg-block width-content">
                                                :
                                            </Col>
                                            <Col xs={12} lg={8} className="mt-1-sm">
                                                {
                                                    !data ? '' : UpperCaseName(data.client.profession)
                                                }
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col xs={12} lg={3}>
                                                Agama
                                                {' '}
                                                <span className="d-inline-block d-lg-none px-0 mx-0">:</span>
                                            </Col>
                                            <Col lg={1} className="d-none d-lg-block width-content">
                                                :
                                            </Col>
                                            <Col xs={12} lg={8} className="mt-1-sm">
                                                {
                                                    !data ? '' : data.client.religion
                                                }
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col xs={12} lg={3}>
                                                No. KTP
                                                {' '}
                                                <span className="d-inline-block d-lg-none px-0 mx-0">:</span>
                                            </Col>
                                            <Col lg={1} className="d-none d-lg-block width-content">
                                                :
                                            </Col>
                                            <Col xs={12} lg={8} className="mt-1-sm">
                                                {
                                                    !data ? '' : data.client.number_kyc
                                                }
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col xs={12} lg={3}>
                                                Alamat KTP
                                                {' '}
                                                <span className="d-inline-block d-lg-none px-0 mx-0">:</span>
                                            </Col>
                                            <Col lg={1} className="d-none d-lg-block width-content">
                                                :
                                            </Col>
                                            <Col xs={12} lg={8} className="mt-1-sm">
                                                {
                                                    !data ? '' : UpperCaseName(`${data.client.address_kyc} ${data.client.ward_kyc.name} ${data.client.town_kyc.name} ${data.client.city_kyc.name} ${data.client.province_kyc.name} ${data.client.postal_code_kyc}`)
                                                }
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col xs={12} lg={3}>
                                                Alamat Domisili
                                                {' '}
                                                <span className="d-inline-block d-lg-none px-0 mx-0">:</span>
                                            </Col>
                                            <Col lg={1} className="d-none d-lg-block width-content">
                                                :
                                            </Col>
                                            <Col xs={12} lg={8} className="mt-1-sm">
                                                {
                                                    !data ? '' : UpperCaseName(`${data.client.address_domicile} ${data.client.ward_domicile.name} ${data.client.town_domicile.name} ${data.client.city_domicile.name} ${data.client.province_domicile.name} ${data.client.postal_code_domicile} `)
                                                }
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={12} className="mt-4">
                                        <Label className="fw-bold font-size-14">
                                            Data Diri Pengacara
                                        </Label>
                                    </Col>
                                    <Col xs={12}>
                                        <Row className="mt-3 font-size-14">
                                            <Col xs={12} lg={3}>
                                                Nama Lengkap
                                                {' '}
                                                <span className="d-inline-block d-lg-none px-0 mx-0">:</span>
                                            </Col>
                                            <Col lg={1} className="d-none d-lg-block width-content">
                                                :
                                            </Col>
                                            <Col xs={12} lg={8} className="mt-1-sm">
                                                {!data ? '' : UpperCaseName(data.lawyer.full_name)}
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col xs={12} lg={3}>
                                                Jenis Kelamin
                                                {' '}
                                                <span className="d-inline-block d-lg-none px-0 mx-0">:</span>
                                            </Col>
                                            <Col lg={1} className="d-none d-lg-block width-content">
                                                :
                                            </Col>
                                            <Col xs={12} lg={8} className="mt-1-sm">
                                                {
                                                    data?.lawyer?.gender?.toLowerCase() === 'male'
                                                        ?
                                                        'Laki - laki'
                                                        :
                                                        'Perempuan'
                                                }
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col xs={12} lg={3}>
                                                Status Sebagai
                                                {' '}
                                                <span className="d-inline-block d-lg-none px-0 mx-0">:</span>
                                            </Col>
                                            <Col lg={1} className="d-none d-lg-block width-content">
                                                :
                                            </Col>
                                            <Col xs={12} lg={8} className="mt-1-sm">
                                                Pendamping
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col xs={12} lg={3}>
                                                No Telepon
                                                {' '}
                                                <span className="d-inline-block d-lg-none px-0 mx-0">:</span>
                                            </Col>
                                            <Col lg={1} className="d-none d-lg-block width-content">
                                                :
                                            </Col>
                                            <Col xs={12} lg={8} className="mt-1-sm">
                                                {
                                                    !data ? '' : data.lawyer.phone_number
                                                }
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col xs={12} lg={3}>
                                                Email
                                                {' '}
                                                <span className="d-inline-block d-lg-none px-0 mx-0">:</span>
                                            </Col>
                                            <Col lg={1} className="d-none d-lg-block width-content">
                                                :
                                            </Col>
                                            <Col xs={12} lg={8} className="mt-1-sm">
                                                {
                                                    !data ? '' : data.lawyer.email
                                                }
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col xs={12} lg={3}>
                                                Tempat Tanggal Lahir
                                                {' '}
                                                <span className="d-inline-block d-lg-none px-0 mx-0">:</span>
                                            </Col>
                                            <Col lg={1} className="d-none d-lg-block width-content">
                                                :
                                            </Col>
                                            <Col xs={12} lg={8} className="mt-1-sm">
                                                {
                                                    !data ? '' : data.lawyer.place_birth + ', ' + reformatDate(`${data.lawyer.date_birth} 00:00`, 'LL')
                                                }
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col xs={12} lg={3}>
                                                Pendidikan Terakhir
                                                {' '}
                                                <span className="d-inline-block d-lg-none px-0 mx-0">:</span>
                                            </Col>
                                            <Col lg={1} className="d-none d-lg-block width-content">
                                                :
                                            </Col>
                                            <Col xs={12} lg={8} className="mt-1-sm">
                                                {
                                                    !data ? '' : data.lawyer.education.qualification
                                                }
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col xs={12} lg={3}>
                                                Pekerjaan Saat Ini
                                                {' '}
                                                <span className="d-inline-block d-lg-none px-0 mx-0">:</span>
                                            </Col>
                                            <Col lg={1} className="d-none d-lg-block width-content">
                                                :
                                            </Col>
                                            <Col xs={12} lg={8} className="mt-1-sm">
                                                {
                                                    !data ? '' : UpperCaseName(data.lawyer.profession)
                                                }
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col xs={12} lg={3}>
                                                Agama
                                                {' '}
                                                <span className="d-inline-block d-lg-none px-0 mx-0">:</span>
                                            </Col>
                                            <Col lg={1} className="d-none d-lg-block width-content">
                                                :
                                            </Col>
                                            <Col xs={12} lg={8} className="mt-1-sm">
                                                {
                                                    !data ? '' : data.lawyer.religion
                                                }
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col xs={12} lg={3}>
                                                No. KTP
                                                {' '}
                                                <span className="d-inline-block d-lg-none px-0 mx-0">:</span>
                                            </Col>
                                            <Col lg={1} className="d-none d-lg-block width-content">
                                                :
                                            </Col>
                                            <Col xs={12} lg={8} className="mt-1-sm">
                                                {
                                                    !data ? '' : data.lawyer.number_kyc
                                                }
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col xs={12} lg={3}>
                                                Alamat KTP
                                                {' '}
                                                <span className="d-inline-block d-lg-none px-0 mx-0">:</span>
                                            </Col>
                                            <Col lg={1} className="d-none d-lg-block width-content">
                                                :
                                            </Col>
                                            <Col xs={12} lg={8} className="mt-1-sm">
                                                {
                                                    !data ? '' : UpperCaseName(`${data.lawyer.address_kyc} ${data.lawyer.ward_kyc.name} ${data.lawyer.town_kyc.name} ${data.lawyer.city_kyc.name} ${data.lawyer.province_kyc.name} ${data.lawyer.postal_code_kyc}`)
                                                }
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col xs={12} lg={3}>
                                                Alamat Domisili
                                                {' '}
                                                <span className="d-inline-block d-lg-none px-0 mx-0">:</span>
                                            </Col>
                                            <Col lg={1} className="d-none d-lg-block width-content">
                                                :
                                            </Col>
                                            <Col xs={12} lg={8} className="mt-1-sm">
                                                {
                                                    !data ? '' : UpperCaseName(`${data.lawyer.address_domicile} ${data.lawyer.ward_domicile.name} ${data.lawyer.town_domicile.name} ${data.lawyer.city_domicile.name} ${data.lawyer.province_domicile.name} ${data.lawyer.postal_code_domicile} `)
                                                }
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={12} className="mt-4 mb-1">
                                        <Label className="fw-bold font-size-14">
                                            Alasan Memesan Layanan Ini
                                        </Label>
                                    </Col>
                                    {data.reason_divorce?.map((val, key) => {
                                        return <Col key={key} xs={12}>
                                            <b>{key + 1}.</b> {' '}{val.reason}
                                        </Col>
                                    })}
                                </CardBody>
                            </Card>
                        </Row>
                    </Container>
                </div>
            </>
        }
    }

}

export default DetailInfoOrder;
