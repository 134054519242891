import { Button, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import DevImg from "../../assets/images/development.svg";


const NotifyDevelopmentProcessing = (
    {
        isOpen,
        toggle,
    }
) => {
    return (
        <Modal
            className="custom-backdrop"
            isOpen={isOpen}
            toggle={toggle}
            centered
            keyboard={false}
            backdrop={false}
            size='md'
        >
            {/* <ModalHeader toggle={toggle} /> */}
            <ModalBody>
                <Row className="justify-content-center">
                    <Col
                        xs={12}
                        className="text-center"
                    >
                        <img
                            className="avatar avatar-xl"
                            src={DevImg}
                            data-holder-rendered="true"
                        />
                    </Col>
                    <Col xs={12} className="text-center">
                        <p className="text-primary">Mohon maaf fitur ini sedang dalam pengerjaan</p>
                    </Col>
                    <Col xs={12} className="text-center mt-3 px-5">
                        <Button
                            onClick={toggle}
                            block
                            color="primary"
                            className="btn-outline-secondary mb-2 fw-bold"
                            outline
                        >
                            Tutup
                        </Button>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    );
}

export default NotifyDevelopmentProcessing;
