import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { Button, Table } from "reactstrap";
import { randomString } from "../../helpers/GlobalHelper";

/**
 *
 * note important: header key table must be match with field of key value data
 *
 */

const TableComp = ({
  headerTable = [],
  data = [],
  totalPage = null,
  currentPage,
  totalData = 0,
  onClickPage,
  headerClassTable = 'table-soft-secondary',
  messageEmptyData = 'Tidak Ada Data'
}) => {
  const [page, setPage] = useState(0);
  const [newHeaderTable, setNewHeaderTable] = useState([
    {
      key: 1,
      value: "header",
    },
    {
      key: 2,
      value: "header",
    },
    {
      key: 3,
      value: "header",
    },
  ]);

  const handlePageChange = (event) => {
    const page = event.selected + 1;
    if (onClickPage) {
      onClickPage(page)
    }
  };

  useEffect(() => {
    if (!headerTable.length) {
      setNewHeaderTable(["column", "column", "column"]);
    } else {
      setNewHeaderTable(headerTable);
    }
  }, [headerTable]);

  useEffect(() => {
    if (currentPage <= 1 || !currentPage) {
      setPage(0)
    } else {
      const page = parseInt(currentPage) - 1
      setPage(page)
    }
  }, [currentPage]);

  const TBodyRow = ({ data, field }) => {
    let tdRow = [];
    for (let index = 0; index < field.length; index++) {
      tdRow.push(
        <td key={`${index}${randomString(10)}`}>
          {data[field[index].key]}
        </td>
      );
    }
    return <tr>{tdRow}</tr>;
  }

  const ContentTBody = ({ data, field }) => {
    if (data.length < 1) {
      return <tr>
        <td colSpan={newHeaderTable.length} className="text-center text-dark opacity-50">
          {messageEmptyData}
        </td>
      </tr>
    } else {
      return data.map((valueData, keyData) => {
        return <TBodyRow data={valueData} field={field} key={keyData} />;
      });
    }
  }

  return (
    <div className="table-responsive mb-0">
      <Table className="table table-striped" responsive>
        <thead className={`${headerClassTable}`}>
          <tr className="text-center">
            {newHeaderTable.map((val, key) => (
              <th
                key={!val.key ? key : val.key}
                className="fw-bold text-dark opacity-75"
              >
                {!val.value ? val : val.value}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="text-center">
          <ContentTBody data={data} field={newHeaderTable} />
        </tbody>
      </Table>
      <ReactPaginate
        previousLabel="Previous"
        nextLabel="Next"
        containerClassName="pagination pagination-sm justify-content-end"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakClassName="page-item"
        breakLinkClassName="page-link"
        pageCount={totalPage}
        marginPagesDisplayed={1}
        pageRangeDisplayed={1}
        activeClassName="page-item active"
        forcePage={page}
        onPageChange={handlePageChange}
        renderOnZeroPageCount
      />
    </div>
  );
};

export default TableComp;
