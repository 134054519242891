import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FaRegEnvelope } from "react-icons/fa6";
import { useDispatch } from "react-redux";
import { Button, Card, CardBody, CardText, CardTitle, Form, Input, InputGroup, InputGroupText, Label } from "reactstrap";
import * as yup from "yup";
import { forgotPassword } from "../../../app/authSlice";
import { usePostForgotPasswordMutation } from "../api/AuthAPI";
import AlertForm from "./AlertForm";
import HeaderForm from "./HeaderForm";
import { useNavigate } from "react-router-dom";

const FormSendEmailForgot = ({ type, handlerStatusForm }) => {
    const [messageAlert, setMessageAlert] = useState("");
    const [isStatusAlert, setIsStatusAlert] = useState("");
    const [postForgotPassword, { isLoading }] = usePostForgotPasswordMutation()
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const schema = yup.object({
        email: yup.string().email('Email harus berformat email!').required('Email wajib di isi!')
    });

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            email: "",
        },
    });

    const handleOnSubmit = async (param) => {
        try {
            const sendEmailForgot = await postForgotPassword(param).unwrap();
            dispatch(forgotPassword({ email: param.email }))

            handlerStatusForm('form-verification-code')
        } catch (error) {
            setIsStatusAlert('error');
            setMessageAlert(error.data.message)
        }
    };

    return <>
        <Card className="overflow-hidden shadow-none border-0 rounded-3 align-self-center bg-transparent px-lg-6">
            <HeaderForm
                title="Lupa Kata Sandi"
            />
            <CardBody className="py-lg-5">
                <CardTitle className="d-none d-lg-block mt-0 fs-3 fw-bold text-center">
                    Lupa Kata Sandi
                </CardTitle>
                <CardText className="text-secondary fs-5 fw-normal text-center">
                    Silakan masukkan email Anda untuk membuat kata sandi baru
                </CardText>
                <div className="p-0 p-lg-2 mt-4">
                    <Form
                        onSubmit={handleSubmit(handleOnSubmit)}
                        className="form-horizontal"
                    >
                        {
                            isStatusAlert
                            &&
                            <AlertForm
                                status={isStatusAlert}
                                description={messageAlert}
                            />
                        }

                        <div className="mb-2">
                            <Label for="email">
                                Email
                                {` `}
                                <span className="text-orange">*</span>
                            </Label>
                            <InputGroup>
                                <InputGroupText className={`bg-transparent border-end-0 text-start justify-content-end rounded-3 rounded-end-0 ${errors.email && 'border-orange'}`}>
                                    <FaRegEnvelope size={20} />
                                </InputGroupText>
                                <Controller
                                    name="email"
                                    control={control}
                                    render={({ field: { onChange, onBlur, value } }) => {
                                        return (
                                            <>
                                                <Input
                                                    bsSize="lg"
                                                    className={`d-none d-md-block border-start-0 rounded-3 rounded-start-0 text-start ${errors.email && 'border-orange'}`}
                                                    type="email"
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    value={value}
                                                    placeholder="Masukan email anda"
                                                />
                                                <Input
                                                    bsSize="sm"
                                                    className={`d-block d-md-none border-start-0 rounded-3 rounded-start-0 text-start ${errors.email && 'border-orange'}`}
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    value={value}
                                                    type="email"
                                                    placeholder="Masukan email anda"
                                                />
                                            </>
                                        )
                                    }}
                                />
                            </InputGroup>
                        </div>
                        <div className="mb-3">
                            <span className="mt-2 text-orange text-start">
                                {errors.email?.message}
                            </span>
                        </div>

                        <div className="mt-4">
                            <Button type="submit"
                                block
                                color="danger"
                                className="rounded-4"
                                disabled={isLoading}
                            >
                                {!isLoading ? 'Kirim' : 'Memproses...'}
                            </Button>
                        </div>
                        <div className="mt-2">
                            <Button
                                onClick={
                                    () => navigate(type === 'lawyer' ? '/login-lawyer' : '/login')
                                }
                                type="button"
                                block
                                color="secondary"
                                className="rounded-4"
                            >
                                Kembali
                            </Button>
                        </div>
                    </Form>
                </div>
            </CardBody>
        </Card>
    </>
}

export default FormSendEmailForgot;
