import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, Input, InputGroup, Label, Row } from 'reactstrap';
import * as yup from "yup";
import YupPassword from "yup-password";
import { authSetupSelector, setRefreshToken, setSessions, setToken } from '../../../app/authSlice';
import avatar from '../../../assets/images/users/user.png';
import PhoneNumber from '../../../components/form/PhoneNumber';
import RadioGender from '../../../components/form/RadioGender';
import { notify } from '../../../helpers/GlobalHelper';
import { usePostRefreshTokenMutation } from '../../auth/api/AuthAPI';
import { usePostUploadPhotoProfileUserMutation } from '../../file/api/FileAPI';
import { usePutUpdateDataProfileMutation } from '../../user/api/UsersAPI';
import { updateStatusRefetchSession } from '../../../app/profileSlice';
import FormChangePassword from './FormChangePassword';
YupPassword(yup)

const FormDataProfileLawyer = ({ onChangeTab }) => {
    const [isShowDropdownPhotoProfile, setIsShowDropdownPhotoProfile] = useState(false);
    const [isUpdateProfile, setIsUpdateProfile] = useState(false);
    const [isModalChangePassword, setIsModalChangePassword] = useState(false);

    const { sessions, refresh_token } = useSelector(authSetupSelector)

    const [
        postUploadPhotoProfileUser,
        {
            isSuccess: SuccessUpdatePhotoProfile,
            isError: isErrorUpdatePhotoProfile,
            error: errorUpdatePhotoProfile,
        }
    ] = usePostUploadPhotoProfileUserMutation()

    const [putUpdateDataProfile, { isSuccess: SuccessUpdateDataProfile, isLoading: loadingDataProfile }] = usePutUpdateDataProfileMutation()

    const [postRefreshToken, { isLoading: isLoadingRefetchSession }] = usePostRefreshTokenMutation()

    const dispatch = useDispatch()

    const inputProfilePhoto = useRef(null)

    const handlerRefreshSession = async (isUpdateProfile = false) => {
        const resultGenerateNewToken = await postRefreshToken({ refresh_token }).unwrap()
        const dataSessions = resultGenerateNewToken.data;

        if (isUpdateProfile) {
            localStorage.setItem('data', JSON.stringify(dataSessions))
            dispatch(setSessions(dataSessions))
            return true;
        }

        localStorage.setItem('refreshToken', resultGenerateNewToken.refreshToken)
        localStorage.setItem('token', resultGenerateNewToken.token)

        dispatch(setToken(resultGenerateNewToken.token))
        dispatch(setRefreshToken(resultGenerateNewToken.refreshToken))
        return true;

    }

    const handleChangeFile = async (event) => {
        // await handlerRefreshSession()
        const file = event.target.files[0];
        await postUploadPhotoProfileUser({ file }).unwrap()
    }

    const handleTriggerInputFile = () => {
        inputProfilePhoto.current.click()
    }

    const handleOnSubmit = async (param) => {
        setIsUpdateProfile(true)
        await handlerRefreshSession()
        const data = {
            full_name: param.full_name,
            phone_number: param.phone_number,
            gender: param.gender,
        }
        await putUpdateDataProfile(data).unwrap()
    }

    const schema = yup.object({
        full_name: yup.string().required("Nama lengkap wajib di isi!"),
        gender: yup.mixed().oneOf(['male', 'female'], "Jenis kelamin hanya tersedia Laki - laki dan Perempuan!").required("jenis kelamin wajib di isi!"),
        phone_number: yup
            .string()
            .required('No Telepon wajib di isi!')
            .matches(/^\+62\d{10,}$/, 'Format tidak valid & minimal 10 digit sesudah +62!'),
    })

    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            full_name: "",
            gender: "female",
            phone_number: "",
            email: "",
        },
    });

    useEffect(() => {
        if (sessions && !isUpdateProfile) {
            setValue('full_name', sessions.full_name)
            setValue('gender', sessions.gender)
            setValue('phone_number', sessions.phone_number)
            setValue('email', sessions.email)
        }
    }, [sessions, isUpdateProfile]);

    useEffect(() => {
        if (SuccessUpdatePhotoProfile) {
            notify('success', 30000, 'Profile berhasil diubah');
            handlerRefreshSession(true)
        }
    }, [SuccessUpdatePhotoProfile]);

    useEffect(() => {
        if (SuccessUpdateDataProfile) {
            notify('success', 30000, 'Profile berhasil diubah');
            setIsUpdateProfile(false)
            handlerRefreshSession(true)
            onChangeTab(2)
        }
    }, [SuccessUpdateDataProfile]);

    useEffect(() => {
        if (isLoadingRefetchSession) {
            dispatch(updateStatusRefetchSession(true))
        } else {
            setTimeout(() => {
                dispatch(updateStatusRefetchSession(false))
            }, 3000);
        }
    }, [isLoadingRefetchSession]);

    useEffect(() => {
        if (isErrorUpdatePhotoProfile) {
            notify('error', 30000, errorUpdatePhotoProfile.data.message);
        }
    }, [isErrorUpdatePhotoProfile])

    return (
        <Row>
            <Col xs={12} lg={2} className='justify-content-center text-center'>
                <Dropdown
                    isOpen={isShowDropdownPhotoProfile}
                    toggle={() => setIsShowDropdownPhotoProfile(!isShowDropdownPhotoProfile)}
                >
                    <DropdownToggle className="bg-transparent border-0" caret>
                        <img
                            src={!sessions.photo ? avatar : sessions.photo}
                            alt="profile-photo"
                            className="avatar-lg rounded-circle mb-3 img-thumbnail"
                        />
                    </DropdownToggle>
                    <DropdownMenu className='bg-primary'>
                        <DropdownItem
                            className='dropdown-item-primary'
                            onClick={() => handleTriggerInputFile()}
                        >
                            Ubah
                        </DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </Col>
            <Col xs={12} lg={10}>
                <Form onSubmit={handleSubmit(handleOnSubmit)}>
                    <Row>
                        <Col xs={12}>
                            <div className="mb-3">
                                <Label >
                                    Nama Lengkap
                                    <span className="text-orange">
                                        *
                                    </span>
                                </Label>
                                <Controller
                                    name='full_name'
                                    control={control}
                                    render={({ field: { onChange, onBlur, value } }) => {
                                        return <>
                                            <Input
                                                onBlur={onBlur}
                                                onChange={onChange}
                                                value={value}
                                                type="text"
                                                className="form-control"
                                                placeholder="Masukan nama lengkap anda"
                                            />
                                        </>
                                    }}
                                />
                            </div>
                            <div className="mb-3">
                                <span className="mt-2 text-orange text-start">
                                    {errors.full_name?.message}
                                </span>
                            </div>
                        </Col>
                        <Col xs={12}>
                            <div className="mb-3">
                                <Label >
                                    Jenis Kelamin
                                    <span className="text-orange">
                                        *
                                    </span>
                                </Label>
                                <div className="d-flex justify-content-start">
                                    <Controller
                                        name='gender'
                                        control={control}
                                        render={({ field: { onChange, value } }) => {
                                            return <RadioGender
                                                onChange={onChange}
                                                value={value}
                                            />
                                        }}
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col xs={12}>
                            <div className="mb-3">
                                <Label >
                                    No Telepon
                                    <span className="text-orange">
                                        *
                                    </span>
                                </Label>
                                <Controller
                                    name="phone_number"
                                    control={control}
                                    rules={{
                                        validate: (value) => isValidPhoneNumber(value)
                                    }}
                                    render={({ field: { onChange, value } }) => {
                                        return <PhoneNumber
                                            value={value}
                                            onChange={onChange}
                                            className={`${errors.phone_number && 'border-orange'}`}
                                        />
                                    }}
                                />
                            </div>
                            <div className="mb-3">
                                <span className="mt-2 text-orange text-start">
                                    {errors.phone_number?.message}
                                </span>
                            </div>
                        </Col>
                        <Col xs={12}>
                            <div className="mb-3">
                                <Label >
                                    Email
                                </Label>
                                <Controller
                                    name='email'
                                    control={control}
                                    render={({ field: { onChange, onBlur, value } }) => {
                                        return <Input
                                            type="email"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                            className="form-control"
                                            disabled={true}
                                        />
                                    }}
                                />
                            </div>
                        </Col>
                        <Col xs={12}>
                            <Label>
                                Kata Sandi
                            </Label>
                            <InputGroup>
                                <Input
                                    type="password"
                                    value="******************"
                                    className="form-control"
                                    disabled={true}
                                />
                                <Button
                                    onClick={() => setIsModalChangePassword(!isModalChangePassword)}
                                    type='button'
                                    size='md'
                                    color="primary"
                                    outline
                                >
                                    Ubah
                                </Button>
                            </InputGroup>
                        </Col>
                        <Col xs={12}>
                            <div className="d-lg-flex justify-content-lg-end">
                                <Button
                                    type='submit'
                                    className="d-none d-lg-block ms-lg-2 mt-3"
                                    color="primary"
                                    disabled={loadingDataProfile}
                                >
                                    {loadingDataProfile ? 'Memproses...' : 'Selanjutnya'}
                                </Button>
                                <Button
                                    type='submit'
                                    block
                                    className="d-block d-lg-none ms-lg-2 mt-3"
                                    color="primary"
                                    disabled={loadingDataProfile}
                                >
                                    {loadingDataProfile ? 'Memproses...' : 'Selanjutnya'}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Col>
            <Input
                innerRef={inputProfilePhoto}
                accept=".gif,.jpg,.jpeg,.png,.svg,.gif,.webp"
                type="file"
                hidden
                onChange={handleChangeFile}
            />
            <FormChangePassword
                isModal={isModalChangePassword}
                handlerToggle={() => setIsModalChangePassword(!isModalChangePassword)}
            />
        </Row>
    );
}

export default FormDataProfileLawyer;
