import { useDispatch, useSelector } from "react-redux";
import { Button, Col, FormGroup, Input, Label, Modal, ModalBody, Row } from "reactstrap";
import { authSetupSelector } from "../../../app/authSlice";
import { useEffect, useState } from "react";
import { checkLengthKeys, notify } from "../../../helpers/GlobalHelper";
import { orderSetupSelector, setListService, setPickService } from "../../../app/orderSlice";

const ModalChooseServiceOrder = ({
    isOpen,
    toggle
}) => {
    const dispatch = useDispatch()

    const {
        choose_lawyer,
        pick_service_id,
        list_services
    } = useSelector(orderSetupSelector)

    const handlerActionModal = (from = 'canceled') => {
        if (from === 'canceled') {
            toggle(true, {})
        } else {
            toggle(false, {})
        }
    }

    const handlerChooseService = (choose_lawyer, pick_service_id) => {
        const checkChooseLawyer = checkLengthKeys(choose_lawyer)

        if (!checkChooseLawyer) {
            dispatch(setListService([]));
        } else {
            const data = [];
            choose_lawyer?.service_rates?.map((value) => {
                if (value.category_service_name.toLowerCase() !== 'konsultasi online (chat) / jam') {
                    if (value.price > 0 || value.price) {
                        data.push({
                            id: value.id,
                            category_service_name: value.category_service_name,
                            price: value.price,
                            isChecked: value.id === pick_service_id ? true : false,
                        })
                    }
                }
            })

            dispatch(setListService(data));
        }
    }

    const handlerChoosed = (id) => {
        dispatch(setPickService(id))
    }

    const handlerNext = () => {
        if (!pick_service_id) {
            return notify('warn', 30000, 'Layanan harus dipilih!')
        }
        toggle(true, {}, 'scope_statement')
    }

    useEffect(() => {
        handlerChooseService(choose_lawyer, pick_service_id)
    }, [choose_lawyer, pick_service_id]);

    return <Modal
        isOpen={isOpen}
        toggle={handlerActionModal}
        centered
        keyboard={false}
        backdrop={false}
        className="border-0 custom-backdrop"
    >
        <ModalBody>
            <div className="text-center fw-bold mb-2">
                Pilih Layanan
            </div>
            <div className="border-bottom mb-3"></div>
            <Row className="px-3">
                {
                    list_services.length < 1
                        ?
                        <p className="text-center text-primary fw-bold">
                            Pengacara ini tidak memiliki layanan!
                        </p>
                        :
                        list_services.map((val, key) => (
                            <Col key={key} xs={12} className="mb-2">
                                <FormGroup check>
                                    <Input
                                        checked={val.isChecked}
                                        type="radio"
                                        value={val.id}
                                        className="me-2 fs-6"
                                        onChange={() => handlerChoosed(val.id)}
                                    />
                                    <Label className="fs-6 fw-light">
                                        {val.category_service_name}
                                    </Label>
                                </FormGroup>
                            </Col>
                        ))
                }

                <Col xs={12} className="text-center px-0 mt-3">
                    <div className="d-lg-flex justify-content-lg-center">
                        <Button
                            onClick={handlerActionModal}
                            block
                            color="primary"
                            className="btn-outline-secondary mx-2 mb-2 fw-bold"
                            outline
                        >
                            Batal
                        </Button>
                        <Button
                            onClick={handlerNext}
                            block
                            color="primary"
                            className="btn-primary mx-2 mb-2"
                        >
                            Lanjutkan
                        </Button>
                    </div>
                </Col>
            </Row>
        </ModalBody>
    </Modal>
}

export default ModalChooseServiceOrder;
