const dummyCaseList = [
  {
    case_id: "#SK2540",
    date: "07 Oct, 2019",
    client_name: "Neal Matthews 1",
    service_name: "Gugat Cerai",
    case_status: "new",
  },
  {
    case_id: "#SK2541",
    date: "07 Oct, 2019",
    client_name: "Neal Matthews 2",
    service_name: "Gugat Harta Gono Gini",
    case_status: "new",
  },
  {
    case_id: "#SK2543",
    date: "07 Oct, 2019",
    client_name: "Neal Matthews 3",
    service_name: "Konusltasi Bebayar",
    case_status: "new",
  },
  {
    case_id: "#SK2544",
    date: "07 Oct, 2019",
    client_name: "Neal Matthews 4",
    service_name: "Gugat Harta",
    case_status: "new",
  },
  {
    case_id: "#SK2545",
    date: "07 Oct, 2019",
    client_name: "Neal Matthews 5",
    service_name: "Gugat Harta",
    case_status: "new",
  },
  {
    case_id: "#SK2546",
    date: "07 Oct, 2019",
    client_name: "Neal Matthews 6",
    service_name: "Gugat Cerai",
    case_status: "new",
  },
  {
    case_id: "#SK2547",
    date: "07 Oct, 2019",
    client_name: "Neal Matthews 7",
    service_name: "Gugat Harta Gono Gini",
    case_status: "new",
  },
  {
    case_id: "#SK2548",
    date: "07 Oct, 2019",
    client_name: "Neal Matthews 8",
    service_name: "Konusltasi Bebayar",
    case_status: "new",
  },
  {
    case_id: "#SK2549",
    date: "07 Oct, 2019",
    client_name: "Neal Matthews 9",
    service_name: "Gugat Harta",
    case_status: "new",
  },
  {
    case_id: "#SK25410",
    date: "07 Oct, 2019",
    client_name: "Neal Matthews 10",
    service_name: "Gugat Harta",
    case_status: "new",
  },
];
const dummyChats = [
  {
    id: 2,
    username: "Jenny Wilson",
    chat: "Halo, saya ingin mengajukan booking untuk pendampingan perceraian. Detail sudah dikirim ke email Bapak/Ibu, terima kasih",
    create_at: "4.10",
    button_type: "new_client",
  },
  {
    id: 4,
    username: "as@gmail.com",
    chat: "Halo, saya ingin mengajukan booking untuk pendampingan perceraian. Detail sudah dikirim ke email Bapak/Ibu, terima kasih",
    create_at: "4.20",
    button_type: null,
  },
  {
    id: 4,
    username: "as@gmail.com",
    chat: "Halo, saya ingin mengajukan booking untuk pendampingan perceraian. Detail sudah dikirim ke email Bapak/Ibu, terima kasih",
    create_at: "4.20",
    button_type: "payment",
  },
  {
    id: 2,
    username: "Jenny Wilson",
    chat: "Halo, saya ingin mengajukan booking untuk pendampingan perceraian. Detail sudah dikirim ke email Bapak/Ibu, terima kasih",
    create_at: "5.10",
    button_type: "payment",
  },
];

const dummyTransaction = [
  {
    order_id: Math.random() * 1000,
    billing_name: "Susilo Bambang",
    transaction_type: "Cerai Harta",
    amount: "7.500.000",
  },
  {
    order_id: Math.random() * 1000,
    billing_name: "Susilo",
    transaction_type: "Cerai Harta",
    amount: "2.500.000",
  },
  {
    order_id: Math.random() * 1000,
    billing_name: "Bambang",
    transaction_type: "Cerai Harta",
    amount: "4.500.000",
  },
  {
    order_id: Math.random() * 1000,
    billing_name: "Jacob Bambang",
    transaction_type: "Cerai Harta",
    amount: "9.000.000",
  },
  {
    order_id: Math.random() * 1000,
    billing_name: "Jimmy Bambang",
    transaction_type: "Cerai Harta",
    amount: "10.500.000",
  },
];

export { dummyCaseList, dummyChats, dummyTransaction };
