import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Offcanvas, OffcanvasBody, OffcanvasHeader } from 'reactstrap';
import { authSetupSelector } from '../../app/authSlice';
import { checkLengthKeys } from '../../helpers/GlobalHelper';
import { UserNavigationDropdown } from '../global/UserNavigationDropDown';

const HeaderNonAuth = ({ type }) => {
  const navigate = useNavigate();

  const [isBackToDashboard, setIsBackToDashboard] = useState(false);
  const [urlBackToDashboard, setUrlBackToDashboard] = useState('/');
  const [showSidebar, setShowSidebar] = useState(false);
  const { sessions } = useSelector(authSetupSelector);

  useEffect(() => {
    const checkingObj = checkLengthKeys(sessions);
    if (!checkingObj) {
      setIsBackToDashboard(false);
    } else {
      setIsBackToDashboard(true);
      if (sessions.role_name.toLowerCase() === 'lawyer') {
        setUrlBackToDashboard('/dashboard-lawyer');
      } else {
        setUrlBackToDashboard('/dashboard-client');
      }
    }
  }, [sessions]);

  const MenuCondition = () => {
    if (isBackToDashboard) {
      return <UserNavigationDropdown />;
    } else {
      return (
        <>
          <div className="dropdown d-none d-md-block me-3">
            <button
              type="button"
              className="btn header-item noti-icon "
              onClick={() => {
                navigate(type === 'lawyer' ? '/register-lawyer' : '/register');
              }}
            >
              Daftar
            </button>
          </div>
          <div className="dropdown d-none d-md-block align-self-center">
            <button
              type="button"
              className="btn right-bar-toggle btn-primary"
              onClick={() => {
                navigate(type === 'lawyer' ? '/login-lawyer' : '/login');
              }}
            >
              Masuk
            </button>
          </div>
        </>
      );
    }
  };

  const redirectToDashboard = () => {
    navigate(urlBackToDashboard);
  };

  const handlerClickNavigateMenu = (url) => {
    setShowSidebar(false);
    return navigate(url);
  };

  return (
    <>
      <header
        id="page-topbar"
        className="bg-white text-white px-0 border-bottom border-light"
      >
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box d-block">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src="/logo@2x.png" alt="logo" height="22" />
                </span>
                <span className="logo-lg">
                  <img src="/logo@2x.png" alt="logo" height="22" />
                </span>
              </Link>
            </div>
            <div className="dropdown d-none d-md-block ms-3">
              <button
                type="button"
                className="btn header-item noti-icon nav-item-hover"
                onClick={() => handlerClickNavigateMenu('/')}
              >
                <span>Home</span>
              </button>
            </div>
            <div className="dropdown d-none d-md-block ms-3">
              <button
                type="button"
                className="btn header-item noti-icon nav-item-hover"
                onClick={() => handlerClickNavigateMenu('/how-to-work')}
              >
                <span>Cara Kerja</span>
              </button>
            </div>
            <div className="dropdown d-none d-md-block ms-3">
              <button
                type="button"
                className="btn header-item noti-icon nav-item-hover"
                onClick={() => handlerClickNavigateMenu('/lawyer-list')}
              >
                <span>List Pengacara</span>
              </button>
            </div>
          </div>
          <div className="d-flex">
            <MenuCondition />
            <div className="dropdown d-block d-md-none align-self-center text-dark">
              <Button
                color="transparent"
                onClick={() => setShowSidebar(!showSidebar)}
              >
                <i className="fa fa-fw fa-bars" />
              </Button>
            </div>
          </div>
        </div>
      </header>
      <Offcanvas
        isOpen={showSidebar}
        toggle={() => setShowSidebar(!showSidebar)}
      >
        <OffcanvasHeader
          toggle={() => setShowSidebar(!showSidebar)}
          className="mb-0 py-0 border-bottom"
        >
          <Link to="/" className="logo logo-dark">
            <span className="logo-sm">
              <img src="/logo@2x.png" alt="logo" height="22" />
            </span>
            <span className="logo-lg">
              <img src="/logo@2x.png" alt="logo" height="22" />
            </span>
          </Link>
        </OffcanvasHeader>
        <OffcanvasBody className="px-0 mt-0">
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              <li>
                <button
                  type="button"
                  className="btn nav-item-hover ms-2"
                  onClick={() => handlerClickNavigateMenu('/')}
                >
                  <span>Home</span>
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className="btn nav-item-hover ms-2"
                  onClick={() => handlerClickNavigateMenu('/how-to-work')}
                >
                  <span>Cara Kerja</span>
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className="btn nav-item-hover ms-2"
                  onClick={() => handlerClickNavigateMenu('/lawyer-list')}
                >
                  <span>List Pengacara</span>
                </button>
              </li>
            </ul>
          </div>
        </OffcanvasBody>
      </Offcanvas>
    </>
  );
};

export default HeaderNonAuth;
