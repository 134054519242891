import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const ForgotPasswordLink = ({ type }) => {
    const [url, setUrl] = useState('/');

    useEffect(() => {
        if (type === 'lawyer') {
            setUrl('/forgot-password-lawyer')
        } else {
            setUrl('/forgot-password')
        }
    }, [type]);
    return (
        <>
            <Link
                to={url}
                className="d-none d-lg-block fs-5"
            >
                Lupa Kata Sandi
            </Link>
            <Link
                to={url}
                className="d-block d-lg-none"
            >
                Lupa Kata Sandi
            </Link>
        </>
    );
}

export default ForgotPasswordLink;
