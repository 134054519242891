import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { usePostChangeForgotPasswordMutation } from "../api/AuthAPI";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authSetupSelector, forgotPassword } from "../../../app/authSlice";
import { Button, Card, CardBody, CardText, CardTitle, Form, Input, InputGroup, InputGroupText, Label } from "reactstrap";
import HeaderForm from "./HeaderForm";
import AlertForm from "./AlertForm";
import { HiOutlineEye, HiOutlineEyeOff, HiOutlineLockClosed } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

const ChangePasswordFromForgot = ({ type, handlerStatusForm }) => {
    const [messageAlert, setMessageAlert] = useState("");
    const [isStatusAlert, setIsStatusAlert] = useState("");
    const [isShowPassword, setIsShowPassword] = useState(false);
    const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const [postChangeForgotPassword, { isLoading }] = usePostChangeForgotPasswordMutation();

    const schema = yup.object({
        password: yup.string().minUppercase(1, "Minimal 1 huruf kapital!").minLowercase(1, "Minimal 1 huruf kecil!").minNumbers(1, "Minimal 1 angka!").minSymbols(1, "Minimal 1 simbol: _-#1@&^$").min(8, "Minimal panjang 8 karakter!").required("Kata sandi wajib di isi"),
        confirmation_password: yup.string()
            .oneOf([yup.ref('password'), null], 'Konfirmasi kata sandi tidak sama dengan kata sandi')
    });

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            password: "",
            confirmation_password: "",
        },
    });

    const { email } = useSelector(authSetupSelector)

    const handleOnSubmit = async (param) => {
        try {
            param.email = email

            await postChangeForgotPassword(param).unwrap()

            handlerStatusForm('change-password-success')

        } catch (error) {
            setIsStatusAlert('error');
            setMessageAlert(error.data.message)
        }
    }

    const handlerCancel = () => {
        dispatch(forgotPassword({ email: '' }))
        navigate(type === 'lawyer' ? '/login-lawyer' : '/login')
    }

    return (
        <Card className="overflow-hidden shadow-none border-0 rounded-3 align-self-center bg-transparent px-lg-6">
            <HeaderForm
                title="Lupa Kata Sandi"
            />
            <CardBody className="py-lg-5">
                <CardTitle className="d-none d-lg-block mt-0 fs-3 fw-bold text-center">
                    Lupa Kata Sandi
                </CardTitle>
                <CardText className="text-secondary fs-5 fw-normal text-center">
                    Silakan masukkan email Anda untuk membuat kata sandi baru
                </CardText>
                <div className="p-0 p-lg-2 mt-4">
                    <Form
                        onSubmit={handleSubmit(handleOnSubmit)}
                        className="form-horizontal"
                    >
                        {
                            isStatusAlert
                            &&
                            <AlertForm
                                status={isStatusAlert}
                                description={messageAlert}
                            />
                        }

                        <div className="mb-2">
                            <Label for="email">
                                Kata Sandi
                                {` `}
                                <span className="text-orange">*</span>
                            </Label>
                            <InputGroup>
                                <InputGroupText className={`bg-transparent border-end-0 text-start justify-content-end rounded-3 rounded-end-0 ${errors.password && 'border-orange'}`}>
                                    <HiOutlineLockClosed size={20} />
                                </InputGroupText>
                                <Controller
                                    name="password"
                                    control={control}
                                    render={({ field: { onChange, onBlur, value } }) => {
                                        return (
                                            <>
                                                <Input
                                                    bsSize="lg"
                                                    className={`d-none d-md-block border-start-0 border-end-0 rounded-3 rounded-start-0 rounded-end-0 text-start ${errors.password && 'border-orange'}`}
                                                    type={!isShowPassword ? 'password' : 'text'}
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    value={value}
                                                    placeholder="Masukan kata sandi anda"
                                                />
                                                <Input
                                                    bsSize="sm"
                                                    className={`d-block d-md-none border-start-0 border-end-0 rounded-3 rounded-start-0 rounded-end-0 text-start ${errors.password && 'border-orange'}`}
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    value={value}
                                                    type={!isShowPassword ? 'password' : 'text'}
                                                    placeholder="Masukan kata sandi anda"
                                                />
                                            </>
                                        )
                                    }}
                                />
                                <InputGroupText className={`bg-transparent border-start-0 text-start justify-content-end rounded-3 rounded-start-0 ${errors.password && 'border-orange'}`} onClick={() => setIsShowPassword(!isShowPassword)} style={{ 'cursor': 'pointer' }}>
                                    {isShowPassword ?
                                        <HiOutlineEyeOff size={20} />
                                        :
                                        <HiOutlineEye size={20} />
                                    }
                                </InputGroupText>
                            </InputGroup>
                        </div>
                        <div className="mb-3">
                            <span className="mt-2 text-orange text-start">
                                {errors.password?.message}
                            </span>
                        </div>

                        <div className="mb-2">
                            <Label for="email">
                                Konfirmasi Kata Sandi
                                {` `}
                                <span className="text-orange">*</span>
                            </Label>
                            <InputGroup>
                                <InputGroupText className={`bg-transparent border-end-0 text-start justify-content-end rounded-3 rounded-end-0 ${errors.confirmation_password && 'border-orange'}`}>
                                    <HiOutlineLockClosed size={20} />
                                </InputGroupText>
                                <Controller
                                    name="confirmation_password"
                                    control={control}
                                    render={({ field: { onChange, onBlur, value } }) => {
                                        return (
                                            <>
                                                <Input
                                                    bsSize="lg"
                                                    className={`d-none d-md-block border-start-0 border-end-0 rounded-3 rounded-start-0 rounded-end-0 text-start ${errors.confirmation_password && 'border-orange'}`}
                                                    type={!isShowConfirmPassword ? 'password' : 'text'}
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    value={value}
                                                    placeholder="Masukan ulang kata sandi anda"
                                                />
                                                <Input
                                                    bsSize="sm"
                                                    className={`d-block d-md-none border-start-0 border-end-0 rounded-3 rounded-start-0 rounded-end-0 text-start  ${errors.confirmation_password && 'border-orange'}`}
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    value={value}
                                                    type={!isShowConfirmPassword ? 'password' : 'text'}
                                                    placeholder="Masukan ulang kata sandi anda"
                                                />
                                            </>
                                        )
                                    }}
                                />
                                <InputGroupText className={`bg-transparent border-start-0 text-start justify-content-end rounded-3 rounded-start-0 ${errors.confirmation_password && 'border-orange'}`} onClick={() => setIsShowConfirmPassword(!isShowConfirmPassword)} style={{ 'cursor': 'pointer' }} >
                                    {isShowConfirmPassword ?
                                        <HiOutlineEyeOff size={20} />
                                        :
                                        <HiOutlineEye size={20} />
                                    }
                                </InputGroupText>
                            </InputGroup>
                        </div>
                        <div className="mb-3">
                            <span className="mt-2 text-orange text-start">
                                {errors.confirmation_password?.message}
                            </span>
                        </div>

                        <div className="mt-4">
                            <Button
                                type="submit"
                                block
                                color="danger"
                                className="rounded-4"
                                disabled={isLoading}
                            >
                                {!isLoading ? 'Verifikasi' : 'Memproses...'}
                            </Button>
                        </div>
                        <div className="mt-2">
                            <Button
                                onClick={
                                    () => handlerCancel()
                                }
                                type="button"
                                block
                                color="secondary"
                                className="rounded-4"
                            >
                                Kembali
                            </Button>
                        </div>
                    </Form>
                </div>
            </CardBody>
        </Card>
    );
}

export default ChangePasswordFromForgot;
