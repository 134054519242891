import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
const { VITE_BASE_URL } = import.meta.env;

const getToken = (state) => state.authSetup.token;

export const caseApi = createApi({
  reducerPath: 'caseApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${VITE_BASE_URL}/order/`,
    prepareHeaders: (headers, { getState }) => {
      const token = getToken(getState());
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getListOrders: builder.query({
      query: ({ limit, page, keyword }) =>
        `list-orders?limit=${limit}&page=${page}&keyword=${keyword}`,
    }),
    postOrderDivorced: builder.mutation({
      query: (body) => ({
        url: `divorced`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['divorcer-order'],
    }),
    postOrderConsultation: builder.mutation({
      query: (body) => ({
        url: `consultation`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['consultation-order'],
    }),
    deleteEndConsultationSession: builder.mutation({
      query: ({ id }) => ({
        url: `consultations/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['consultation-end'],
    }),
    getDetailOrder: builder.query({
      query: ({ case_id }) => `get-detail-orders?case_id=${case_id}`,
    }),
    getOrder: builder.query({
      query: ({ id }) => `${id}`,
    }),
    getDetailInfoOrder: builder.query({
      query: ({ case_id }) => `get-detail-info-order?case_id=${case_id}`,
    }),
    getDetailInfoOrderNoCache: builder.mutation({
      query: ({ case_id }) => ({
        url: `get-detail-info-order?case_id=${case_id}`,
        method: 'GET',
      }),
      invalidatesTags: ['get-detail-info-order'],
    }),
    postCancelOrder: builder.mutation({
      query: (body) => ({
        url: `cancel-order-service`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['cancel-order-service'],
    }),
    postRejectOrder: builder.mutation({
      query: (body) => ({
        url: `reject-order-service`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['reject-order-service'],
    }),
    postApproveOrderDivorce: builder.mutation({
      query: (body) => ({
        url: `approve-order-divorce`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['approve-order-divorce'],
    }),
    postApproveOrderConsultation: builder.mutation({
      query: (body) => ({
        url: `approve-order-consultation`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['approve-order-consultation'],
    }),
    postRescheduleConsultation: builder.mutation({
      query: (body) => ({
        url: `resechedule-consultation`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['resechedule-consultation'],
    }),
    getListProgressTimeline: builder.query({
      query: ({ id_order_service }) =>
        `list-progress-order-timeline?id_order_service=${id_order_service}`,
    }),
    getDetailProgressTimeline: builder.query({
      query: ({ id }) => `detail-progress-order-timeline?id=${id}`,
    }),
    getDetailProgressNoCache: builder.mutation({
      query: ({ id }) => ({
        url: `detail-progress-order-timeline?id=${id}`,
        method: 'GET',
      }),
      invalidatesTags: ['add-condition-upload-document'],
    }),
    getListFileByIdProgressTimelineNoCache: builder.mutation({
      query: ({ id_order_timeline }) => ({
        url: `get-file-by-id-progress-order-timeline?id_order_timeline=${id_order_timeline}`,
        method: 'GET',
      }),
      invalidatesTags: ['get-file-by-id-progress-order-timeline'],
    }),
    getListCategoryOrderTimeline: builder.query({
      query: () => 'list-category-order-timelines',
    }),
    postCreateConditionForUploadFile: builder.mutation({
      query: (body) => ({
        url: `add-condition-upload-document`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['add-condition-upload-document'],
    }),
    postCreateProgressOrderTimeline: builder.mutation({
      query: (body) => ({
        url: `create-progress-order-timeline`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['create-progress-order-timeline'],
    }),
    postUploadFileByIdProgressTimeline: builder.mutation({
      query: ({ id_file, file }) => {
        const bodyFormData = new FormData();
        bodyFormData.append('file', file);
        bodyFormData.append('id_file', id_file);
        return {
          url: `upload-file-with-condition-document`,
          method: 'POST',
          body: bodyFormData,
          formData: true,
        };
      },
      invalidatesTags: ['upload-file-with-condition-document'],
    }),
    postUploadFileInOrderTimeline: builder.mutation({
      query: ({ id_order_timeline, file, file_name }) => {
        const bodyFormData = new FormData();
        bodyFormData.append('file', file);
        bodyFormData.append('id_order_timeline', id_order_timeline);
        bodyFormData.append('file_name', file_name);
        return {
          url: `upload-file-order-timeline-not-specific-extensions`,
          method: 'POST',
          body: bodyFormData,
          formData: true,
        };
      },
      invalidatesTags: ['upload-file-order-timeline-not-specific-extensions'],
    }),
    putUpdateProgressOrderTimeline: builder.mutation({
      query: (body) => ({
        url: `update-progress-order-timeline`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['update-progress-order-timeline'],
    }),
    deleteConditionUploadDocument: builder.mutation({
      query: (body) => ({
        url: `delete-condition-upload-document`,
        method: 'DELETE',
        body,
      }),
      invalidatesTags: ['delete-condition-upload-document'],
    }),
    deleteFileProgressTimeline: builder.mutation({
      query: (body) => ({
        url: `delete-upload-file-progress-timeline`,
        method: 'DELETE',
        body,
      }),
      invalidatesTags: ['delete-upload-file-progress-timeline'],
    }),
    postConfirmationEndDivorce: builder.mutation({
      query: (body) => ({
        url: `confirmation-end-order-divorce`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['confirmation-end-order-divorce'],
    }),
  }),
});

export const {
  useGetListOrdersQuery,
  usePostOrderDivorcedMutation,
  usePostOrderConsultationMutation,
  useGetDetailOrderQuery,
  useLazyGetOrderQuery,
  usePostCancelOrderMutation,
  usePostApproveOrderConsultationMutation,
  usePostApproveOrderDivorceMutation,
  usePostRejectOrderMutation,
  usePostRescheduleConsultationMutation,
  useGetDetailInfoOrderQuery,
  useGetListProgressTimelineQuery,
  useGetListCategoryOrderTimelineQuery,
  useGetDetailProgressTimelineQuery,
  usePostCreateConditionForUploadFileMutation,
  usePostCreateProgressOrderTimelineMutation,
  usePostUploadFileByIdProgressTimelineMutation,
  usePostUploadFileInOrderTimelineMutation,
  usePutUpdateProgressOrderTimelineMutation,
  useDeleteConditionUploadDocumentMutation,
  useDeleteFileProgressTimelineMutation,
  useGetDetailProgressNoCacheMutation,
  useGetListFileByIdProgressTimelineNoCacheMutation,
  usePostConfirmationEndDivorceMutation,
  useGetDetailInfoOrderNoCacheMutation,
  useDeleteEndConsultationSessionMutation,
} = caseApi;
