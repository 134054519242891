import { useNavigate } from 'react-router-dom';
import { Button, Col, Container, Row } from 'reactstrap';
import HelmetTitle from '../../components/layout/HelmetTitle';

const Error404 = () => {
    const navigate = useNavigate()
    return (
        <>
            <HelmetTitle
                title="Error Not Found"
                meta="notfound, error, errornotfound, 404, ceraionline"
            />
            <div className="account-pages my-5 pt-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="8" xl="6">
                            <div>
                                <img src="disconect.svg" alt="404" className="img-fluid" />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <div className="text-center mb-5">
                                <h1 className="display-2 fw-medium">
                                    4<i className="bx bx-buoy bx-spin text-primary display-3" />
                                    4
                                </h1>
                                <h4 className="text-uppercase">Sorry, page not found</h4>
                                <div className="mt-5 text-center">
                                    <Button onClick={() => navigate('/')} color="danger" block className='rounded-3'>
                                        Kembali Ke Home Page
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default Error404;
