import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const NonAuthMiddleware = (props) => {
    const { children, isPublic } = props;
    const navigate = useNavigate()

    useEffect(() => {
        checkingSession();
    }, []);

    const checkingSession = () => {
        const sessions = localStorage.getItem('data');
        if (!isPublic) {
            if (sessions) {
                const parseSessions = JSON.parse(sessions);
                const url = parseSessions.role === 'lawyer' ? '/dashboard-lawyer' : '/dashboard-client'
                navigate(url)
            }
        }
    }

    return <>
        {children}
    </>

}

export default NonAuthMiddleware;
