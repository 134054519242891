import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Form, Input, Label, Modal, ModalBody, Row } from 'reactstrap';
import * as yup from "yup";
import { authSetupSelector } from "../../../app/authSlice";
import { orderSetupSelector, setDataProfile } from "../../../app/orderSlice";
import SelectQualification from "../../../components/form/SelectQualification";
import SelectReligion from "../../../components/form/SelectReligion";
import SelectorDistricts from "../../../components/form/SelectorDistricts";
import SelectorProvince from "../../../components/form/SelectorProvince";
import SelectorRegencies from "../../../components/form/SelectorRegencies";
import SelectorVillages from "../../../components/form/SelectorVillages";
import { checkLengthKeys } from "../../../helpers/GlobalHelper";

const ModalFormPersonalInformation = ({ isOpen, toggle }) => {
    const dispatch = useDispatch()
    const { sessions } = useSelector(authSetupSelector)
    const { data_profile } = useSelector(orderSetupSelector)

    const [defaultValueProvinceKyc, setDefaultValueProvinceKyc] = useState('');
    const [defaultValueProvinceDomicile, setDefaultValueProvinceDomicile] = useState('');
    const [defaultValueCityKyc, setDefaultValueCityKyc] = useState('');
    const [defaultValueCityDomicile, setDefaultValueCityDomicile] = useState('');
    const [defaultValueTownKyc, setDefaultValueTownKyc] = useState('');
    const [defaultValueTownDomicile, setDefaultValueTownDomicile] = useState('');
    const [defaultValueWardKyc, setDefaultValueWardKyc] = useState('');
    const [defaultValueWardDomicile, setDefaultValueWardDomicile] = useState('');

    const schema = yup.object({
        place_birth: yup.string().required("Tempat lahir wajib di isi!"),
        date_birth: yup.string().required("Tanggal lahir wajib di isi!"),
        profession: yup.string().required("Pekerjaan wajib di isi"),
        qualification: yup.string().required("Pendidikan wajib di isi!"),
        number_kyc: yup.string()
            .required('Nomor KTP wajib di isi')
            .min(16, 'Minimal 16 digit')
            .max(16, 'Maksimal 16 digit'),
        religion: yup
            .mixed()
            .oneOf(
                ['Islam', 'Katolik', 'Protestan', 'Hindu', 'Budha', 'Konghucu'],
                "Agama hanya ada: islam, katolik, protestan, hindu, budha, konghucu"
            )
            .required("Agama wajib di isi"),
        address_kyc: yup.string().required('Alamat Sesuai KTP wajib di isi'),
        ward_kyc: yup.string().required('Kelurahan Sesuai KTP wajib di isi'),
        town_kyc: yup.string().required('Kecamatan Sesuai KTP wajib di isi'),
        city_kyc: yup.string().required('Kota Sesuai KTP wajib di isi'),
        province_kyc: yup.string().required('Kota Sesuai KTP wajib di isi'),
        postal_code_kyc: yup.string()
            .required('Kode pos sesuai alamat KTP wajib di isi')
            .length(5, 'Kode pos harus sesuai dengan format indonesia atau dengan panjang 5 digit'),
        address_domicile: yup.string().required('Alamat Sesuai domisili wajib di isi'),
        ward_domicile: yup.string().required('Kelurahan Sesuai domisili wajib di isi'),
        town_domicile: yup.string().required('Kecamatan Sesuai domisili wajib di isi'),
        city_domicile: yup.string().required('Kota Sesuai domisili wajib di isi'),
        province_domicile: yup.string().required('Kota Sesuai domisili wajib di isi'),
        postal_code_domicile: yup.string()
            .required('Kode pos sesuai alamat domisili wajib di isi')
            .length(5, 'Kode pos harus sesuai dengan format indonesia atau dengan panjang 5 digit'),
    })

    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue,
        reset,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            place_birth: "",
            date_birth: "",
            profession: "",
            qualification: "",
            number_kyc: "",
            religion: "",
            address_kyc: "",
            ward_kyc: "",
            town_kyc: "",
            city_kyc: "",
            province_kyc: "",
            postal_code_kyc: "",
            address_domicile: "",
            ward_domicile: "",
            town_domicile: "",
            city_domicile: "",
            province_domicile: "",
            postal_code_domicile: "",
        },
    });

    const handlerActionModal = () => {
        toggle('scope_statement')
    }

    const handleNext = async (param) => {
        dispatch(setDataProfile(param))
        toggle('reason_divorce')
    }

    const handlerChangeSelectorProvince = (from, value) => {
        if (from === 'kyc') {
            setDefaultValueProvinceKyc(value)
            setDefaultValueCityKyc('')
            setDefaultValueTownKyc('')
            setDefaultValueWardKyc('')
            setValue('ward_kyc', '')
            setValue('town_kyc', '')
            setValue('city_kyc', '')
        }

        if (from === 'domicile') {
            setDefaultValueProvinceDomicile(value)
            setDefaultValueCityDomicile('')
            setDefaultValueTownDomicile('')
            setDefaultValueWardDomicile('')
            setValue('ward_domicile', '')
            setValue('town_domicile', '')
            setValue('city_domicile', '')
        }
    }

    const handlerChangeSelectorCity = (from, value) => {
        if (from === 'kyc') {
            setDefaultValueCityKyc(value)
            setDefaultValueTownKyc('')
            setDefaultValueWardKyc('')
            setValue('ward_kyc', '')
            setValue('town_kyc', '')
        }

        if (from === 'domicile') {
            setDefaultValueCityDomicile(value)
            setDefaultValueTownDomicile('')
            setDefaultValueWardDomicile('')
            setValue('ward_domicile', '')
            setValue('town_domicile', '')
        }
    }

    const handlerChangeSelectorDistrict = (from, value) => {
        if (from === 'kyc') {
            setDefaultValueTownKyc(value)
            setDefaultValueWardKyc('')
            setValue('ward_kyc', '')
        }

        if (from === 'domicile') {
            setDefaultValueTownDomicile(value)
            setDefaultValueWardDomicile('')
            setValue('ward_domicile', '')
        }
    }

    const handlerChangeSelectorVillage = (from, value) => {
        if (from === 'kyc') {
            setDefaultValueWardKyc(value)
        }

        if (from === 'domicile') {
            setDefaultValueWardDomicile(value)
        }
    }

    useEffect(() => {
        if (isOpen) {
            reset({
                place_birth: "",
                date_birth: "",
                profession: "",
                qualification: "",
                number_kyc: "",
                religion: "",
                address_kyc: "",
                ward_kyc: "",
                town_kyc: "",
                city_kyc: "",
                province_kyc: "",
                postal_code_kyc: "",
                address_domicile: "",
                ward_domicile: "",
                town_domicile: "",
                city_domicile: "",
                province_domicile: "",
                postal_code_domicile: "",
            })

            const checkDataProfile = checkLengthKeys(data_profile)
            if (checkDataProfile) {
                const valueQualification = !data_profile.qualification ? '-' : data_profile.qualification

                let parseDateBirth = data_profile.date_birth

                if (parseDateBirth) {
                    parseDateBirth = new Date(data_profile.date_birth);
                    parseDateBirth.toString()
                }

                const valueProvinceKyc = !data_profile.province_kyc ? '' : data_profile.province_kyc
                const valueProvinceDomicile = !data_profile.province_domicile ? '' : data_profile.province_domicile

                const valueCityKyc = !data_profile.city_kyc ? '' : data_profile.city_kyc
                const valueCityDomicile = !data_profile.city_domicile ? '' : data_profile.city_domicile

                const valueDistrictKyc = !data_profile.town_kyc ? '' : data_profile.town_kyc
                const valueDistrictDomicile = !data_profile.town_domicile ? '' : data_profile.town_domicile

                const valueVillageKyc = !data_profile.ward_kyc ? '' : data_profile.ward_kyc
                const valueVillageDomicile = !data_profile.ward_domicile ? '' : data_profile.ward_domicile

                setValue('qualification', valueQualification)
                setValue('place_birth', data_profile.place_birth)
                setValue('date_birth', parseDateBirth)
                setValue('profession', data_profile.profession)
                setValue('religion', data_profile.religion)
                setValue('number_kyc', data_profile.number_kyc)
                setValue('address_kyc', data_profile.address_kyc)
                setValue('address_domicile', data_profile.address_domicile)
                setValue('postal_code_kyc', data_profile.postal_code_kyc)
                setValue('postal_code_domicile', data_profile.postal_code_domicile)
                setValue('ward_kyc', valueVillageKyc)
                setValue('town_kyc', valueDistrictKyc)
                setValue('city_kyc', valueCityKyc)
                setValue('province_kyc', valueProvinceKyc)
                setValue('ward_domicile', valueVillageDomicile)
                setValue('town_domicile', valueDistrictDomicile)
                setValue('city_domicile', valueCityDomicile)
                setValue('province_domicile', valueProvinceDomicile)
            }
        }
    }, [isOpen]);

    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            centered
            keyboard={false}
            backdrop={false}
            className="border-0 custom-backdrop"
            size='lg'
        >
            <ModalBody>
                <div className="text-center fw-bold mb-2">
                    Data Diri Penggugat (Tahap 1)
                </div>
                <div className="border-bottom mb-3"></div>
                <Row>
                    <Col xs={12}>
                        <Form onSubmit={handleSubmit(handleNext)}>
                            <Row>
                                <Col xs={12}>
                                    <Label className="fw-bold fs-6">
                                        Data Diri
                                        <span className="text-orange">
                                            *
                                        </span>
                                    </Label>
                                </Col>
                                <Col xs={12}>
                                    <div className="mb-3">
                                        <Input
                                            value={sessions.full_name}
                                            disabled
                                            type="text"
                                            className="form-control"
                                        />
                                    </div>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <div className="mb-3">
                                        <Input
                                            value={sessions.phone_number}
                                            disabled
                                            type="text"
                                            className="form-control"
                                        />
                                    </div>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <div className="mb-3">
                                        <Input
                                            value={sessions.email}
                                            disabled
                                            type="text"
                                            className="form-control"
                                        />
                                    </div>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <div className="mb-3">
                                        <Controller
                                            name='place_birth'
                                            control={control}
                                            render={({ field: { onChange, onBlur, value } }) => {
                                                return <>
                                                    <Input
                                                        onBlur={onBlur}
                                                        onChange={onChange}
                                                        value={value}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Masukan tempat lahir anda"
                                                    />
                                                </>
                                            }}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <span className="mt-2 text-orange text-start">
                                            {errors.place_birth?.message}
                                        </span>
                                    </div>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <div className="mb-3">
                                        <Controller
                                            name='date_birth'
                                            control={control}
                                            render={({ field: { onChange, onBlur, value } }) => {
                                                return <>
                                                    <DatePicker
                                                        dateFormat="yyyy-MM-dd"
                                                        onBlur={onBlur}
                                                        onChange={onChange}
                                                        selected={value}
                                                        placeholderText="Pilih tanggal lahir anda"
                                                        className="form-control cursor-pointer"
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        maxDate={
                                                            new Date(
                                                                new Date().setFullYear(
                                                                    new Date().getFullYear() - 12
                                                                )
                                                            )
                                                        }
                                                    />
                                                </>
                                            }}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <span className="mt-2 text-orange text-start">
                                            {errors.date_birth?.message}
                                        </span>
                                    </div>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <div className="mb-1">Pendidikan</div>
                                    <div className="mb-3">
                                        <Controller
                                            name='qualification'
                                            control={control}
                                            render={({ field: { onChange, value } }) => {
                                                return <>
                                                    <SelectQualification
                                                        onChange={(param) => onChange(param.value)}
                                                        value={value}
                                                    />
                                                </>
                                            }}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <span className="mt-2 text-orange text-start">
                                            {errors.qualification?.message}
                                        </span>
                                    </div>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <div className="text-white mb-1">o</div>
                                    <div className="mb-3">
                                        <Controller
                                            name='profession'
                                            control={control}
                                            render={({ field: { onChange, onBlur, value } }) => {
                                                return <>
                                                    <Input
                                                        onBlur={onBlur}
                                                        onChange={onChange}
                                                        value={value}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Pekerjaan"
                                                    />
                                                </>
                                            }}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <span className="mt-2 text-orange text-start">
                                            {errors.profession?.message}
                                        </span>
                                    </div>
                                </Col>
                                <Col xs={12}>
                                    <div className="mb-1">Agama</div>
                                    <div className="mb-3">
                                        <Controller
                                            name='religion'
                                            control={control}
                                            render={({ field: { onChange, value } }) => {
                                                return <>
                                                    <SelectReligion
                                                        onChange={(param) => onChange(param.value)}
                                                        value={value}
                                                    />
                                                </>
                                            }}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <span className="mt-2 text-orange text-start">
                                            {errors.religion?.message}
                                        </span>
                                    </div>
                                </Col>
                                <Col xs={12}>
                                    <div className="mb-3">
                                        <Controller
                                            name='number_kyc'
                                            control={control}
                                            render={({ field: { onChange, onBlur, value } }) => {
                                                if (value) {
                                                    value = value.replace(/\D/g, "")
                                                }
                                                return <>
                                                    <Input
                                                        onBlur={onBlur}
                                                        onChange={onChange}
                                                        value={value}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Nomor KTP"
                                                    />
                                                </>
                                            }}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <span className="mt-2 text-orange text-start">
                                            {errors.number_kyc?.message}
                                        </span>
                                    </div>
                                </Col>
                                <Col xs={12}>
                                    <Label className="fw-bold fs-6">
                                        Alamat KTP
                                        <span className="text-orange">
                                            *
                                        </span>
                                    </Label>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <div className="mb-3">
                                        <Controller
                                            name='province_kyc'
                                            control={control}
                                            render={({ field: { onChange, value } }) => {
                                                return <>
                                                    <SelectorProvince
                                                        key={1}
                                                        onChange={(param) => {
                                                            handlerChangeSelectorProvince(
                                                                'kyc',
                                                                param.value
                                                            )
                                                            onChange(param.value)
                                                        }}
                                                        value={value}
                                                    />
                                                </>
                                            }}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <span className="mt-2 text-orange text-start">
                                            {errors.province_kyc?.message}
                                        </span>
                                    </div>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <div className="mb-3">
                                        <Controller
                                            name='city_kyc'
                                            control={control}
                                            render={({ field: { onChange, value } }) => {
                                                return <>
                                                    <SelectorRegencies
                                                        key={1}
                                                        provinceId={defaultValueProvinceKyc}
                                                        onChange={(param) => {
                                                            handlerChangeSelectorCity(
                                                                'kyc',
                                                                param.value
                                                            )
                                                            onChange(param.value)
                                                        }}
                                                        value={value}
                                                        isHaveParent
                                                    />
                                                </>
                                            }}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <span className="mt-2 text-orange text-start">
                                            {errors.city_kyc?.message}
                                        </span>
                                    </div>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <div className="mb-3">
                                        <Controller
                                            name='town_kyc'
                                            control={control}
                                            render={({ field: { onChange, value } }) => {
                                                return <>
                                                    <SelectorDistricts
                                                        key={1}
                                                        cityId={defaultValueCityKyc}
                                                        onChange={(param) => {
                                                            handlerChangeSelectorDistrict(
                                                                'kyc',
                                                                param.value
                                                            )
                                                            onChange(param.value)
                                                        }}
                                                        value={value}
                                                        isHaveParent
                                                    />
                                                </>
                                            }}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <span className="mt-2 text-orange text-start">
                                            {errors.town_kyc?.message}
                                        </span>
                                    </div>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <div className="mb-3">
                                        <Controller
                                            name='ward_kyc'
                                            control={control}
                                            render={({ field: { onChange, value } }) => {
                                                return <>
                                                    <SelectorVillages
                                                        key={1}
                                                        districtId={defaultValueTownKyc}
                                                        onChange={(param) => {
                                                            handlerChangeSelectorVillage(
                                                                'kyc',
                                                                param.value
                                                            )
                                                            onChange(param.value)
                                                        }}
                                                        value={value}
                                                        isHaveParent
                                                    />
                                                </>
                                            }}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <span className="mt-2 text-orange text-start">
                                            {errors.ward_kyc?.message}
                                        </span>
                                    </div>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <div className="mb-3">
                                        <Controller
                                            name='address_kyc'
                                            control={control}
                                            render={({ field: { onChange, onBlur, value } }) => {
                                                return <>
                                                    <Input
                                                        onBlur={onBlur}
                                                        onChange={onChange}
                                                        value={value}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Alamat KTP"
                                                    />
                                                </>
                                            }}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <span className="mt-2 text-orange text-start">
                                            {errors.address_kyc?.message}
                                        </span>
                                    </div>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <div className="mb-3">
                                        <Controller
                                            name='postal_code_kyc'
                                            control={control}
                                            render={({ field: { onChange, onBlur, value } }) => {
                                                if (value) {
                                                    value = value.replace(/\D/g, "")
                                                }
                                                return <>
                                                    <Input
                                                        onBlur={onBlur}
                                                        onChange={onChange}
                                                        value={value}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Kode pos sesuai alamat KTP"
                                                    />
                                                </>
                                            }}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <span className="mt-2 text-orange text-start">
                                            {errors.postal_code_kyc?.message}
                                        </span>
                                    </div>
                                </Col>
                                <Col xs={12}>
                                    <Label className="fw-bold fs-6">
                                        Alamat Tempat Tinggal Saat Ini
                                        <span className="text-orange">
                                            *
                                        </span>
                                    </Label>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <div className="mb-3">
                                        <Controller
                                            name='province_domicile'
                                            control={control}
                                            render={({ field: { onChange, value } }) => {
                                                return <>
                                                    <SelectorProvince
                                                        key={2}
                                                        onChange={(param) => {
                                                            handlerChangeSelectorProvince(
                                                                'domicile',
                                                                param.value
                                                            )
                                                            onChange(param.value)
                                                        }}
                                                        value={value}
                                                        restrictionsByLabel={['DKI JAKARTA']}
                                                    />
                                                </>
                                            }}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <span className="mt-2 text-orange text-start">
                                            {errors.province_domicile?.message}
                                        </span>
                                    </div>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <div className="mb-3">
                                        <Controller
                                            name='city_domicile'
                                            control={control}
                                            render={({ field: { onChange, value } }) => {
                                                return <>
                                                    <SelectorRegencies
                                                        key={2}
                                                        provinceId={defaultValueProvinceDomicile}
                                                        onChange={(param) => {
                                                            handlerChangeSelectorCity(
                                                                'domicile',
                                                                param.value
                                                            )
                                                            onChange(param.value)
                                                        }}
                                                        value={value}
                                                        isHaveParent
                                                    />
                                                </>
                                            }}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <span className="mt-2 text-orange text-start">
                                            {errors.city_domicile?.message}
                                        </span>
                                    </div>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <div className="mb-3">
                                        <Controller
                                            name='town_domicile'
                                            control={control}
                                            render={({ field: { onChange, value } }) => {
                                                return <>
                                                    <SelectorDistricts
                                                        key={2}
                                                        cityId={defaultValueCityDomicile}
                                                        onChange={(param) => {
                                                            handlerChangeSelectorDistrict(
                                                                'domicile',
                                                                param.value
                                                            )
                                                            onChange(param.value)
                                                        }}
                                                        value={value}
                                                        isHaveParent
                                                    />
                                                </>
                                            }}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <span className="mt-2 text-orange text-start">
                                            {errors.town_domicile?.message}
                                        </span>
                                    </div>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <div className="mb-3">
                                        <Controller
                                            name='ward_domicile'
                                            control={control}
                                            render={({ field: { onChange, onBlur, value } }) => {
                                                return <>
                                                    <SelectorVillages
                                                        key={1}
                                                        districtId={defaultValueTownDomicile}
                                                        onChange={(param) => {
                                                            handlerChangeSelectorVillage(
                                                                'domicile',
                                                                param.value
                                                            )
                                                            onChange(param.value)
                                                        }}
                                                        value={value}
                                                        isHaveParent
                                                    />
                                                </>
                                            }}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <span className="mt-2 text-orange text-start">
                                            {errors.ward_domicile?.message}
                                        </span>
                                    </div>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <div className="mb-3">
                                        <Controller
                                            name='address_domicile'
                                            control={control}
                                            render={({ field: { onChange, onBlur, value } }) => {
                                                return <>
                                                    <Input
                                                        onBlur={onBlur}
                                                        onChange={onChange}
                                                        value={value}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Alamat domisili"
                                                    />
                                                </>
                                            }}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <span className="mt-2 text-orange text-start">
                                            {errors.address_domicile?.message}
                                        </span>
                                    </div>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <div className="mb-3">
                                        <Controller
                                            name='postal_code_domicile'
                                            control={control}
                                            render={({ field: { onChange, onBlur, value } }) => {
                                                if (value) {
                                                    value = value.replace(/\D/g, "")
                                                }
                                                return <>
                                                    <Input
                                                        onBlur={onBlur}
                                                        onChange={onChange}
                                                        value={value}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Kode pos sesuai alamat domisili"
                                                    />
                                                </>
                                            }}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <span className="mt-2 text-orange text-start">
                                            {errors.postal_code_domicile?.message}
                                        </span>
                                    </div>
                                </Col>
                                <Col xs={12} className="text-center mt-3 px-2 px-lg-1">
                                    <div className="d-lg-flex">
                                        <Button
                                            onClick={handlerActionModal}
                                            block
                                            color="primary"
                                            className="btn-outline-secondary mx-lg-2 mb-2 fw-bold"
                                            outline
                                        >
                                            Kembali
                                        </Button>
                                        <Button
                                            type="submit"
                                            block
                                            color="primary"
                                            className="btn-primary mx-lg-2 mb-2"
                                        >
                                            Lanjutkan
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    );
}

export default ModalFormPersonalInformation;
