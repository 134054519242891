import { useEffect } from 'react';
import { useState } from 'react';
import { Alert } from 'reactstrap';

const AlertForm = ({ status = "error", description = "" }) => {
    const [isColor, setIsColor] = useState('bg-orange');

    useEffect(() => {
        if (status === 'success') {
            setIsColor('bg-success');
        } else if (status === 'info') {
            setIsColor('bg-info');
        } else {
            setIsColor('bg-orange');
        }
    }, [status]);

    return (
        <Alert
            color="warning"
            className={`${isColor} text-white text-center rounded-3 mb-3`}
            role="alert"
        >
            {description}
        </Alert>
    );
}

export default AlertForm;
