import { useSelector } from "react-redux";
import { Button, Col, Modal, ModalBody, Row } from "reactstrap";
import { orderSetupSelector } from "../../../app/orderSlice";
import { useEffect, useState } from "react";
import { reformatToIDRCurrency } from "../../../helpers/GlobalHelper";

const ModalScopeStatement = ({
    isOpen,
    toggle
}) => {
    const [serviceActive, setServiceActive] = useState(0);
    const {
        list_services
    } = useSelector(orderSetupSelector)

    const handlerActionModal = () => {
        toggle('choose_service')
    }

    const handlerNext = () => {
        toggle('data_personal_information')
    }

    useEffect(() => {
        const active = list_services.filter((val) => val.isChecked)
        if (!active[0]) {
            setServiceActive(0)
        } else {
            setServiceActive(active[0].price)
        }
    }, [list_services]);

    return <Modal
        isOpen={isOpen}
        toggle={handlerActionModal}
        centered
        keyboard={false}
        backdrop={false}
        size='lg'
        className="border-0 custom-backdrop"
    >
        <ModalBody>
            <div className="text-center fw-bold mb-2">
                Pernyataan Ruang Lingkup
            </div>
            <div className="border-bottom mb-3"></div>
            <Row>
                <Col xs={12} className="px-0">
                    <ul style={{ listStyleType: 'disc' }}>
                        <li className="mb-1">
                            <p className="fs-6 mb-0">
                                Terdapat biaya untuk melakukan book lawyer, pembayaran dilakukan setelah Lawyer bersedia menerima kasus Anda, anda harus melakukan pembayaran: {reformatToIDRCurrency(serviceActive)}
                            </p>
                        </li>
                        <li className="mb-1">
                            <p className="fs-6 mb-0">
                                Pengisian form kemungkinan akan memakan waktu 20 menit dengan 2 langkah
                            </p>
                        </li>
                        <li className="mb-1">
                            <p className="fs-6 mb-0">
                                Pastikan koneksi Anda stabil karena Cerai Online tidak melakukan auto-save
                            </p>
                        </li>
                        <li className="mb-1">
                            <p className="fs-6 mb-1">
                                Siapakan dokuman Anda dalam format PNG/JPEG/PDF (maks. 1 MB), dengan format nama file [Dokumen_Nama Dokumen, contoh: Dokumen_Buku Nikah]
                            </p>
                            <ul style={{ listStyleType: 'disc' }}>
                                <li className="mb-1">
                                    <p className="fs-6 mb-0">
                                        KTP pengaju perceraian
                                    </p>
                                </li>
                                <li className="mb-1">
                                    <p className="fs-6 mb-0">
                                        KK
                                    </p>
                                </li>
                                <li className="mb-1">
                                    <p className="fs-6 mb-0">
                                        Buku nikah / surat nikah
                                    </p>
                                </li>
                                <li className="mb-1">
                                    <p className="fs-6 mb-0">
                                        Buku nikah / surat nikah
                                    </p>
                                </li>
                                <li className="mb-1">
                                    <p className="fs-6 mb-0">
                                        Surat keterangan luar (opsi)
                                    </p>
                                </li>
                                <li className="mb-1">
                                    <p className="fs-6 mb-0">
                                        Surat izin atasan
                                    </p>
                                </li>
                                <li className="mb-1">
                                    <p className="fs-6 mb-0">
                                        Surat keterangan lurah
                                    </p>
                                </li>
                                <li className="mb-1">
                                    <p className="fs-6 mb-0">
                                        Akte kelahiran anak (jika memiliki anak)
                                    </p>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </Col>
                <Col xs={12} className="text-center px-0 mt-3">
                    <div className="d-lg-flex justify-content-lg-center">
                        <Button
                            onClick={handlerActionModal}
                            block
                            color="primary"
                            className="btn-outline-secondary mx-2 mb-2 fw-bold"
                            outline
                        >
                            Kembali
                        </Button>
                        <Button
                            onClick={handlerNext}
                            block
                            color="primary"
                            className="btn-primary mx-2 mb-2"
                        >
                            Lanjutkan
                        </Button>
                    </div>
                </Col>
            </Row>
        </ModalBody>
    </Modal>
}

export default ModalScopeStatement;
