import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Col, Form, Input, Label, Row } from "reactstrap";
import * as yup from "yup";
import { authSetupSelector, setRefreshToken, setSessions, setToken } from "../../../app/authSlice";
import { updateStatusRefetchSession } from "../../../app/profileSlice";
import SelectReligion from "../../../components/form/SelectReligion";
import { notify, reformatDate } from "../../../helpers/GlobalHelper";
import { usePostRefreshTokenMutation } from "../../auth/api/AuthAPI";
import { usePostUploadFileImageMutation } from "../../file/api/FileAPI";
import { usePostActivationAccountLawyerMutation, usePutUpdatePersonalDataMutation } from "../../user/api/UsersAPI";
import SelectorProvince from "../../../components/form/SelectorProvince";
import SelectorRegencies from "../../../components/form/SelectorRegencies";
import SelectorDistricts from "../../../components/form/SelectorDistricts";
import SelectorVillages from "../../../components/form/SelectorVillages";

const FormPersonalDataLawyer = ({ onChangeTab }) => {
    const [previewFileKyc, setPreviewFileKyc] = useState(null);

    const [defaultValueProvinceKyc, setDefaultValueProvinceKyc] = useState('');
    const [defaultValueProvinceDomicile, setDefaultValueProvinceDomicile] = useState('');
    const [defaultValueCityKyc, setDefaultValueCityKyc] = useState('');
    const [defaultValueCityDomicile, setDefaultValueCityDomicile] = useState('');
    const [defaultValueTownKyc, setDefaultValueTownKyc] = useState('');
    const [defaultValueTownDomicile, setDefaultValueTownDomicile] = useState('');
    const [defaultValueWardKyc, setDefaultValueWardKyc] = useState('');
    const [defaultValueWardDomicile, setDefaultValueWardDomicile] = useState('');

    const [isUpdateProfile, setIsUpdateProfile] = useState(false);

    const { sessions, refresh_token } = useSelector(authSetupSelector)
    const dispatch = useDispatch()

    const [postRefreshToken, { isLoading: isLoadingRefetchSession }] = usePostRefreshTokenMutation()
    const [postUploadFileImage, { isLoading: isLoadingUploadFile }] = usePostUploadFileImageMutation()
    const [
        putUpdatePersonalData,
        {
            isLoading: isLoadingUpdatePersonalData,
            isSuccess: isSuccessUpdateDataProfile,
            isError: isErrorUpdateDataProfile,
            error: errorUpdateDataProfile,
        }
    ] = usePutUpdatePersonalDataMutation()
    const [
        postActivationAccountLawyer,
        {
            isLoading: isLoadingActivationAccountLawyer,
            isSuccess: isSuccessActivationAccountLawyer
        }
    ] = usePostActivationAccountLawyerMutation()

    const schema = yup.object({
        place_birth: yup.string().required("Tempat lahir wajib di isi!"),
        date_birth: yup.string().required("Tanggal lahir wajib di isi!"),
        profession: yup.string().required("Pekerjaan wajib di isi"),
        start_profession_date: yup.string().required("Tanggal Mulai Kerja wajib di isi!"),
        number_kyc: yup.string()
            .required('Nomor KTP wajib di isi')
            .min(16, 'Minimal 16 digit')
            .max(16, 'Maksimal 16 digit'),
        file_kyc: yup.string().required('Foto ktp wajib di upload'),
        religion: yup.mixed().oneOf(['Islam', 'Katolik', 'Protestan', 'Hindu', 'Budha', 'Konghucu'], "Agama tidak tersedia di Indonesia").required("Agama wajib di isi"),
        address_kyc: yup.string().required('Alamat Sesuai KTP wajib di isi'),
        ward_kyc: yup.string().required('Kelurahan Sesuai KTP wajib di isi'),
        town_kyc: yup.string().required('Kecamatan Sesuai KTP wajib di isi'),
        city_kyc: yup.string().required('Kota Sesuai KTP wajib di isi'),
        province_kyc: yup.string().required('Kota Sesuai KTP wajib di isi'),
        postal_code_kyc: yup.string()
            .required('Kode pos sesuai alamat KTP wajib di isi')
            .length(5, 'Kode pos harus sesuai dengan format indonesia atau dengan panjang 5 digit'),
        address_domicile: yup.string().required('Alamat Sesuai domisili wajib di isi'),
        ward_domicile: yup.string().required('Kelurahan Sesuai domisili wajib di isi'),
        town_domicile: yup.string().required('Kecamatan Sesuai domisili wajib di isi'),
        city_domicile: yup.string().required('Kota Sesuai domisili wajib di isi'),
        province_domicile: yup.string().required('Kota Sesuai domisili wajib di isi'),
        postal_code_domicile: yup.string()
            .required('Kode pos sesuai alamat domisili wajib di isi')
            .length(5, 'Kode pos harus sesuai dengan format indonesia atau dengan panjang 5 digit')
    })

    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue,
        clearErrors,
        setError
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            place_birth: "",
            date_birth: "",
            profession: "",
            start_profession_date: "",
            number_kyc: "",
            file_kyc: "",
            religion: 'Islam',
            address_kyc: "",
            ward_kyc: "",
            town_kyc: "",
            city_kyc: "",
            province_kyc: "",
            postal_code_kyc: "",
            address_domicile: '',
            ward_domicile: '',
            town_domicile: '',
            city_domicile: "",
            province_domicile: '',
            postal_code_domicile: '',
        },
    });

    const handlerRefreshSession = async (isUpdateProfile = false) => {

        const resultGenerateNewToken = await postRefreshToken({ refresh_token }).unwrap()
        const dataSessions = resultGenerateNewToken.data;
        if (isUpdateProfile) {
            localStorage.setItem('data', JSON.stringify(dataSessions))
            dispatch(setSessions(dataSessions))
            return true;

        }

        localStorage.setItem('refreshToken', resultGenerateNewToken.refreshToken)
        localStorage.setItem('token', resultGenerateNewToken.token)

        dispatch(setToken(resultGenerateNewToken.token))
        dispatch(setRefreshToken(resultGenerateNewToken.refreshToken))
        return true;
    }

    const handleOnSubmit = async (param) => {
        await handlerRefreshSession(false)
        param.date_birth = reformatDate(param.date_birth,)
        param.start_profession_date = reformatDate(param.start_profession_date)

        param.province_kyc = parseInt(param.province_kyc)
        param.city_kyc = parseInt(param.city_kyc)
        param.town_kyc = parseInt(param.town_kyc)
        param.ward_kyc = parseInt(param.ward_kyc)
        param.province_domicile = parseInt(param.province_domicile)
        param.city_domicile = parseInt(param.city_domicile)
        param.town_domicile = parseInt(param.town_domicile)
        param.ward_domicile = parseInt(param.ward_domicile)
        await putUpdatePersonalData(param).unwrap()
    }

    const handleChangeFile = async (event) => {
        const file = event.target.files[0];
        if (!file) {
            setValue('file_kyc', "")
            setError("file_kyc", { type: 'required', message: 'File ktp wajib di isi!' })
            return false
        }

        if (file.size > 1024000) {
            setValue('file_kyc', "")
            setError("file_kyc", { type: 'required', message: 'File ktp wajib di isi!' })
            return notify('error', 5000, 'File KTP yang anda pilih terlalu besar, maksimal 1mb!');
        }

        const splitNameFile = file.name.split('.');
        if (
            !['svg', 'png', 'jpg', 'jpeg', 'svg', 'gif', 'webp'].includes(
                splitNameFile[1],
            )
        ) {
            setValue('file_kyc', "")
            setError("file_kyc", { type: 'required', message: 'File ktp wajib di isi!' })
            return notify('error', 5000, 'File KTP hanya boleh: png, svg, jpg, jpeg, gif, webp');

        }

        await handlerRefreshSession(false)
        const resultUploadFileImage = await postUploadFileImage({ file }).unwrap()
        setValue('file_kyc', resultUploadFileImage.data.image)
        clearErrors("file_kyc")
    }

    const handlerActivationAccount = async () => {
        await handlerRefreshSession(false)
        await postActivationAccountLawyer().unwrap()
    }

    const ContentButtonActivation = (params) => {
        const {
            isLoadingUploadFile,
            isLoadingUpdatePersonalData,
            handlerActivationAccount,
            statusVerifyJob
        } = params

        if ([3, 4].includes(statusVerifyJob)) {
            return (
                <>
                    <Button
                        type='button'
                        className="d-none d-lg-block ms-lg-2 mt-3"
                        color="info"
                        disabled={isLoadingUploadFile || isLoadingUpdatePersonalData ? true : false}
                        onClick={handlerActivationAccount}
                    >
                        Aktivasi Sebagai Lawyer
                    </Button>
                    <Button
                        type='button'
                        block
                        className="d-block d-lg-none ms-lg-2 mt-3"
                        color="info"
                        disabled={isLoadingUploadFile || isLoadingUpdatePersonalData ? true : false}
                        onClick={handlerActivationAccount}
                    >
                        Aktivasi Sebagai Lawyer
                    </Button>
                </>
            )
        }
    }

    const handlerChangeSelectorProvince = (from, value) => {
        if (from === 'kyc') {
            setDefaultValueProvinceKyc(value)
            setDefaultValueCityKyc('')
            setDefaultValueTownKyc('')
            setDefaultValueWardKyc('')
            setValue('ward_kyc', '')
            setValue('town_kyc', '')
            setValue('city_kyc', '')
        }

        if (from === 'domicile') {
            setDefaultValueProvinceDomicile(value)
            setDefaultValueCityDomicile('')
            setDefaultValueTownDomicile('')
            setDefaultValueWardDomicile('')
            setValue('ward_domicile', '')
            setValue('town_domicile', '')
            setValue('city_domicile', '')
        }
    }

    const handlerChangeSelectorCity = (from, value) => {
        if (from === 'kyc') {
            setDefaultValueCityKyc(value)
            setDefaultValueTownKyc('')
            setDefaultValueWardKyc('')
            setValue('ward_kyc', '')
            setValue('town_kyc', '')
        }

        if (from === 'domicile') {
            setDefaultValueCityDomicile(value)
            setDefaultValueTownDomicile('')
            setDefaultValueWardDomicile('')
            setValue('ward_domicile', '')
            setValue('town_domicile', '')
        }
    }

    const handlerChangeSelectorDistrict = (from, value) => {
        if (from === 'kyc') {
            setDefaultValueTownKyc(value)
            setDefaultValueWardKyc('')
            setValue('ward_kyc', '')
        }

        if (from === 'domicile') {
            setDefaultValueTownDomicile(value)
            setDefaultValueWardDomicile('')
            setValue('ward_domicile', '')
        }
    }

    const handlerChangeSelectorVillage = (from, value) => {
        if (from === 'kyc') {
            setDefaultValueWardKyc(value)
        }

        if (from === 'domicile') {
            setDefaultValueWardDomicile(value)
        }
    }

    useEffect(() => {
        if (sessions && !isUpdateProfile) {
            let parseDateBirth = sessions.date_birth

            if (parseDateBirth) {
                parseDateBirth = new Date(sessions.date_birth);
                parseDateBirth.toString()
            }

            let parseStarProfession = sessions.start_profession_date

            if (parseStarProfession) {
                parseStarProfession = new Date(sessions.start_profession_date);
                parseStarProfession.toString()
            }

            const valueProvinceKyc = !sessions.province_kyc ? '' : sessions.province_kyc.id
            const valueProvinceDomicile = !sessions.province_domicile ? '' : sessions.province_domicile.id

            const valueCityKyc = !sessions.city_kyc ? '' : sessions.city_kyc.id
            const valueCityDomicile = !sessions.city_domicile ? '' : sessions.city_domicile.id

            const valueDistrictKyc = !sessions.town_kyc ? '' : sessions.town_kyc.id
            const valueDistrictDomicile = !sessions.town_domicile ? '' : sessions.town_domicile.id

            const valueVillageKyc = !sessions.ward_kyc ? '' : sessions.ward_kyc.id
            const valueVillageDomicile = !sessions.ward_domicile ? '' : sessions.ward_domicile.id

            setPreviewFileKyc(sessions.file_kyc)
            setDefaultValueProvinceKyc(valueProvinceKyc)
            setDefaultValueProvinceDomicile(valueProvinceDomicile)
            setDefaultValueCityKyc(valueCityKyc)
            setDefaultValueCityDomicile(valueCityDomicile)

            setDefaultValueTownKyc(valueDistrictKyc)
            setDefaultValueTownDomicile(valueDistrictDomicile)

            setDefaultValueWardKyc(valueVillageKyc)
            setDefaultValueWardDomicile(valueVillageDomicile)

            setValue('place_birth', sessions.place_birth)
            setValue('date_birth', parseDateBirth)
            setValue('profession', sessions.profession)
            setValue("start_profession_date", parseStarProfession)
            setValue('number_kyc', sessions.number_kyc)
            setValue('file_kyc', sessions.file_kyc)
            setValue('religion', !sessions.religion ? 'Islam' : sessions.religion)
            setValue('address_kyc', sessions.address_kyc)
            setValue('ward_kyc', valueVillageKyc)
            setValue('town_kyc', valueDistrictKyc)
            setValue('city_kyc', valueCityKyc)
            setValue('province_kyc', valueProvinceKyc)
            setValue('postal_code_kyc', sessions.postal_code_kyc)
            setValue('address_domicile', sessions.address_domicile)
            setValue('ward_domicile', valueVillageDomicile)
            setValue('town_domicile', valueDistrictDomicile)
            setValue('city_domicile', valueCityDomicile)
            setValue('province_domicile', valueProvinceDomicile)
            setValue('postal_code_domicile', sessions.postal_code_domicile)
        }
    }, [sessions, isUpdateProfile]);

    useEffect(() => {
        if (isLoadingRefetchSession) {
            dispatch(updateStatusRefetchSession(true))

        } else {
            setTimeout(() => {
                dispatch(updateStatusRefetchSession(false))
            }, 3000);
        }
    }, [isLoadingRefetchSession]);

    useEffect(() => {
        if (isSuccessUpdateDataProfile) {
            notify('success', 30000, 'Berhasil merubah data diri');
            setIsUpdateProfile(true)
            handlerRefreshSession(true)
            onChangeTab(3)
        }
    }, [isSuccessUpdateDataProfile]);

    useEffect(() => {
        if (isSuccessActivationAccountLawyer) {
            notify('success', 30000, 'Berhasil mengirimkan email aktivasi!');
            setIsUpdateProfile(false)
            handlerRefreshSession(true)
        }
    }, [isSuccessActivationAccountLawyer]);

    useEffect(() => {
        if (isErrorUpdateDataProfile) {
            notify('error', 30000, errorUpdateDataProfile.data.message);
        }
    }, [isErrorUpdateDataProfile]);

    return (
        <Form onSubmit={handleSubmit(handleOnSubmit)}>
            <Row>
                <Col xs={12}>
                    <Label className="fw-bold fs-6">
                        Data Diri
                        <span className="text-orange">
                            *
                        </span>
                    </Label>
                </Col>
                <Col xs={12} lg={6}>
                    <div className="mb-3">
                        <Controller
                            name='place_birth'
                            control={control}
                            render={({ field: { onChange, onBlur, value } }) => {
                                return <>
                                    <Input
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        value={value}
                                        type="text"
                                        className="form-control"
                                        placeholder="Masukan tempat lahir anda"
                                    />
                                </>
                            }}
                        />
                    </div>
                    <div className="mb-3">
                        <span className="mt-2 text-orange text-start">
                            {errors.place_birth?.message}
                        </span>
                    </div>
                </Col>
                <Col xs={12} lg={6}>
                    <div className="mb-3">
                        <Controller
                            name='date_birth'
                            control={control}
                            render={({ field: { onChange, onBlur, value } }) => {
                                return <>
                                    <DatePicker
                                        dateFormat="yyyy-MM-dd"
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        selected={value}
                                        placeholderText="Pilih tanggal lahir anda"
                                        className="form-control cursor-pointer"
                                        showMonthDropdown
                                        showYearDropdown
                                        maxDate={
                                            new Date(
                                                new Date().setFullYear(
                                                    new Date().getFullYear() - 17
                                                )
                                            )
                                        }
                                    />
                                </>
                            }}
                        />
                    </div>
                    <div className="mb-3">
                        <span className="mt-2 text-orange text-start">
                            {errors.date_birth?.message}
                        </span>
                    </div>
                </Col>
                <Col xs={12} lg={4}>
                    <div className="mb-3">
                        <Controller
                            name='profession'
                            control={control}
                            render={({ field: { onChange, onBlur, value } }) => {
                                return <>
                                    <Input
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        value={value}
                                        type="text"
                                        className="form-control"
                                        placeholder="Pekerjaan"
                                    />
                                </>
                            }}
                        />
                    </div>
                    <div className="mb-3">
                        <span className="mt-2 text-orange text-start">
                            {errors.profession?.message}
                        </span>
                    </div>
                </Col>
                <Col xs={12} lg={4}>
                    <div className="mb-3">
                        <Controller
                            name='start_profession_date'
                            control={control}
                            render={({ field: { onChange, onBlur, value } }) => {
                                return <>
                                    <DatePicker
                                        dateFormat="yyyy-MM-dd"
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        selected={value}
                                        placeholderText="Pilih tanggal mulai bekerja"
                                        className="form-control cursor-pointer"
                                        showMonthDropdown
                                        showYearDropdown
                                        maxDate={new Date()}
                                    />
                                </>
                            }}
                        />
                    </div>
                    <div className="mb-3">
                        <span className="mt-2 text-orange text-start">
                            {errors.start_profession_date?.message}
                        </span>
                    </div>
                </Col>
                <Col xs={12} lg={4}>
                    <div className="mb-3">
                        <Controller
                            name='number_kyc'
                            control={control}
                            render={({ field: { onChange, onBlur, value } }) => {
                                if (value) {
                                    value = value.replace(/\D/g, "")
                                }
                                return <>
                                    <Input
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        value={value}
                                        type="text"
                                        className="form-control"
                                        placeholder="Nomor KTP"
                                    />
                                </>
                            }}
                        />
                    </div>
                    <div className="mb-3">
                        <span className="mt-2 text-orange text-start">
                            {errors.number_kyc?.message}
                        </span>
                    </div>
                </Col>
                <Col xs={12} lg={6}>
                    <div className="mb-1">
                        <span className="mt-2 text-black-50 text-start">
                            KTP, hanya format foto dan maksimal ukuran 1mb
                        </span>
                    </div>
                    <div className="mb-2">
                        <input
                            id="file_kyc"
                            onChange={handleChangeFile}
                            type="file"
                            accept=".jpg,.jpeg,.png,.svg,.gif,.webp"
                            className="form-control"
                            placeholder="Upload KTP anda"
                        />
                        <Controller
                            name='file_kyc'
                            control={control}
                            render={({ field: { value } }) => {
                                return <>
                                    <input
                                        value={value}
                                        type="text"
                                        hidden
                                    />
                                </>
                            }}
                        />
                    </div>
                    {
                        previewFileKyc && <Link to={previewFileKyc} target="_blank"
                        >
                            lihat file
                        </Link>
                    }

                    <div className="mb-3">
                        <span className="mt-2 text-orange text-start">
                            {errors.file_kyc?.message}
                        </span>
                    </div>
                </Col>
                <Col xs={12} lg={6}>
                    <div className="mb-1 d-none d-lg-block">
                        <span className="mt-2 text-white text-start">
                            &nbsp;
                        </span>
                    </div>
                    <div className="mb-3">
                        <Controller
                            name='religion'
                            control={control}
                            render={({ field: { onChange, value } }) => {
                                return <>
                                    <SelectReligion
                                        onChange={(param) => onChange(param.value)}
                                        value={value}
                                    />
                                </>
                            }}
                        />
                    </div>
                    <div className="mb-3">
                        <span className="mt-2 text-orange text-start">
                            {errors.religion?.message}
                        </span>
                    </div>
                </Col>
                <Col xs={12}>
                    <Label className="fw-bold fs-6">
                        Alamat KTP
                        <span className="text-orange">
                            *
                        </span>
                    </Label>
                </Col>
                <Col xs={12} lg={6}>
                    <div className="mb-3">
                        <Controller
                            name='province_kyc'
                            control={control}
                            render={({ field: { onChange, value } }) => {
                                return <>
                                    <SelectorProvince
                                        key={1}
                                        onChange={(param) => {
                                            handlerChangeSelectorProvince(
                                                'kyc',
                                                param.value
                                            )
                                            onChange(param.value)
                                        }}
                                        value={value}
                                    />
                                </>
                            }}
                        />
                    </div>
                    <div className="mb-3">
                        <span className="mt-2 text-orange text-start">
                            {errors.province_kyc?.message}
                        </span>
                    </div>
                </Col>
                <Col xs={12} lg={6}>
                    <div className="mb-3">
                        <Controller
                            name='city_kyc'
                            control={control}
                            render={({ field: { onChange, value } }) => {
                                return <>
                                    <SelectorRegencies
                                        key={1}
                                        provinceId={defaultValueProvinceKyc}
                                        onChange={(param) => {
                                            handlerChangeSelectorCity(
                                                'kyc',
                                                param.value
                                            )
                                            onChange(param.value)
                                        }}
                                        value={value}
                                        isHaveParent
                                    />
                                </>
                            }}
                        />
                    </div>
                    <div className="mb-3">
                        <span className="mt-2 text-orange text-start">
                            {errors.city_kyc?.message}
                        </span>
                    </div>
                </Col>
                <Col xs={12} lg={6}>
                    <div className="mb-3">
                        <Controller
                            name='town_kyc'
                            control={control}
                            render={({ field: { onChange, value } }) => {
                                return <>
                                    <SelectorDistricts
                                        key={1}
                                        cityId={defaultValueCityKyc}
                                        onChange={(param) => {
                                            handlerChangeSelectorDistrict(
                                                'kyc',
                                                param.value
                                            )
                                            onChange(param.value)
                                        }}
                                        value={value}
                                        isHaveParent
                                    />
                                </>
                            }}
                        />
                    </div>
                    <div className="mb-3">
                        <span className="mt-2 text-orange text-start">
                            {errors.town_kyc?.message}
                        </span>
                    </div>
                </Col>
                <Col xs={12} lg={6}>
                    <div className="mb-3">
                        <Controller
                            name='ward_kyc'
                            control={control}
                            render={({ field: { onChange, value } }) => {
                                return <>
                                    <SelectorVillages
                                        key={1}
                                        districtId={defaultValueTownKyc}
                                        onChange={(param) => {
                                            handlerChangeSelectorVillage(
                                                'kyc',
                                                param.value
                                            )
                                            onChange(param.value)
                                        }}
                                        value={value}
                                        isHaveParent
                                    />
                                </>
                            }}
                        />
                    </div>
                    <div className="mb-3">
                        <span className="mt-2 text-orange text-start">
                            {errors.ward_kyc?.message}
                        </span>
                    </div>
                </Col>
                <Col xs={12} lg={6}>
                    <div className="mb-3">
                        <Controller
                            name='address_kyc'
                            control={control}
                            render={({ field: { onChange, onBlur, value } }) => {
                                return <>
                                    <Input
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        value={value}
                                        type="text"
                                        className="form-control"
                                        placeholder="Alamat KTP"
                                    />
                                </>
                            }}
                        />
                    </div>
                    <div className="mb-3">
                        <span className="mt-2 text-orange text-start">
                            {errors.address_kyc?.message}
                        </span>
                    </div>
                </Col>
                <Col xs={12} lg={6}>
                    <div className="mb-3">
                        <Controller
                            name='postal_code_kyc'
                            control={control}
                            render={({ field: { onChange, onBlur, value } }) => {
                                if (value) {
                                    value = value.replace(/\D/g, "")
                                }
                                return <>
                                    <Input
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        value={value}
                                        type="text"
                                        className="form-control"
                                        placeholder="Kode pos sesuai alamat KTP"
                                    />
                                </>
                            }}
                        />
                    </div>
                    <div className="mb-3">
                        <span className="mt-2 text-orange text-start">
                            {errors.postal_code_kyc?.message}
                        </span>
                    </div>
                </Col>
                <Col xs={12}>
                    <Label className="fw-bold fs-6">
                        Alamat Tempat Tinggal Saat Ini
                        <span className="text-orange">
                            *
                        </span>
                    </Label>
                </Col>
                <Col xs={12} lg={6}>
                    <div className="mb-3">
                        <Controller
                            name='province_domicile'
                            control={control}
                            render={({ field: { onChange, value } }) => {
                                return <>
                                    <SelectorProvince
                                        key={2}
                                        onChange={(param) => {
                                            handlerChangeSelectorProvince(
                                                'domicile',
                                                param.value
                                            )
                                            onChange(param.value)
                                        }}
                                        value={value}
                                    />
                                </>
                            }}
                        />
                    </div>
                    <div className="mb-3">
                        <span className="mt-2 text-orange text-start">
                            {errors.province_domicile?.message}
                        </span>
                    </div>
                </Col>
                <Col xs={12} lg={6}>
                    <div className="mb-3">
                        <Controller
                            name='city_domicile'
                            control={control}
                            render={({ field: { onChange, value } }) => {
                                return <>
                                    <SelectorRegencies
                                        key={2}
                                        provinceId={defaultValueProvinceDomicile}
                                        onChange={(param) => {
                                            handlerChangeSelectorCity(
                                                'domicile',
                                                param.value
                                            )
                                            onChange(param.value)
                                        }}
                                        value={value}
                                        isHaveParent
                                    />
                                </>
                            }}
                        />
                    </div>
                    <div className="mb-3">
                        <span className="mt-2 text-orange text-start">
                            {errors.city_domicile?.message}
                        </span>
                    </div>
                </Col>
                <Col xs={12} lg={6}>
                    <div className="mb-3">
                        <Controller
                            name='town_domicile'
                            control={control}
                            render={({ field: { onChange, value } }) => {
                                return <>
                                    <SelectorDistricts
                                        key={2}
                                        cityId={defaultValueCityDomicile}
                                        onChange={(param) => {
                                            handlerChangeSelectorDistrict(
                                                'domicile',
                                                param.value
                                            )
                                            onChange(param.value)
                                        }}
                                        value={value}
                                        isHaveParent
                                    />
                                </>
                            }}
                        />
                    </div>
                    <div className="mb-3">
                        <span className="mt-2 text-orange text-start">
                            {errors.town_domicile?.message}
                        </span>
                    </div>
                </Col>
                <Col xs={12} lg={6}>
                    <div className="mb-3">
                        <Controller
                            name='ward_domicile'
                            control={control}
                            render={({ field: { onChange, onBlur, value } }) => {
                                return <>
                                    <SelectorVillages
                                        key={1}
                                        districtId={defaultValueTownDomicile}
                                        onChange={(param) => {
                                            handlerChangeSelectorVillage(
                                                'domicile',
                                                param.value
                                            )
                                            onChange(param.value)
                                        }}
                                        value={value}
                                        isHaveParent
                                    />
                                </>
                            }}
                        />
                    </div>
                    <div className="mb-3">
                        <span className="mt-2 text-orange text-start">
                            {errors.ward_domicile?.message}
                        </span>
                    </div>
                </Col>
                <Col xs={12} lg={6}>
                    <div className="mb-3">
                        <Controller
                            name='address_domicile'
                            control={control}
                            render={({ field: { onChange, onBlur, value } }) => {
                                return <>
                                    <Input
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        value={value}
                                        type="text"
                                        className="form-control"
                                        placeholder="Alamat domisili"
                                    />
                                </>
                            }}
                        />
                    </div>
                    <div className="mb-3">
                        <span className="mt-2 text-orange text-start">
                            {errors.address_domicile?.message}
                        </span>
                    </div>
                </Col>
                <Col xs={12} lg={6}>
                    <div className="mb-3">
                        <Controller
                            name='postal_code_domicile'
                            control={control}
                            render={({ field: { onChange, onBlur, value } }) => {
                                if (value) {
                                    value = value.replace(/\D/g, "")
                                }
                                return <>
                                    <Input
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        value={value}
                                        type="text"
                                        className="form-control"
                                        placeholder="Kode pos sesuai alamat domisili"
                                    />
                                </>
                            }}
                        />
                    </div>
                    <div className="mb-3">
                        <span className="mt-2 text-orange text-start">
                            {errors.postal_code_domicile?.message}
                        </span>
                    </div>
                </Col>
                <Col xs={12}>
                    <div className="d-lg-flex justify-content-lg-end">
                        <Button
                            type='button'
                            className="d-none d-lg-block ms-lg-2 mt-3"
                            color="secondary"
                            disabled={isLoadingUploadFile || isLoadingUpdatePersonalData ? true : false}
                            onClick={() => onChangeTab(1)}
                        >
                            Kembali
                        </Button>
                        <Button
                            type='button'
                            block
                            className="d-block d-lg-none ms-lg-2 mt-3"
                            color="secondary"
                            disabled={isLoadingUploadFile || isLoadingUpdatePersonalData ? true : false}
                            onClick={() => onChangeTab(1)}
                        >
                            Kembali
                        </Button>
                        <ContentButtonActivation
                            handlerActivationAccount={handlerActivationAccount}
                            isLoadingUploadFile={isLoadingUploadFile}
                            isLoadingUpdatePersonalData={isLoadingUpdatePersonalData}
                            statusVerifyJob={!sessions.status_verify_job ? 0 : sessions.status_verify_job}
                        />
                        <Button
                            type='submit'
                            className="d-none d-lg-block ms-lg-2 mt-3"
                            color="primary"
                            disabled={isLoadingUploadFile || isLoadingUpdatePersonalData ? true : false}
                        >
                            Selanjutnya
                        </Button>
                        <Button
                            type='submit'
                            block
                            className="d-block d-lg-none ms-lg-2 mt-3"
                            color="primary"
                            disabled={isLoadingUploadFile || isLoadingUpdatePersonalData ? true : false}
                        >
                            Selanjutnya
                        </Button>
                    </div>
                </Col>
            </Row>
        </Form >
    );
}

export default FormPersonalDataLawyer;
