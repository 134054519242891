import React from 'react';
import { Card, CardBody, CardText, Col, Row } from "reactstrap";

const ContentEmptyTokenActivationLawyer = () => {
    return (
        <Row className="justify-content-center align-items-center px-2 min-vh-100 bg-transparent">
            <Col xs={12} className="justify-content-center align-items-center text-center">
                <img
                    className="img-content"
                    alt="empty"
                    src="empty.svg"
                />
                <Card className="bg-transparent shadow-none">
                    <CardBody className="bg-transparent">
                        <CardText className="text-secondary fw-normal">
                            Token aktivasi kosong atau tidak ditemukan
                        </CardText>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
}

export default ContentEmptyTokenActivationLawyer;
