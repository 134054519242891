const ContentLeftForm = ({ description, ImgContent }) => {
    return (
        <>
            <img
                className="w-25"
                alt="logo"
                src="/image-11@2x.png"
            />
            <p className="fs-4 fw-bold text-center">{description}</p>
            <img
                src={!ImgContent ? "5790719-1-1.svg" : ImgContent}
                className="img-fluid rounded-3 bg-transparent" alt="activity" />
        </>
    );
}

export default ContentLeftForm;
