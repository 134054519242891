import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const { VITE_BASE_URL } = import.meta.env;

const getToken = (state) => state.authSetup.token;

export const fileApi = createApi({
    reducerPath: "fileApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${VITE_BASE_URL}/file/`,
        prepareHeaders: (headers, { getState }) => {
            const token = getToken(getState());
            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        postUploadFileImage: builder.mutation({
            query: ({ file }) => {
                const bodyFormData = new FormData();
                bodyFormData.append('file', file);
                return {
                    url: `upload-file-image`,
                    method: 'POST',
                    body: bodyFormData,
                    formData: true
                }
            },
            invalidatesTags: ["file"],
        }),
        postUploadPhotoProfileUser: builder.mutation({
            query: ({ file }) => {
                const bodyFormData = new FormData();
                bodyFormData.append('file', file);
                return {
                    url: `upload-photo-profile`,
                    method: 'POST',
                    body: bodyFormData,
                    formData: true
                }
            },
            invalidatesTags: ["file"],
        }),
        postUploadAnotherFile: builder.mutation({
            query: ({ file }) => {
                const bodyFormData = new FormData();
                bodyFormData.append('file', file);
                return {
                    url: `upload-all-type`,
                    method: 'POST',
                    body: bodyFormData,
                    formData: true
                }
            },
            invalidatesTags: ["file"],
        }),
    }),
});
export const {
    usePostUploadFileImageMutation,
    usePostUploadPhotoProfileUserMutation,
    usePostUploadAnotherFileMutation
} = fileApi;
