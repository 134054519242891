import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
const { VITE_BASE_URL } = import.meta.env;
const getToken = (state) => state.authSetup.token;

export const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${VITE_BASE_URL}/user/`,
    prepareHeaders: (headers, { getState }) => {
      const token = getToken(getState());
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    putUpdateDataProfile: builder.mutation({
      query: (body) => ({
        url: `update-data-profile`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['user'],
    }),
    putUpdatePersonalData: builder.mutation({
      query: (body) => ({
        url: `update-personal-data`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['user'],
    }),
    putUpdateDataEducation: builder.mutation({
      query: (body) => ({
        url: `update-education-data`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['user'],
    }),
    putUpdateDataServiceRates: builder.mutation({
      query: (body) => ({
        url: `update-service-rate-data`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['user'],
    }),
    postActivationAccountLawyer: builder.mutation({
      query: () => ({
        url: `send-email-verify-lawyer`,
        method: 'POST',
        body: {},
      }),
      invalidatesTags: ['user'],
    }),
    postCheckingTokenActivationLawyer: builder.mutation({
      query: (body) => ({
        url: `check-verification-user-lawyer`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['user'],
    }),
    postApproveActivationAccountLawyer: builder.mutation({
      query: (body) => ({
        url: `approve-verification-user-lawyer`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['user'],
    }),
    postRejectActivationAccountLawyer: builder.mutation({
      query: (body) => ({
        url: `reject-verification-user-lawyer`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['user'],
    }),
    getUserLawyers: builder.query({
      query: ({ limit, page, provinceId, acceptConsultation }) => {
        let params = [];
        if (limit !== undefined) {
          params.push(`limit=${limit}`);
        }
        if (page !== undefined) {
          params.push(`page=${page}`);
        }
        if (provinceId !== undefined) {
          params.push(`province_id=${provinceId}`);
        }
        if (acceptConsultation !== undefined) {
          params.push(`accept_consultation=${acceptConsultation}`);
        }

        return `get-list-lawyer${
          params.length > 0 ? '?' + params.join('&') : ''
        }`;
      },
    }),
    postAcceptConsultation: builder.mutation({
      query: (value) => ({
        url: `accept-consultation`,
        method: 'POST',
        body: {
          value,
        },
      }),
    }),
  }),
});
export const {
  usePutUpdateDataProfileMutation,
  usePutUpdatePersonalDataMutation,
  usePostActivationAccountLawyerMutation,
  usePostCheckingTokenActivationLawyerMutation,
  usePostApproveActivationAccountLawyerMutation,
  usePostRejectActivationAccountLawyerMutation,
  usePutUpdateDataEducationMutation,
  usePutUpdateDataServiceRatesMutation,
  useGetUserLawyersQuery,
  usePostAcceptConsultationMutation,
} = usersApi;
