const caseHeaderTableMenus = [
  {
    key: "case_id",
    value: "Case ID",
  },
  {
    key: "date",
    value: "Date",
  },
  {
    key: "client_name",
    value: "Klien",
  },
  {
    key: "service_name",
    value: "Layanan",
  },
  {
    key: "service_name",
    value: "Layanan",
  },
  {
    key: "case_status",
    value: "Case Status",
  },
  {
    key: "view_details",
    value: "View Details",
  },
];

const transactionHeaderTable = [
  {
    key: "order_id",
    value: "No Order",
  },
  {
    key: "billing_name",
    value: "Nama Klien",
  },
  {
    key: "transaction_type",
    value: "Jenis Transaksi",
  },
  {
    key: "amount",
    value: "Nilai Transaksi",
  },
  {
    key: "view_details",
    value: "Details",
  },
];

const paymentInputInformationUserFiled = [
  {
    key: "first_name",
    value: "Nama Depan",
    type: "text",
  },
  {
    key: "last_name",
    value: "Name Belakang",
    type: "text",
  },
  {
    key: "country_code",
    value: "Kode Negara",
  },
  {
    key: "phone_number",
    value: "Nomor Telepon",
    type: "text",
  },
  {
    key: "email_address",
    value: "Alamat Email",
    type: "email",
  },
];

const progressMenuList = [
  {
    title: "Upload berkas",
    description: "Kebutuhan upload berkas terkait proses perceraian",
    type: "attachment",
    to: "/upload-file",
  },
  {
    title: "Surat Kuasa",
    description: "Proses pemberian kuasa kepada pihak pengacara",
    type: "file",
    to: "/power-of-attorney",
  },
  {
    title: "Daftar Gugatan",
    description: "Mendaftarkan gugatan pada pengadilan",
    type: "edit",
    to: "/lawsuit-list",
  },
  {
    title: "Sidang berkas",
    description: "Proses sidang cek kelengkapan berkas",
    type: "attachment",
    to: "/file-trial",
  },
  {
    title: "Proses Mediasi",
    description: "Proses mediasi antar kedua belah pihak",
    type: "attachment",
    to: "/mediation-process",
  },
  {
    title: "Sidang Jawaban",
    description: "Mendaftarkan gugatan pada pengadilan",
    type: "attachment",
    to: "/answer-trial",
  },
  {
    title: "Replik",
    description: "Replik Penggugat atau Pemohon",
    type: "attachment",
    to: "/replica",
  },
  {
    title: "Duplik",
    description: "Duplik Penggugat atau Pemohon",
    type: "attachment",
    to: "/duplicate",
  },
  {
    title: "Sidang Pembuktian",
    description: "Sidang pembuktian Surat dari Penggugat / tergugat",
    type: "attachment",
    to: "/evidentiary-trial",
  },
  {
    title: "Sidang Kesimpulan",
    description: "Sidang kesimpulan",
    type: "attachment",
    to: "/conclusion-trial",
  },
  {
    title: "Putusan Pengadilan",
    description: "Sidang pembuktian Surat dari Penggugat / tergugat",
    type: "attachment",
    to: "/court-ruling",
  },
  {
    title: "Perceraian Selesai",
    description: "Serah terima akte cerai antar klien dan kuasa",
    type: "attachment",
    to: "/divorce-over",
  },
];

const listDescDetailTimeline = [
  {
    key: "list_detail_0",
    title: "Upload File",
    desc: "Upload file akan otomatis tersimpan di database dan maksimal size 5mb, tanpa perlu klik tombol simpan",
  },
  {
    key: "list_detail_1",
    title: "Hapus File",
    desc: "Hapus file akan otomatis terhapus dari database, tanpa perlu klik tombol simpan",
  },
  {
    key: "list_detail_2",
    title: "Deskripsi mengenai progress",
    desc: "Masukkan informasi terkait progress seperti lokasi, nama yang terlibat, hasil putusan, dan lainnya",
  },
  {
    key: "list_detail_3",
    title: "File Pendukung",
    desc: "Foto, Pdf, Ppt, Doc, Docx atau Video yang dapat menjadi pendukung dalam kasus",
  },
];

const listInfoPriceService = [
  {
    info: "Masukkan total tarif layanan yang Anda kenakan untuk setiap layanan Anda",
    subInfo: [],
  },
  {
    info: "Minimal Tarif adalah Rp 25.000 dan anda dapat mengkosongkan atau ubah menjadi 0 jika layanan tidak ingin di aktifkan",
    subInfo: [],
  },
  {
    info: "Pembayaran dari klien akan dilakukan dengan 2x termin(*Hanya berlaku selain konsultasi chat)",
    subInfo: [
      {
        info: "Penarikan Dp senilai 50% dibayarkan klien setelah kasus diterima",
      },
      {
        info: "Pelunasan senilai 50%, dapat ditarik setelah kasus selesai",
      },
    ],
  },
  {
    info: "Untuk setiap pencairan uang Cerai Online akan mengambil komisi sebesar 30% dari uang yang dicairkan dan untuk biaya admin transfer ke bank sebesar Rp 4.440 akan dibebankan kepada pengguna.",
    subInfo: [],
  },
];

export {
  caseHeaderTableMenus,
  transactionHeaderTable,
  paymentInputInformationUserFiled,
  progressMenuList,
  listDescDetailTimeline,
  listInfoPriceService,
};
