import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  confirmationModal: {
    isOpen: false,
    description: '',
    confirmText: 'Ya',
    cancelText: 'Batal',
    action: () => {},
  },
};

const globalSlice = createSlice({
  name: 'globalSetup',
  initialState,
  reducers: {
    showConfirmationModal: (state, action) => {
      state.confirmationModal = {
        ...state.confirmationModal,
        description: action.payload.description,
        action: action.payload.action,
        isOpen: true,
      };
    },
    hideConfirmationModal: (state, action) => {
      state.confirmationModal = {
        ...initialState.confirmationModal,
      };
    },
  },
});

export const globalSetupSelector = (state) => state.globalSetup;
export const { showConfirmationModal, hideConfirmationModal } =
  globalSlice.actions;
export default globalSlice.reducer;
