import React from "react";

/**
 *
 * @param {{listTitle: string, listDesc: string}} param0
 * @returns
 */

function ListDescription({ listTitle, listDesc }) {
  return (
    <li className="pb-2">
      <span className="fw-semibold">{listTitle}</span>
      <br />
      <span className="fw-light">{listDesc}</span>
    </li>
  );
}

export default ListDescription;
