import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    case_id: null,
    pick_service_id: null,
    detail_service_consultation: {},
    tax_service_consultation: 2000,
    choose_lawyer: {},
    list_services: [],

    //type have: choose_service, scope_statement, data_personal_information, reason_divorce, book_success, book_failure, successfully, failed
    form_book_type_now: null,
    form_book_type_prev: null,
    form_book_type_next: null,
    data_profile: {},
    reason_divorce: [
        {
            id: 1,
            reason: 'Pasangan berbuat zina atau menjadi pemabok, pemadat, penjudi, dan lain sebagainya yang sukar disembuhkan',
            is_checked: false,
            type: 'default',
        },
        {
            id: 2,
            reason: 'Suami meninggalkan istri selama 2 (dua) tahun berturut-turut tanpa izin istri dan tanpa alasan yang sah atau karena hal lain diluar kemampuannya',
            is_checked: false,
            type: 'default',
        },
        {
            id: 3,
            reason: 'Pasangan mendapat hukuman penjara 5 (lima) tahun atau hukuman yang lebih berat setelah perkawinan berlangsung',
            is_checked: false,
            type: 'default',
        },
        {
            id: 4,
            reason: 'Pasangan melakukan kekejaman atau penganiayaan berat yang membahayakan istrinya',
            is_checked: false,
            type: 'default',
        },
        {
            id: 5,
            reason: 'Antara suami dan istri terus-menerus terjadi perselisihan dan pertengkaran dan tidak ada harapan akan hidup rukun lagi dalam rumah tangga',
            is_checked: false,
            type: 'default',
        },
        {
            id: 6,
            reason: '',
            is_checked: false,
            type: 'custom',
        }
    ],
    order_failed_reason: '',

    // for case and chat
    case_id_active: null,
    room_id: null,
    bubble_chats: [],
    consultation_sessions: [],
    history_chat_action: {},
    detail_order_service: {},
    message: '',

    // progress timeline or not consultation case type
    condition_showing_left_card: 'chat',
    is_progress_timeline_active: '',
    choosed_category_progress: '',
    is_refetching_list_progress_timeline: false,
    is_refetching_detail_progress_timeline: false,
    is_refetching_list_file_progress_timeline: false,
    // chat | form_create_normal_progress | form_create_request_end_case | detail_order_progress_timeline

    // for modal detail case
    choose_consultation_session: {},
    is_modal_approve_consultation: false,
    is_modal_approve_divorce: false,
    is_modal_reschedule_consultation: false,
    is_modal_canceled_order: false,
    is_modal_reject_order: false,
};

const orderSlice = createSlice({
    name: 'orderSetup',
    initialState,
    reducers: {
        setPickLawyer: (state, action) => {
            state.choose_lawyer = action.payload
        },
        setPickService: (state, action) => {
            state.pick_service_id = action.payload
        },
        setListService: (state, action) => {
            state.list_services = action.payload;
        },
        setPositionFormOrder: (state, action) => {
            if (action.payload === 'cancel') {
                state.form_book_type_next = null
                state.form_book_type_prev = null
                state.form_book_type_now = null
            }

            if (action.payload === 'choose_service') {
                state.form_book_type_next = 'scope_statement'
                state.form_book_type_prev = null
                state.form_book_type_now = 'choose_service'
            }

            if (action.payload === 'scope_statement') {
                state.form_book_type_next = 'data_personal_information'
                state.form_book_type_prev = 'choose_service'
                state.form_book_type_now = 'scope_statement'
            }

            if (action.payload === 'data_personal_information') {
                state.form_book_type_next = 'reason_divorce'
                state.form_book_type_prev = 'scope_statement'
                state.form_book_type_now = 'data_personal_information'
            }

            if (action.payload === 'reason_divorce') {
                state.form_book_type_next = 'finish_order'
                state.form_book_type_prev = 'data_personal_information'
                state.form_book_type_now = 'reason_divorce'
            }

            if (action.payload === 'successfuly') {
                state.form_book_type_next = null
                state.form_book_type_prev = null
                state.form_book_type_now = 'successfully'
            }

            if (action.payload === 'failed') {
                state.form_book_type_next = null
                state.form_book_type_prev = null
                state.form_book_type_now = 'failed'
            }
        },
        setDataProfile: (state, action) => {
            state.data_profile = action.payload
        },
        updateReasonDivorce: (state, action) => {
            state.reason_divorce = action.payload;
        },
        clearReasonDivorce: (state) => {
            state.reason_divorce = [
                {
                    id: 1,
                    reason: 'Pasangan berbuat zina atau menjadi pemabok, pemadat, penjudi, dan lain sebagainya yang sukar disembuhkan',
                    is_checked: false,
                    type: 'default',
                },
                {
                    id: 2,
                    reason: 'Suami meninggalkan istri selama 2 (dua) tahun berturut-turut tanpa izin istri dan tanpa alasan yang sah atau karena hal lain diluar kemampuannya',
                    is_checked: false,
                    type: 'default',
                },
                {
                    id: 3,
                    reason: 'Pasangan mendapat hukuman penjara 5 (lima) tahun atau hukuman yang lebih berat setelah perkawinan berlangsung',
                    is_checked: false,
                    type: 'default',
                },
                {
                    id: 4,
                    reason: 'Pasangan melakukan kekejaman atau penganiayaan berat yang membahayakan istrinya',
                    is_checked: false,
                    type: 'default',
                },
                {
                    id: 5,
                    reason: 'Antara suami dan istri terus-menerus terjadi perselisihan dan pertengkaran dan tidak ada harapan akan hidup rukun lagi dalam rumah tangga',
                    is_checked: false,
                    type: 'default',
                },
                {
                    id: 6,
                    reason: '',
                    is_checked: false,
                    type: 'custom',
                }
            ];
        },
        setFailedReason: (state, action) => {
            state.order_failed_reason = action.payload
        },
        setDetailServiceConsultation: (state, action) => {
            state.detail_service_consultation = action.payload
        },
        setChooseTimeConsultation: (state, action) => {
            state.choose_time_consultation = action.payload;
        },

        //room chat & message
        setCaseId: (state, action) => {
            state.case_id = action.payload
        },
        setCaseIdActive: (state, action) => {
            state.case_id_active = action.payload
        },
        setRoomId: (state, action) => {
            state.room_id = action.payload
        },
        addMessage: (state, action) => {
            state.bubble_chats.push(action.payload)
        },
        changeAllBubbleChats: (state, action) => {
            state.bubble_chats = action.payload
        },
        setHistoryChatAction: (state, action) => {
            state.history_chat_action = action.payload
        },
        setConsultationSessions: (state, action) => {
            state.consultation_sessions = action.payload
        },
        setDetailOrderService: (state, action) => {
            state.detail_order_service = action.payload
        },
        setMultipleBubbleChat: (state, action) => {
            state.bubble_chats = action.payload
        },
        clearRoomChat: (state) => {
            state.case_id_active = null
            state.room_id = null
            state.bubble_chats = []
            state.consultation_sessions = []
            state.history_chat_action = {}
            state.detail_order_service = {}
        },
        setChooseConsultationSession: (state, action) => {
            state.choose_consultation_session = action.payload
        },
        setModalCase: (state, action) => {
            const { modalName, isActive } = action.payload
            state.is_modal_approve_consultation = false
            state.is_modal_reschedule_consultation = false
            state.is_modal_approve_divorce = false
            state.is_modal_canceled_order = false
            state.is_modal_reject_order = false
            switch (modalName) {
                case 'approve_consultation':
                    state.is_modal_approve_consultation = isActive
                    break;
                case 'approve_divorce':
                    state.is_modal_approve_divorce = isActive
                    break;
                case 'reschdule_consultation':
                    state.is_modal_reschedule_consultation = isActive
                    break;
                case 'reject':
                    state.is_modal_reject_order = isActive
                    break;
                default:
                    state.is_modal_canceled_order = isActive
                    break;
            }
        },
        setTypingMessage: (state, action) => {
            state.message = action.payload
        },

        //progress timeline or not consultation case type
        setConditionShowingLeftCard: (state, action) => {
            state.condition_showing_left_card = action.payload;
        },
        resetConditionShowingLeftCard: (state) => {
            state.condition_showing_left_card = 'chat';
        },
        setActiveProgressTimeline: (state, action) => {
            state.is_progress_timeline_active = action.payload
        },
        resetActiveProgressTimeline: (state) => {
            state.is_progress_timeline_active = ''
        },
        setChoosedCategoryProgress: (state, action) => {
            state.choosed_category_progress = action.payload
        },
        setIsRefetchingListProgressTimeline: (state, action) => {
            state.is_refetching_list_progress_timeline = action.payload
        },
        setIsRefetchingDetailProgressTimeline: (state, action) => {
            state.is_refetching_detail_progress_timeline = action.payload;
        },
        setIsRefetchingListFileProgressTimeline: (state, action) => {
            state.is_refetching_list_file_progress_timeline = action.payload;
        },
    }
})

export const orderSetupSelector = (state) => state.orderSetup
export const {
    setPickLawyer,
    setPickService,
    setListService,
    setPositionFormOrder,
    setDataProfile,
    updateReasonDivorce,
    clearReasonDivorce,
    setFailedReason,
    setDetailServiceConsultation,
    setCaseId,
    setCaseIdActive,
    setRoomId,
    addMessage,
    changeAllBubbleChats,
    setHistoryChatAction,
    setConsultationSessions,
    setDetailOrderService,
    clearRoomChat,
    setMultipleBubbleChat,
    setModalCase,
    setChooseConsultationSession,
    setTypingMessage,
    setConditionShowingLeftCard,
    resetConditionShowingLeftCard,
    setActiveProgressTimeline,
    resetActiveProgressTimeline,
    setChoosedCategoryProgress,
    setIsRefetchingListProgressTimeline,
    setIsRefetchingDetailProgressTimeline,
    setIsRefetchingListFileProgressTimeline,
} = orderSlice.actions
export default orderSlice.reducer;