import { useEffect, useState } from 'react';
import { BottomScrollListener } from 'react-bottom-scroll-listener';
import { FaMagnifyingGlass } from 'react-icons/fa6';

import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Button, Col, Row } from 'reactstrap';
import {
  changeValueProvince,
  landingPageSetupSelector,
  resetDataListLawyer,
  setValueDataListLawyer,
  setValuePageDataLawyer,
} from '../../app/landingPageSlice';
import SelectorProvince from '../../components/form/SelectorProvince';
import HelmetTitle from '../../components/layout/HelmetTitle';
import { getDifferenceData, notify } from '../../helpers/GlobalHelper';
import { useGetUserLawyersQuery } from '../user/api/UsersAPI';
import ContentListLawyer from './components/ContentListLawyer';

const ListLawyer = () => {
  const [newPage, setNewPage] = useState(1);
  const { province_id, page, limit, lawyerList } = useSelector(
    landingPageSetupSelector
  );

  const dispatch = useDispatch();

  const {
    data: dataLawyer,
    refetch: refetchLawyer,
    isLoading: loadingDataLawyer,
  } = useGetUserLawyersQuery(
    {
      limit,
      page,
      // provinceId: province_id
      acceptConsultation: true,
    },
    {
      refetchOnMountOrArgChange: false,
      refetchOnReconnect: true,
    }
  );

  const handlerChangeProvinceValue = (param) => {
    if (province_id !== province_id) {
      dispatch(changeValueProvince(province_id));
    } else {
      notify(
        'warn',
        70000,
        'Sepertinya anda ingin melakukan pencarian untuk provinsi yang sama'
      );
    }
  };

  const handlerRefetchDataLawyer = async () => {
    refetchLawyer();
  };

  const handlerOnScrollBottom = () => {
    dispatch(setValuePageDataLawyer(newPage));
  };

  const handlerMapDataList = (data, paginate) => {
    if (lawyerList.length < 1) {
      dispatch(setValueDataListLawyer(data));
    } else {
      const newDataListLawyer = getDifferenceData(data, lawyerList);

      if (newDataListLawyer) {
        dispatch(setValueDataListLawyer(newDataListLawyer));
      }
    }

    if (paginate.nextPage) {
      setNewPage(paginate.nextPage);
    }
  };

  useEffect(() => {
    if (loadingDataLawyer) {
      notify('loading', false, 'Sedang memuat data');
    } else {
      setTimeout(() => {
        toast.dismiss();
      }, 2000);
    }
  }, [loadingDataLawyer]);

  useEffect(() => {
    if (dataLawyer) {
      const { data, paginate } = dataLawyer;

      handlerMapDataList(data, paginate);
    } else {
      dispatch(resetDataListLawyer());
    }
  }, [dataLawyer]);

  useEffect(() => {
    handlerRefetchDataLawyer();
  }, []);

  return (
    <>
      <HelmetTitle
        title="List Lawyer"
        meta="carilawyer, lawyer, findlawyerceraionline.com, lawyerdiceraionline.com, ceraionline.com, ceraionline.com"
      />
      <BottomScrollListener onBottom={handlerOnScrollBottom}>
        <Row className="mt-10 mt-lg-13 px-4 px-xl-18 min-vh-100">
          <Col xs={12}>
            <Row>
              <Col xs={12} lg={10} className="mb-3">
                <SelectorProvince
                  onChange={handlerChangeProvinceValue}
                  value={province_id}
                  placeholder="ini provinsi"
                  restrictionsByLabel={['DKI JAKARTA']}
                />
              </Col>
              <Col xs={12} lg={2}>
                <Button
                  type="button"
                  block
                  color="danger"
                  onClick={() => handlerRefetchDataLawyer()}
                >
                  <FaMagnifyingGlass className="me-2" /> Cari pengacara
                </Button>
              </Col>
            </Row>
            <Row className="mt-3">
              <ContentListLawyer data={lawyerList} />
            </Row>
          </Col>
        </Row>
      </BottomScrollListener>
    </>
  );
};

export default ListLawyer;
