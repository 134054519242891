import React, { useRef, useState } from "react";
import CaseLayout from "../../components/layout/CaseLayout";
import HeaderName from "./components/HeaderName";
import CardChat from "./components/CardChat";
import ButtonCard from "./components/ButtonCard";
import { useSelector } from "react-redux";
import { dummyChats } from "../../docs/dummy";
import { BiSend } from "react-icons/bi";
import { Button, Col, Row } from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";

const ChatView = ({ type }) => {
  const location = useLocation();
  const { caseId } = location.state;

  const inputTextRef = useRef();
  const [content, setContent] = useState("");
  const user = useSelector((state) => state.authSetup.sessions);
  const handleChange = (e) => {
    inputTextRef.current.textContent = e.target.textContent;
  };

  const getCaretPosition = () => {
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      const clonedRange = range.cloneRange();
      clonedRange.selectNodeContents(inputTextRef.current);
      clonedRange.setEnd(range.endContainer, range.endOffset);
      return clonedRange.toString().length;
    }
    return 0;
  };

  const handleEnterKey = (e) => {
    if (e.which === 13 && !e.shiftKey) {
      e.preventDefault();
    }
    //eksekusi kode jika enter langsung kirim
  };

  const handlePaste = (e) => {
    e.preventDefault();

    const text = (e.clipboardData || Window.clipboardData).getData(
      "text/plain"
    );
    const caretPosition = getCaretPosition();

    if (content.length) {
      const newContent =
        content.slice(0, caretPosition) +
        text.replace(/\n/g, " </br> ") +
        content.slice(caretPosition);
      setContent(newContent);
    } else {
      setContent(text);
    }

    // inputTextRef.current.focus();
    document.execCommand("insertText", false, text);
  };

  const handleSendMessage = () => {
  };

  return (
    <CaseLayout
      breadcrumbTitle={"Tahap penyelesaian kasus"}
      isChat
      backTo={"/case-client"}
    >
      {/* <HeaderName name={"Jenny Wilson"} /> */}
      <div className="chat-content mb-5">
        <div className="overflow-auto chat-views gx-0 px-3 pt-4 row">
          {[...dummyChats, ...dummyChats, ...dummyChats]?.map((item, index) => {
            const sender = item?.id === user.id;
            return (
              <CardChat date={"4.10 Am"} key={`key_${index}`} isSender={sender}>
                <div className="font-size-12">{item?.chat}</div>
                {item.button_type ? (
                  <ButtonCard
                    buttonText={
                      item.button_type.includes("payment")
                        ? !sender && "Selesaikan Pembayaran"
                        : item.button_type.includes("new_client")
                          ? "Klien diterima"
                          : ""
                    }
                    to={
                      item.button_type.includes("payment")
                        ? "/payment-client"
                        : "#"
                    }
                    isSender={sender}
                  />
                ) : null}
              </CardChat>
            );
          })}
        </div>
        <div className="input-box p-3 py-4">
          {/* EditableDiv */}
          <div
            name="textbox"
            id="editableDiv"
            ref={inputTextRef}
            className="bg-white w-100 chats-input border ps-3 w-75 pe-5 py-3"
            onChange={handleChange}
            onInput={(e) => {
              const element = e.target;
              const message = element.innerText;
              const replaceMsg = message.replace(/\n/g, " </br> ");
              setContent(replaceMsg);
            }}
            onPaste={handlePaste}
            onKeyDown={handleEnterKey}
            suppressContentEditableWarning={true}
            contentEditable="true"
            role="textbox"
            aria-multiline="true"
            tabIndex="0px"
            placeholder="Masukan pesan..."
            spellCheck="false"
          ></div>
          <Button
            className="icon-send bg-transparent border-0"
            onClick={handleSendMessage}
          >
            <BiSend size={24} color="#E91E23" />
          </Button>
        </div>
      </div>
    </CaseLayout>
  );
};

export default ChatView;
