import { Badge, Button, Card, CardBody, Col, Label, Row } from 'reactstrap';
import HelmetTitle from '../../components/layout/HelmetTitle';
import iHamer from '/icon/hammer.png';
import iBagEmployee from '/icon/bag-employee.png';
import iBookLoveHeart from '/icon/book-love-heart.png';
import iBrokenHeartLightning from '/icon/broken-heart-lightning.png';
import iReportPhone from '/icon/report-phone.png';
import iBrokenHeartWhite from '/icon/broken-heart-white.png';
import iMessageBrokenHeart from '/icon/message-broken-heart.png';
import iPaperWhite from '/icon/paper-white.png';
import iSpy from '/icon/spy.png';
import { Link, useNavigate } from 'react-router-dom';
import ButtonMyService from './components/ButtonMyService';
import SelectorProvince from '../../components/form/SelectorProvince';
import { FaMagnifyingGlass } from 'react-icons/fa6';
import {
  changeValueProvince,
  landingPageSetupSelector,
} from '../../app/landingPageSlice';
import { useDispatch, useSelector } from 'react-redux';
import IsImgFootContent from './components/isImgFootContent';
import imgBrokenHeart from '/brokenHeart.png';

const Home = () => {
  const actionButtonMyService = [
    // {
    //     icon: iSpy,
    //     title: 'Sewa Detektif',
    //     to: '#',
    //     type: 'ex-link',
    // },
    {
      icon: iBagEmployee,
      title: 'Chat Pengacara',
      to: 'lawyer-list',
      type: 'in-link',
    },
    // {
    //     icon: iHamer,
    //     title: 'Gugat Cerai',
    //     to: 'lawyer-list',
    //     type: 'in-link',
    // },
    // {
    //     icon: iBookLoveHeart,
    //     title: 'Digugat Cerai',
    //     to: 'lawyer-list',
    //     type: 'in-link',
    // },
    // {
    //     icon: iBrokenHeartWhite,
    //     title: 'Gugat Cerai PNS',
    //     to: 'lawyer-list',
    //     type: 'in-link',
    // },
    // {
    //     icon: iMessageBrokenHeart,
    //     title: 'Digugat Cerai PNS',
    //     to: 'lawyer-list',
    //     type: 'in-link',
    // },
    // {
    //     icon: iPaperWhite,
    //     title: 'Gugat Harta Gono - Gini',
    //     to: 'lawyer-list',
    //     type: 'in-link',
    // },
    // {
    //     icon: iReportPhone,
    //     title: 'Lapor Perselingkuhan',
    //     to: 'lawyer-list',
    //     type: 'in-link',
    // },
    // {
    //     icon: iBrokenHeartLightning,
    //     title: 'Lapor KDRT',
    //     to: 'lawyer-list',
    //     type: 'in-link',
    // },
  ];

  const { province_id } = useSelector(landingPageSetupSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handlerChangeProvinceValue = (param) => {
    dispatch(changeValueProvince(param.value));
  };

  return (
    <>
      <HelmetTitle
        title="Home"
        meta="home, landingpage, landingpageceraionline.com, ceraionline, ceraionline.com"
      />
      <Row className="mt-10 mt-lg-10 px-4 px-xl-18 min-vh-100 mb-5">
        <Col xs={12} lg={12} className="mb-0">
          <Label className="fw-bold fs-4 mb-3">Layanan Kami</Label>
          <Row className="mb-4">
            {actionButtonMyService.map((val, key) => (
              <Col xs={12} lg={3} key={key}>
                <ButtonMyService
                  iconImg={val.icon}
                  title={val.title}
                  to={val.to}
                  type={val.type}
                />
              </Col>
            ))}
          </Row>
          <Label className="fw-bold fs-4 mb-3">Cari Pengacara</Label>
          <Row className="mb-4">
            <Col xs={12} lg={9} className="mb-3">
              <SelectorProvince
                onChange={handlerChangeProvinceValue}
                value={province_id}
                placeholder="ini provinsi"
                restrictionsByLabel={['DKI JAKARTA']}
              />
            </Col>
            <Col xs={12} lg={3}>
              <Button
                block
                color="primary"
                onClick={() => navigate('/lawyer-list')}
              >
                <FaMagnifyingGlass className="me-2" /> Cari pengacara
              </Button>
            </Col>
          </Row>
          <Row className="px-0 mb-5">
            <Col xs={12} lg={8} className="mb-3 mt-6">
              <Label className="fw-bold fs-5 mb-4coma5">
                Layani Proses Perceraian Anda dengan anti ribet
              </Label>
              <p>
                Cerai tidak pernah mudah, tetapi Cerai Online hadir untuk
                membantu Anda melalui proses ini dengan lancar. Temukan
                pengacara perceraian yang tepat untuk Anda dan atasi setiap
                langkah dengan kepercayaan diri. Dapatkan bantuan hukum yang
                Anda butuhkan secara online. Bersama-sama, kita akan melangkah
                maju.
              </p>
            </Col>
            <Col
              xs={12}
              lg={4}
              className="justify-content-center text-sm-center"
            >
              <img src={imgBrokenHeart} alt="img" className="img-fluid" />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Card className="bg-red-pink rounded rounded-4 h-100">
                <CardBody>
                  <Row>
                    <Col xs={12} lg={4} className="text-sm-center">
                      <IsImgFootContent />
                    </Col>
                    <Col xs={12} lg={8} className="text-center text-lg-start">
                      <Badge
                        color="white"
                        className="p-3 text-danger fs-4 rounded rounded-3 mt-4 mt-lg-0"
                      >
                        Segera Hadir
                      </Badge>
                      <div className="pe-0 pe-lg-20">
                        <p className="fw-bold fs-4 mt-5 text-white">
                          Cek tentang Kesehatan Pernikahanmu
                        </p>
                        <p className="text-white">
                          Eksplorasi berbagai aspek penting yang mempengaruhi
                          kesehatan pernikahan, termasuk komunikasi, keintiman,
                          dukungan emosional, konflik, dan kepuasan hubungan.
                          Cek tentang Kesehatan Pernikahan memberikan kamu dan
                          pesangan kesempatan untuk memperkuat ikatan mereka dan
                          mencapai hubungan yang lebih sehat dan bahagia dalam
                          pernikahan.
                        </p>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Home;
