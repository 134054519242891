import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store.jsx';
import Routing from './routes';
import { HelmetProvider } from 'react-helmet-async';
import ModalConfirmation from './components/modal/ModalConfirmation.jsx';

const helmetContext = {};

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <Provider store={store}>
      <HelmetProvider context={helmetContext}>
        <Routing />
        <ModalConfirmation />
      </HelmetProvider>
    </Provider>
  </React.StrictMode>
);
