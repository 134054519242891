import { FaFileInvoiceDollar, FaHouse, FaMessage, FaScaleBalanced, FaUser } from "react-icons/fa6";

const Menu = [
    {
        item: 'Beranda',
        url: '/dashboard-lawyer',
        icon: <FaHouse />,
        type: 'item_content',
        role: ['lawyer']
    },
    {
        item: 'Beranda',
        url: '/dashboard-client',
        icon: <FaHouse />,
        type: 'item_content',
        role: ['client']
    },
    {
        item: 'Kasus',
        url: '/case-lawyer',
        icon: <FaScaleBalanced />,
        type: 'item_content',
        role: ['lawyer']
    },
    {
        item: 'Kasus',
        url: '/case-client',
        icon: <FaScaleBalanced />,
        type: 'item_content',
        role: ['client']
    },
    {
        item: 'Riwayat Transaksi',
        url: '/history-transaction-lawyer',
        icon: <FaFileInvoiceDollar />,
        type: 'item_content',
        role: ['lawyer']
    },
    {
        item: 'Riwayat Transaksi',
        url: '/history-transaction-client',
        icon: <FaFileInvoiceDollar />,
        type: 'item_content',
        role: ['client']
    },
    {
        item: 'Profile',
        url: '/profile-lawyer',
        icon: <FaUser />,
        type: 'item_content',
        role: ['lawyer']
    },
    {
        item: 'Profile',
        url: '/profile-client',
        icon: <FaUser />,
        type: 'item_content',
        role: ['client']
    },
];

export { Menu };
