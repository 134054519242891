import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const { VITE_BASE_URL } = import.meta.env;

export const zoneApi = createApi({
    reducerPath: "zoneApi",
    tagTypes: ["zone"],
    baseQuery: fetchBaseQuery({
        baseUrl: `${VITE_BASE_URL}/`,
    }),
    endpoints: (builder) => ({
        getProvinces: builder.query({
            query: () => `provinces`,
        }),
        getRegencies: builder.query({
            query: (province_id) => `regencies?province_id=${province_id}&limit=100&page=1`,
        }),
        getDistricts: builder.query({
            query: (regencie_id) => `district?regencies_id=${regencie_id}&limit=100&page=1`,
        }),
        getVillages: builder.query({
            query: (district_id) => `villages?district_id=${district_id}&limit=100&page=1`,
        }),
    })
})

export const {
    useGetProvincesQuery,
    useGetRegenciesQuery,
    useGetDistrictsQuery,
    useGetVillagesQuery,
} = zoneApi;