import { useEffect, useRef, useState } from 'react';
import SimpleBar from 'simplebar-react';

import MetisMenu from "metismenujs";

import { Link, useLocation } from "react-router-dom";

import { useCallback } from "react";
import { useSelector } from 'react-redux';
import { authSetupSelector } from '../../app/authSlice';
import { checkLengthKeys } from '../../helpers/GlobalHelper';
import { Menu } from "./JsonSidebarContent";

const SidebarContentAuth = () => {
    const [role, setRole] = useState('all');
    const refSidebar = useRef();
    const { sessions } = useSelector(authSetupSelector)

    const path = useLocation();

    const activateParentDropdown = useCallback((item) => {
        item.classList.add("active");
        const parent = item.parentElement;
        const parent2El = parent.childNodes[1];
        if (parent2El && parent2El.id !== "side-menu") {
            parent2El.classList.add("mm-show");
        }

        if (parent) {
            parent.classList.add("mm-active");
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add("mm-show"); // ul tag

                const parent3 = parent2.parentElement; // li tag

                if (parent3) {
                    parent3.classList.add("mm-active"); // li
                    parent3.childNodes[0].classList.add("mm-active"); //a
                    const parent4 = parent3.parentElement; // ul
                    if (parent4) {
                        parent4.classList.add("mm-show"); // ul
                        const parent5 = parent4.parentElement;
                        if (parent5) {
                            parent5.classList.add("mm-show"); // li
                            parent5.childNodes[0].classList.add("mm-active"); // a tag
                        }
                    }
                }
            }
            scrollElement(item);
            return false;
        }
        scrollElement(item);
        return false;
    }, []);

    const removeActivation = (items) => {
        for (var i = 0; i < items.length; ++i) {
            var item = items[i];
            const parent = items[i].parentElement;

            if (item && item.classList.contains("active")) {
                item.classList.remove("active");
            }
            if (parent) {
                const parent2El =
                    parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
                        ? parent.childNodes[1]
                        : null;
                if (parent2El && parent2El.id !== "side-menu") {
                    parent2El.classList.remove("mm-show");
                }

                parent.classList.remove("mm-active");
                const parent2 = parent.parentElement;

                if (parent2) {
                    parent2.classList.remove("mm-show");

                    const parent3 = parent2.parentElement;
                    if (parent3) {
                        parent3.classList.remove("mm-active"); // li
                        parent3.childNodes[0].classList.remove("mm-active");

                        const parent4 = parent3.parentElement; // ul
                        if (parent4) {
                            parent4.classList.remove("mm-show"); // ul
                            const parent5 = parent4.parentElement;
                            if (parent5) {
                                parent5.classList.remove("mm-show"); // li
                                parent5.childNodes[0].classList.remove("mm-active"); // a tag
                            }
                        }
                    }
                }
            }
        }
    };

    const activeMenu = useCallback(() => {
        let pathName = path.pathname;
        if (pathName) {
            pathName = `/${pathName.split('/')[1]}`;
        }

        let matchingMenuItem = null;
        const ul = document.getElementById("side-menu");
        const items = ul.getElementsByTagName("a");
        removeActivation(items);

        for (let i = 0; i < items.length; ++i) {
            if (pathName === items[i].pathname) {
                matchingMenuItem = items[i];
                break;
            }
        }

        if (matchingMenuItem) {
            activateParentDropdown(matchingMenuItem);
        }
    }, [path.pathname, activateParentDropdown]);

    useEffect(() => {
        refSidebar.current.recalculate();
    }, []);

    useEffect(() => {
        new MetisMenu("#side-menu");
        activeMenu();
    }, []);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        activeMenu();
    }, [activeMenu]);

    useEffect(() => {
        const checkingObj = checkLengthKeys(sessions)
        if (checkingObj) {
            setRole(sessions.role_name.toLowerCase())
            activeMenu();
            // setTimeout(() => {
            //     setRole(sessions.role_name.toLowerCase())
            //     activeMenu();
            // }, 3000);
        } else {
            setRole('')
            activeMenu();
        }
    }, [sessions]);


    function scrollElement(item) {
        if (item) {
            const currentPosition = item.offsetTop;
            if (currentPosition > window.innerHeight) {
                refSidebar.current.getScrollElement().scrollTop = currentPosition - 300;
            }
        }
    }

    const renderMenuItem = (role, menu) => {
        return menu?.map((val, key) => {
            if (val.role.includes(role)) {
                if (val.type === 'item_header') {
                    return (
                        <li
                            key={key}
                            className="menu-title fw-bolder text-dark">
                            {val.item}
                        </li>
                    )
                } else {
                    return (
                        <li key={key} className="ms-1 ms-0-lg">
                            <Link to={val.url}>
                                <i>
                                    {val.icon}
                                </i>
                                <span className="ms-3 mt-0">{val.item}</span>
                            </Link>
                        </li>
                    )
                }
            }
        })
    }

    return (
        <div data-simplebar className='h-100'>
            <SimpleBar className='h-100' ref={refSidebar}>
                <div id="sidebar-menu">
                    <ul className="metismenu list-unstyled" id="side-menu">
                        {renderMenuItem(role, Menu)}
                    </ul>
                </div>
            </SimpleBar>
        </div>
    );
}

export default SidebarContentAuth;
