import { useState } from "react";
import { RiBriefcaseFill, RiGraduationCapFill, RiMapPin2Fill, RiStarFill, RiStarHalfFill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Button, Card, CardBody, CardImg, CardText, CardTitle, Col, Row } from "reactstrap";
import { authSetupSelector } from "../../../app/authSlice";
import { clearReasonDivorce, setCaseId, setDataProfile, setDetailServiceConsultation, setFailedReason, setListService, setPickLawyer, setPickService, setPositionFormOrder } from "../../../app/orderSlice";
import avatar from '../../../assets/images/users/user.png';
import { UpperCaseName, checkLengthKeys, notify, parseDate } from "../../../helpers/GlobalHelper";
import ModalChooseServiceOrder from "./ModalChooseServiceOrder";
import ModalFailedBookingDivorce from "./ModalFailedBookingDivorce";
import ModalFormConsultation from "./ModalFormConsultation";
import ModalFormPersonalInformation from "./ModalFormPersonalInformation";
import ModalReasonDivorce from "./ModalReasonDivorce";
import ModalScopeStatement from "./ModalScopeStatement";
import ModalSuccessBookingDivorce from "./ModalSuccessBookingDivorce";
import ModalListPriceServices from "./ModalListPriceServices";

const ContentListLawyer = ({ data }) => {
    const [isModalShowChooseService, setIsModalShowChooseService] = useState(false);
    const [isModalShowScopeStatement, setIsModalShowScopeStatement] = useState(false);
    const [isModalFormPersonalInformation, setIsFormPersonalInformation] = useState(false);
    const [isModalReasonDivorce, setIsModalReasonDivorce] = useState(false);
    const [isModalSuccessBookDivorce, setIsModalSuccessBookDivorce] = useState(false);
    const [isModalFormConsultation, setIsModalFormConsultation] = useState(false);
    const [isModalFailedBookDivorce, setIsModalFailedBookDivorce] = useState(false);
    const [isModalListPriceService, setIsModalListPriceService] = useState(false);

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { sessions } = useSelector(authSetupSelector)

    const handlerModalChooseService = (isSetValue, param, form_type = 'canceled') => {
        const checkingObj = checkLengthKeys(sessions);
        if (!checkingObj) {
            dispatch(setPickLawyer({}))
            dispatch(setPickService(null))
            dispatch(setListService([]))
            dispatch(setPositionFormOrder('canceled'))
            dispatch(setDataProfile({}))
            dispatch(clearReasonDivorce())
            notify('warn', 30000, 'Harus login terlebih dahulu!')
            return navigate('/login')
        }

        if (sessions.role_name.toLowerCase() !== 'client') {
            dispatch(setPickLawyer({}))
            dispatch(setPickService(null))
            dispatch(setListService([]))
            dispatch(setPositionFormOrder('canceled'))
            dispatch(setDataProfile({}))
            return notify('warn', 30000, 'Hanya klien yang dapat mengorder!')
        }

        if (isSetValue) {
            if (form_type === 'choose_service') {
                dispatch(setPositionFormOrder('choose_service'))
            }

            if (form_type === 'scope_statement') {
                handlerModalScopeStatement('scope_statement')
                dispatch(setPositionFormOrder('scope_statement'))
            }

            if (checkLengthKeys(param)) {
                dispatch(setPickLawyer(param))
            }
        } else {
            dispatch(setPickLawyer({}))
            dispatch(setPickService(null))
            dispatch(setListService([]))
            dispatch(setPositionFormOrder('canceled'))
            dispatch(setDataProfile({}))
        }

        setIsModalShowChooseService(!isModalShowChooseService)
    }

    const handlerModalScopeStatement = (form_type = 'scope_statement') => {
        const checkingObj = checkLengthKeys(sessions);
        if (!checkingObj) {
            dispatch(setPickLawyer({}))
            dispatch(setPickService(null))
            dispatch(setListService([]))
            dispatch(setPositionFormOrder('canceled'))
            dispatch(setDataProfile({}))
            notify('warn', 30000, 'Harus login terlebih dahulu!')
            return navigate('/login')
        }

        if (sessions.role_name.toLowerCase() !== 'client') {
            dispatch(setPickLawyer({}))
            dispatch(setPickService(null))
            dispatch(setListService([]))
            dispatch(setPositionFormOrder('canceled'))
            dispatch(setDataProfile({}))
            return notify('warn', 30000, 'Hanya klien yang dapat mengorder!')
        }

        if (form_type === 'choose_service') {
            setIsModalShowChooseService(true)
            dispatch(setPositionFormOrder('choose_service'))
        }

        if (form_type === 'data_personal_information') {
            setIsFormPersonalInformation(true)
            dispatch(setPositionFormOrder('data_personal_information'))
        }

        if (form_type === 'scope_statement') {
            dispatch(setPositionFormOrder('scope_statement'))
        }

        setIsModalShowScopeStatement(!isModalShowScopeStatement)
    }

    const handlerFormPersonalInformation = (form_type = 'scope_statement') => {
        const checkingObj = checkLengthKeys(sessions);
        if (!checkingObj) {
            dispatch(setPickLawyer({}))
            dispatch(setPickService(null))
            dispatch(setListService([]))
            dispatch(setPositionFormOrder('canceled'))
            dispatch(setDataProfile({}))
            notify('warn', 30000, 'Harus login terlebih dahulu!')
            return navigate('/login')
        }

        if (sessions.role_name.toLowerCase() !== 'client') {
            dispatch(setPickLawyer({}))
            dispatch(setPickService(null))
            dispatch(setListService([]))
            dispatch(setPositionFormOrder('canceled'))
            dispatch(setDataProfile({}))
            return notify('warn', 30000, 'Hanya klien yang dapat mengorder!')
        }

        if (form_type === 'scope_statement') {
            setIsModalShowScopeStatement(true)
            dispatch(setPositionFormOrder('scope_statement'))
        }

        if (form_type === 'reason_divorce') {
            setIsModalReasonDivorce(true)
            dispatch(setPositionFormOrder('reason_divorce'))
        }

        setIsFormPersonalInformation(!isModalFormPersonalInformation)
    }

    const handlerFormReasonDivorce = (form_type = 'reason_divorce') => {
        const checkingObj = checkLengthKeys(sessions);
        if (!checkingObj) {
            dispatch(setPickLawyer({}))
            dispatch(setPickService(null))
            dispatch(setListService([]))
            dispatch(setPositionFormOrder('canceled'))
            dispatch(setDataProfile({}))
            notify('warn', 30000, 'Harus login terlebih dahulu!')
            return navigate('/login')
        }

        if (sessions.role_name.toLowerCase() !== 'client') {
            dispatch(setPickLawyer({}))
            dispatch(setPickService(null))
            dispatch(setListService([]))
            dispatch(setPositionFormOrder('canceled'))
            dispatch(setDataProfile({}))
            return notify('warn', 30000, 'Hanya klien yang dapat mengorder!')
        }

        if (form_type === 'data_personal_information') {
            setIsFormPersonalInformation(true)
            dispatch(setPositionFormOrder('data_personal_information'))
        }

        if (form_type === 'success') {
            handlerModalBookSuccess(false)
            dispatch(setPositionFormOrder('successfuly'))
        }

        if (form_type === 'failed') {
            handlerModalBookFailedDivorce(false)
            dispatch(setPositionFormOrder('failed'))
        }

        setIsModalReasonDivorce(!isModalReasonDivorce)
    }

    const handlerModalBookSuccess = (value) => {
        if (value) {
            dispatch(setPickLawyer({}))
            dispatch(setPickService(null))
            dispatch(setListService([]))
            dispatch(setPositionFormOrder('canceled'))
            dispatch(setDataProfile({}))
            dispatch(clearReasonDivorce())
            dispatch(setFailedReason(''))
            dispatch(setCaseId(''))
            return navigate('/case-client')
        }
        setIsModalSuccessBookDivorce(!value)
    }

    const handlerModalBookFailedDivorce = (value) => {
        if (!value) {
            dispatch(setPickLawyer({}))
            dispatch(setPickService(null))
            dispatch(setListService([]))
            dispatch(setPositionFormOrder('canceled'))
            dispatch(setDataProfile({}))
            dispatch(clearReasonDivorce())
            dispatch(setFailedReason(''))
        }
        setIsModalFailedBookDivorce(!value)
    }

    const handlerBookConsultation = (val) => {
        const checkingObj = checkLengthKeys(sessions);
        if (!checkingObj) {
            dispatch(setPickLawyer({}))
            dispatch(setDetailServiceConsultation({}))
            dispatch(setCaseId(''))
            notify('warn', 30000, 'Harus login terlebih dahulu!')
            return navigate('/login')
        }

        if (sessions.role_name.toLowerCase() !== 'client') {
            dispatch(setPickLawyer({}))
            dispatch(setDetailServiceConsultation({}))
            dispatch(setCaseId(''))
            return notify('warn', 30000, 'Hanya klien yang dapat mengorder!')
        }

        if (isModalFormConsultation) {
            dispatch(setPickLawyer({}))
            dispatch(setDetailServiceConsultation({}))
            dispatch(setCaseId(''))
        } else {
            let serviceChoosed = {}
            val.service_rates.map((valService) => {
                if (valService.category_service_name === 'Konsultasi online (chat) / jam') {
                    serviceChoosed = valService
                }
            })
            dispatch(setDetailServiceConsultation(serviceChoosed))
            dispatch(setPickLawyer(val))
        }
        setIsModalFormConsultation(!isModalFormConsultation)
    }

    const handlerShowPriceServices = (val) => {
        if (isModalListPriceService) {
            dispatch(setPickLawyer({}))
        } else {
            dispatch(setPickLawyer(val))
        }

        setIsModalListPriceService(!isModalListPriceService)
    }

    const Content = ({ val }) => {
        const avatarUser = !val.photo ? avatar : val.photo;
        const nameUser = !val.full_name ? 'user' : val.full_name;
        const educationInstituteName = !val.education.institution_name ? '-' : UpperCaseName(val.education.institution_name)
        const addressDomicile = `${val.address_domicile} ${val.province_domicile.name}`
        const upaNumber = !val.education.upa_number_certificate ? '-' : val.education.upa_number_certificate;

        return <Col xs={12} lg={6} className="mb-3">
            <Card className="border border-3 rounded rounded-4">
                <Row className="no-gutters align-items-center">
                    <Col md={4} className="text-center">
                        <CardImg
                            className="my-3 my-lg-0 ms-md-3 img-fluid w-50 d-md-none"
                            src={avatarUser}
                            alt="avatar"
                        />
                        <CardImg
                            className="my-3 my-lg-0 ms-md-3 img-fluid d-none d-md-block"
                            src={avatarUser}
                            alt="avatar"
                        />
                        <hr
                            className="border border-danger border-1 opacity-25 d-md-none"
                        />
                    </Col>
                    <Col md={8}>
                        <CardBody>
                            <CardTitle>
                                {UpperCaseName(nameUser)}
                            </CardTitle>
                            <CardText>
                                <Link
                                    to="#"
                                    onClick={
                                        () => handlerShowPriceServices(val)
                                    }
                                >
                                    Lihat Tarif
                                </Link>
                            </CardText>
                            <CardText>
                                <span className="me-2 me-lg-3">
                                    <RiStarFill className="text-warning" size={20} />
                                </span>
                                <span className="fw-bold text-dark">
                                    {val.rating}
                                </span>
                            </CardText>
                            <CardText>
                                <span className="me-2 me-lg-3">
                                    <RiBriefcaseFill size={20} />
                                </span>
                                <span>
                                    {parseDate(val.start_profession_date)}
                                </span>
                            </CardText>
                            <CardText>
                                <span className="me-2 me-lg-3">
                                    <RiGraduationCapFill size={20} />
                                </span>
                                <span>
                                    {educationInstituteName}
                                </span>
                            </CardText>
                            <CardText>
                                <span className="me-2 me-lg-3">
                                    <RiMapPin2Fill size={20} />
                                </span>
                                <span>
                                    {UpperCaseName(addressDomicile)}
                                </span>
                            </CardText>
                            {/* <CardText>
                                <span className="me-2 me-lg-3 fw-bold">
                                    No UPA:
                                </span>
                                <span>
                                    {upaNumber}
                                </span>
                            </CardText> */}
                            <Row>
                                <Col
                                    xs={12}
                                    lg={6}
                                    className="mb-3"
                                >
                                    <Button
                                        onClick={() => handlerBookConsultation(val)}
                                        color="light"
                                        block
                                        className="rounded-4"
                                    >
                                        Chat
                                    </Button>
                                </Col>
                                <Col
                                    xs={12}
                                    lg={6}
                                    className="mb-3"
                                >
                                    <Button
                                        onClick={
                                            () => handlerModalChooseService(
                                                true, val, 'choose_service'
                                            )
                                        }
                                        color="light"
                                        block
                                        className="rounded-4"
                                    >
                                        Booking
                                    </Button>
                                </Col>
                            </Row>
                        </CardBody>
                    </Col>
                </Row>
            </Card>
        </Col>
    }

    if (data.length < 1) {
        return <>
            <Col xs={12} className="text-center">
                <p>Pengacara tidak ditemukan!</p>
            </Col>
        </>
    } else {
        return <>
            {
                data.map((val, key) => (
                    <Content key={key} val={val} />
                ))
            }
            <ModalChooseServiceOrder
                isOpen={isModalShowChooseService}
                toggle={handlerModalChooseService}
            />
            <ModalScopeStatement
                isOpen={isModalShowScopeStatement}
                toggle={handlerModalScopeStatement}
            />
            <ModalFormPersonalInformation
                isOpen={isModalFormPersonalInformation}
                toggle={handlerFormPersonalInformation}
            />
            <ModalReasonDivorce
                isOpen={isModalReasonDivorce}
                toggle={handlerFormReasonDivorce}
            />
            <ModalSuccessBookingDivorce
                isOpen={isModalSuccessBookDivorce}
                toggle={handlerModalBookSuccess}
            />
            <ModalFailedBookingDivorce
                isOpen={isModalFailedBookDivorce}
                toggle={handlerModalBookFailedDivorce}
            />
            <ModalFormConsultation
                isOpen={isModalFormConsultation}
                toggle={handlerBookConsultation}
            />
            <ModalListPriceServices
                isOpen={isModalListPriceService}
                toggle={
                    () => setIsModalListPriceService(!isModalListPriceService)
                }
            />
        </>
    }
}

export default ContentListLawyer;
