import { Button } from 'reactstrap';
import { UserNavigationDropdown } from '../global/UserNavigationDropDown';
import { Link } from 'react-router-dom';

const HeaderAuth = () => {
  function tToggle() {
    var body = document.body;
    if (window.screen.width <= 998) {
      body.classList.toggle('sidebar-enable');
    } else {
      body.classList.toggle('vertical-collpsed');
      body.classList.toggle('sidebar-enable');
    }
  }

  return (
    <header
      id="page-topbar"
      className="bg-white border-bottom border-primary px-0"
    >
      <div className="navbar-header">
        <div className="d-flex">
          <div className="dropdown d-block align-self-center text-dark">
            <Button
              color="transparent"
              onClick={() => {
                tToggle();
              }}
            >
              <i className="fa fa-fw fa-bars" />
            </Button>
          </div>
        </div>
        <div className="d-flex">
          <UserNavigationDropdown hiddenNavigations={['dashboard']} />
        </div>
      </div>
    </header>
  );
};

export default HeaderAuth;
