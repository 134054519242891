import { CardHeader } from "reactstrap";

const HeaderForm = (props) => {
    const { title } = props
    return (
        <>
            <CardHeader className="d-block d-lg-none bg-white border-primary border-2 text-center">
                <img
                    className="w-25"
                    alt="logo"
                    src="/image-11@2x.png"
                />
                <p className="mb-0 mt-3 fw-bold fs-5">{title}</p>
            </CardHeader>
        </>
    );
}

export default HeaderForm;
