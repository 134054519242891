import { FormGroup, Input, Label } from "reactstrap";

const RadioGender = ({
    value = "male",
    onChange
}) => {
    return (
        <>
            <FormGroup check className="mx-3">
                <Input
                    checked={value === 'male' ? true : false}
                    type="radio"
                    value="male"
                    className="d-none d-md-block fs-5"
                    onChange={onChange}
                />
                {' '}
                <Label
                    className="d-none d-md-block fs-5"
                >
                    Laki - laki
                </Label>
                <Input
                    checked={value === 'male' ? true : false}
                    type="radio"
                    value="male"
                    className="d-block d-md-none"
                    onChange={onChange}
                />
                {' '}
                <Label
                    check
                    className="d-block d-md-none"
                >
                    Laki - laki
                </Label>
            </FormGroup>
            <FormGroup check className="mx-3">
                <Input
                    checked={value === 'female' ? true : false}
                    type="radio"
                    value="female"
                    className="d-none d-md-block fs-5"
                    onChange={onChange}
                />
                {' '}
                <Label
                    check
                    className="d-none d-md-block fs-5"
                >
                    Perempuan
                </Label>
                <Input
                    checked={value === 'female' ? true : false}
                    type="radio"
                    value="female"
                    className="d-block d-md-none"
                    onChange={onChange}
                />
                {' '}
                <Label
                    check
                    className="d-block d-md-none"
                >
                    Perempuan
                </Label>
            </FormGroup>
        </>
    )
}

export default RadioGender;
