import { useEffect, useState } from 'react';
import {
  FaChevronDown,
  FaChevronUp,
  FaRightFromBracket,
} from 'react-icons/fa6';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
} from 'reactstrap';
import { authSetupSelector, setSessions } from '../../app/authSlice';
import avatar from '../../assets/images/users/user.png';
import { usePostLogoutMutation } from '../../features/auth/api/AuthAPI';
import { SetDisplayName } from '../../helpers/GlobalHelper';
import { usePostAcceptConsultationMutation } from '../../features/user/api/UsersAPI';
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash.debounce';

export const UserNavigationDropdown = ({ hiddenNavigations = [] }) => {
  const [isToggle, setIsToggle] = useState(false);
  const [urlBackToDashboard, setUrlBackToDashboard] = useState('/');
  const [postLogout, { isSuccess: isSuccessLogout }] = usePostLogoutMutation();
  const { sessions } = useSelector(authSetupSelector);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [
    postAcceptConsultation,
    { isLoading: isPostAcceptConsultationMutationLoading },
  ] = usePostAcceptConsultationMutation();

  const isDashboardNavigationHidden = hiddenNavigations.includes('dashboard');
  const isLawyer = sessions?.role_name?.toLowerCase() === 'lawyer';
  const lawyerIsAcceptingConsultation = sessions?.accept_consultation;

  const acceptConsultation = debounce(async (e) => {
    if (e.preventDefault) e.preventDefault();
    const { data: { data: { accept_consultation } = {} } = {} } =
      await postAcceptConsultation(!lawyerIsAcceptingConsultation);
    dispatch(setSessions({ ...sessions, accept_consultation }));
  });

  useEffect(() => {
    if (isLawyer) {
      setUrlBackToDashboard('/dashboard-lawyer');
    } else {
      setUrlBackToDashboard('/dashboard-client');
    }
  }, [sessions]);

  useEffect(() => {
    if (isSuccessLogout) {
      localStorage.clear();
      location.href = location.origin;
    }
  }, [isSuccessLogout]);

  const handlerLogout = async () => {
    await postLogout().unwrap();
  };

  return (
    <Dropdown isOpen={isToggle} toggle={() => setIsToggle(!isToggle)}>
      <DropdownToggle
        className="btn header-item"
        id="page-header-user-dropdown"
        tag="button"
      >
        <img
          className="rounded-circle header-profile-user border-success"
          src={!sessions.photo ? avatar : sessions.photo}
          style={lawyerIsAcceptingConsultation ? { border: 'solid 4px' } : {}}
          alt="Header Avatar"
        />
        <span className="d-none d-xl-inline-block ms-2 me-1">
          {!sessions.full_name ? 'user' : SetDisplayName(sessions.full_name)}
        </span>
        {isToggle ? (
          <FaChevronUp className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        ) : (
          <FaChevronDown className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        )}
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-end">
        <DropdownItem className="d-block d-lg-none">
          {!sessions.full_name ? 'user' : SetDisplayName(sessions.full_name)}
        </DropdownItem>
        <div className="d-block d-lg-none dropdown-divider" />
        {!isDashboardNavigationHidden && (
          <DropdownItem onClick={() => navigate(urlBackToDashboard)}>
            Dashboard
          </DropdownItem>
        )}
        {isLawyer && (
          <DropdownItem onClick={acceptConsultation}>
            Accept Consultation{' '}
            <FormGroup switch>
              <Input
                type="switch"
                checked={lawyerIsAcceptingConsultation}
                onChange={acceptConsultation}
              />
            </FormGroup>
          </DropdownItem>
        )}

        <DropdownItem
          tag="button"
          onClick={handlerLogout}
          className="dropdown-item text-primary"
        >
          <FaRightFromBracket
            size={16}
            className="align-middle me-2 text-danger"
          />
          <span>Logout</span>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};
