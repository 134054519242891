import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { orderSetupSelector, setModalCase } from '../../../app/orderSlice';
import { Button, Modal, ModalBody } from 'reactstrap';
import { usePostRefreshTokenMutation } from '../../auth/api/AuthAPI';
import { authSetupSelector, setRefreshToken, setSessions, setToken } from '../../../app/authSlice';
import { usePostCancelOrderMutation } from '../api/CaseAPI';
import { notify } from '../../../helpers/GlobalHelper';

const ModalCanceledOrder = () => {
    const { refresh_token } = useSelector(authSetupSelector)

    const {
        is_modal_canceled_order,
        detail_order_service
    } = useSelector(orderSetupSelector)

    const dispatch = useDispatch();
    const [postRefreshToken] = usePostRefreshTokenMutation();
    const [postCancelOrder,
        {
            isLoading: loadingCancelOrder,
            isSuccess: successCancelOrder,
            isError: isErrorCancelOrder,
            error: errorCancelOrder
        }
    ] = usePostCancelOrderMutation();


    const handlerRefreshSession = async () => {
        const resultGenerateNewToken = await postRefreshToken({ refresh_token }).unwrap()
        const dataSessions = resultGenerateNewToken.data;

        localStorage.setItem('data', JSON.stringify(dataSessions))
        dispatch(setSessions(dataSessions))

        localStorage.setItem('refreshToken', resultGenerateNewToken.refreshToken)
        localStorage.setItem('token', resultGenerateNewToken.token)

        dispatch(setToken(resultGenerateNewToken.token))
        dispatch(setRefreshToken(resultGenerateNewToken.refreshToken))
        return true
    }

    const handlerCancelOrder = async () => {
        try {
            await handlerRefreshSession()
            const param = {
                case_id: detail_order_service.case_id
            };
            await postCancelOrder(param).unwrap()

        } catch (error) {
            console.warn("error cancel order:", error.data.message)
            notify('error', 30000, `error message: ${error.data.message}`);
        }
    }

    useEffect(() => {
        if (successCancelOrder) {
            notify('success', 30000, 'Berhasil membatalkan order');
            dispatch(setModalCase({ isActive: !is_modal_canceled_order }))
        }
    }, [successCancelOrder]);

    useEffect(() => {
        if (isErrorCancelOrder) {
            notify('error', 30000, errorCancelOrder.data.message);
        }
    }, [isErrorCancelOrder, errorCancelOrder]);

    return (
        <Modal
            isOpen={is_modal_canceled_order}
            toggle={() => dispatch(setModalCase({ isActive: !is_modal_canceled_order }))}
            centered
            keyboard={false}
            backdrop={false}
            className="border-0 custom-backdrop"
            size='md'
        >
            <ModalBody>
                <div className="text-center fw-bold mb-2">
                    Batalkan Booking
                </div>
                <div className="border-bottom mb-3"></div>
                <div className="text-center mb-2">
                    Apakah anda yakin ingin membatalkan booking?
                </div>
                <div className='d-flex justify-content-center gap-2'>
                    <Button
                        outline
                        type="button"
                        color="secondary"
                        className="rounded-3"
                        size="sm"
                        onClick={() => dispatch(setModalCase({ isActive: !is_modal_canceled_order }))}
                        block
                        disabled={loadingCancelOrder}
                    >
                        Kembali
                    </Button>
                    <Button
                        onClick={handlerCancelOrder}
                        outline
                        type="button"
                        color="danger"
                        className="rounded-3"
                        size="sm"
                        block
                        disabled={loadingCancelOrder}
                    >
                        {loadingCancelOrder ? 'Memproses...' : 'Ya'}
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    );
}

export default ModalCanceledOrder;
