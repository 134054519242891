import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';

const ButtonMyService = (
    {
        iconImg,
        title = '-',
        to = '#',
        type = "in-link"
    }
) => {
    const navigate = useNavigate();
    const handlerClickedWidget = (link, type) => {
        if (type === 'in-link') {
            if (link !== '#') {
                navigate(link)
            }
        } else {
            if (link !== '#') {
                window.open(link, '_blank', 'noopener,noreferrer');
            }
        }
    }
    return (
        <Card
            onClick={() => handlerClickedWidget(to, type)}
            className="border border-3 rounded-4 cursor-pointer"
        >
            <CardBody>
                <div
                    className="text-center mb-3 align-items-center">
                    <img
                        src={iconImg}
                        alt="image"
                        className="avatar-md rounded-circle"
                    />
                    <p className="mt-3 mb-0 fs-6 fw-bold">
                        {title}
                    </p>
                </div>
            </CardBody>
        </Card>
    );
}

export default ButtonMyService;
