import { useEffect, useState } from "react";
import { FaPen } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Col } from "reactstrap";
import { authSetupSelector } from "../../app/authSlice";
import { orderSetupSelector, setActiveProgressTimeline, setIsRefetchingListProgressTimeline } from "../../app/orderSlice";
import { useGetListProgressTimelineQuery } from "../../features/case/api/CaseAPI";
import { UpperCaseName, checkLengthKeys, reformatDate } from "../../helpers/GlobalHelper";
import ModalChooseContentProgressTimeline from "../modal/ModalChooseContentProgressTimeline";
import ModalValidateCreateProgressTimeline from "../modal/ModalValidateCreateProgressTimeline";

function TimelineSidebar() {
  const [DataListProgress, setDataListProgress] = useState([]);
  const [isModalChooseProgressTimeline, setIsModalChooseProgressTimeline] = useState(false);
  const [isModalValidateProgressTimeline, setIsModalValidateProgressTimeline] = useState(false);
  const [isShowTopContentProgress, setIsShowTopContentProgress] = useState(false);

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const {
    token,
    sessions,
  } = useSelector(authSetupSelector)

  const {
    detail_order_service,
    is_progress_timeline_active,
    is_refetching_list_progress_timeline,
    room_id,
  } = useSelector(orderSetupSelector)

  const {
    data: dataListProgressTimeline,
    isLoading: loadingListProgressTimeline,
    refetch: refetchListProgressTimeline
  } = useGetListProgressTimelineQuery(
    {
      id_order_service: detail_order_service.id,
    },
    {
      refetchOnMountOrArgChange: false,
      refetchOnReconnect: true
    });

  const handlerShowCategoryProgressTimeline = (condition) => {
    if (condition === 'show_validation') {
      setIsModalValidateProgressTimeline(true)
    }
    setIsModalChooseProgressTimeline(!isModalChooseProgressTimeline)
  }

  const handleShowValidateCreateProgressTimeline = (condition, id_progress_active) => {
    if (!condition) {
      setIsModalChooseProgressTimeline(true)
    }

    if (condition === 'created') {
      dispatch(setActiveProgressTimeline(id_progress_active))
      const role = sessions.role_name.toLowerCase();
      const link = `/case-${role}/detail-progress-timeline/`;
      navigate(link, {
        state: {
          data_room_id: room_id,
          idProgressTimeline: id_progress_active,
          data_detail_order_service: detail_order_service,
        }
      })
    }
    setIsModalValidateProgressTimeline(false)
  }

  const handlerRefetchingList = async () => {
    refetchListProgressTimeline()
    dispatch(setIsRefetchingListProgressTimeline(false))
  }

  const handlerChooseProgressTimeline = (val) => {
    dispatch(setActiveProgressTimeline(val.id))
    const role = sessions.role_name.toLowerCase();

    const link = `/case-${role}/detail-progress-timeline/`;
    navigate(link, {
      state: {
        data_room_id: room_id,
        idProgressTimeline: val.id,
        data_detail_order_service: detail_order_service,
      }
    })
  }

  const ContentTopProgressTimeline = () => {
    return <Button
      className="bg-primary border-0 px-5 my-4"
      block
      onClick={handlerShowCategoryProgressTimeline}
    >
      Tambah Progress
    </Button>
  }

  const ContentListProgress = ({ data, isActiveProgress }) => {
    if (data.length < 1) {
      return <span className="d-flex justify-content-center fst-italic text-primary fw-lighter">
        Belum ada progress
      </span>
    } else {
      return data.map((val, key) => {
        const isShowPen = val.id === isActiveProgress
        const conditionTextProgress = val.id === isActiveProgress ? 'text-primary' : 'text-black-50'
        return <div
          key={key}
          className="d-flex cursor-pointer"
          onClick={() => handlerChooseProgressTimeline(val)}
        >
          <div className="line_progress_indicator">
            <span></span>
          </div>
          <div className="d-flex w-100 align-items-center justify-content-between"
          >
            <div className="d-flex flex-column border-bottom w-100 py-3">
              <span
                className={`${conditionTextProgress} font-size-14 fw-bold`}
              >
                {UpperCaseName(val.custom_title)}
              </span>
              <span className="text-black-50">
                {reformatDate(val.created_at, 'll')} • {reformatDate(val.created_at, 'HH:mm:ss')} WIB
              </span>
            </div>
            {
              isShowPen
              &&
              <FaPen
                size={18}
                color='#E91E23'
              />
            }
          </div>
        </div>
      })
    }
  }

  useEffect(() => {
    if (!dataListProgressTimeline) {
      setDataListProgress([])
    } else {
      const { status } = dataListProgressTimeline;
      if (status === 'success') {
        setDataListProgress(dataListProgressTimeline.data)
      } else {
        setDataListProgress([])
      }
    }
  }, [dataListProgressTimeline]);

  useEffect(() => {
    const checLengthKey = checkLengthKeys(detail_order_service)
    if (checLengthKey) {
      if (sessions.role_name.toLowerCase() === 'lawyer') {
        if (['progress'].includes(detail_order_service.category_order_service_name)) {
          setIsShowTopContentProgress(true)
        } else {
          setIsShowTopContentProgress(false)
        }
      } else {
        setIsShowTopContentProgress(false)
      }
      handlerRefetchingList()
    }
  }, [detail_order_service]);

  useEffect(() => {
    if (is_refetching_list_progress_timeline) {
      handlerRefetchingList()
    }
  }, [is_refetching_list_progress_timeline]);

  useEffect(() => {
    if (token) {
      handlerRefetchingList()
    }
  }, [token]);

  return (
    <>
      <aside className="bg-white h-100 p-4 mb-5">
        <div className="timeline mb-6 mb-lg-0">
          <h4 className="font-size-16 mb-5">Progress Timeline</h4>
          {/* {sessions?.role_name?.toLowerCase() === 'lawyer' && <ContentTopProgressTimeline />} */}
          {isShowTopContentProgress && <ContentTopProgressTimeline />}
          <Col className="overflow-auto mt-1 timeline">
            {loadingListProgressTimeline &&
              <span className="my-3">
                Sedang Memuat Data...
              </span>
            }
            {
              !loadingListProgressTimeline
              &&
              <ContentListProgress data={DataListProgress} isActiveProgress={is_progress_timeline_active} />
            }
          </Col>
        </div>
      </aside>
      <ModalChooseContentProgressTimeline
        isOpen={isModalChooseProgressTimeline}
        toggle={(condition) => handlerShowCategoryProgressTimeline(condition)}
      />
      <ModalValidateCreateProgressTimeline
        isOpen={isModalValidateProgressTimeline}
        toggle={
          (condition, id_progress_active) => handleShowValidateCreateProgressTimeline(condition, id_progress_active)
        }
      />
    </>
  );
}
export default TimelineSidebar;
