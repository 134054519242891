import { Card, CardBody, CardText, Col, Row } from "reactstrap";

const ContentSuccessActivationLawyer = () => {
    return (
        <Row className="justify-content-center align-items-center px-2 min-vh-100 bg-transparent">
            <Col xs={12} className="justify-content-center align-items-center text-center">
                <img
                    className="img-content"
                    alt="appreciation"
                    src="appreciation.svg"
                />
                <Card className="bg-transparent shadow-none">
                    <CardBody className="bg-transparent">
                        <CardText className="text-secondary fw-normal">
                            Berhasil mengkonfirmasi
                        </CardText>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
}

export default ContentSuccessActivationLawyer;
