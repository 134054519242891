import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { HiOutlineEyeOff } from "react-icons/hi";
import { HiOutlineEye, HiOutlineLockClosed } from "react-icons/hi2";
import { useSelector } from "react-redux";
import { Button, Col, Form, Input, InputGroup, InputGroupText, Label, Modal, ModalBody, Row } from "reactstrap";
import * as yup from "yup";
import YupPassword from "yup-password";
import { authSetupSelector } from "../../../app/authSlice";
import { usePostChangeForgotPasswordMutation } from "../../auth/api/AuthAPI";
import { notify } from "../../../helpers/GlobalHelper";
YupPassword(yup)

const FormChangePassword = ({
    isModal,
    handlerToggle
}) => {
    const [isShowPassword, setIsShowPassword] = useState(false);
    const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);
    const { sessions } = useSelector(authSetupSelector);

    const [
        postChangeForgotPassword,
        {
            isLoading: isLoadingChangePassword,
            isSuccess: isSuccessChangePassword,
            isError: isErrorChangePassword,
            error: errorChangePassword
        }
    ] = usePostChangeForgotPasswordMutation()

    const schema = yup.object({
        password: yup.string().minUppercase(1, "Minimal 1 huruf kapital!").minLowercase(1, "Minimal 1 huruf kecil!").minNumbers(1, "Minimal 1 angka!").minSymbols(1, "Minimal 1 simbol: _-#1@&^$").min(8, "Minimal panjang 8 karakter!"),
        confirmation_password: yup.string()
            .required('Konfirmasi kata sandi wajib di isi!')
            .oneOf([yup.ref('password'), null], 'Konfirmasi kata sandi tidak sama dengan kata sandi')
    })

    const {
        control,
        setValue,
        handleSubmit,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            password: "",
            confirmation_password: "",
        },
    });

    const handleOnSubmit = async (param) => {
        param.email = sessions.email;
        try {
            await postChangeForgotPassword(param).unwrap();
        } catch (error) {
            return notify('error', 50000, error.data.message);
        }
    }

    useEffect(() => {
        if (isSuccessChangePassword) {
            setTimeout(() => {
                setValue("password", "")
                setValue("confirmation_password", "")
                notify('success', 30000, 'Berhasil merubah kata sandi');
                handlerToggle()
            }, 100);
        }
    }, [isSuccessChangePassword]);

    useEffect(() => {
        if (isErrorChangePassword) {
            notify('error', 30000, errorChangePassword.message);
        }
    }, [isErrorChangePassword, errorChangePassword]);

    return (
        <Modal
            isOpen={isModal}
            toggle={handlerToggle}
            keyboard={false}
            backdrop={false}
            className="custom-backdrop"
            centered
        >
            <ModalBody>
                <div className="text-center fw-bold mb-2">
                    Ubah Password
                </div>
                <div className="text-center mb-2">
                    Silakan masukan kata sandi baru Anda
                </div>
                <Form onSubmit={handleSubmit(handleOnSubmit)}>
                    <Row>
                        <Col xs={12} className="mb-3">
                            <Label>
                                Kata Sandi
                                <span className="text-orange">
                                    *
                                </span>
                            </Label>
                            <Controller
                                name="password"
                                control={control}
                                render={({ field: { onChange, onBlur, value } }) => {
                                    return (
                                        <InputGroup>
                                            <InputGroupText
                                                className={`bg-transparent border-end-0 text-start justify-content-end rounded-3 rounded-end-0 ${errors.password && 'border-orange'}`}
                                            >
                                                <HiOutlineLockClosed size={16} />
                                            </InputGroupText>
                                            <Input
                                                name="password"
                                                bsSize="sm"
                                                className={`border-start-0 border-end-0 rounded-3 rounded-start-0 rounded-end-0 text-start ${errors.password && 'border-orange'}`}
                                                type={isShowPassword ? 'text' : 'password'}
                                                placeholder="Masukan kata sandi baru"
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                value={value}
                                            />
                                            <InputGroupText className={`bg-transparent border-start-0 text-start justify-content-end rounded-3 rounded-start-0 ${errors.password && 'border-orange'}`} style={{ 'cursor': 'pointer' }} onClick={
                                                () => {
                                                    setIsShowPassword(!isShowPassword)
                                                }}>
                                                {isShowPassword ?
                                                    <HiOutlineEyeOff size={16} />
                                                    :
                                                    <HiOutlineEye size={16} />
                                                }
                                            </InputGroupText>
                                        </InputGroup>
                                    )
                                }}
                            />
                            <div className="mt-2">
                                <span className="text-orange text-start">
                                    {errors.password?.message}
                                </span>
                            </div>
                        </Col>
                        <Col xs={12} className="mb-3">
                            <Label>
                                Konfirmasi Kata Sandi
                                <span className="text-orange">
                                    *
                                </span>
                            </Label>
                            <Controller
                                name="confirmation_password"
                                control={control}
                                render={({ field: { onChange, onBlur, value } }) => {
                                    return (
                                        <InputGroup>
                                            <InputGroupText
                                                className={`bg-transparent border-end-0 text-start justify-content-end rounded-3 rounded-end-0 ${errors.password && 'border-orange'}`}
                                            >
                                                <HiOutlineLockClosed size={16} />
                                            </InputGroupText>
                                            <Input
                                                name="password"
                                                bsSize="sm"
                                                className={`border-start-0 border-end-0 rounded-3 rounded-start-0 rounded-end-0 text-start ${errors.password && 'border-orange'}`}
                                                type={isShowConfirmPassword ? 'text' : 'password'}
                                                placeholder="Masukan ulang kata sandi baru"
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                value={value}
                                            />
                                            <InputGroupText className={`bg-transparent border-start-0 text-start justify-content-end rounded-3 rounded-start-0 ${errors.password && 'border-orange'}`} style={{ 'cursor': 'pointer' }} onClick={
                                                () => {
                                                    setIsShowConfirmPassword(!isShowConfirmPassword)
                                                }}>
                                                {isShowConfirmPassword ?
                                                    <HiOutlineEyeOff size={16} />
                                                    :
                                                    <HiOutlineEye size={16} />
                                                }
                                            </InputGroupText>
                                        </InputGroup>
                                    )
                                }}
                            />
                            <div className="mt-2">
                                <span className="text-orange text-start">
                                    {errors.confirmation_password?.message}
                                </span>
                            </div>
                        </Col>
                        <Col xs={12}>
                            <div className="mb-2">
                                <Button type="submit"
                                    block
                                    color="danger"
                                    className="rounded-3"
                                    size="sm"
                                    disabled={isLoadingChangePassword}
                                >
                                    {!isLoadingChangePassword ? 'Ubah' : 'Memproses...'}
                                </Button>
                            </div>
                            <div className="mb-2">
                                <Button
                                    onClick={() => handlerToggle()}
                                    type="button"
                                    block
                                    size="sm"
                                    color="secondary"
                                    className="rounded-3"
                                    disabled={isLoadingChangePassword}
                                >
                                    Batal
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </ModalBody>
        </Modal>
    );
}

export default FormChangePassword;
