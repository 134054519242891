import { notify } from '../../helpers/GlobalHelper';
import {
  updateDetailCase,
  updateExistingBubbleChat,
  updateListBubbleChat,
} from './ClientUpdateState';

const ClientListner = (Socket) => {
  Socket.on('message', (data) => {
    updateListBubbleChat(data);
  });
  Socket.on('case_detail.updated', (data) => {
    updateDetailCase(data);
  });
  Socket.on('message.updated', (data) => {
    updateExistingBubbleChat(data);
  });
  Socket.on('processing.alert', (data) => {
    console.info('alert_socket_processing:', data);
    if (data?.message) {
      notify('warn', 3000, data?.message);
    }
  });
};

export default ClientListner;
