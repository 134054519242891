import { FaFile, FaFileDownload, FaFileUpload } from "react-icons/fa";
import { Badge, Button, Col, Label, Row, Table } from "reactstrap";
import EmptyFile from "../../../assets/images/empty-file.png";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { orderSetupSelector } from "../../../app/orderSlice";
import { UpperCaseName } from "../../../helpers/GlobalHelper";
import { BsFolder } from "react-icons/bs";
import ModalApproveEndCaseDivorce from "./ModalApproveEndCaseDivorce";
import ModalRejectEndCaseDivorce from "./ModalRejectEndCaseDivorce";

const FormConfirmationEndCase = ({ dataDetailProgress }) => {
    const [dataFile, setDataFile] = useState([]);
    const [isUpdating, setIsUpdating] = useState(false);
    const [statusEndCase, setStatusEndCase] = useState('Belum Dikonfirmasi');
    const [reasonRejected, setReasonReject] = useState('');
    const [isModalReject, setIsModalReject] = useState(false);
    const [isModalApprove, setIsModalApprove] = useState(false);

    const {
        detail_order_service,
    } = useSelector(orderSetupSelector)

    const handlerRejected = () => {
        setIsModalReject(!isModalReject)
    }

    const handlerApprove = () => {
        setIsModalApprove(!isModalApprove)
    }

    const ContentListFile = ({ dataFile }) => {
        if (dataFile.length > 0) {
            return <div className="table-responsive">
                <Table className="table mt-4">
                    <thead className="table-primary">
                        <tr>
                            <th className="fw-bold ps-5 text-dark opacity-75">
                                Dokumen
                            </th>
                            <th className="fw-bold ps-5 text-center text-dark opacity-75">
                                Tipe File
                            </th>
                            <th className="fw-bold text-center text-dark opacity-75"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {dataFile?.map((val) => {
                            return <tr key={val.id}>
                                <td className="ps-5 py-3 fw-semibold">
                                    {!val.file_name ? '-' : val.file_name}
                                </td>
                                <td className="ps-5 text-center py-3 fw-semibold">
                                    {!val.extension_file_uploaded ? '-' : val.extension_file_uploaded}
                                </td>
                                <td className="py-3">
                                    <div className="d-flex gap-3 justify-content-center">
                                        {
                                            val.url
                                            &&
                                            <FaFileDownload
                                                onClick={() => {
                                                    const redirectUrl = window.open();
                                                    redirectUrl.location.href = val.url;
                                                }}
                                                size={24}
                                                className="cursor-pointer text-primary"
                                                title="download file ini"
                                            />
                                        }

                                        {
                                            !val.url
                                            &&
                                            <FaFile
                                                size={24}
                                                className="text-secondary"
                                                title="belum ada file"
                                            />
                                        }
                                    </div>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </Table>
            </div>
        } else {
            return <div className="text-center d-flex justify-content-center flex-column align-items-center">
                <img src={EmptyFile} className="empty-file" />
                <span>Tidak ada dokumen</span>
            </div>
        }
    }

    const ContentButton = () => {
        return <div className="d-flex justify-content-between gap-3 mt-3">
            <Button
                onClick={handlerRejected}
                color="primary"
                className="btn-orange"
                block
            >
                Tolak
            </Button>
            <Button
                onClick={handlerApprove}
                color="primary"
                block
            >
                Terima
            </Button>
        </div>
    }

    // useEffect(() => {
    //     if (detail_order_service.category_order_service_name === 'request_end_case_to_client') {
    //         setIsUpdating(true);
    //     } else {
    //         setIsUpdating(false);
    //     }
    // }, [detail_order_service]);

    useEffect(() => {
        if (detail_order_service.category_order_service_name === 'request_end_case_to_client') {
            if (!dataDetailProgress.status_confirmation) {
                if (dataDetailProgress.reason_confirmation.length > 0) {
                    setIsUpdating(false)
                    setStatusEndCase('Telah ditolak')
                    setReasonReject(dataDetailProgress.reason_confirmation)
                } else {
                    setIsUpdating(true)
                    setStatusEndCase('Belum dikonfirmasi')
                    setReasonReject('')
                }
            } else {
                setIsUpdating(false)
                setStatusEndCase('Telah diterima')
                setReasonReject('')
            }
        } else {
            if (!dataDetailProgress.status_confirmation) {
                if (dataDetailProgress.reason_confirmation.length > 0) {
                    setIsUpdating(false)
                    setStatusEndCase('Telah ditolak')
                    setReasonReject(dataDetailProgress.reason_confirmation)
                } else {
                    setIsUpdating(false)
                    setStatusEndCase('Belum dikonfirmasi')
                    setReasonReject('')
                }
            } else {
                setIsUpdating(false)
                setStatusEndCase('Telah diterima')
                setReasonReject('')
            }
        }
        setDataFile(dataDetailProgress.file)
    }, [dataDetailProgress, detail_order_service]);

    return (
        <>
            <Row>
                <div className="pb-1 fw-bold mt-2 font-size-14">
                    {UpperCaseName(dataDetailProgress?.custom_title)}
                </div>
                <div className="mt-3">
                    <span>
                        Status:{' '}
                        <Badge color="info" className="my-0 font-size-12 px-2">
                            {statusEndCase}
                        </Badge>
                    </span>
                </div>
                {
                    reasonRejected.length > 0
                    &&
                    <div className="mt-3">
                        <span>Alasan: {reasonRejected}</span>
                    </div>
                }
            </Row>
            <Row className="mt-4">
                <div className="d-flex align-items-center gap-3">
                    <BsFolder size={20} color="#F54748" />
                    <span className="font-size-14 fw-bold">Dokumen Terlampir</span>
                </div>
                <ContentListFile dataFile={dataFile} />
            </Row>
            <Row className='mt-4'>
                <Col xs={12}>
                    <Label for="description">
                        <span className="font-size-14 fw-bold">
                            Deskripsi
                        </span>
                    </Label>
                </Col>
                <Col xs={12}>
                    <p className='fst-light'>
                        {dataDetailProgress?.description}
                    </p>
                </Col>
            </Row>
            {isUpdating && <ContentButton />}

            <ModalApproveEndCaseDivorce
                isOpen={isModalApprove}
                toggle={() => handlerApprove()}
            />

            <ModalRejectEndCaseDivorce
                isOpen={isModalReject}
                toggle={() => handlerRejected()}
            />
        </>
    );
}

export default FormConfirmationEndCase;
