import React from "react";
import { CardTitle, Input, Row } from "reactstrap";

/**
 *
 * @param {{setTitle: React.Dispatch<React.SetStateAction<string>>, title: string, hideNote: boolean, isPreview: boolean}} param0
 * @returns
 */

function TitleDocument({
  setTitle,
  title,
  hideNote,
  isPreview = false,
  docReqFiles,
}) {
  return !docReqFiles ? (
    <>
      <CardTitle className="fw-semibold font-size-16">Judul Progress</CardTitle>
      <Row>
        <div className="pb-1 border-bottom border-primary mt-4">
          <Input
            placeholder={"Judul Progress"}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="border-0 p-0 py-2 bg-white"
            disabled={isPreview}
          />
        </div>
        {hideNote || isPreview ? null : (
          <div className="font-size-10 py-2 text-secondary">
            *Judul dapat Anda rubah, contoh: Daftar Gugatan 1
          </div>
        )}
      </Row>
    </>
  ) : (
    <>
      <div className="mb-5">
        <div className="d-flex flex-column gap-2">
          <Input
            placeholder={"Judul Progress"}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="border-0 p-0 py-2 bg-white fw-semibold"
            // disabled={isPreview}
          />
          <span className="font-size-12">
            Masukkan dokumen yang Anda butuhkan dari klien
          </span>
        </div>
      </div>
    </>
  );
}

export default TitleDocument;
