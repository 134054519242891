export const caseHeaderTableMenus = [
  {
    key: "case_id",
    value: "Kasus ID",
  },
  {
    key: "updated_at",
    value: "Tanggal",
  },
  {
    key: "client_name",
    value: "Klien",
  },
  {
    key: "client_city_domicile",
    value: "Domisili",
  },
  {
    key: "category_order_service",
    value: "Status Kasus",
  },
  {
    key: "tools",
    value: "Tools",
  },
];

export const caseHeaderTableTransactionsMenu = [
  {
    key: "case_id",
    value: "Kasus ID",
  },
  {
    key: "client_name",
    value: "Nama Klien",
  },
  {
    key: "service_name",
    value: "Jenis Layanan",
  },
  {
    key: "price_service",
    value: "Nilai Transaksi",
  },
  {
    key: "status_transaction",
    value: "Status Transaksi",
  },
  {
    key: "tools",
    value: "Aksi",
  },
];

export const historyHeaderTableTransactionMenu = [
  {
    key: "payout_name",
    value: "Jenis Pembayaran",
  },
  {
    key: "amount",
    value: "Nominal",
  },
  {
    key: "percent_amount",
    value: "Persentase Nominal",
  },
  {
    key: "tax_service",
    value: "Biaya Layanan",
  },
  {
    key: "date_request",
    value: "Tanggal Pengajuan",
  },
  {
    key: "status",
    value: "Status",
  },
  {
    key: "action",
    value: "Aksi",
  },
];
