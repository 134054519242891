import React from "react";
import { Card, CardBody, Collapse, Row, Table } from "reactstrap";
import { BsFolder } from "react-icons/bs";
import PropsTypes from "prop-types";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import {
  AiFillDelete,
  AiOutlineDelete,
  AiOutlineDownload,
} from "react-icons/ai";
import EmptyFile from "../../../assets/images/empty-file.png";

/**
 *
 * @param {{data: File[]}} param0
 * @returns
 */

function DocAttachment({
  data,
  setIsOpen,
  isOpen,
  onDeleteFile,
  isPreview,
  title,
}) {
  const toggle = () => setIsOpen(!isOpen);

  if (title.includes("Request Berkas/File")) {
    return (
      <div>
        <div className="d-flex align-items-center gap-2 ">
          <BsFolder size={28} color="#F54748" />
          <span className="font-size-14 fw-semibold">Dokumen Terlampir</span>
        </div>
        <Table responsive className="mt-4">
          <thead className="table-primary">
            <tr>
              <th className="fw-bold text-dark opacity-75">
                Dokumen
              </th>
              <th className="fw-bold text-center text-dark opacity-75">
                Tipe
              </th>
              <th className="fw-bold text-dark opacity-75"></th>
            </tr>
          </thead>
          <tbody>
            {data?.map((val, index) => {
              return (
                <tr key={"doc_file_" + index}>
                  <td className="ps-5 py-3 fw-semibold">{val?.docName}</td>
                  <td className="py-3 text-center fw-semibold">{`${val?.docType?.isPdf ? "PDF " : ""
                    }${val?.docType?.isImg ? " Image " : ""}${val?.docType?.isVideo ? " Video" : ""
                    }`}</td>
                  <td className="py-3">
                    <div className="d-flex gap-1 gap-md-3 justify-content-center">
                      <AiOutlineDownload
                        onClick={() => {
                          const file = window.open();
                          file.location.href =
                            "https://cdn2.hubspot.net/hubfs/53/tools/state-of-marketing/dummy.pdf?scriptPath=bundles%2Fapp.js&inpageEditorUI=true&cssPath=bundles%2Fapp.css&hubs_content=www.hubspot.jp%2Fflywheel&title=Direct_mail_marketing&hubs_signup-cta=null&videoHeight=360&videoWidth=640&_preview=true&__hstc=175426365.e73b5866dc0b9bff67459efb52581f72.1550229414908.1550229414908.1550229414908.1&portalId=53&benderPackage=InpageEditorUI&staticVersion=static-1.22392&wmode=transparent&controlsVisibleOnLoad=true&redirect=no&preview_key=pSOAWoAu&hs_amp=true&playerColor=688AAD&hubs_signup-url=preview.hs-sites.com%2F_hcms%2Fpreview%2Fcontent%2F5241498400&version=v1&cacheBust=1594720451930&hubs_content-cta=%E3%83%98%E3%83%AB%E3%83%97%20%E3%83%87%E3%82%B9%E3%82%AF%20%E3%82%BD%E3%83%95%E3%83%88%E3%82%A6%E3%82%A7%E3%82%A2&__hssc=175426365.30.1550745867488&t=48s&v=jNsvxGAd__0&autoPlay=true&__hsfp=911730534";
                        }}
                        size={24}
                      />
                      <AiOutlineDelete
                        onClick={() => onDeleteFile(val)}
                        size={24}
                        color="red"
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  }

  return (
    <Row className="my-4">
      <div className="d-flex align-items-center gap-3">
        <BsFolder size={28} color="#F54748" />
        <span className="font-size-14 fw-semibold">Dokumen Terlampir</span>
      </div>
      <div
        className="dokumen_detail_box mt-4 mx-md-3 mb-1 d-flex justify-content-between"
        onClick={() => {
          if (!isPreview) {
            toggle?.();
          }
        }}
      >
        <span>Dokumen</span>
        {/* {isPreview ? null : isOpen ? (
          <IoIosArrowUp size={24} />
        ) : (
          <IoIosArrowDown size={24} />
        )} */}
      </div>
      <Collapse isOpen={isPreview ? true : isOpen} className="px-0 mx-md-3">
        <Card>
          <CardBody>
            {!data.length ? (
              <div className="text-center d-flex justify-content-center flex-column align-items-center">
                {isPreview ? (
                  <img src={EmptyFile} className="empty-file" />
                ) : null}
                <span>Belum ada dokumen</span>
              </div>
            ) : (
              data.map((item, index) => {
                return (
                  <div
                    className="p-2 border-1 border-secondary border-bottom d-flex justify-content-between"
                    key={index}
                  >
                    <span
                      onClick={() => {
                        if (item) {
                          const pdfWindow = window.open();
                          pdfWindow.location.href = item?.path;
                        }
                      }}
                    >
                      {item.name}
                    </span>
                    {isPreview ? (
                      <AiOutlineDownload
                        onClick={() => {
                          if (item) {
                            const pdfWindow = window.open();
                            pdfWindow.location.href = item?.path;
                          }
                        }}
                        size={24}
                      />
                    ) : (
                      <AiFillDelete
                        onClick={() => onDeleteFile(index)}
                        size={24}
                      />
                    )}
                  </div>
                );
              })
            )}
          </CardBody>
        </Card>
      </Collapse>
    </Row>
  );
}

DocAttachment.propTypes = {
  data: PropsTypes.array,
  setIsOpen: PropsTypes.func,
  isOpen: PropsTypes.bool,
  onDeleteFile: PropsTypes.func,
};

export default DocAttachment;
