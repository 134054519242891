import React, { useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { BreadcrumbItem, Button, Col, Row } from "reactstrap";
import { changeCurrentProgress } from "../../app/timeline";
import { progressMenuList } from "../../docs/text";
import ProgressMenuCard from "../card/ProgressMenu";
import ModalMenu from "../modal/ModalMenu";
import TimelineSidebar from "./TimelineSidebar";

const CaseLayout = ({
  breadcrumbTitle,
  isChat,
  children,
  handlerBackTo,
  listTimeline = [],
  mode,
  roleType = "client",
}) => {
  const dispatch = useDispatch();
  const progressTimeline = useSelector((state) => state.progressSetup);
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);

  const handleAddProgress = () => {
    setModal(!modal);
  };
  const handleClickChat = () => {
    navigate("/chat-view");
  };
  const handleClickSidebar = (item) => {
    navigate("/timeline-details-client", { state: { item, isPreview: true } });
    dispatch(changeCurrentProgress(item));
  };

  const handlerBackToList = () => {
    handlerBackTo()
  }

  return (
    <>
      <div className="page-content w-100 timeline overflow-auto pb-md-3 h-100 row gx-0 gx-sm-3">
        <Col lg={12} xl={9} className="mb-4">
          <div>
            <div
              className={`d-flex align-items-center gap-1 px-3 py-1 ${isChat ? "bg-white border border-2" : ""
                } `}
            >
              {/* <Link to={backTo}>
                <IoIosArrowBack size={20} color="#495057" />
              </Link> */}
              <Button
                onClick={handlerBackToList}
                color="transparent"
                className="me-0 px-0"
              >
                <IoIosArrowBack size={20} color="#495057" />
              </Button>
              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <BreadcrumbItem active>
                    <div className="text-dark font-size-14">
                      {breadcrumbTitle}
                    </div>
                  </BreadcrumbItem>
                </ol>
              </div>
            </div>
            {children}
          </div>
        </Col>
        <Col className="my-4 my-md-0">
          <TimelineSidebar />
        </Col>
      </div>
      <ModalMenu
        modal={modal}
        setModal={setModal}
        title={"Pilih Tahapan Progres"}
        description={
          "Silakan pilih kategori proses perceraian yang akan dilaksanakan. Detail proses akan disimpan dan dipublikasikan pada klien. Anda bisa mengedit kembali informasi yang dipublikasikan selama kasus belum berstatus PERCERAIAN SELESAI"
        }
      >
        <Row className="w-100">
          {progressMenuList.map((item, index) => {
            return (
              <Col lg={4} key={"key_menu_" + index}>
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    let state;
                    // change to not equal later, if using as lawyer
                    if (
                      roleType === "client" &&
                      item.title.toLowerCase().includes("upload berkas")
                    ) {
                      state = {
                        ...item,
                        title: "Request Berkas/File",
                        isPreview: true,
                      };
                    } else {
                      state = { ...item, isCreated: true };
                    }
                    navigate("/timeline-details-client", {
                      state,
                    });
                    setModal(false);
                  }}
                >
                  <ProgressMenuCard
                    title={
                      index === 0
                        ? roleType !== "client"
                          ? item.title
                          : "Request Berkas/File"
                        : item.title
                    }
                    description={item.description}
                    type={item.type}
                    isActive={index === 0}
                  />
                </div>
              </Col>
            );
          })}
        </Row>
      </ModalMenu>
    </>
  );
};

export default CaseLayout;
