import ContentFormRegister from "./components/ContentFormRegister";
import { useState } from "react";
import ContentSuccessRegister from "./components/ContentSuccessRegister";
import HelmetTitle from "../../components/layout/HelmetTitle";
import RegisterContentForm from "./components/RegisterContentForm";

const Register = ({ type }) => {
    const [isSucessRegister, SetIsSuccessRegister] = useState(false);

    const handlePageRegister = (value) => {
        SetIsSuccessRegister(value)
    }

    return (
        <>
            <HelmetTitle
                title={` Register | ${type == 'client' ? 'Klien' : 'Lawyer'}`}
                meta="register, ceraionline"
            />
            {
                !isSucessRegister
                    ?
                    <RegisterContentForm
                        type={type}
                        onUpdatePage={handlePageRegister}
                    />
                    : <ContentSuccessRegister />
            }
        </>
    );
}

export default Register;
