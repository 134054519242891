import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Row } from "reactstrap";
import HelmetTitle from "../../components/layout/HelmetTitle";
import { usePostVerificationRegisterMutation } from "./api/AuthAPI";
import CheckingVerificationRegister from "./components/CheckingVerificationRegister";
import NotValidTokenVerifyRegister from "./components/NotValidTokenVerifyRegister";
import VerificationRegisterSuccess from "./components/VerificationRegisterSuccess";

const VerificationRegister = () => {
    const [verificationStatus, setVerificationStatus] = useState('loader');
    const [searchParams] = useSearchParams()
    const token = searchParams.get('token');
    const role = searchParams.get('role');
    const [postVerificationRegister] = usePostVerificationRegisterMutation()

    const validationToken = async (token) => {
        try {
            await postVerificationRegister({ token }).unwrap();
            setInterval(() => {
                setVerificationStatus('success')
            }, 3000);
        } catch (error) {
            setInterval(() => {
                setVerificationStatus('not_found')
            }, 3000);
        }
    }

    useEffect(() => {
        if (!token) {
            setInterval(() => {
                return setVerificationStatus('not_found')
            }, 3000);
        } else {
            validationToken(token)
        }
    }, [token]);

    return (
        <>
            <HelmetTitle
                title='Verifikasi Pendaftaran'
                meta="verifikasipendaftaran, verifikasi, ceraionline"
            />

            <Row className="justify-content-center align-self-center mt-15 mt-lg-20 px-2 px-lg-15 min-vh-100 bg-transparent">
                {verificationStatus === 'loader'
                    ?
                    <CheckingVerificationRegister />
                    :
                    verificationStatus === 'not_found'
                        ?
                        <NotValidTokenVerifyRegister />
                        :
                        verificationStatus === 'success' ?
                            <VerificationRegisterSuccess type={role} /> :
                            <NotValidTokenVerifyRegister />
                }
            </Row>
        </>
    );
}

export default VerificationRegister;
