import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Container } from "reactstrap";
import HelmetTitle from "../../components/layout/HelmetTitle";
import { usePostCheckingTokenActivationLawyerMutation } from "../user/api/UsersAPI";
import ContentEmptyTokenActivationLawyer from "./components/ContentEmptyTokenActivationLawyer";
import ContentLoadingActivationLawyer from "./components/ContentLoadingActivationLawyer";
import ContentFormActivationLawyer from "./components/ContentFormActivationLawyer";
import ContentSuccessActivationLawyer from "./components/ContentSuccessActivationLawyer";

const ActivationAccountLawyer = () => {
    const [searchParams] = useSearchParams()
    const [dataSessions, setDataSessions] = useState([]);
    const [isEmptyToken, setIsEmptyToken] = useState(false);
    const [isSuccessActivation, setIsSuccessActivation] = useState(false);

    const [postCheckingTokenActivationLawyer, { isLoading: isLoadingCheckingTokenActivationLawyer, isSuccess: isSuccessTokenActivationLawyer, isError: isErrorTokenActivationLawyer }] = usePostCheckingTokenActivationLawyerMutation()

    const verifyToken = searchParams.get("token_verify")

    const handlerGetVerifyToken = async (verifyToken) => {
        const getData = await postCheckingTokenActivationLawyer({ token_verify: verifyToken }).unwrap()
        setDataSessions(getData.data)
    }

    useEffect(() => {
        if (!setIsEmptyToken) {
            setIsEmptyToken(true)
        } else {
            setIsEmptyToken(false)
            handlerGetVerifyToken(verifyToken)
        }
    }, [verifyToken]);


    const ContentActivation = (param) => {
        const {
            isLoading,
            isError,
            isShowForm,
            isEmpty,
            data,
            setIsSuccessActivation,
            isSuccessActivation,
            verifyToken
        } = param;

        if (isSuccessActivation) {
            return (
                <ContentSuccessActivationLawyer />
            )
        }

        if (isLoading || !data) {
            return (
                <ContentLoadingActivationLawyer />
            )
        }

        if (isEmpty || isError) {
            return (
                <ContentEmptyTokenActivationLawyer />
            )
        }

        if (isShowForm) {
            return (
                <ContentFormActivationLawyer
                    data={data}
                    verifyToken={verifyToken}
                    handlerChangeStatus={(value) => setIsSuccessActivation(value)}
                />
            )
        }

    }

    return (
        <>
            <HelmetTitle
                title="Aktivasi Akun | Lawyer"
                meta="aktivasi, activation, lawyer, activationlawyer, aktivasilawyer, ceraionline"
            />
            <div className="page-content min-vh-100 mt-3">
                <Container fluid>
                    <ContentActivation
                        isLoading={isLoadingCheckingTokenActivationLawyer}
                        isError={isErrorTokenActivationLawyer}
                        isShowForm={isSuccessTokenActivationLawyer}
                        isEmpty={isEmptyToken}
                        data={dataSessions}
                        setIsSuccessActivation={setIsSuccessActivation}
                        isSuccessActivation={isSuccessActivation}
                        verifyToken={verifyToken}
                    />
                </Container>
            </div>
        </>
    );
}

export default ActivationAccountLawyer;
