import PropsTypes from "prop-types";
import { Card, CardBody, CardText, CardTitle } from "reactstrap";
function ProgressMenuCard(props) {
  return (
    <Card className="menu-card border-2 border-black-50 py-4 rounded-4">
      <CardBody className="d-flex align-items-center gap-3">
        <div className="d-flex align-items-center">
          <i className={`${props.icon} font-size-22`} style={{ color: props.icon_color }}></i>
        </div>
        <div>
          <CardTitle className="font-size-14" style={{ color: props.name_color }}>{props.name}</CardTitle>
          <CardText className="font-size-12" style={{ color: props.description_color }}>{props.description}</CardText>
        </div>
      </CardBody>
    </Card>
  );
}

ProgressMenuCard.propsTypes = {
  icon: PropsTypes.string,
  icon_color: PropsTypes.string,
  name: PropsTypes.string,
  name_color: PropsTypes.string,
  description: PropsTypes.string,
  description_color: PropsTypes.string,
};

export default ProgressMenuCard;
