import { useEffect } from 'react';
import { RiStarFill } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { authSetupSelector, setRefreshToken, setToken } from '../../../app/authSlice';
import { orderSetupSelector } from '../../../app/orderSlice';
import { ratingSetupSelector, resetDataRating, setDataRating } from '../../../app/ratingSlice';
import { usePostRefreshTokenMutation } from '../../auth/api/AuthAPI';
import { useGetRatingMutation } from '../../rating/api/RatingAPI';

const ModalShowRate = ({ isOpen, toggle }) => {
    const dispatch = useDispatch()

    const {
        refresh_token
    } = useSelector(authSetupSelector)

    const {
        rate, comment
    } = useSelector(ratingSetupSelector)

    const {
        detail_order_service
    } = useSelector(orderSetupSelector)

    const [postRefreshToken] = usePostRefreshTokenMutation()
    const [
        getRating
    ] = useGetRatingMutation()

    const handlerRefreshSession = async () => {
        const resultGenerateNewToken = await postRefreshToken({ refresh_token }).unwrap()

        localStorage.setItem('refreshToken', resultGenerateNewToken.refreshToken)
        localStorage.setItem('token', resultGenerateNewToken.token)

        dispatch(setToken(resultGenerateNewToken.token))
        dispatch(setRefreshToken(resultGenerateNewToken.refreshToken))
        return true;
    }

    const handlerCloseRate = () => {
        dispatch(resetDataRating())
        toggle()
    }

    const handlerRefreshDataRating = async () => {
        await handlerRefreshSession()
        const getDataRating = await getRating(
            {
                case_id: detail_order_service.case_id
            }
        ).unwrap()
        dispatch(setDataRating(getDataRating.data))
    }

    useEffect(() => {
        if (isOpen) {
            handlerRefreshDataRating()
        }
    }, [isOpen]);

    const ContentRate = ({ rate }) => {
        if (rate === 0) {
            return <span>-</span>
        }

        if (rate < 2) {
            return <RiStarFill className="text-warning" size={14} />
        }

        if (rate > 1) {
            const starCount = [];
            for (let index = 0; index < rate; index++) {
                starCount.push(index)
            }

            return starCount.map((val) => {
                return <RiStarFill
                    key={val}
                    className="text-warning"
                    size={14}
                />
            })
        }
    }

    return (
        <Modal
            isOpen={isOpen}
            toggle={handlerCloseRate}
            centered
            keyboard={false}
            backdrop={false}
            className="border-0 custom-backdrop"
            size='md'
        >
            <ModalHeader toggle={handlerCloseRate}>
                Lihat Rating
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col xs={12} className='mb-0'>
                        <span>
                            Rating:
                        </span>
                        {' '}
                    </Col>
                    <Col xs={12}>
                        <ContentRate rate={rate} />
                    </Col>
                    <Col xs={12} className='mt-2 mb-0'>
                        <span>
                            Comment:
                        </span>
                        {' '}
                    </Col>
                    <Col xs={12}>
                        <span>{comment}</span>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    );
}

export default ModalShowRate;
