import React, { useEffect, useRef, useState } from "react";
import CaseLayout from "../../../components/layout/CaseLayout";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  List,
  Row,
} from "reactstrap";
import TitleDocument from "./TitleDocument";
import FileDesc from "./FileDesc";
import { BsImages } from "react-icons/bs";
import { listDescDetailTimeline } from "../../../docs/text";
import ListDescription from "./ListDescription";
import DocAttachment from "./DocAttachment";
import { useNavigate } from "react-router-dom";
import ModalMenu from "../../../components/modal/ModalMenu";
import RatingCom from "./Rating";
import { useDispatch, useSelector } from "react-redux";
import { removeFileData } from "../../../app/caseSlice";

const CheckoBoxFiles = ({ label, id, value, onChange }) => {
  return (
    <div className="d-flex align-items-center h-100 gap-3">
      <Input
        id={id}
        type="checkbox"
        value={value}
        className="p-2 border-2 border-primary"
        onChange={onChange}
      />
      <label htmlFor={id} className="mb-0 fw-normal">
        {label}
      </label>
    </div>
  );
};

function TimelineLayout({
  breadcrumbTitle = "Tahap penyelesaian kasus",
  timeline = { progress: [] },
  onSaveEdit,
  title,
  setTitle,
  files = [],
  desc,
  setDesc,
  mode,
  item,
  onSaveRequestFiles,
  onSaveReqDocFileModal,
}) {
  const dispatch = useDispatch();
  const isPreview = mode === "preview";
  const newDocReqFile = useSelector((state) => state.caseSetup.reqFileData);
  const inputRef = useRef(null);
  const newDocNameRef = useRef("");
  const [newFileTypes, setNewFilesTypes] = useState({
    isPdf: false,
    isImg: false,
    isVideo: false,
  });
  const [fileUploads, setFileUploads] = useState([]);
  const [modalDivorceOver, setModalDivorceOver] = useState(false);
  const [modalRequestFiles, setModalRequestFiles] = useState(false);
  const [isOpen] = useState(true);
  const navigate = useNavigate();

  const onDeleteFile = (data) => {
    if (!title?.includes("Request Berkas/File")) {
      const deleteFile = fileUploads.filter((_, idx) => idx !== data);
      setFileUploads(deleteFile);
    } else {
      dispatch(removeFileData(data));
    }
  };

  const handleSaveTimeline = () => {
    const dateYear = new Date().toLocaleDateString();
    const time = new Date().toLocaleTimeString("id-ID", { hour12: true });
    const payload = {
      //id is from db, this id just dummy for serach at redux
      id: Math.round(Math.random(10) * 100),
      date: dateYear,
      time,
      progressName: title,
      progressDescription: desc,
      files: fileUploads.map((val) => {
        if (!val?.path) {
          return {
            name: val.name,
            path: URL.createObjectURL(val),
          };
        } else {
          return { ...val };
        }
      }),
    };
    onSaveEdit?.(payload);
  };

  const onSaveNewRequestFiles = () => {
    const reqFilesPayload = {
      docName: newDocNameRef.current,
      docType: newFileTypes,
    };
    onSaveReqDocFileModal?.(reqFilesPayload);
    setModalRequestFiles(false);
    setNewFilesTypes({ isPdf: false, isImg: false, isVideo: false });
  };

  const handleSaveRequestFiles = () => {
    onSaveRequestFiles?.({ newFiles: newDocReqFile });
  };

  const handleChangeFileType = (event, index) => {
    if (index === 0) {
      setNewFilesTypes((prev) => ({ ...prev, isPdf: event.target.checked }));
    }
    if (index === 1) {
      setNewFilesTypes((prev) => ({ ...prev, isImg: event.target.checked }));
    }
    if (index === 2) {
      setNewFilesTypes((prev) => ({ ...prev, isVideo: event.target.checked }));
    }
  };

  useEffect(() => {
    if (files?.length) {
      setFileUploads(files);
    } else {
      setFileUploads([]);
    }
  }, [files.length]);

  // change this to render from sidebar timeline action
  const rating = false;

  const renderBody = () => {
    return rating ? (
      <RatingCom
        title="Rating Sesi Pendampingan Anda"
        subtitle="Selamat Anda sudah menyelesaikan sesi kondultasi. Bagaimana sesi konsultasi dengan pengacara?"
      />
    ) : (
      <>
        {/* title doc */}
        <TitleDocument
          setTitle={setTitle}
          title={title}
          isPreview={isPreview}
          docReqFiles={title?.includes("Request Berkas/File")}
        />
        {!isPreview ? (
          <Row className="mt-4">
            <Col sm={6} className="mb-5">
              <FileDesc title="Upload dokumentasi / berkas">
                <Button
                  onClick={() => {
                    inputRef.current?.click();
                  }}
                  className="border-0 w-100 file_upload file_button p-3 d-flex flex-column align-items-center justify-content-center gap-3"
                >
                  <BsImages size={42} color="#438FFE" />
                  <span className="text-black">
                    Browes image / Video / Document
                  </span>
                </Button>
                <input
                  ref={inputRef}
                  type="file"
                  hidden
                  onChange={(e) =>
                    setFileUploads([e.target.files[0], ...fileUploads])
                  }
                />
              </FileDesc>
            </Col>
            <Col>
              <FileDesc title="Tips menulis detail deskripsi" type="desc">
                <div className="border-0 w-100 file_upload file_button p-2 overflow-auto">
                  <List>
                    {listDescDetailTimeline.map((item) => (
                      <ListDescription
                        key={item.key}
                        listTitle={item.title}
                        listDesc={item.desc}
                      />
                    ))}
                  </List>
                </div>
              </FileDesc>
            </Col>
          </Row>
        ) : null}
        {/* doc attachment */}
        <DocAttachment
          data={
            !title.includes("Request Berkas/File") ? fileUploads : newDocReqFile
          }
          isOpen={isOpen}
          title={title}
          // setIsOpen={setIsOpen}
          onDeleteFile={onDeleteFile}
          isPreview={isPreview}
        />
        {!title.includes("Request Berkas/File") ? (
          <FormGroup className="w-100 h-25">
            <Label for="forDescription" sm={2}>
              <span>
                {isPreview ? "Deskripsi" : "Tulis deskripsi"}
                {isPreview ? null : <span className="text-primary">*</span>}
              </span>
            </Label>
            <Input
              id="forDescription"
              name="text"
              type="textarea"
              placeholder="Deskripsi"
              onChange={(e) => setDesc(e.target.value)}
              className={`description_input bg-white ${isPreview ? "disable" : ""
                }`}
              value={desc}
              disabled={isPreview}
            />
          </FormGroup>
        ) : null}
        <div
          className={`d-flex flex-column flex-md-row gap-4 pt-5 pb-3 ${isPreview && !title.includes("Request Berkas/File")
            ? "justify-content-end"
            : "justify-content-between"
            }`}
        >
          {isPreview ? null : (
            <Button
              className={"btn-card-outline px-5"}
              onClick={() => navigate("/chat-view")}
            >
              <span className={"text-primary"}>Batal</span>
            </Button>
          )}
          <Button
            className={` ${title.includes("Request Berkas/File")
              ? "btn-card-outline"
              : "btn-card-red"
              } px-5`}
            onClick={() => {
              if (title.includes("Request Berkas/File")) {
                setModalRequestFiles(!modalRequestFiles);
              } else {
                if (isPreview) {
                  navigate("/timeline-details-client", {
                    state: { item, isEdit: true },
                  });
                } else {
                  if (title.includes("Perceraian Selesai")) {
                    setModalDivorceOver(!modalDivorceOver);
                  } else {
                    handleSaveTimeline();
                  }
                }
              }
            }}
          >
            <span
              className={`${title.includes("Request Berkas/File")
                ? "text-primary"
                : "text-white"
                }`}
            >
              {isPreview
                ? title.includes("Request Berkas/File")
                  ? "Tambah jenis dokumen"
                  : "Ubah"
                : "Simpan"}
            </span>
          </Button>
          {title.includes("Request Berkas/File") ? (
            <Button
              className={`btn-card-red px-5`}
              onClick={handleSaveRequestFiles}
            >
              <span className={`text-white`}>Simpan</span>
            </Button>
          ) : null}
        </div>
      </>
    );
  };

  return (
    <CaseLayout
      breadcrumbTitle={breadcrumbTitle}
      backTo={"/chat-view"}
      listTimeline={timeline?.progress}
      mode={mode}
    >
      <Card className="p-2 p-md-3 rounded-4">
        <CardBody>{renderBody()}</CardBody>
      </Card>
      {modalDivorceOver ? (
        <ModalMenu
          modal={modalDivorceOver}
          setModal={setModalDivorceOver}
          title={"Proses Perceraian Selesai"}
          description={
            "Status masih bisa diubah dalam waktu 7 hari. Setelah proses perceraian selesai, setiap pihak dapat mencairkan dana tersisa dalam waktu 1 x 24 jam dan tidak bisa menambahkan progres baru."
          }
          hideModalBottom
          isDescriptionCenter
          modalSize="md"
        >
          <div className="d-flex w-100 flex-column gap-3 px-5">
            <Button className="btn-card-red" onClick={handleSaveTimeline}>
              Simpan
            </Button>
            <Button
              className="btn-card-outline text-primary"
              onClick={() => setModalDivorceOver(false)}
            >
              Kembali
            </Button>
          </div>
        </ModalMenu>
      ) : null}
      {modalRequestFiles ? (
        <ModalMenu
          modal={modalRequestFiles}
          setModal={setModalRequestFiles}
          title={"Tambah Jenis Dokumen"}
          description={
            "Dokumen dapat dikirimkan dalame bentuk Image / Video / PDF. Masukkan satu nama dokumen yang Anda minta"
          }
          hideModalBottom
          isDescriptionCenter
          modalSize="md"
        >
          <FormGroup>
            <Input
              name="doc_type"
              placeholder="Contoh: Kartu Keluarga"
              className="p-3"
              type="text"
              onChange={(val) => (newDocNameRef.current = val.target.value)}
            />
            <div className="py-4">
              <div className="font-size-14 mb-3">Pilih jenis file</div>
              <div className="d-flex gap-4">
                {Array.from(Array(3).keys()).map((val, index) => {
                  return (
                    <CheckoBoxFiles
                      key={val}
                      id={
                        index === 0
                          ? "pdfType"
                          : index === 1
                            ? "imagetype"
                            : "videoType"
                      }
                      label={
                        index === 0 ? "PDF" : index === 1 ? "Image" : "Video"
                      }
                      value={
                        index === 0 ? "pdf" : index === 1 ? "img" : "video"
                      }
                      onChange={(e) => handleChangeFileType(e, index)}
                    />
                  );
                })}
              </div>
            </div>
            <div className="d-flex w-100 flex-column gap-3 px-5 mt-4">
              <Button
                className="btn-card-outline text-primary"
                onClick={() => setModalRequestFiles(false)}
              >
                Kembali
              </Button>
              <Button className="btn-card-red" onClick={onSaveNewRequestFiles}>
                Simpan
              </Button>
            </div>
          </FormGroup>
        </ModalMenu>
      ) : null}
    </CaseLayout>
  );
}

export default TimelineLayout;
