import React from "react";
import { IoInformationCircle } from "react-icons/io5";
/**
 *
 * @param {{title: string, children: React.Component, type: 'desc' | 'file'}} param0
 * @returns
 */

function FileDesc({ title, children, type }) {
  return (
    <div>
      <div className="fw-semibold font-size-4 d-flex gap-2">
        {type === "desc" ? <IoInformationCircle size={16} /> : null}
        <span>{title}</span>
      </div>
      <div className="border rounded-4 mt-3">{children}</div>
    </div>
  );
}

export default FileDesc;
