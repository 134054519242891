import { addMessage, changeAllBubbleChats, setConsultationSessions, setDetailOrderService, setHistoryChatAction } from '../../app/orderSlice';
import { store } from '../../app/store';

export const updateListBubbleChat = (data) => {
    const state = store.getState();
    const { orderSetup } = state;
    if (orderSetup.room_id === data.chat_id) {
        const checkAvailBubble = orderSetup.bubble_chats.filter((val) => val.id === data.id)
        if (checkAvailBubble.length < 1) {
            store.dispatch(addMessage(data))
        }
    }
};

export const updateDetailCase = (data) => {
    const state = store.getState();
    const { orderSetup } = state;
    if (orderSetup.room_id === data.id) {
        store.dispatch(setDetailOrderService(data.detail_order_service))
        store.dispatch(setConsultationSessions(data.consultation_sessions))
        store.dispatch(changeAllBubbleChats(data.bubble_chats))
        store.dispatch(setHistoryChatAction(data.history_chat_action))
    }
};

export const updateExistingBubbleChat = (data) => {
    const state = store.getState();
};