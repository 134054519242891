import { Card, Col, Row } from "reactstrap";
import welcomeClient from '../../assets/images/welcome/cliet.svg'
import welcomeLawyer from '../../assets/images/welcome/lawyer.svg'
import { useEffect, useState } from "react";
import { UpperCaseName } from "../../helpers/GlobalHelper";

const CardWelcome = (props) => {
    const { type } = props
    const [nameUser, setNameUser] = useState('');

    useEffect(() => {
        const sessions = localStorage.getItem('data');
        if (sessions) {
            setNameUser(UpperCaseName(JSON.parse(sessions).full_name));
        }
    }, []);
    return (
        <Card className="rounded-3">
            <Row>
                <Col lg="9" sm="8">
                    <div className="p-4">
                        <h3 className="text-primary">
                            <span className="wave">
                                👋
                            </span>
                            {' '}
                            <span>😀</span>
                            {' '}
                            <span>Hai</span>
                        </h3>
                        <p>{nameUser}</p>
                    </div>
                </Col>
                <Col lg="3" sm="4" className="align-self-center">
                    <div>
                        <img
                            src={
                                type === 'lawyer' ? welcomeLawyer : welcomeClient
                            }
                            alt="welcome"
                            className="img-fluid d-block"
                        />
                    </div>
                </Col>
            </Row>
        </Card>
    );
}

export default CardWelcome;
