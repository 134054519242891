import { Card, CardBody, Col, Collapse, Label, Row } from "reactstrap";
import HelmetTitle from "../../components/layout/HelmetTitle";
import { useState } from "react";
import classnames from "classnames";
import AccordionItem from "../../components/global/AccordionItem";

const HowAboutWork = () => {
    const [col1, setcol1] = useState(true);
    const [col2, setcol2] = useState(false);
    const [col3, setcol3] = useState(false);
    const [col4, setcol4] = useState(false);

    const handlerChangeCol = (colNumber) => {
        if (colNumber === 1) {
            setcol1(!col1);
        }

        if (colNumber === 2) {
            setcol2(!col2);

        }

        if (colNumber === 3) {
            setcol3(!col3);
        }

        if (colNumber === 4) {
            setcol4(!col4);
        }
    }

    const listAccordionItems = [
        {
            headerName:
                <>
                    <span className="text-primary me-3 fw-bold" >
                        1.
                    </span>
                    <span className="text-primary fw-bold">
                        Cari Pengacara yang Anda ingin ajukan konsultasi
                    </span>
                </>,
            isContent: 'Pilih Pengacara sesuai kebutuhan Anda dengan filter search lokasi, mempermudah Anda bertemu dengan pengacara yang tepat',
            isCollapsed: col1,
            isNumberCollapse: 1
        },
        {
            headerName:
                <>
                    <span className="text-primary me-3 fw-bold" >
                        2.
                    </span>
                    <span className="text-primary fw-bold">
                        Chat Pengacara
                    </span>
                </>,
            isContent: 'Chat untuk menanyakan secara personal kepada pengacara terkait kasus yang Anda miliki',
            isCollapsed: col2,
            isNumberCollapse: 2
        },
        {
            headerName:
                <>
                    <span className="text-primary me-3 fw-bold" >
                        3.
                    </span>
                    <span className="text-primary fw-bold">
                        Booking Pengacara
                    </span>
                </>,
            isContent: 'Isi form yang tersedia terkait data diri Anda dan ajukan kasus kepada pengacara',
            isCollapsed: col3,
            isNumberCollapse: 3
        },
        {
            headerName:
                <>
                    <span className="text-primary me-3 fw-bold" >
                        4.
                    </span>
                    <span className="text-primary fw-bold">
                        Pantau perceraian dengan mudah
                    </span>
                </>,
            isContent: 'Isi form yang tersedia terkait data diri Anda dan ajukan kasus kepada pengacara',
            isCollapsed: col4,
            isNumberCollapse: 4
        }
    ]

    return (
        <>
            <HelmetTitle
                title="CaraKerja"
                meta="CaraKerja, carakerja, carakerjaceraionline.com, ceraionline.com, ceraionline.com"
            />
            <Row className="min-vh-100">
                <Col xs={12}>
                    <Card className="px-0 px-lg-3" color="white" style={{ backgroundColor: 'transparent' }}>
                        <CardBody className="px-0 px-lg-3" >
                            <Row
                                className="mt-10 mt-lg-10  px-3 px-lg-3"
                            >
                                <Col xs={12}>
                                    <Label className="fw-bold fs-4 mb-3">
                                        Cara Cerai Online Bekerja
                                    </Label>
                                    <div className="accordion mt-0" id="accordion">
                                        {listAccordionItems.map((val, key) => (
                                            <AccordionItem
                                                key={key}
                                                headerName={val.headerName}
                                                isContent={val.isContent}
                                                isCollapsed={val.isCollapsed}
                                                onClick={
                                                    () => handlerChangeCol(val.isNumberCollapse)
                                                }
                                            />
                                        ))}
                                    </div>
                                </Col>
                            </Row >
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
}

export default HowAboutWork;
