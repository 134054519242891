import Select from 'react-select';

const SelectReligion = ({ onChange, value = '' }) => {
    const religionData = [
        { value: 'Islam', label: 'Islam' },
        { value: 'Katolik', label: 'Katolik' },
        { value: 'Protestan', label: 'Protestan' },
        { value: 'Hindu', label: 'Hindu' },
        { value: 'Budha', label: 'Budha' },
        { value: 'Konghucu', label: 'Konghucu' },
    ];

    return (
        <>
            <Select
                onChange={onChange}
                defaultValue={{ value: '', label: '' }}
                value={{ value, label: value }}
                isSearchable={true}
                options={religionData}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: "0.25rem",
                    colors: {
                        ...theme.colors,
                        primary25: '#FFEDED',
                        primary: '#E91E23',
                    },
                })}
            />
        </>
    );
}

export default SelectReligion;
