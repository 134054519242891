import { Button, Card, CardBody, CardText, Col, Input, Label, Row, Table } from "reactstrap";
import { UpperCaseName, notify } from "../../../helpers/GlobalHelper";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { usePostApproveActivationAccountLawyerMutation, usePostRejectActivationAccountLawyerMutation } from "../../user/api/UsersAPI";
// import avatar from '../../../assets/images/users/user.png';
import avatar from '../../../assets/images/users/user.png';


const ContentFormActivationLawyer = ({ data, handlerChangeStatus, verifyToken }) => {
    const [dataEducation, setDataEducation] = useState({});
    const [dataServiceRate, setDataServiceRate] = useState([]);
    const [reasonRejected, setReasonRejected] = useState('');

    const [postApproveActivationAccountLawyer, { isLoading: isLoadingApprove, isSuccess: isSuccessApprove, isError: isErrorApproveActivationAccountLawyer, error: errorApproveActivationAccountLawyer }] = usePostApproveActivationAccountLawyerMutation()

    const [postRejectActivationAccountLawyer, { isLoading: isLoadingReject, isSuccess: isSuccessReject, isError: isErrorRejectActivationAccountLawyer, error: errorRejectActivationAccountLawyer }] = usePostRejectActivationAccountLawyerMutation()

    const handlerActivation = async (type) => {
        try {
            if (type === 'approve') {
                await postApproveActivationAccountLawyer({ token_verify: verifyToken }).unwrap();
            } else {
                const reasonReplaceSpace = reasonRejected.replace(/\n/g, '');
                if (!reasonReplaceSpace) {
                    return notify('error', 5000, 'Alasan atau reason wajib di isi jika melakukan reject!');

                }
                await postRejectActivationAccountLawyer({ token_verify: verifyToken, reason: reasonRejected }).unwrap()
            }
        } catch (error) {
            console.warn("error:", error)
        }
    }

    useEffect(() => {
        if (data) {
            setDataEducation(data.education)
            setDataServiceRate(data.service_rates)
        }
    }, [data]);

    useEffect(() => {
        if (isSuccessApprove || isSuccessReject) {
            handlerChangeStatus(true)
        }
    }, [
        isSuccessApprove,
        isSuccessReject
    ]);

    useEffect(() => {
        if (isErrorApproveActivationAccountLawyer) {
            const messageError = !errorApproveActivationAccountLawyer.data ? 'Terjadi kesalahan saat memproses konfirmasi' : errorApproveActivationAccountLawyer.data.message;

            notify('error', 5000, messageError);
        }
    }, [isErrorApproveActivationAccountLawyer]);

    useEffect(() => {
        if (isErrorRejectActivationAccountLawyer) {
            const messageError = !errorRejectActivationAccountLawyer.data ? 'Terjadi kesalahan saat memproses menolak' : errorRejectActivationAccountLawyer.data.message;

            notify('error', 5000, messageError);
        }
    }, [isErrorRejectActivationAccountLawyer]);

    const ContentTableServiceRate = ({ dataTable }) => {
        if (!dataTable) {
            return (
                <div className="text-center">Tidak Ada Layanan</div>
            )
        } else {
            return (
                <div className="table-responsive">
                    <Table className="table mb-0">
                        <thead className="table-danger">
                            <tr>
                                <th className="text-secondary">Layanan</th>
                                <th className="text-secondary">Harga</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dataTable.map((val, key) => (
                                <tr key={key}>
                                    <td className="bg-white border-dark border-top">
                                        {val.category_service_name}
                                    </td>
                                    <td className="bg-white border-dark border-top">
                                        {
                                            !val.price || val.price < 1
                                                ?
                                                0
                                                :
                                                Intl.NumberFormat("id-ID", {
                                                    style: "currency",
                                                    currency: "IDR",
                                                }).format(val.price)
                                                    .replace(/(\.|,)00$/g, '')
                                        }
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            )
        }
    }

    return (
        <Row className="justify-content-center">
            <Card className="bg-white border rounded">
                <CardBody>
                    <Col xs={12} className="text-center">
                        <Link
                            to={!data.photo ? avatar : data.photo}
                            target="_blank"
                        >
                            <img className="img-thumbnail rounded-circle avatar-xl" alt="photo_profile" src={!data.photo ? avatar : data.photo} data-holder-rendered="true" />
                        </Link>
                    </Col>
                    <Col xs={12}>
                        <div className="invoice-title justify-content-center text-center">
                            <h4 className="font-size-18">
                                <span className="fw-normal">
                                    Verifikasi User
                                </span>
                                {' '}
                                <span className="fw-bold">
                                    {UpperCaseName(data.full_name)}
                                </span>
                            </h4>
                            <p className="fw-light font-size-13 mt-3-sm">
                                Pengacara telah melakukan pengajuan aktivasi akunnya untuk menerima klien, mohon untuk dilakukan pengecekan dan konfirmasi apakah di setujui atau tidak
                            </p>
                        </div>
                    </Col>
                    <Col xs={12} className="mt-4">
                        <Label className="fw-bold fs-5">
                            Data Diri Pengacara
                        </Label>
                    </Col>
                    <Col xs={12}>
                        <Row className="mt-3">
                            <Col xs={12} lg={5} className="fw-bold">
                                Nama Lengkap<span className="d-inline-block d-lg-none px-0 mx-0">:</span>
                            </Col>
                            <Col lg={1} className="d-none d-lg-block width-content">
                                :
                            </Col>
                            <Col xs={12} lg={6} className="mt-1-sm">
                                {UpperCaseName(data.full_name)}
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={12} lg={5} className="fw-bold">
                                Jenis Kelamin<span className="d-inline-block d-lg-none px-0 mx-0">:</span>
                            </Col>
                            <Col lg={1} className="d-none d-lg-block width-content">
                                :
                            </Col>
                            <Col xs={12} lg={6} className="mt-1-sm">
                                {
                                    data?.gender?.toLowerCase() === 'male'
                                        ?
                                        'Laki - laki'
                                        :
                                        'Perempuan'
                                }
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={12} lg={5} className="fw-bold">
                                No Telepon<span className="d-inline-block d-lg-none px-0 mx-0">:</span>
                            </Col>
                            <Col lg={1} className="d-none d-lg-block width-content">
                                :
                            </Col>
                            <Col xs={12} lg={6} className="mt-1-sm">
                                {data.phone_number}
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={12} lg={5} className="fw-bold">
                                Email<span className="d-inline-block d-lg-none px-0 mx-0">:</span>
                            </Col>
                            <Col lg={1} className="d-none d-lg-block width-content">
                                :
                            </Col>
                            <Col xs={12} lg={6} className="mt-1-sm">
                                {data.email}
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={12} lg={5} className="fw-bold">
                                Tempat Lahir<span className="d-inline-block d-lg-none px-0 mx-0">:</span>
                            </Col>
                            <Col lg={1} className="d-none d-lg-block width-content">
                                :
                            </Col>
                            <Col xs={12} lg={6} className="mt-1-sm">
                                {UpperCaseName(data.place_birth)}
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={12} lg={5} className="fw-bold">
                                Tanggal Lahir<span className="d-inline-block d-lg-none px-0 mx-0">:</span>
                            </Col>
                            <Col lg={1} className="d-none d-lg-block width-content">
                                :
                            </Col>
                            <Col xs={12} lg={6} className="mt-1-sm">
                                {data.date_birth}
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={12} lg={5} className="fw-bold">
                                Pekerjaan<span className="d-inline-block d-lg-none px-0 mx-0">:</span>
                            </Col>
                            <Col lg={1} className="d-none d-lg-block width-content">
                                :
                            </Col>
                            <Col xs={12} lg={6} className="mt-1-sm">
                                {UpperCaseName(data.profession)}
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={12} lg={5} className="fw-bold">
                                No. KTP<span className="d-inline-block d-lg-none px-0 mx-0">:</span>
                            </Col>
                            <Col lg={1} className="d-none d-lg-block width-content">
                                :
                            </Col>
                            <Col xs={12} lg={6} className="mt-1-sm">
                                {data.number_kyc}
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={12} lg={5} className="fw-bold">
                                File KTP<span className="d-inline-block d-lg-none px-0 mx-0">:</span>
                            </Col>
                            <Col lg={1} className="d-none d-lg-block width-content">
                                :
                            </Col>
                            <Col xs={12} lg={6} className="mt-1-sm">
                                {
                                    !data.file_kyc
                                        ?
                                        '-'
                                        :
                                        <Link
                                            className="text-primary"
                                            to={data.file_kyc}
                                            target="_blank"
                                        >
                                            Lihat File
                                        </Link>
                                }
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={12} lg={5} className="fw-bold">
                                Alamat KTP<span className="d-inline-block d-lg-none px-0 mx-0">:</span>
                            </Col>
                            <Col lg={1} className="d-none d-lg-block width-content">
                                :
                            </Col>
                            <Col xs={12} lg={6} className="mt-1-sm">
                                {data?.address_kyc}
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={12} lg={5} className="fw-bold">
                                Kelurahan KTP<span className="d-inline-block d-lg-none px-0 mx-0">:</span>
                            </Col>
                            <Col lg={1} className="d-none d-lg-block width-content">
                                :
                            </Col>
                            <Col xs={12} lg={6} className="mt-1-sm">
                                {data?.ward_kyc?.name}
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={12} lg={5} className="fw-bold">
                                Kecamatan KTP<span className="d-inline-block d-lg-none px-0 mx-0">:</span>
                            </Col>
                            <Col lg={1} className="d-none d-lg-block width-content">
                                :
                            </Col>
                            <Col xs={12} lg={6} className="mt-1-sm">
                                {data?.town_kyc?.name}
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={12} lg={5} className="fw-bold">
                                Kota KTP<span className="d-inline-block d-lg-none px-0 mx-0">:</span>
                            </Col>
                            <Col lg={1} className="d-none d-lg-block width-content">
                                :
                            </Col>
                            <Col xs={12} lg={6} className="mt-1-sm">
                                {data?.city_kyc?.name}
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={12} lg={5} className="fw-bold">
                                Provinsi KTP<span className="d-inline-block d-lg-none px-0 mx-0">:</span>
                            </Col>
                            <Col lg={1} className="d-none d-lg-block width-content">
                                :
                            </Col>
                            <Col xs={12} lg={6} className="mt-1-sm">
                                {data?.province_kyc?.name}
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={12} lg={5} className="fw-bold">
                                Kode Pos KTP<span className="d-inline-block d-lg-none px-0 mx-0">:</span>
                            </Col>
                            <Col lg={1} className="d-none d-lg-block width-content">
                                :
                            </Col>
                            <Col xs={12} lg={6} className="mt-1-sm">
                                {data?.postal_code_kyc}
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={12} lg={5} className="fw-bold">
                                Alamat Domisili<span className="d-inline-block d-lg-none px-0 mx-0">:</span>
                            </Col>
                            <Col lg={1} className="d-none d-lg-block width-content">
                                :
                            </Col>
                            <Col xs={12} lg={6} className="mt-1-sm">
                                {data?.address_domicile}
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={12} lg={5} className="fw-bold">
                                Kelurahan Domisili<span className="d-inline-block d-lg-none px-0 mx-0">:</span>
                            </Col>
                            <Col lg={1} className="d-none d-lg-block width-content">
                                :
                            </Col>
                            <Col xs={12} lg={6} className="mt-1-sm">
                                {data?.ward_domicile?.name}
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={12} lg={5} className="fw-bold">
                                Kecamatan Domisili<span className="d-inline-block d-lg-none px-0 mx-0">:</span>
                            </Col>
                            <Col lg={1} className="d-none d-lg-block width-content">
                                :
                            </Col>
                            <Col xs={12} lg={6} className="mt-1-sm">
                                {data?.town_domicile?.name}
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={12} lg={5} className="fw-bold">
                                Kota Domisili<span className="d-inline-block d-lg-none px-0 mx-0">:</span>
                            </Col>
                            <Col lg={1} className="d-none d-lg-block width-content">
                                :
                            </Col>
                            <Col xs={12} lg={6} className="mt-1-sm">
                                {data?.city_domicile?.name}
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={12} lg={5} className="fw-bold">
                                Provinsi Domisili<span className="d-inline-block d-lg-none px-0 mx-0">:</span>
                            </Col>
                            <Col lg={1} className="d-none d-lg-block width-content">
                                :
                            </Col>
                            <Col xs={12} lg={6} className="mt-1-sm">
                                {data?.province_domicile?.name}
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={12} lg={5} className="fw-bold">
                                Kode Pos Domisili<span className="d-inline-block d-lg-none px-0 mx-0">:</span>
                            </Col>
                            <Col lg={1} className="d-none d-lg-block width-content">
                                :
                            </Col>
                            <Col xs={12} lg={6} className="mt-1-sm">
                                {data?.postal_code_domicile}
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} className="mt-5">
                        <Row>
                            <Col xs={12} lg={6}>
                                <Label className="fw-bold fs-5">
                                    Pendidikan
                                </Label>
                                <Row className="mt-3">
                                    <Col xs={12} lg={5} className="fw-bold">
                                        Universitas<span className="d-inline-block d-lg-none px-0 mx-0">:</span>
                                    </Col>
                                    <Col lg={1} className="d-none d-lg-block width-content">
                                        :
                                    </Col>
                                    <Col xs={12} lg={6} className="mt-1-sm">
                                        {dataEducation.institution_name}
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col xs={12} lg={5} className="fw-bold">
                                        Kualifikasi<span className="d-inline-block d-lg-none px-0 mx-0">:</span>
                                    </Col>
                                    <Col lg={1} className="d-none d-lg-block width-content">
                                        :
                                    </Col>
                                    <Col xs={12} lg={6} className="mt-1-sm">
                                        {dataEducation.qualification}
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col xs={12} lg={5} className="fw-bold">
                                        Tahun<span className="d-inline-block d-lg-none px-0 mx-0">:</span>
                                    </Col>
                                    <Col lg={1} className="d-none d-lg-block width-content">
                                        :
                                    </Col>
                                    <Col xs={12} lg={6} className="mt-1-sm">
                                        {dataEducation.join_date}
                                        {' - '}
                                        {dataEducation.end_date}
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col xs={12} lg={5} className="fw-bold">
                                        Jurusan<span className="d-inline-block d-lg-none px-0 mx-0">:</span>
                                    </Col>
                                    <Col lg={1} className="d-none d-lg-block width-content">
                                        :
                                    </Col>
                                    <Col xs={12} lg={6} className="mt-1-sm">
                                        {dataEducation.major}
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col xs={12} lg={5} className="fw-bold">
                                        Skor<span className="d-inline-block d-lg-none px-0 mx-0">:</span>
                                    </Col>
                                    <Col lg={1} className="d-none d-lg-block width-content">
                                        :
                                    </Col>
                                    <Col xs={12} lg={6} className="mt-1-sm">
                                        {dataEducation.score}
                                        {' / 4'}
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col xs={12} lg={5} className="fw-bold">
                                        Ijazah & Tanda Anggota<span className="d-inline-block d-lg-none px-0 mx-0">:</span>
                                    </Col>
                                    <Col lg={1} className="d-none d-lg-block width-content">
                                        :
                                    </Col>
                                    <Col xs={12} lg={6} className="mt-1-sm">
                                        {
                                            !dataEducation.file_certificate
                                                ?
                                                '-'
                                                :
                                                <Link
                                                    className="text-primary"
                                                    to={dataEducation.file_certificate}
                                                    target="_blank"
                                                >
                                                    Lihat File
                                                </Link>
                                        }
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12} lg={6}>
                                <Label className="d-block d-lg-none fw-bold fs-5 mt-5">
                                    Sertifikat
                                </Label>
                                <Label className="d-none d-lg-block fw-bold fs-5">
                                    Sertifikat
                                </Label>
                                <Row className="mt-3">
                                    <Col xs={12} lg={5} className="fw-bold">
                                        PKPA<span className="d-inline-block d-lg-none px-0 mx-0">:</span>
                                    </Col>
                                    <Col lg={1} className="d-none d-lg-block width-content">
                                        :
                                    </Col>
                                    <Col xs={12} lg={6} className="mt-1-sm">
                                        {dataEducation.pkpa_number_certificate}
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col xs={12} lg={5} className="fw-bold">
                                        UPA<span className="d-inline-block d-lg-none px-0 mx-0">:</span>
                                    </Col>
                                    <Col lg={1} className="d-none d-lg-block width-content">
                                        :
                                    </Col>
                                    <Col xs={12} lg={6} className="mt-1-sm">
                                        {dataEducation.upa_number_certificate}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12}>
                        <Label className="fw-bold fs-5 mt-5">
                            Tarif Layanan
                        </Label>
                    </Col>
                    <Col xs={12}>
                        <ContentTableServiceRate dataTable={dataServiceRate} />
                    </Col>
                    <Col xs={12}>
                        <Label className="fw-bold fs-5 mt-5">
                            Masukan alasan jika menolak aktivasi
                        </Label>
                    </Col>
                    <Col xs={12}>
                        <Input
                            type="textarea"
                            className="off-resize-textarea"
                            onChange={(e) => setReasonRejected(e.target.value)}
                            maxLength="500"
                            rows="3"
                            placeholder="Masukan alasan anda, jika melakukan reject dengan maksimal 500 karakter"
                        />
                    </Col>
                    <Row className="mt-3">
                        <Col xs={6}>
                            <Button
                                color="primary"
                                className="btn-orange"
                                block
                                disabled={isLoadingReject || isLoadingApprove ? true : false}
                                onClick={() => handlerActivation('reject')}
                            >
                                Tolak
                            </Button>
                        </Col>
                        <Col xs={6}>
                            <Button
                                color="success"
                                block
                                disabled={isLoadingReject || isLoadingApprove ? true : false}
                                onClick={() => handlerActivation('approve')}
                            >
                                Terima
                            </Button>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Row>
    );
}

export default ContentFormActivationLawyer;
