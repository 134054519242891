import { useEffect, useState } from "react";
import { FaMoneyCheckDollar } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    Card,
    CardBody,
    Col,
    Row
} from "reactstrap";
import { authSetupSelector, setRefreshToken, setToken } from "../../../app/authSlice";
import { checkLengthKeys, parseToStar } from "../../../helpers/GlobalHelper";
import { usePostRefreshTokenMutation } from "../../auth/api/AuthAPI";
import { useGetListAccountBankQuery } from "../../payments/api/paymentAPI";
import ModalFormAddAccountBank from "./ModalFormAddAccountBank";
import { paymentSetupSelector, setDataListAccountBank, setEmptyListAccountBank, setMainAccountBank } from "../../../app/paymentSlice";

const WidgetBankAccount = () => {
    const [isModalAddAccountBank, setModalAddAccountBank] = useState(false);
    const [suggestDataAccountBank, setSuggestDataAccountBank] = useState('Belum Didaftarkan');
    const { sessions, refresh_token } = useSelector(authSetupSelector)
    const { accountBankMain } = useSelector(paymentSetupSelector)
    const dispatch = useDispatch()

    const [postRefreshToken] = usePostRefreshTokenMutation()
    const {
        data: dataBankAccount,
        refetch: refetchListBankAccount
    } = useGetListAccountBankQuery()


    const handlerRefreshSession = async () => {
        const resultGenerateNewToken = await postRefreshToken({ refresh_token }).unwrap()

        localStorage.setItem('refreshToken', resultGenerateNewToken.refreshToken)
        localStorage.setItem('token', resultGenerateNewToken.token)

        dispatch(setToken(resultGenerateNewToken.token))
        dispatch(setRefreshToken(resultGenerateNewToken.refreshToken))

        return true;
    }

    const hanlderFormAddAccountBank = () => {
        setModalAddAccountBank(!isModalAddAccountBank)
    }

    const handlerMainDataBankAccount = (param) => {
        if (param?.count < 1) {
            dispatch(setEmptyListAccountBank)
        } else {
            dispatch(setDataListAccountBank(!param ? [] : param.data))

            const getActiveAccountBank = !param ?
                []
                :
                param.data.filter((val) => {
                    if (val.is_main) {
                        return val
                    }
                })

            if (!getActiveAccountBank) {
                dispatch(setMainAccountBank({}))
            } else {
                dispatch(setMainAccountBank(getActiveAccountBank[0]))

            }
        }
    }

    const getListBankAccounts = async () => {
        await handlerRefreshSession()
        refetchListBankAccount()
    }

    useEffect(() => {
        const checkingObj = checkLengthKeys(sessions)
        if (checkingObj) {
            getListBankAccounts()
        }
    }, [sessions]);

    useEffect(() => {
        handlerMainDataBankAccount(dataBankAccount)
    }, [dataBankAccount]);

    useEffect(() => {
        if (!accountBankMain.bank_name || !accountBankMain.account_bank_number) {
            setSuggestDataAccountBank("Belum Didaftarkan")
        } else {
            const suggestTitle = parseToStar(accountBankMain.account_bank_number.toString(), 5)
            setSuggestDataAccountBank(suggestTitle)
        }
    }, [accountBankMain]);

    return <>
        <Card className='rounded rounded-4'>
            <CardBody>
                <div
                    className="d-flex align-items-center mb-3"
                >
                    <div className="avatar-sm me-3">
                        <span className="avatar-title rounded-4 bg-light bg-soft font-size-20 border border-1">
                            <FaMoneyCheckDollar
                                className='text-primary'
                            />
                        </span>
                    </div>
                    <h5 className="font-size-14 mb-0 text-secondary fw-medium">
                        Rekening
                    </h5>

                </div>
                <div className="text-primary mt-4">
                    <Row>
                        <Col xs={12} lg={10}>
                            <h4>
                                {suggestDataAccountBank}
                            </h4>
                        </Col>
                        <Col xs={12} lg={2} className='px-lg-0'>
                            <div className='d-flex px-0'>
                                <Button
                                    onClick={hanlderFormAddAccountBank}
                                    color='danger'
                                    className='btn-primary btn-sm d-block d-lg-none ms-1'
                                    block>
                                    Tambah
                                </Button>
                                <Button
                                    onClick={hanlderFormAddAccountBank}
                                    color='danger'
                                    className='btn-primary btn-sm d-none d-lg-block'>
                                    Tambah
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </div>
            </CardBody>
        </Card>
        <ModalFormAddAccountBank
            isModalAddAccountBank={isModalAddAccountBank} handlerToggle={hanlderFormAddAccountBank}
        />
    </>
}

export default WidgetBankAccount;
