import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Col, Modal, ModalBody, ModalFooter, Row } from "reactstrap";
import { setChoosedCategoryProgress } from "../../app/orderSlice";
import { useGetListCategoryOrderTimelineQuery } from "../../features/case/api/CaseAPI";
import ProgressMenuCard from "../card/ProgressMenu";

const ModalChooseContentProgressTimeline = ({ isOpen, toggle }) => {
    const [listCategoryTimeline, setListCategoryTimeline] = useState([]);
    const dispatch = useDispatch()

    const {
        data: dataListCategoryOrderTimeline,
        isLoading: loadingListCategoryOrderTimeline,
        refetch: refetchListCategoryOrderTimeline
    } = useGetListCategoryOrderTimelineQuery(
        {},
        {
            refetchOnMountOrArgChange: false,
            refetchOnReconnect: true
        });

    const handlerRefetchingList = async () => {
        refetchListCategoryOrderTimeline()
    }

    const handleChooseProgressTimeline = (val) => {
        dispatch(setChoosedCategoryProgress(val))
        toggle('show_validation')
    }

    const ContentListCategoryProgressTimeline = ({ data, isLoading }) => {
        if (isLoading) {
            <span className="text-center text-primary my-3 fst-italic">
                Sedang Memuat Pilihan...
            </span>
        } else {
            if (data.length < 1) {
                return <span className="text-center text-primary my-3 fst-italic">
                    Tidak ada pilihan yang dapat dipilih!
                </span>
            }

            if (data.length > 0) {
                return data.map((val) => {
                    return <Col lg={4} onClick={() => handleChooseProgressTimeline(val)}>
                        <div className="cursor-pointer">
                            <ProgressMenuCard
                                key={val.id}
                                {...val}
                            />
                        </div>
                    </Col>
                })
            }
        }
    }

    useEffect(() => {
        if (!dataListCategoryOrderTimeline) {
            setListCategoryTimeline([])
        } else {
            const { status } = dataListCategoryOrderTimeline;
            if (status === 'success') {
                setTimeout(() => {
                    setListCategoryTimeline(dataListCategoryOrderTimeline.data)
                }, 5000);
            } else {
                setListCategoryTimeline([])
            }
        }
    }, [dataListCategoryOrderTimeline]);

    useEffect(() => {
        if (isOpen) {
            handlerRefetchingList()
        }
    }, [isOpen]);

    useEffect(() => {
        if (loadingListCategoryOrderTimeline) {
            setListCategoryTimeline([])
        }
    }, [loadingListCategoryOrderTimeline]);

    return (
        <Modal
            className="custom-backdrop"
            isOpen={isOpen}
            toggle={toggle}
            keyboard={false}
            centered
            backdrop={false}
            size='xl'
        >
            <span className="text-center pt-4 pb-0 font-size-18 fw-bold">
                Pilih Tahapan Progress
            </span>
            <ModalBody>
                <div
                    className='mb-0 text-center'
                >
                    Silakan pilih kategori proses kasus yang akan dilaksanakan. Detail proses akan disimpan dan dipublikasikan pada klien. Anda bisa mengedit kembali informasi yang dipublikasikan selama kasus belum berstatus <span className="fw-bold">selesai</span>
                </div>
                <div className="border-top border-2 mt-3"></div>
                <Row className="mt-3 overflow-auto" style={{ maxHeight: '55vh' }}>
                    <ContentListCategoryProgressTimeline
                        data={listCategoryTimeline}
                        isLoading={loadingListCategoryOrderTimeline}
                    />
                    {/* {progressMenuList.map((item, index) => {
                        return (
                            <Col lg={4} key={"key_menu_" + index}>
                                <div
                                    className="cursor-pointer"
                                >
                                    <ProgressMenuCard
                                        title="Request Berkas/File"
                                        description={item.description}
                                        type={item.type}
                                        isActive={index === 0}
                                    />
                                </div>
                            </Col>
                        );
                    })} */}
                </Row>
            </ModalBody>
            <ModalFooter className="d-flex justify-content-center w-full">
                <Button color="primary" onClick={toggle} size="md">
                    Kembali
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default ModalChooseContentProgressTimeline;
