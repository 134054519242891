import { useEffect, useRef, useState } from "react";
import { BiSend } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import { authSetupSelector } from "../../../app/authSlice";
import { clearRoomChat, orderSetupSelector, resetActiveProgressTimeline, resetConditionShowingLeftCard, setChoosedCategoryProgress, setTypingMessage } from "../../../app/orderSlice";
import DivContentEditable from "../../../components/divContentEditable/DivContentEditable";
import CaseLayoutDivorce from "../../../components/layout/CaseLayoutDivorce";
import HelmetTitle from "../../../components/layout/HelmetTitle";
import { checkLengthKeys, dateNowToObj, decyrptData, encryptData, getDateNow, notify, randomString } from "../../../helpers/GlobalHelper";
import { sendMessage } from "../../../socket/Clients/ClientAction";
import CardChat from "../../chat/components/CardChat";
import ModalCanceledOrder from "./ModalCanceledOrder";
import ModalInfoApproveDivorce from "./ModalInfoApproveDivorce";
import ModalRejectOrder from "./ModalRejectOrder";
import { resetDataRating } from "../../../app/ratingSlice";

const DetailCaseDivorce = ({ roleName }) => {
    const [lengthBubbleChat, setLengthBubbleChat] = useState(0);
    const [statusOrder, setStatusOrder] = useState('');

    const {
        bubble_chats,
        room_id,
        detail_order_service,
        message,
    } = useSelector(orderSetupSelector)

    const { sessions } = useSelector(authSetupSelector)

    const dispatch = useDispatch()
    const navigate = useNavigate()


    const refSimpleBar = useRef();
    const setRefMsg = useRef(message);

    const handlerBackToListConsultation = () => {
        const elMsg = document.getElementById('input-message');
        if (elMsg) {
            elMsg.innerHTML = '';
        }

        dispatch(resetConditionShowingLeftCard())
        dispatch(resetActiveProgressTimeline())
        dispatch(setChoosedCategoryProgress(''))
        dispatch(setTypingMessage(''));
        dispatch(clearRoomChat())
        dispatch(resetDataRating())

        toast.dismiss()
        navigate("/case-client")
    }

    const handlerBackTo = () => {
        handlerBackToListConsultation()
    }

    const scrollToBottom = () => {
        setTimeout(() => {
            refSimpleBar.current?.scrollIntoView({ behavior: "smooth" })
        }, 3000);
    }

    const handlerResetMessage = () => {
        const elMsg = document.getElementById('input-message');
        elMsg.innerHTML = '';
    };

    const handlerKeyupMessage = (event) => {
        if (event.which === 13 && !event.shiftKey) {
            handleSendMessage();
            event.preventDefault();
        }
    }

    const handlerOnInputMessage = (event) => {
        if (statusOrder.toLowerCase() !== 'progress') {
            handlerResetMessage()
            return notify('warn', 3000, 'Anda tidak dapat mengetikan pesan karna bukan ditahap progres');
        } else {
            const element = event.target;
            const message = element.innerText;

            // typingMessage(chatId,true);
            handlerFocusInputChat()
            dispatch(setTypingMessage(message));
        }
    };

    const handlerFocusInputChat = () => {
        if (statusOrder.toLowerCase() === 'progress') {
            document.getElementById('input-message').focus();
        }
    };

    const handlerOnPasteMessage = (event) => {
        event.stopPropagation();
        event.preventDefault();

        let cbPayload = [
            ...(event.clipboardData || event.originalEvent.clipboardData).items,
        ];

        const dataFile = cbPayload[0].getAsFile();
        if (dataFile) {
            return notify('error', 5000, 'Tidak dapat menempel file yang anda harapkan');
        } else {
            let txtPaste = event.clipboardData.getData('text/plain');
            document.execCommand('insertText', false, txtPaste);
            const elMsg = document.getElementById('input-message');
        }
    };

    const handleSendMessage = () => {
        const lengthMsg = message
            .replace(/<\/div>/gi, '')
            .replace(/<br\s*[\/]?>/gi, '')
            .replace(/\s\s+/g, '')
            .replace(/&nbsp;/g, '')
            .replace(/\n/g, '')
        if (lengthMsg.length < 1) {
            notify('error', 3000, 'Mohon masukan pesan anda!');
            handlerResetMessage()
        } else if (lengthMsg.length > 300) {
            notify('error', 3000, 'Maksimal panjang pesan adalah 300!');
        } else {
            const getDate = dateNowToObj();
            const reformatMsg = message
                .replace(/<\/div>/gi, '')
                .replace(/<br\s*[\/]?>/gi, '\n')
                .replace(/&nbsp;/g, '')
                .trim();

            sendMessage({
                id: `BO${getDate.year}${getDate.month}${getDate.day}${getDate.hour}${getDate.minute
                    }${getDate.second}${randomString(15)}${detail_order_service.id}`,
                chat_id: room_id,
                message: encryptData(reformatMsg),
                is_file: false,
                file: [],
                from: sessions.id,
                created_at: getDateNow(),
                updated_at: getDateNow(),
            })
            handlerResetMessage()
        }
    }

    useEffect(() => {
        if (bubble_chats) {
            setLengthBubbleChat(bubble_chats.length);
        }
    }, [bubble_chats]);

    useEffect(() => {
        const elMsg = document.getElementById('input-message');
    }, [message]);

    useEffect(() => {
        handlerFocusInputChat()
        document
            .getElementById('input-message')
            .addEventListener('paste', handlerOnPasteMessage);
    }, []);

    useEffect(() => {
        dispatch(resetActiveProgressTimeline())
        const checLengthKey = checkLengthKeys(detail_order_service)
        if (checLengthKey) {
            setStatusOrder(detail_order_service.category_order_service_name)
            handlerFocusInputChat()
        } else {
            setStatusOrder('')
        }
    }, [detail_order_service]);

    return (
        <>
            <HelmetTitle
                title={`Detail Kasus | ${roleName}`}
                meta="detailkasus"
            />
            <CaseLayoutDivorce
                breadcrumbTitle="Kembali Ke List Kasus"
                isChat={true}
                handlerBackTo={handlerBackTo}
            >
                <div className="chat-content mb-3 mt-2 py-0">
                    <div className="chat-views gx-0 px-1 pt-4 row">
                        {bubble_chats?.map((val, key) => {
                            if (key === lengthBubbleChat - 1) {
                                scrollToBottom()
                            }
                            return <CardChat
                                key={key}
                                dataBubble={val}
                                sessions={sessions}
                                clientName={detail_order_service.client_name}
                                lawyerName={detail_order_service.lawyer_name}
                            >
                                {decyrptData(val.message)}
                            </CardChat>
                        })}
                        <div ref={refSimpleBar} />
                    </div>
                    <div className="input-box p-3 py-3">
                        {/* EditableDiv */}
                        <DivContentEditable
                            attrProps={{
                                id: 'input-message',
                                className: `bg-white w-100 chats-input border ps-3 w-75 pe-5 py-3`,
                                placeholder: 'Masukan pesan...',
                                contentEditable: true,
                            }}
                            handlerKey={handlerKeyupMessage}
                            handlerInput={handlerOnInputMessage}
                            setupRef={setRefMsg}
                        />
                        {
                            statusOrder.toLowerCase() === 'progress'
                            &&
                            <Button
                                className="icon-send bg-transparent border-0"
                                onClick={handleSendMessage}
                            >
                                <BiSend size={24} color="#E91E23" />
                            </Button>
                        }
                    </div>
                </div>
                {/* <ContentLeftSidebar condition_showing_left_card={condition_showing_left_card} /> */}
            </CaseLayoutDivorce>
            <ModalCanceledOrder />
            <ModalRejectOrder />
            <ModalInfoApproveDivorce />
        </>
    );
}

export default DetailCaseDivorce;
