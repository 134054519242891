import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';

import {
  globalSetupSelector,
  hideConfirmationModal,
} from '../../app/globalSlice';

const ModalConfirmation = (props) => {
  const dispatch = useDispatch();

  const globalState = useSelector(globalSetupSelector);
  const {
    confirmationModal: { isOpen, description, confirmText, cancelText, action },
  } = globalState || {};

  const toggle = () => {
    dispatch(hideConfirmationModal());
  };

  const performAction = async () => {
    await action();
    toggle();
  };
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      centered
      backdrop={false}
      size={'md'}
    >
      <ModalBody className="text-center">{description}</ModalBody>
      <ModalFooter className="d-flex justify-content-center w-full">
        <Button color="secondary" onClick={toggle}>
          {cancelText}
        </Button>
        <Button color="primary" onClick={performAction}>
          {confirmText}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalConfirmation;
