import { useSelector } from 'react-redux';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { orderSetupSelector } from '../../../app/orderSlice';
import { useEffect, useState } from 'react';
import { checkLengthKeys, reformatToIDRCurrency } from '../../../helpers/GlobalHelper';

const ModalListPriceServices = ({ isOpen, toggle }) => {
    const [listServices, setListServices] = useState([]);
    const { choose_lawyer } = useSelector(orderSetupSelector)

    useEffect(() => {
        const checkChooseLawyer = checkLengthKeys(choose_lawyer)
        if (checkChooseLawyer) {
            setListServices(choose_lawyer.service_rates)
        }
    }, [choose_lawyer]);

    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            centered
            backdrop={false}
            className="custom-backdrop"
        >
            <ModalHeader toggle={toggle}>
                List Tarif Layanan
            </ModalHeader>
            <ModalBody>
                {listServices.length < 1 && <span className='my-2 text-primary'>Tarif Tidak Ditemukan</span>}
                {listServices.length > 0 && listServices.map((val, key) => {
                    let priceService = 'Tidak aktif'
                    if (val.price > 0) {
                        priceService = reformatToIDRCurrency(val.price)
                    }
                    return <div key={key}>
                        <span className='me-1'>{key + 1}.</span>
                        <span>{val.category_service_name} (<b className='text-primary'>
                            {priceService}</b>)
                        </span>
                    </div>
                })}
            </ModalBody>
        </Modal>
    );
}

export default ModalListPriceServices;
