import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoadingRefetchSession: false,
    serviceRates: []
};

const profileSlice = createSlice({
    name: 'profileSetup',
    initialState,
    reducers: {
        updateStatusRefetchSession: (state, action) => {
            state.isLoadingRefetchSession = action.payload
        },
        updateServiceRateData: (state, action) => {
            state.serviceRates = action.payload
        }
    }
})

export const profileSetupSelector = (state) => state.profileSetup
export const {
    updateStatusRefetchSession,
    updateServiceRateData
} = profileSlice.actions
export default profileSlice.reducer;