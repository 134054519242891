import { useEffect, useRef, useState } from 'react';
import { BiSend } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';
import { authSetupSelector } from '../../../app/authSlice';
import {
  clearRoomChat,
  orderSetupSelector,
  resetActiveProgressTimeline,
  resetConditionShowingLeftCard,
  setChoosedCategoryProgress,
  setTypingMessage,
} from '../../../app/orderSlice';
import { resetDataRating } from '../../../app/ratingSlice';
import DivContentEditable from '../../../components/divContentEditable/DivContentEditable';
import CaseLayoutConsultation from '../../../components/layout/CaseLayoutConsultation';
import HelmetTitle from '../../../components/layout/HelmetTitle';
import {
  checkLengthKeys,
  dateNowToObj,
  decyrptData,
  diffTwoDate,
  encryptData,
  getDateNow,
  getLocalTimeFromUTC,
  notify,
  randomString,
} from '../../../helpers/GlobalHelper';
import { sendMessage } from '../../../socket/Clients/ClientAction';
import CardChat from '../../chat/components/CardChat';
import ModalCanceledOrder from './ModalCanceledOrder';
import ModalInfoApproveConsultation from './ModalInfoApproveConsultation';
import ModalRejectOrder from './ModalRejectOrder';
import { CONSULTATION_EXPIRATION } from '../../../constants/app.constants';

const DetailCaseConsultation = ({ roleName }) => {
  const [statusOrder, setStatusOrder] = useState('');
  const [isCountDownRunning, setIsCountDownRunning] = useState(false);

  const {
    room_id,
    detail_order_service,
    bubble_chats,
    message,
    consultation_sessions,
  } = useSelector(orderSetupSelector);
  const { sessions } = useSelector(authSetupSelector);

  const refSimpleBar = useRef();
  const setRefMsg = useRef(message);
  const interval = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const lengthBubbleChat = bubble_chats?.length;

  const scrollToBottom = () => {
    setTimeout(() => {
      refSimpleBar.current?.scrollIntoView({ behavior: 'smooth' });
    }, 3000);
  };

  const handlerResetMessage = () => {
    const elMsg = document.getElementById('input-message');
    elMsg.innerHTML = '';
  };

  const handlerKeyupMessage = (event) => {
    if (event.which === 13 && !event.shiftKey) {
      handleSendMessage();
      event.preventDefault();
    }
  };

  const handlerOnInputMessage = (event) => {
    if (statusOrder.toLowerCase() !== 'progress' || !isCountDownRunning) {
      handlerResetMessage();
      return notify(
        'warn',
        3000,
        'Anda hanya dapat mengetikan pesan ketika sesi konsultasi berjalan'
      );
    }

    const element = event.target;
    const message = element.innerText;
    dispatch(setTypingMessage(message));
  };

  const handlerFocusInputChat = () => {
    if (statusOrder.toLowerCase() === 'progress') {
      document.getElementById('input-message').focus();
    }
  };

  const handlerOnPasteMessage = (event) => {
    event.stopPropagation();
    event.preventDefault();

    let cbPayload = [
      ...(event.clipboardData || event.originalEvent.clipboardData).items,
    ];

    const dataFile = cbPayload[0].getAsFile();
    if (dataFile) {
      return notify(
        'error',
        5000,
        'Tidak dapat menempel file yang anda harapkan'
      );
    } else {
      let txtPaste = event.clipboardData.getData('text/plain');
      document.execCommand('insertText', false, txtPaste);
      const elMsg = document.getElementById('input-message');
    }
  };

  const handleSendMessage = () => {
    const lengthMsg = message
      .replace(/<\/div>/gi, '')
      .replace(/<br\s*[\/]?>/gi, '')
      .replace(/\s\s+/g, '')
      .replace(/&nbsp;/g, '')
      .replace(/\n/g, '');
    if (lengthMsg.length < 1) {
      notify('error', 3000, 'Mohon masukan pesan anda!');
      handlerResetMessage();
    } else if (lengthMsg.length > 300) {
      notify('error', 3000, 'Maksimal panjang pesan adalah 300!');
    } else {
      const getDate = dateNowToObj();
      const reformatMsg = message
        .replace(/<\/div>/gi, '')
        .replace(/<br\s*[\/]?>/gi, '\n')
        .replace(/&nbsp;/g, '')
        .trim();

      sendMessage({
        id: `BO${getDate.year}${getDate.month}${getDate.day}${getDate.hour}${
          getDate.minute
        }${getDate.second}${randomString(15)}${detail_order_service.id}`,
        chat_id: room_id,
        message: encryptData(reformatMsg),
        is_file: false,
        file: [],
        from: sessions.id,
        created_at: getDateNow(),
        updated_at: getDateNow(),
      });
      handlerResetMessage();
    }
  };

  const handlerBackToListConsultation = (intervalCaseLayout) => {
    const elMsg = document.getElementById('input-message');
    elMsg.innerHTML = '';

    dispatch(resetConditionShowingLeftCard());
    dispatch(resetActiveProgressTimeline());
    dispatch(setChoosedCategoryProgress(''));
    dispatch(resetDataRating());
    dispatch(setTypingMessage(''));
    dispatch(clearRoomChat());

    clearInterval(intervalCaseLayout);
    clearInterval(interval.current);
    toast.dismiss();
    navigate('/case-client');
  };

  useEffect(() => {
    const elMsg = document.getElementById('input-message');
  }, [message]);

  useEffect(() => {
    handlerFocusInputChat();
    document
      .getElementById('input-message')
      .addEventListener('paste', handlerOnPasteMessage);
  }, []);

  useEffect(() => {
    const checLengthKey = checkLengthKeys(detail_order_service);
    if (checLengthKey) {
      setStatusOrder(detail_order_service.category_order_service_name);
      handlerFocusInputChat();
    } else {
      setStatusOrder('');
    }
  }, [detail_order_service]);

  useEffect(() => {
    let getSessionConsultation = consultation_sessions.filter(
      (val) => val.is_active && !val.is_skip
    );
    if (getSessionConsultation.length > 0) {
      getSessionConsultation = getSessionConsultation[0];
      const dateConsultation = getLocalTimeFromUTC(
        getSessionConsultation.start_time
      );

      const idInterval = setInterval(() => {
        const compareDateConsulWithNow = diffTwoDate(
          getDateNow(),
          dateConsultation,
          'second'
        );

        if (
          compareDateConsulWithNow >= 0 &&
          compareDateConsulWithNow <= CONSULTATION_EXPIRATION
        ) {
          setIsCountDownRunning(true);
        } else if (compareDateConsulWithNow >= CONSULTATION_EXPIRATION) {
          setIsCountDownRunning(false);
        } else {
          setIsCountDownRunning(false);
        }
      }, 3000);

      interval.current = idInterval;
      return () => {
        clearInterval(interval.current);
      };
    } else {
      setIsCountDownRunning(false);
    }
  }, [consultation_sessions]);

  return (
    <>
      <HelmetTitle
        title={`Detail Kasus Konsultasi | ${roleName}`}
        meta="detailkasus"
      />
      <CaseLayoutConsultation
        breadcrumbTitle={'Kembali Ke List Konsultasi'}
        isChat
        handlerBackTo={handlerBackToListConsultation}
      >
        <div className="chat-content mb-3 mt-2 py-0">
          <div className="chat-views gx-0 px-1 pt-4 row">
            {bubble_chats?.map((val, key) => {
              if (key === lengthBubbleChat - 1) {
                scrollToBottom();
              }
              return (
                <CardChat
                  key={key}
                  dataBubble={val}
                  sessions={sessions}
                  clientName={detail_order_service.client_name}
                  lawyerName={detail_order_service.lawyer_name}
                >
                  {decyrptData(val.message)}
                </CardChat>
              );
            })}
            <div ref={refSimpleBar} />
          </div>
          <div className="input-box p-3 py-3">
            <DivContentEditable
              attrProps={{
                id: 'input-message',
                className: `bg-white w-100 chats-input border ps-3 w-75 pe-5 py-3`,
                placeholder: 'Masukan pesan...',
                contentEditable: true,
              }}
              handlerKey={handlerKeyupMessage}
              handlerInput={handlerOnInputMessage}
              setupRef={setRefMsg}
            />
            {isCountDownRunning && (
              <Button
                className="icon-send bg-transparent border-0"
                onClick={handleSendMessage}
              >
                <BiSend size={24} color="#E91E23" />
              </Button>
            )}
          </div>
        </div>
      </CaseLayoutConsultation>
      <ModalCanceledOrder />
      <ModalRejectOrder />
      <ModalInfoApproveConsultation />
    </>
  );
};

export default DetailCaseConsultation;
