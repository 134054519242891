import { io } from 'socket.io-client';
import { store } from '../app/store';
import ClientListner from './Clients/ClientListener';
const { VITE_SERVICE_WEB_SOCKET } = import.meta.env;

let socketInstance;

const Socket = () => {
  const state = store.getState();
  const { refresh_token } = state.authSetup;

  if (socketInstance) return socketInstance;

  socketInstance = new io(VITE_SERVICE_WEB_SOCKET, {
    withCredentials: true,
    autoConnect: false,
    allowEIO3: false,
    extraHeaders: {
      Authorization: !refresh_token ? '' : `Bearer ${refresh_token}`,
    },
  });

  ClientListner(socketInstance);
  return socketInstance;
};

export default Socket;
