import { useEffect, useState } from "react";
import { Helmet, HelmetData } from "react-helmet-async";
const { VITE_APP_TITLE } = import.meta.env;

const HelmetTitle = (props) => {
    const [titleContent, setTitleContent] = useState('');
    const [metaContent, setMetaContent] = useState('');
    const { title, meta } = props;
    const helmetData = new HelmetData({});

    useEffect(() => {
        if (title) {
            setTitleContent(`${title} | ${VITE_APP_TITLE}`)
        }

        setMetaContent(meta)
    }, [title, meta]);

    return (
        <Helmet helmetData={helmetData}>
            <title>{!titleContent ? VITE_APP_TITLE : titleContent}</title>
            <meta charset="UTF-8" />
            <meta name="description" content="Cerai Online Login" />
            <meta
                name="keywords"
                content={metaContent}
            />
            <meta name="author" content="cerai online" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        </Helmet>
    );
}

export default HelmetTitle;
