import { useEffect, useRef, useState } from "react";
import { BsFolder, BsImages } from "react-icons/bs";
import { FaFileDownload } from "react-icons/fa";
import { FaFile } from "react-icons/fa6";
import { MdDelete } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Button, CardTitle, Col, FormGroup, Input, Label, List, Row, Table } from "reactstrap";
import { orderSetupSelector, setIsRefetchingDetailProgressTimeline, setIsRefetchingListProgressTimeline } from "../../../app/orderSlice";
import EmptyFile from "../../../assets/images/empty-file.png";
import { listDescDetailTimeline } from "../../../docs/text";
import { getExtensionAllowedFile, notify } from "../../../helpers/GlobalHelper";
import FileDesc from "../../timelineDetails/components/FileDesc";
import ListDescription from "../../timelineDetails/components/ListDescription";
import { useDeleteConditionUploadDocumentMutation, useDeleteFileProgressTimelineMutation, usePostUploadFileInOrderTimelineMutation, usePutUpdateProgressOrderTimelineMutation } from "../api/CaseAPI";
import ModalFormRequestFile from "./ModalFormRequestFile";

const FormProgressTimeline = ({ isCondition, dataDetailProgress }) => {
    const [valueNameProgress, setValueNameProgress] = useState('');
    const [valuDescriptionProgress, setValuDescriptionProgress] = useState('');
    const [colorCountLengthDescription, setColorCountLengthDescription] = useState('');
    const [maxLengthDescription, setMaxLengthDescription] = useState(1000);
    const [isModalFormRequestDocument, setIsModalFormRequestDocument] = useState(false);
    const [dataFile, setDataFile] = useState([]);
    const [isEnd, setIsEnd] = useState(false);
    const inputRef = useRef(null);
    const dispatch = useDispatch();

    const {
        detail_order_service,
    } = useSelector(orderSetupSelector)

    const [
        postUploadFileInOrderTimeline,
        {
            isError: isErrorUploadFileInOrderTimeline,
            isSuccess: isSuccessUploadFileInOrderTimeline,
            error: errorUploadFileInOrderTimeline,
        }
    ] = usePostUploadFileInOrderTimelineMutation();

    const [
        putUpdateProgressOrderTimeline,
        {
            isLoading: isLoadingUpdateProgressTimeline,
            isError: isErrorUpdateProgressTimeline,
            isSuccess: isSuccessUpdateProgressTimeline,
        }
    ] = usePutUpdateProgressOrderTimelineMutation();

    const [
        deleteConditionUploadDocument,
        {
            isLoading: isLoadingDeleteConditionUploadDocument,
            isError: isErrorDeleteConditionUploadDocument,
            error: errorDeleteConditionUploadDocument,
            isSuccess: isSuccessDeleteConditionUploadDocument,
        }
    ] = useDeleteConditionUploadDocumentMutation();

    const [
        deleteFileProgressTimeline,
        {
            isLoading: isLoadingDeleteFileProgressTimeline,
            isError: isErrorDeleteFileProgressTimeline,
            error: errorDeleteFileProgressTimeline,
            isSuccess: isSuccessDeleteFileProgressTimeline,
        }
    ] = useDeleteFileProgressTimelineMutation();

    // const handlerRefetchListFile = async () => {
    //     const getData = await getListFileByIdProgressTimelineNoCache({ id_order_timeline: dataDetailProgress.id }).unwrap()

    //     if (checkLengthKeys(getData)) {
    //         const { status, data } = getData;
    //         if (status === 'success') {
    //             setDataFile(data);
    //         }
    //     }
    // }

    const handlerUpdate = async () => {
        if (!valueNameProgress) {
            return notify('warn', 30000, 'Judul Progress wajib di isi!');
        }

        if (valuDescriptionProgress.length > maxLengthDescription) {
            return notify('warn', 30000, `Panjang deskripsi tidak boleh dari ${maxLengthDescription} karakter`);
        }

        const param = {
            id: dataDetailProgress.id,
            custom_title: valueNameProgress,
            description: valuDescriptionProgress
        }

        await putUpdateProgressOrderTimeline(param).unwrap()
    }

    const handlerDeleteFile = async (param) => {
        if (!isLoadingDeleteConditionUploadDocument && !isLoadingDeleteFileProgressTimeline) {
            const paramReq = {
                id_file: param.id
            }

            if (isCondition === 'form_request_file') {
                await deleteConditionUploadDocument(paramReq).unwrap();
            } else {
                await deleteFileProgressTimeline(paramReq).unwrap();
            }
        } else {
            return notify('error', 30000, 'Sedang memproses permintaan sebelumnya!');
        }
    }

    const handlerUploadFile = async (event) => {
        if (event.target.files.length > 0) {
            const getExtensionFileAllowed = getExtensionAllowedFile('word-ppt-img-vid-pdf');
            if (!getExtensionFileAllowed.includes(event.target.files[0].type)) {
                return notify('warn', 30000, 'Ektensi file yang anda masukan tidak diperbolehkan!');
            } else {
                const param = {
                    file: event.target.files[0],
                    id_order_timeline: dataDetailProgress.id,
                    file_name: ''
                }
                await postUploadFileInOrderTimeline(param).unwrap();
            }
        }
    }

    const ContentUploadFile = () => {
        return <Row className="mt-4">
            <Col sm={6} className="mb-5">
                <FileDesc title="Upload dokumentasi / berkas">
                    <Button
                        onClick={() => {
                            inputRef.current?.click();
                        }}
                        className="border-0 w-100 file_upload file_button p-3 d-flex flex-column align-items-center justify-content-center gap-3"
                    >
                        <BsImages size={42} color="#438FFE" />
                        <span className="text-black">
                            Browes image / Video / Document
                        </span>
                    </Button>
                    <input
                        ref={inputRef}
                        type="file"
                        hidden
                        onChange={handlerUploadFile}
                        accept="image/*,.pdf,video/*,.ppt,.pptx,.doc,.docx"
                    />
                </FileDesc>
            </Col>
            <Col>
                <FileDesc title="Tips & Info" type="desc">
                    <div className="border-0 w-100 file_upload file_button p-2">
                        <List className="mt-3">
                            {listDescDetailTimeline.map((item) => (
                                <ListDescription
                                    key={item.key}
                                    listTitle={item.title}
                                    listDesc={item.desc}
                                />
                            ))}
                        </List>
                    </div>
                </FileDesc>
            </Col>
        </Row>
    }

    const ContentListFile = ({ isCondition, dataFile, isEnd }) => {
        if (dataFile.length > 0) {
            return <div className="table-responsive">
                <Table className="table mt-4">
                    <thead className="table-primary">
                        <tr>
                            <th className="fw-bold ps-5 text-dark opacity-75">
                                Dokumen
                            </th>
                            <th className="fw-bold ps-5 text-center text-dark opacity-75">
                                Tipe File
                            </th>
                            <th className="fw-bold text-center text-dark opacity-75"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {dataFile?.map((val) => {
                            const fileName = isCondition === 'form_create_information' ? val.file_name : val.setup_file_name
                            const extensionAllowed = isCondition === 'form_create_information' ? val.extension_file_uploaded : 'Video/Foto/Dokumen'
                            return <tr key={val.id}>
                                <td className="ps-5 py-3 fw-semibold">
                                    {fileName}
                                </td>
                                <td className="ps-5 text-center py-3 fw-semibold">
                                    {!extensionAllowed ? '-' : extensionAllowed}
                                </td>
                                <td className="py-3">
                                    <div className="d-flex gap-3 justify-content-center">
                                        {
                                            val.url
                                            &&
                                            <FaFileDownload
                                                onClick={() => {
                                                    const redirectUrl = window.open();
                                                    redirectUrl.location.href = val.url;
                                                }}
                                                size={24}
                                                className="cursor-pointer text-primary"
                                                title="download file ini"
                                            />
                                        }

                                        {
                                            !val.url
                                            &&
                                            <FaFile
                                                size={24}
                                                className="text-secondary"
                                                title="belum ada file"
                                            />
                                        }

                                        {
                                            !isEnd
                                            &&
                                            <MdDelete
                                                onClick={() => handlerDeleteFile(val)}
                                                size={24}
                                                className="cursor-pointer text-orange"
                                                title="hapus file ini"
                                            />
                                        }
                                    </div>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </Table>
            </div>
        } else {
            return <div className="text-center d-flex justify-content-center flex-column align-items-center">
                <img src={EmptyFile} className="empty-file" />
                <span>Tidak ada dokumen</span>
            </div>
        }
    }

    useEffect(() => {
        if (detail_order_service.category_order_service_name === 'end_case') {
            setIsEnd(true);
        } else {
            setIsEnd(false);
        }
    }, [detail_order_service]);

    useEffect(() => {
        setValueNameProgress(dataDetailProgress.custom_title)
        setValuDescriptionProgress(dataDetailProgress.description)
        setDataFile(dataDetailProgress.file)
    }, [dataDetailProgress]);

    useEffect(() => {
        if (valuDescriptionProgress.length <= maxLengthDescription / 2) {
            setColorCountLengthDescription('text-success')
        } else if (valuDescriptionProgress.length >= maxLengthDescription / 2 && valuDescriptionProgress.length < maxLengthDescription) {
            setColorCountLengthDescription('text-warning')
        } else {
            setColorCountLengthDescription('text-orange')
        }
    }, [valuDescriptionProgress]);

    useEffect(() => {
        if (isErrorUpdateProgressTimeline) {
            notify('error', 30000, 'Terjadi kesalahan saat memproses');
        }
    }, [isErrorUpdateProgressTimeline]);

    useEffect(() => {
        if (isSuccessUpdateProgressTimeline) {
            dispatch(setIsRefetchingListProgressTimeline(true))
            dispatch(setIsRefetchingDetailProgressTimeline(true))
            notify('success', 30000, 'Berhasil mengupdate');
        }
    }, [isSuccessUpdateProgressTimeline]);

    useEffect(() => {
        if (isSuccessDeleteConditionUploadDocument) {
            // dispatch(setIsRefetchingListFileProgressTimeline(true))
            dispatch(setIsRefetchingDetailProgressTimeline(true))
            notify('success', 30000, 'Berhasil menghapus request file');
        }
    }, [isSuccessDeleteConditionUploadDocument]);

    useEffect(() => {
        if (isSuccessDeleteFileProgressTimeline) {
            // dispatch(setIsRefetchingListFileProgressTimeline(true))
            dispatch(setIsRefetchingDetailProgressTimeline(true))
            notify('success', 30000, 'Berhasil menghapus file yang sudah di upload');
        }
    }, [isSuccessDeleteFileProgressTimeline]);

    useEffect(() => {
        if (isErrorDeleteConditionUploadDocument) {
            notify('error', 30000, errorDeleteConditionUploadDocument.data.message);
        }
    }, [isErrorDeleteConditionUploadDocument]);

    useEffect(() => {
        if (isErrorDeleteFileProgressTimeline) {
            notify('error', 30000, errorDeleteFileProgressTimeline.data.message);
        }
    }, [isErrorDeleteFileProgressTimeline]);

    useEffect(() => {
        if (isSuccessUploadFileInOrderTimeline) {
            dispatch(setIsRefetchingDetailProgressTimeline(true))
            notify('success', 30000, 'Berhasil mengupload file');
        }
    }, [isSuccessUploadFileInOrderTimeline]);

    useEffect(() => {
        if (isErrorUploadFileInOrderTimeline) {
            notify('error', 30000, errorUploadFileInOrderTimeline.data.message);
        }
    }, [isErrorUploadFileInOrderTimeline]);

    return <>
        <CardTitle className="fw-bold font-size-14">Judul Progress</CardTitle>
        <Row>
            <div className="pb-1 border-bottom border-primary mt-2">
                <Input
                    placeholder={"Masukan Judul Progress"}
                    value={valueNameProgress}
                    onChange={(e) => setValueNameProgress(e.target.value)}
                    className="border-0 p-0 py-2 bg-transparent"
                    disabled={false}
                />
            </div>
            <div className="font-size-10 mt-1 ms-0 text-secondary">
                *Judul dapat anda ubah sesuai kebutuhan di lapangan, contoh: Daftar Gugatan 1
            </div>
        </Row>

        {isCondition === 'form_create_information' && !isEnd ? <ContentUploadFile /> : <></>}

        <Row className="mt-4">
            <div className="d-flex align-items-center gap-3">
                <BsFolder size={20} color="#F54748" />
                <span className="font-size-14 fw-bold">Dokumen Terlampir</span>
            </div>
            <ContentListFile isCondition={isCondition} dataFile={dataFile} isEnd={isEnd} />
        </Row>

        <FormGroup className="w-100 h-25">
            <Label for="forDescription">
                <span className="font-size-14 fw-bold">
                    Deskripsi
                </span>
            </Label>
            <textarea
                placeholder="Masukkan informasi terkait persidangan seperti lokasi, nama yang terlibat, hasil putusan, dan lainnya"
                onChange={(e) => setValuDescriptionProgress(e.target.value)}
                className="form-control description_input bg-white py-2"
                value={valuDescriptionProgress}
            ></textarea>
            <p className="mt-2">
                Maksimal Panjang Deskripsi: {' '}
                <span className={`${colorCountLengthDescription} fw-bold`}>
                    {valuDescriptionProgress.length}
                </span>
                /
                <span className="text-orange fw-bold">
                    {maxLengthDescription}
                </span>
            </p>
        </FormGroup>
        <div className="d-flex justify-content-between gap-3 mt-3">
            {
                isCondition === 'form_request_file' && !isEnd
                    ?
                    <Button
                        onClick={() => setIsModalFormRequestDocument(!isModalFormRequestDocument)}
                        className="rounded-3"
                        color="danger"
                        outline
                        block
                        disabled={isLoadingUpdateProgressTimeline}
                    >
                        Tambah Jenis Dokumen
                    </Button>
                    :
                    <></>
            }
            {
                !isEnd
                &&
                <Button
                    onClick={handlerUpdate}
                    className="rounded-3"
                    color="danger"
                    block
                    disabled={isLoadingUpdateProgressTimeline}
                >
                    {isLoadingUpdateProgressTimeline ? 'Memproses...' : 'Simpan'}
                </Button>
            }

        </div>
        <ModalFormRequestFile
            isOpen={isModalFormRequestDocument}
            toggle={() => setIsModalFormRequestDocument(!isModalFormRequestDocument)}
        />
    </>
}

export default FormProgressTimeline;
