import { useEffect, useState } from "react";
import { useGetVillagesQuery } from "../../features/zones/api/ZoneApi";
import Select from 'react-select';

const SelectorVillages = ({
    districtId = '',
    onChange,
    value = '',
    isHaveParent = false
}) => {
    const [listVillages, setListVillages] = useState([]);
    const [defaultValue, setDefaultValue] = useState({ value: '', label: '' });

    const { data } = useGetVillagesQuery(districtId);

    useEffect(() => {
        if (isHaveParent) {
            if (data && districtId) {
                const option = data.data.map(({ id: value, name: label }) => ({
                    value, label
                }))

                setListVillages(option)
            } else {
                setListVillages([])
            }
        } else {
            if (data) {
                const option = data.data.map(({ id: value, name: label }) => ({
                    value, label
                }))

                setListVillages(option)
            } else {
                setListVillages([])
            }
        }

    }, [data, isHaveParent, districtId]);

    useEffect(() => {
        if (isHaveParent) {
            if (value && districtId) {
                const resultFind = listVillages.find((param) => param.value === value)
                setDefaultValue(resultFind)
            } else {
                setDefaultValue({ value: '', label: '' })
            }
        } else {
            if (value) {
                const resultFind = listVillages.find((param) => param.value === value)
                setDefaultValue(resultFind)
            } else {
                setDefaultValue({ value: '', label: '' })
            }
        }
    }, [value, listVillages, isHaveParent, districtId]);

    return (
        <>
            <Select
                onChange={onChange}
                value={defaultValue}
                isSearchable={true}
                options={listVillages}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: "0.25rem",
                    colors: {
                        ...theme.colors,
                        primary25: '#FFEDED',
                        primary: '#E91E23',
                    },
                })}
            />
        </>
    );
}

export default SelectorVillages;
