import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Col, Form, Input, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import * as yup from "yup";
import SelectorListBanks from "../../../components/form/SelectorListBanks";
import { Controller, useForm } from "react-hook-form";
import { checkLengthKeys, notify } from "../../../helpers/GlobalHelper";
import { useGetListAccountBankQuery, usePostDataAccountBankMutation } from "../../payments/api/paymentAPI";
import { useDispatch, useSelector } from "react-redux";
import { authSetupSelector, setRefreshToken, setToken } from "../../../app/authSlice";
import { usePostRefreshTokenMutation } from "../../auth/api/AuthAPI";
import { useEffect } from "react";
import { setDataListAccountBank, setEmptyListAccountBank, setMainAccountBank } from "../../../app/paymentSlice";
import { toast } from "react-toastify";

const ModalFormAddAccountBank = ({
    isModalAddAccountBank,
    handlerToggle
}) => {
    const dispatch = useDispatch()

    const { refresh_token } = useSelector(authSetupSelector)

    const [postRefreshToken] = usePostRefreshTokenMutation()

    const {
        data: dataBankAccount,
        refetch: refetchListBankAccount
    } = useGetListAccountBankQuery()

    const [
        postDataAccountBank,
        {
            isLoading: loadingAddAccountBank,
            isSuccess: isSuccessAccountBank,
            isError: isErrorBankAccount,
            error: errorBankAccount
        }
    ] = usePostDataAccountBankMutation()

    const schema = yup.object({
        id_bank: yup.string().required('Bank wajib di pilih!'),
        user_account_name: yup.string().required('Nama pemilik rekening wajib di isi!'),
        user_account_number: yup.string().required('Nomor rekening wajib di isi!').min(8, 'Minimal 8 digit'),
    })

    const {
        control,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            id_bank: "",
            user_account_name: "",
            user_account_number: "",
        },
    });

    const handlerRefreshSession = async () => {
        const resultGenerateNewToken = await postRefreshToken({ refresh_token }).unwrap()

        localStorage.setItem('refreshToken', resultGenerateNewToken.refreshToken)
        localStorage.setItem('token', resultGenerateNewToken.token)

        dispatch(setToken(resultGenerateNewToken.token))
        dispatch(setRefreshToken(resultGenerateNewToken.refreshToken))

        return true;
    }

    const handlerMainDataBankAccount = (param) => {
        if (param?.count < 1) {
            dispatch(setEmptyListAccountBank)
        } else {
            dispatch(setDataListAccountBank(!param ? [] : param.data))

            const getActiveAccountBank = !param ?
                []
                :
                param.data.filter((val) => {
                    if (val.is_main) {
                        return val
                    }
                })

            if (!getActiveAccountBank) {
                dispatch(setMainAccountBank({}))
            } else {
                dispatch(setMainAccountBank(getActiveAccountBank[0]))
            }
        }
    }

    const handleOnSubmit = async (param) => {
        await handlerRefreshSession()
        param.id_bank = parseInt(param.id_bank)
        param.user_account_number = parseInt(param.user_account_number)
        await postDataAccountBank(param).unwrap()
    }

    const getListBankAccounts = async () => {
        await handlerRefreshSession()
        refetchListBankAccount()
    }

    useEffect(() => {
        if (loadingAddAccountBank) {
            notify('loading', false, 'Sedang menambahkan no rekening');
            reset({
                id_bank: "",
                user_account_name: "",
                user_account_number: "",
            })
            handlerToggle()
        } else {
            toast.dismiss()
        }
    }, [loadingAddAccountBank]);

    useEffect(() => {
        if (isSuccessAccountBank) {
            getListBankAccounts()
            notify('success', 30000, 'Berhasil menambahkan rekening bank');
        }
    }, [isSuccessAccountBank]);

    useEffect(() => {
        if (isErrorBankAccount) {
            new Promise(() => {
                return notify('error', 50000, errorBankAccount.data.message);
            })
        }
    }, [isErrorBankAccount, errorBankAccount]);

    useEffect(() => {
        handlerMainDataBankAccount(dataBankAccount)
    }, [dataBankAccount]);

    return <Modal
        className="custom-backdrop"
        isOpen={isModalAddAccountBank}
        toggle={handlerToggle}
        backdrop={false}
    >
        <ModalHeader toggle={handlerToggle}>
            Tambah Rekening Baru
        </ModalHeader>
        <ModalBody>
            <Form onSubmit={handleSubmit(handleOnSubmit)}>
                <Row>
                    <Col xs={12}>
                        <Label>
                            Nama Bank
                            <span className="text-orange">
                                *
                            </span>
                        </Label>
                    </Col>
                    <Col xs={12}>
                        <div className="mb-3">
                            <Controller
                                name='id_bank'
                                control={control}
                                render={({ field: { onChange, value } }) =>
                                (
                                    <SelectorListBanks
                                        onChange={
                                            (event) => onChange(
                                                checkLengthKeys(event)
                                                    ?
                                                    event.value
                                                    :
                                                    ''
                                            )
                                        }
                                        value={value}
                                    />
                                )}
                            />
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div className="mb-3">
                            <span className="mt-2 text-orange text-start">
                                {errors.id_bank?.message}
                            </span>
                        </div>
                    </Col>
                    <Col xs={12}>
                        <Label>
                            Nama Pemilik Rekening
                            <span className="text-orange">
                                *
                            </span>
                        </Label>
                    </Col>
                    <Col xs={12}>
                        <div className="mb-3">
                            <Controller
                                name='user_account_name'
                                control={control}
                                render={({ field: { onChange, onBlur, value } }) =>
                                (
                                    <Input
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        value={value}
                                        placeholder="Masukan nama pemilik rekening"
                                    />
                                )}
                            />
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div className="mb-3">
                            <span className="mt-2 text-orange text-start">
                                {errors.user_account_name?.message}
                            </span>
                        </div>
                    </Col>
                    <Col xs={12}>
                        <Label>
                            Nomor Rekening
                            <span className="text-orange">
                                *
                            </span>
                        </Label>
                    </Col>
                    <Col xs={12}>
                        <div className="mb-3">
                            <Controller
                                name='user_account_number'
                                control={control}
                                render={({
                                    field: { onChange, onBlur, value },
                                }) => {
                                    const handlerReplaceString = (event) => {
                                        const value = event.target.value?.replace(/\D/g, "")
                                        onChange(value)
                                    }

                                    return <input
                                        onChange={handlerReplaceString}
                                        onBlur={onBlur}
                                        value={value}
                                        type="text"
                                        className="form-control"
                                        placeholder="masukan no rekening anda"
                                    />
                                }}
                            />
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div className="mb-3">
                            <span className="mt-2 text-orange text-start">
                                {errors.user_account_number?.message}
                            </span>
                        </div>
                    </Col>
                    <Col xs={12}>
                        <Button
                            type="submit"
                            color="primary"
                            className="btn-primary"
                            block
                        >
                            Simpan
                        </Button>
                    </Col>
                </Row>
            </Form>
        </ModalBody>
    </Modal>
}

export default ModalFormAddAccountBank;
