import { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, CardBody, Col, FormGroup, Input, Label, Modal, ModalBody, Row } from "reactstrap";
import { authSetupSelector, setRefreshToken, setToken } from "../../../app/authSlice";
import { orderSetupSelector, setCaseId, updateReasonDivorce } from "../../../app/orderSlice";
import { convertDataToNumber, notify, reformatDate } from "../../../helpers/GlobalHelper";
import { usePostRefreshTokenMutation } from "../../auth/api/AuthAPI";
import { usePostOrderDivorcedMutation } from "../../case/api/CaseAPI";

const ModalReasonDivorce = ({ isOpen, toggle }) => {
    const [positionCaret, setPositionCaret] = useState('');
    const inputReference = useRef(null);
    const dispatch = useDispatch()
    const { sessions, refresh_token } = useSelector(authSetupSelector)
    const { reason_divorce, data_profile, pick_service_id } = useSelector(orderSetupSelector)

    const [postRefreshToken, { isLoading: isLoadingRefetchSession }] = usePostRefreshTokenMutation()
    const [postOrderDivorced, {
        isLoading: isLoadingOrderDivorced,
        isSuccess: isSuccessOrderDivorce,
        isError: isErrorOrderDivorce,
        data: dataOrderDivorce
    }] = usePostOrderDivorcedMutation()

    const handlerActionModal = () => {
        toggle('data_personal_information')
    }

    const handlerRefreshSession = async () => {

        const resultGenerateNewToken = await postRefreshToken({ refresh_token }).unwrap()

        localStorage.setItem('refreshToken', resultGenerateNewToken.refreshToken)
        localStorage.setItem('token', resultGenerateNewToken.token)

        dispatch(setToken(resultGenerateNewToken.token))
        dispatch(setRefreshToken(resultGenerateNewToken.refreshToken))
        return true;
    }

    const handlerNext = async () => {
        // toggle('reason_divorce')
        const getActiveReason = reason_divorce.filter((val) => val.is_checked);
        const getCustomField = reason_divorce.filter((val) => val.type === 'custom');

        if (getActiveReason.length < 1) {
            return notify('warn', 30000, 'Anda harus memasukan alasan memilih layanan ini!');
        }

        if (getCustomField[0].is_checked) {
            if (!getCustomField[0].reason) {
                return notify('warn', 30000, 'Sepertinya anda menceklis lainnya, mohon berikan alasannya!');
            }
        }

        const mapReason = []

        await handlerRefreshSession()

        await Promise.all(reason_divorce.map((val) => {
            if (val.is_checked) {
                return mapReason.push(val.reason)
            }
        }))

        const param = {
            full_name: sessions.full_name,
            email: sessions.email,
            phone_number: sessions.phone_number,
            place_birth: data_profile.place_birth,
            date_birth: reformatDate(data_profile.date_birth),
            profession: data_profile.profession,
            qualification: data_profile.qualification,
            number_kyc: data_profile.number_kyc,
            religion: data_profile.religion,
            address_kyc: data_profile.address_kyc,
            ward_kyc: convertDataToNumber(data_profile.ward_kyc),
            town_kyc: convertDataToNumber(data_profile.town_kyc),
            city_kyc: convertDataToNumber(data_profile.city_kyc),
            province_kyc: convertDataToNumber(data_profile.province_kyc),
            postal_code_kyc: data_profile.postal_code_kyc,
            address_domicile: data_profile.address_domicile,
            ward_domicile: convertDataToNumber(data_profile.ward_domicile),
            town_domicile: convertDataToNumber(data_profile.town_domicile),
            city_domicile: convertDataToNumber(data_profile.city_domicile),
            province_domicile: convertDataToNumber(data_profile.province_domicile),
            postal_code_domicile: data_profile.postal_code_domicile,
            id_service_rate: convertDataToNumber(pick_service_id),
            reason_divorced: mapReason
        }

        await postOrderDivorced(param).unwrap();
    }

    const handlerCheckReason = (param) => {
        const { id, is_checked } = param
        const newStatus = !is_checked;
        const mapNewData = reason_divorce.map((val) => {
            if (val.type === 'custom' && val.id === id) {
                return {
                    ...val,
                    reason: '',
                    is_checked: newStatus
                }
            } else {
                if (val.id === id) {
                    return {
                        ...val,
                        is_checked: newStatus
                    }
                } else {
                    return val
                }
            }
        })

        dispatch(updateReasonDivorce(mapNewData))
    }

    const handlerTypeReason = (e) => {
        const inputValue = e.target.value
        const mapNewData = reason_divorce.map((val) => {
            if (val.type === 'custom') {
                return {
                    ...val,
                    reason: inputValue
                }
            } else {
                return val
            }
        })

        const input = document.getElementById("reason-content");
        setPositionCaret(!input.selectionStart ? 0 : input.selectionStart);
        dispatch(updateReasonDivorce(mapNewData))
    }

    useEffect(() => {
        if (reason_divorce[5].is_checked) {
            const input = document.getElementById("reason-content");
            if (input) {
                input.focus();
                input.setSelectionRange(positionCaret, positionCaret);
            }
        }
    }, [reason_divorce]);

    useEffect(() => {
        if (isSuccessOrderDivorce) {
            const { data } = dataOrderDivorce;
            dispatch(setCaseId(data.case_id))
            toggle('success')
        }
    }, [isSuccessOrderDivorce, dataOrderDivorce]);

    useEffect(() => {
        if (isErrorOrderDivorce) {
            toggle('failed')
        }
    }, [isErrorOrderDivorce]);


    const ContentReason = ({ data }) => {
        return data.map((val) => {
            if (val.type === 'default') {
                return <Col key={val.id} xs={12}>
                    <Card>
                        <CardBody className="bg-light">
                            <FormGroup check>
                                <Input
                                    onChange={() => handlerCheckReason(val)}
                                    type="checkbox"
                                    className="me-3"
                                    checked={val.is_checked}
                                />
                                <Label
                                    check
                                    onClick={() => handlerCheckReason(val)}
                                >
                                    {val.reason}
                                </Label>
                            </FormGroup>
                        </CardBody>
                    </Card>
                </Col>
            } else {
                return <Fragment key={val.id}>
                    <Col xs={12}>
                        <Card>
                            <CardBody className="bg-light">
                                <FormGroup check>
                                    <Input
                                        onChange={() => handlerCheckReason(val)}
                                        type="checkbox"
                                        className="me-3"
                                        checked={val.is_checked}
                                    />
                                    <Label
                                        check
                                        onClick={() => handlerCheckReason(val)}
                                    >
                                        Lainnya
                                    </Label>
                                </FormGroup>
                                {
                                    val.is_checked && <Input
                                        onInput={handlerTypeReason}
                                        style={{ resize: 'none' }}
                                        id="reason-content"
                                        className="mt-2"
                                        type="textarea"
                                        value={val.reason}
                                        ref={inputReference}
                                        placeholder="Masukan alasan pribadi anda"
                                    />
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Fragment>
            }
        })
    }

    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            centered
            keyboard={false}
            backdrop={false}
            className="border-0 custom-backdrop"
            size='lg'
        >
            <ModalBody>
                <div className="text-center fw-bold mb-2">
                    Berikan alasan mengapa memilih layanan ini? (Tahap 2)
                </div>
                <div className="text-center font-size-12 fw-light mb-2">
                    Silakan pilih alasan di bawah ini, Anda bisa memilih lebih dari satu
                </div>
                <div className="border-bottom mb-3"></div>
                <Row className="px-3">
                    <ContentReason data={reason_divorce} />
                    <Col xs={12} className="text-center px-0 mt-3">
                        <div className="d-lg-flex justify-content-lg-center">
                            <Button
                                disabled={isLoadingOrderDivorced}
                                onClick={handlerActionModal}
                                block
                                color="primary"
                                className="btn-outline-secondary mx-2 mb-2 fw-bold"
                                outline
                            >
                                Kembali
                            </Button>
                            <Button
                                disabled={isLoadingOrderDivorced}
                                onClick={handlerNext}
                                block
                                color="primary"
                                className="btn-primary mx-2 mb-2"
                            >
                                Lanjutkan
                            </Button>
                        </div>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    );
}

export default ModalReasonDivorce;
