export const CategoryOrderService = {
  new_case: 1,
  canceled_by_system: 2,
  rejected_from_lawyer: 3,
  end_case: 4,
  waiting_payout: 5,
  progress: 6,
  reschedule_consultation: 7,
  new_consultation: 8,
  reject_consultation: 9,
  canceled_by_client: 10,
  not_yet_request: 11,
  request_end_case_to_client: 12,
};
